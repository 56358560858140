import { Box, Card, Dialog, DialogContent, Grid2, IconButton, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableContainerComp from "../../Component/TableContainer";
import { Link } from "react-router-dom";
import { Beenhere, BookmarkAdded, Check, Clear, NotInterestedOutlined, RateReview } from "@mui/icons-material";
import { commonCss } from "../../StyleConfig/CommonCss";
import CircleIcon from '@mui/icons-material/Circle';
import { api, companyInfo, roleInfo } from "../API/config";
import { axiosPrivate } from "../../axios/axios";
import moment from "moment";
import NewForm from "./NewForm";
import { useDispatch, useSelector } from "react-redux";
import { permitStatus } from "../../Redux/data";

const GetOeControllerList = () => {

  const login = useSelector((state) => state.auth.loginInfo);
  const loginRoleId=String(login.roleIds).split(",")
  

  const dispatch=useDispatch()

  const [data, setData] = useState([]);
  const [newForm,setNewForm]=useState(false)
  const [pendingValue,setPendingValue]=useState(true)
  const [count,setCount]=useState({
    new:[],
    pending:[],
    approved:[],
    reject:[],
    cancel:[],
    close:[]
  })
  
  const [backUp,setBackUp]=useState([])
  const [roleAccess,setRoleAccess]=useState({
    admin:loginRoleId.includes(roleInfo.admin),
    adminContractTech:loginRoleId.includes(roleInfo.admin)||loginRoleId.includes(roleInfo.contractStaff)||loginRoleId.includes(roleInfo.technican)
  })

  useEffect(() => {
     getData(pendingValue)
  }, []);

  const getData=(valuePending)=>{
    const getForm=api.PTWForm.getOeControllerForm
   
   
    axiosPrivate.get((`${getForm}?isPendingOnly=${valuePending}`)).then(res=>{
       console.log(res)
       const resData=res.data.data
      const newFilter=resData.filter(fil=>fil.formStatus===1)
      const pendingFilter=resData.filter(fil=>fil.formStatus===2)
      const rejectFilter=resData.filter(fil=>fil.formStatus===4)
      const approveFilter=resData.filter(fil=>fil.formStatus===3||fil.formStatus===6)
      const cancelFilter=resData.filter(fil=>fil.formStatus===9)
      const closeFilter=resData.filter(fil=>fil.formStatus===5||fil.formStatus===7||fil.formStatus===8)
       setCount({
        new:newFilter,
        pending:pendingFilter,
        reject:rejectFilter,
        approved:approveFilter,
        cancel:cancelFilter,
        close:closeFilter
       })
       setData(res.data.data)
       setBackUp(res.data.data)
    }).catch(e=>{
       console.log(e)
    })
  
  }

  const columns = [
    {
      Header: "Form No",
      accessor: "formNo",
      Cell: ({ cell }) => {
        return (
          <Link
            to={`/ptwform?id=${cell.row.original.id}&no=${cell.value}`}
            style={{
              color: "#FD0000",
              textDecorationLine: "underline",
              textDecorationColor:commonCss.green,
              fontWeight: 800,
            }}
            onClick={()=>{
              dispatch(permitStatus({permitStatus:cell.row.original.permitStatus,isPermitShift:cell.row.original.isPermitShift}))
            }}
          >
            {cell.value}
          </Link>
        );
      },
    },
    {
      Header: "Contractor",
      accessor: "contractor",
    },
    {
      Header: "Date",
      accessor: "issueDate",
      Cell:({cell})=>{
        return(
            <Typography sx={{fontSize:"inherit",fontWeight:"inherit",color:"inherit"}}>
                {cell.value!==null?moment(cell.value).format("DD/MM/YYYY"):null}
            </Typography>
        )
    }
    },
    {
      Header: "Location",
      accessor: "locationText",
    },
    {
      Header: "Status",
      accessor: "formStatusText",
      Cell:({cell})=>{
        return(
            <Typography sx={{fontSize:"inherit",fontWeight:"inherit",color:"inherit"}}>
                {/* {cell.row.original.deIsolationStatus&&cell.value.toUpperCase()==="CLOSED"?"Deisolation":cell.value} */}
                {cell.value}
                <IconButton sx={{display:((((!cell.row.original.isIsolationOwnerApproved||!cell.row.original.isIsolationVerifierApproved)&&cell.row.original.isLeadPermitIssuerApproved)||((!cell.row.original.isdeIsolationOwnerClosed||!cell.row.original.isdeIsolationVerifierClosed))&&cell.row.original.isPHPermitClosed===true)) &&cell.row.original.enableIsolationCertificate?"default":"none"}}>
                <CircleIcon
                       
                       sx={{
                         color: "#ffbe0b",
                         fontSize:"0.6rem"
                       }}
                     />
                </IconButton>
            </Typography>
        )
    }
    },
      {
          accessor: "isPermitShift",
          disableFilters: true,
          Cell: ({ cell }) => {
            return (
    
              <>
          
              {!cell.row.original.isPIPermitClosed||!cell.row.original.isLPIPermitClosed?
              //cell.row.original.isLeadPermitIssuerApproved&&moment(cell.row.original.piPermitValidUpTo).format("DD/MM/YYYY")<moment().format("DD/MM/YYYY")?
              cell.row.original.isFormExpired?
              <Typography sx={{ fontSize: "inherit", fontWeight: "inherit", color: commonCss.red  }}>
              Expired
            </Typography>:
              cell.row.original.isLeadPermitIssuerApproved&&!cell.row.original.isPHPermitClosed?
                cell.value ? 
                <Typography sx={{ fontSize: "inherit", fontWeight: "inherit", color: commonCss.green  }}>
                Active
              </Typography> : <Typography sx={{ fontSize: "inherit", fontWeight: "inherit", color: commonCss.red  }}>
                Suspend
              </Typography>:null:null}
              </>
            );
          }
        },
  ];

  const newButton=()=>{
    setNewForm(true)
  }

  const pendingChange=(e)=>{
    setPendingValue(e)
    getData(e)
  }

  const handleCard=(value)=>{
    setData(count[value])
  }
  const handleShowAll=()=>{
    setData(backUp)
  }

  return (
    <Box sx={{display:"flex",flexDirection:'column'}}>
      {/* <Grid2 container size={12} mb={"1rem"} spacing={"1rem"}>
         <Grid2 size={{xs:12,md:4,xl:roleAccess.adminContractTech?2:2.4}}>
        <Card sx={{
          wordWrap: "break-word",
          backgroundColor: "#25c2e3",
          padding: "0.8rem 1rem",
          cursor:"pointer",
          // height:"12vh"
       
        }}
        onClick={()=>{handleCard("new")}}
        >
        
        <Box sx={{display:"flex",justifyContent:"space-between",flexWrap:"wrap"}}>
          <Box>
          <Typography
              sx={{
                wordWrap: "break-word",
                fontSize: "0.9rem",
                fontWeight: 800,
                color: "white",
                textTransform: "uppercase",
                letterSpacing: "0.3px",
              }}
            >
              New
            </Typography>
            <Typography
             
             sx={{
              fontWeight: 600,
              color: "white",
              fontSize:"1.7rem",
              mt:"0.5rem"
            }}
            >
              {count.new.length}
             
            </Typography>
          </Box>
          <Box >
          <RateReview  sx={{fontSize:"4rem",color:"white"}} />
          </Box>
        </Box>
        </Card>
        </Grid2>
        <Grid2 size={{xs:12,md:4,xl:roleAccess.adminContractTech?2:2.4}}>
        <Card sx={{
          wordWrap: "break-word",
          backgroundColor: "#ffbe0b",
          padding: "0.8rem 1rem",
          cursor:"pointer",
          // height:"12vh"
          
        }}
        onClick={()=>{handleCard("pending")}}
        >
        
        <Box sx={{display:"flex",justifyContent:"space-between"}}>
          <Box>
          <Typography
              sx={{
                wordWrap: "break-word",
                fontSize: "0.9rem",
                fontWeight: 800,
                color: "white",
                textTransform: "uppercase",
                letterSpacing: "0.3px",
              }}
            >
              Progress
            </Typography>
            <Typography
             
             sx={{
              fontWeight: 600,
              color: "white",
              fontSize:"1.7rem",
              mt:"0.5rem"
            }}
            >
              {count.pending.length}
             
            </Typography>
          </Box>
          <Box >
          <Check  sx={{fontSize:"4rem",color:"white"}} />
          </Box>
        </Box>
        </Card>
        </Grid2>
        <Grid2 size={{xs:12,md:4,xl:roleAccess.adminContractTech?2:2.4}}>
        <Card sx={{
          wordWrap: "break-word",
          backgroundColor: "#ff5c75",
          padding: "0.8rem 1rem",
          cursor:"pointer",
          // height:"12vh"
       
        }}
        onClick={()=>{handleCard("reject")}}
        >
        
        <Box sx={{display:"flex",justifyContent:"space-between"}}>
          <Box>
          <Typography
              sx={{
                wordWrap: "break-word",
                fontSize: "0.9rem",
                fontWeight: 800,
                color: "white",
                textTransform: "uppercase",
                letterSpacing: "0.3px",
              }}
            >
              Rejected
            </Typography>
            <Typography
             
             sx={{
              fontWeight: 600,
              color: "white",
              fontSize:"1.7rem",
              mt:"0.5rem"
            }}
            >
              {count.reject.length}
             
            </Typography>
          </Box>
          <Box >
          <NotInterestedOutlined  sx={{fontSize:"4rem",color:"white"}} />
          </Box>
        </Box>
        </Card>
        </Grid2>
        <Grid2 size={{xs:12,md:4,xl:roleAccess.adminContractTech?2:2.4}}>
        <Card sx={{
          wordWrap: "break-word",
          backgroundColor: "#43d39e",
          padding: "0.8rem 1rem",
          cursor:"pointer",
          // height:"12vh"
       
        }}
        
        onClick={()=>{handleCard("approved")}}>
        
        <Box sx={{display:"flex",justifyContent:"space-between"}}>
          <Box>
          <Typography
              sx={{
                wordWrap: "break-word",
                fontSize: "0.9rem",
                fontWeight: 800,
                color: "white",
                textTransform: "uppercase",
                letterSpacing: "0.3px",
              }}
            >
              Approved
            </Typography>
            <Typography
             
             sx={{
              fontWeight: 600,
              color: "white",
              fontSize:"1.7rem",
              mt:"0.5rem"
            }}
            >
              {count.approved.length}
             
            </Typography>
          </Box>
          <Box >
          <Beenhere  sx={{fontSize:"4rem",color:"white"}} />
          </Box>
        </Box>
        </Card>
        </Grid2>
        <Grid2 size={{xs:12,md:4,xl:roleAccess.adminContractTech?2:2.4}} sx={{display:roleAccess.adminContractTech?'default':"none"}}>
        <Card sx={{
          wordWrap: "break-word",
          backgroundColor:"#D100D1",
          padding: "0.8rem 1rem",
          cursor:"pointer",
          // height:"12vh"
       
        }}
        onClick={()=>{handleCard("cancel")}}
        >
        
        <Box sx={{display:"flex",justifyContent:"space-between"}}>
          <Box>
          <Typography
              sx={{
                wordWrap: "break-word",
                fontSize: "0.9rem",
                fontWeight: 800,
                color: "white",
                textTransform: "uppercase",
                letterSpacing: "0.3px",
              }}
            >
              Cancel
            </Typography>
            <Typography
             
             sx={{
              fontWeight: 600,
              color: "white",
              fontSize:"1.7rem",
              mt:"0.5rem"
            }}
            >
              {count.cancel.length}
             
            </Typography>
          </Box>
          <Box >
          <Clear  sx={{fontSize:"4rem",color:"white"}} />
          </Box>
        </Box>
        </Card>
        </Grid2>
        <Grid2 size={{xs:12,md:4,xl:roleAccess.adminContractTech?2:2.4}}>
        <Card sx={{
          wordWrap: "break-word",
          backgroundColor: "#FBBF77",
          padding: "0.8rem 1rem",
          cursor:"pointer",
          // height:"12vh"
       
        }}
        onClick={()=>{handleCard("close")}}
        >
        
        <Box sx={{display:"flex",justifyContent:"space-between"}}>
          <Box>
          <Typography
              sx={{
                wordWrap: "break-word",
                fontSize: "0.9rem",
                fontWeight: 800,
                color: "white",
                textTransform: "uppercase",
                letterSpacing: "0.3px",
              }}
            >
              Close
            </Typography>
            <Typography
             
             sx={{
              fontWeight: 600,
              color: "white",
              fontSize:"1.7rem",
              mt:"0.5rem"
            }}
            >
              {count.close.length}
             
            </Typography>
          </Box>
          <Box >
          <BookmarkAdded  sx={{fontSize:"4rem",color:"white"}} />
          </Box>
        </Box>
        </Card>
        </Grid2>
      </Grid2> */}
      {/* <Typography
        sx={{
          color: "White",
          fontSize: "1.5rem",
          fontWeight: 600,
          mb: "1rem",
        }}
      >
        PTW Forms
      </Typography> */}
       
     
        <TableContainerComp 
        columns={columns} 
        data={data} 
        formListMain={true} 
        // buttonNew={newButton}
        // buttonDisable={!(login.companyId===companyInfo.ps)}
        filterShow={true}
        pendingShow={roleAccess.admin?false:true}
        pendingValue={pendingValue}
        handleChangePending={pendingChange}
        showAll={roleAccess.admin}
        handleShowAll={handleShowAll}
        />

<Dialog
        // maxWidth={"lg"}
  open={newForm}
  onClose={()=>{
    setNewForm(false)
    getData()
  }}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <NewForm handleCloseClick={()=>{
    setNewForm(false)
    getData(true)
    }}/>
  </DialogContent>
       </Dialog>
      
    </Box>
  );
};

export default GetOeControllerList;

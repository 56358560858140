import React, { useState } from "react";
import {
  Box,
  Button,
  Grid2,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import KeyIcon from "@mui/icons-material/Key";
import {toast} from "react-toastify"

import ErrorProps from "../../Component/Errorprops";
import { useNavigate } from "react-router-dom";
import { commonCss } from "../../StyleConfig/CommonCss";
import { axiosPrivate } from "../../axios/axios";
import { api, roleInfo } from "../API/config";
import { useDispatch } from "react-redux";
import { loginDetail } from "../../Redux/authentication";
import { permitStatus } from "../../Redux/data";
import Logo from "../../Images/Nav/PSPLOGO.jpg"
 

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    emailId: "",
    password: "",
  };

  const navigate=useNavigate()
  const dispatch=useDispatch()

  const validationSchema = Yup.object({
    emailId: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const onSubmit = (values) => {
    const loginLink=api.account.login
    console.log(values)
    axiosPrivate.post(`${loginLink}`,values).then(res=>{
        console.log(res)
        dispatch(loginDetail(res.data.data))
        dispatch(permitStatus({formStages:[],currentStage:0}))
        if(String(res.data.data.roleIds).split(",").includes(roleInfo.admin)){
        navigate("/dashboard")
        }else if(String(res.data.data.roleIds).split(",").includes(roleInfo.oe)||String(res.data.data.roleIds).split(",").includes(roleInfo.controller)){
        navigate("/controllerroom")
        }
          else{
          navigate("/formlist")
        }
    }).catch(e=>{
       console.log(e)
       toast.error(e.response.data.Message)
    })
    // let email="ptw@gmail.com"
    // let password="123@PTW"
    // if(values.email===email&&values.password===password){
    //   navigate("/formlist")
    // }else{
    //   toast.error("Invalid Email or Password")
    // }
  };
  return (
    <Box
      sx={{
        background: "linear-gradient(to right, #00807F, #FD0000)",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper
        sx={{ background: "linear-gradient(to right, #DAF2F2, #FEDADA)", p: 1,borderRadius:"1.5rem" }}
      >
        <Grid2
          container
          
          sx={{ padding: { xs: "2rem" } }}
          gap="1.5rem"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "1.8rem",
            }}
          >
            {/* <Box sx={{display:"flex",width:"100%",justifyContent:"space-between",alignItems:"center"}}>
              <Link to="/" style={{textDecorationColor:"#FD0000"}}>
                <Typography
                  sx={{
                    color: "#00807F",
                    fontSize:"1.3rem",
                    fontWeight: 600,
                  }}
                >
                  PS TERMINAL
                </Typography>
              </Link>
              <img src={logo} alt="logo" style={{width:"100px",height:"80px"}}/>
              </Box> */}
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
            >
              <Box sx={{display:"flex",alignItems:"center",gap:"0.5rem"}}>
                      <img style={{width:"3vw",height:"6vh"}} src={Logo} alt='logo'/>
                    <Typography variant="h6" noWrap  sx={{fontSize:"1.4rem",color:"#ffbe0b",mt:-1}}>
                    <span style={{color:commonCss.green}}>e</span> - PTW
                    </Typography>
                    </Box>
              <Typography sx={{ fontWeight: 400, fontSize: "1.1rem" }}>
                Welcome back!
              </Typography>
              <Typography sx={{ fontSize: "0.9rem", color: "#6C7585" }}>
                Enter your email address and password to access the system.
              </Typography>
            </Box>
          </Box>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.7rem",
                width: "100%",
              }}
            >
              <Typography sx={{ color: commonCss.labelInputColor, fontWeight: 500 }}>
                Email
              </Typography>
              <Field
                as={TextField}
                type="email"
                fullWidth
                size={"large"}
                name="emailId"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: commonCss.inputBackgroundColor, 
                    borderRadius:commonCss.inputBorderRadius
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
              },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AlternateEmailIcon
                        sx={{ color: commonCss.green, fontWeight: 1800 }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <ErrorMessage name="emailId">
                {(error) => <ErrorProps>{error}</ErrorProps>}
              </ErrorMessage>
              <Box
                sx={{
                  color: commonCss.labelInputColor,
                  // fontWeight: 200,

                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontWeight: 500 }}>Password</Typography>
                <Link
                  to="/"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {" "}
                  <Typography sx={{ fontSize: "0.9rem", fontWeight: 500 }}>
                    Forget Password?
                  </Typography>
                </Link>
              </Box>
              <Field
                as={TextField}
                type={showPassword ? "text" : "password"}
                fullWidth
                name="password"
                size={"medium"}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: commonCss.inputBackgroundColor, 
                    borderRadius:commonCss.inputBorderRadius
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <KeyIcon sx={{ color: commonCss.green, fontWeight: 1800 }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <ErrorMessage name="password">
                {(error) => <ErrorProps>{error}</ErrorProps>}
              </ErrorMessage>
              <Button
                type="submit"
                variant="contained"
                disabled={isLoading}
                fullWidth
                sx={{
                  padding: "0.5rem",
                  mt: "0.7rem",
                  color: "white",
                  background: isLoading
                    ? "default"
                    : "linear-gradient(to right, #00807F, #FD0000)",
                  "&:hover": {
                    backgroundColor: isLoading ? "default" : "#FD0000",
                  },
                }}
              >
                Login
              </Button>
            </Form>
          </Formik>
        </Grid2>
      </Paper>
    </Box>
  );
};

export default Login;

import {
  Button,
  Divider,
  Grid2,
  MenuItem,
  Paper,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  InputLabel,
  Box,
  IconButton,
  Collapse,
} from "@mui/material";
import { commonCss } from "../../../StyleConfig/CommonCss";
import {
  ArrowBack,
  DeleteOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  FileUploadOutlined,
  Visibility,
} from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { useSelector } from "react-redux";
import { Form, Formik, ErrorMessage } from "formik";
import React, { useState, useEffect } from "react";
import { axiosPrivate, imageURL } from "../../../axios/axios";
import dayjs from "dayjs";
import moment from "moment";
import { api, companyInfo, roleInfo } from "../../API/config";
import Close from "@mui/icons-material/Close";
import Loading from "../../../Component/Loading";
import ErrorProps from "../../../Component/Errorprops";
import { useRef } from "react";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";

import LAHimage from "../../../Images/LAHimage.png";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import ViewImage from "../ViewImage";
import * as Yup from "yup";
import Remark from "../../../Component/Remark";
import Confirmation from "../../../Component/Confirmation";
import RemarkTableView from "../../../Component/RemarkTableView";

const LiftingAndHoisting = ({
  handleCloseClick,
  certificateDetail,
  approvedData,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const login = useSelector((state) => state.auth.loginInfo);
  const loginRoleId = String(login.roleIds).split(",");
  const [loading, setLoading] = useState(false);
  const [certificateInfo, setCertificateInfo] = useState({});
  const [locations, setLocations] = useState([]);
  const fileInputRef = useRef(null);
  const fileInputRefLoad = useRef(null);
  const [uploadStatus, setUploadStatus] = useState({
    diagramOfLoad: false,
    diagramOfProposedRigging: false,
  });
  const [approveInfo, setApproveInfo] = useState({
    liftName: "",
    lpiName: "",
    certificateClosedName: "",
    lpiCertClosedName: "",
  });

  const [roleAccess, setRoleAccess] = useState({
    contractStaff: false,
    lift: false,
    lpi: false,
    lpiCertClose: false,
    lpiCertCloseReason: false,
    certClose: false,
    piContract: false,
  });
  const [closedRemark, setClosedRemark] = useState({
    PH: "",
    LPI: "",
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [siteLocationData, setSiteLocationData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedProposed, setProposedRigging] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState({ diagramOfLoad: null });
  const [confirmIndex, setConfirmIndex] = useState(null);
  const [confirmIndexRigging, setConfirmIndexRigging] = useState(null);
  const [remarkButton, setRemarkButton] = useState({
    reject: false,
    cancel: false,
    remarkOpen: false,
  });
      const [confirm,setConfirm]=useState({
          submit:false,
          close:false,
          delete:false
        })
      const [confirmValue,setConfirmValue]=useState([])

  useEffect(() => {
    if (certificateDetail && certificateDetail.id) {
      console.log("certificateDetail.id:", certificateDetail.id);
      getData();
      // getlocation();
    } else {
      console.log("certificateDetail is not available or doesn't have an id.");
    }
  }, [certificateDetail]);
  const getData = async () => {
    setLoading(true);
    const getDataLink = api.liftingAndHoisting.getById;
    await axiosPrivate
      .get(`${getDataLink}/${certificateDetail.id}`)
      .then(async (res) => {
        console.log("responce api", res.data.data);
        let resData = res.data.data;
        let loadData = [];
        let riggingData = [];
        const imageLink = api.imageUrl.getUploadFile;
        if (resData.diagramOfLoad !== null) {
          let getLoadImage = String(resData.diagramOfLoad).split(",");
          loadData = await axiosPrivate.post(`${imageLink}`, getLoadImage);
          console.log(loadData);
        }
        if (resData.diagramOfProposedRigging !== null) {
          let getRiggingImage = String(resData.diagramOfProposedRigging).split(
            ","
          );
          riggingData = await axiosPrivate.post(
            `${imageLink}`,
            getRiggingImage
          );
          console.log(riggingData);
        }
        console.log({
          ...resData,
          diagramOfLoad:
            resData.diagramOfLoad !== null ? loadData.data.data : [],
          diagramOfProposedRigging:
            resData.diagramOfProposedRigging !== null
              ? riggingData.data.data
              : [],
        });
        setCertificateInfo({
          ...resData,
          diagramOfLoad:
            resData.diagramOfLoad !== null ? loadData.data.data : [],
          diagramOfProposedRigging:
            resData.diagramOfProposedRigging !== null
              ? riggingData.data.data
              : [],
        });
        setClosedRemark({
          PH: res.data.data.phCertificateClosedReason,
          LPI: res.data.data.lpiCertificateClosedReason,
        });
        setApproveInfo({
          liftName: res.data.data.isLiftingInspectorApproved
            ? res.data.data?.liftingInspectorUserDetail?.firstName +
              " " +
              res.data.data?.liftingInspectorUserDetail?.lastName
            : "",
          lpiName: res.data.data.isLPIApproved
            ? res.data.data?.lpiUserDetails?.firstName +
              " " +
              res.data.data?.lpiUserDetails?.lastName
            : "",
          certificateClosedName: res.data.data.isPHCertificateClosed
            ? approvedData?.permitHolderUser?.firstName +
              " " +
              approvedData?.permitHolderUser?.lastName
            : "",
          lpiCertClosedName: res.data.data.isLPICertificateClosed
            ? res.data.data?.lpiUserDetail?.firstName +
              " " +
              res.data.data?.lpiUserDetail?.lastName
            : "",
        });
        setRoleAccess({
          piContract:
            loginRoleId.includes(roleInfo.contractStaff) &&
            approvedData.assignedUsers.includes(login.userId) &&
            res.data.data.status === 8
              ? (loginRoleId.includes(roleInfo.contractStaff) &&
                  approvedData.assignedUsers.includes(login.userId) &&
                  !approvedData.isContractApproved &&
                  !approvedData.isCancel === true) ||
                (loginRoleId.includes(roleInfo.pi) &&
                  approvedData.isHSSEApproved &&
                  !approvedData.isPermitIssuerApproved &&
                  (res.data.data.status !== 8) &
                    (!approvedData.isCancel === true))
              : (loginRoleId.includes(roleInfo.contractStaff) &&
                  approvedData.assignedUsers.includes(login.userId) &&
                  !approvedData.isContractApproved &&
                  res.data.data.status !== 8 &&
                  !approvedData.isCancel === true) ||
                (loginRoleId.includes(roleInfo.pi) &&
                  approvedData.isHSSEApproved &&
                  !approvedData.isPermitIssuerApproved &&
                  (res.data.data.status !== 8) &
                    (!approvedData.isCancel === true)),
          contractStaff:
            !(
              loginRoleId.includes(roleInfo.contractStaff) &&
              approvedData.assignedUsers.includes(login.userId)
            ) ||
            approvedData.isContractApproved ||
            approvedData.isCancel === true,

          lpi:
            !(
              loginRoleId.includes(roleInfo.lpi) &&
              res.data.data.isLiftingInspectorApproved
            ) ||
            res.data.data.isLPIApproved ||
            res.data.data.status === 8 ||
            res.data.data.isCancel ||
            approvedData.isCancel === true,
          lpiCertClose:
            !loginRoleId.includes(roleInfo.lpi) ||
            res.data.data.isLPICertificateClosed ||
            !res.data.data.isPHCertificateClosed ||
            res.data.data.status === 8 ||
            res.data.data.isCancel ||
            approvedData.isCancel === true,

          lpiCertCloseReason: res.data.data.isLPICertificateClosed
            ? true
            : !loginRoleId.includes(roleInfo.lpi) ||
              res.data.data.isLPICertificateClosed ||
              !res.data.data.isPHCertificateClosed ||
              res.data.data.status === 8 ||
              res.data.data.isCancel ||
              approvedData.isCancel === true,
          certClose:
            login.userId !== approvedData.permitHolderId ||
            res.data.data.isPHCertificateClosed ||
            !res.data.data.isLPIApproved ||
            res.data.data.status === 8 ||
            approvedData.isCancel === true,
          lift:
            !(loginRoleId.includes(roleInfo.liftingInspector)&&approvedData.isPermitIssuerApproved) ||
            approvedData.isLiftingInspectorApproved ||
            res.data.data.isLiftingInspectorApproved ||
            res.data.data.status === 8 ||
            approvedData.isCancel === true,
        });
        setRemarkButton({
          ...remarkButton,
          remarkOpen:
            (res.data.data.isCancel &&
              res.data.data.cancelRemarks !== null &&
              res.data.data.cancelRemarks !== "") ||
            (!res.data.data.isLPIApproved &&
              res.data.data.rejectedRemarks !== null &&
              res.data.data.rejectedRemarks !== "")
              ? true
              : false,
        });
        setLoading(false);
        // setSiteLocationData(res.data.data.diagramOfLoad);
        // setProposedRigging(res.data.data.diagramOfProposedRigging);
      })
      .catch((e) => {
        setCertificateInfo({});
        setLoading(false);
        console.log(e);
      });
  };
  const handleOpenDialog = (file) => {
    const fileData = {
      data: file,
      type: file.type,
    };
    setSelectedFile(fileData);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedFile(null);
  };
  const initialValue = {
    ...certificateInfo,
    id: certificateInfo.id,
    formId: certificateInfo.formId,
    locationText: certificateInfo.locationText,
    dateandTime: certificateInfo.dateandTime,
    zone: certificateInfo.zone,
    operator: certificateInfo.operator,
    liftingSupervisor: certificateInfo.liftingSupervisor,
    overheadUtilities: certificateInfo.overheadUtilities,
    groundConditions: certificateInfo.groundConditions,
    undergroundUtilities: certificateInfo.undergroundUtilities,
    vehiclePedestrianTraffic: certificateInfo.vehiclePedestrianTraffic,
    pinchPoint: certificateInfo.pinchPoint,
    fallHazards: certificateInfo.fallHazards,
    otherEquipment: certificateInfo.otherEquipment,
    spotterSignalPerson: certificateInfo.spotterSignalPerson,
    elevatedWarningSigns: certificateInfo.elevatedWarningSigns,
    cribbingShoringTrenchPlate: certificateInfo.cribbingShoringTrenchPlate,
    kRailsJerseyBarrier: certificateInfo.kRailsJerseyBarrier,
    cautionTapeBarricade: certificateInfo.cautionTapeBarricade,
    fallProtection: certificateInfo.fallProtection,
    otherPrecautions: certificateInfo.otherPrecautions,
    loadWidth: certificateInfo.loadWidth,
    noOfLiftPoints: certificateInfo.noOfLiftPoints,
    noOfSlings: certificateInfo.noOfSlings,
    lengthOfSlings: certificateInfo.lengthOfSlings,
    distanceBetweenHookAndLoad: certificateInfo.distanceBetweenHookAndLoad,
    slingTension: certificateInfo.slingTension,
    diagramOfLoad:
      certificateInfo.diagramOfLoad !== null &&
      certificateInfo.diagramOfLoad !== undefined
        ? certificateInfo.diagramOfLoad
        : [],
    diagramOfProposedRigging:
      certificateInfo.diagramOfProposedRigging !== null &&
      certificateInfo.diagramOfProposedRigging !== undefined
        ? certificateInfo.diagramOfProposedRigging
        : [],
    isPHCertificateClosed: certificateInfo.isPHCertificateClosed,
    phCertificateClosedAt: certificateInfo.phCertificateClosedAt,
    isLPICertificateClosed: certificateInfo.isLPICertificateClosed,
    lpiCertificateClosedAt: certificateInfo.lpiCertificateClosedAt,
    status: certificateInfo.status,
  };
  const onApproveAndSubmit=(value)=>{
    let liftLPIPI =
    loginRoleId.includes(roleInfo.liftingInspector)&&approvedData.isContractApproved ||
          loginRoleId.includes(roleInfo.lpi)&&approvedData.isPermitIssuerApproved;
  let statusValidation =
    (loginRoleId.includes(roleInfo.contractStaff)&&approvedData.assignedUsers.includes(login.userId) && value.status === null) ||
    (loginRoleId.includes(roleInfo.contractStaff)&&approvedData.assignedUsers.includes(login.userId) && value.status === 8)
      ? 1
      : loginRoleId.includes(roleInfo.liftingInspector) && !certificateInfo.isLiftingInspectorApproved && approvedData.isPermitIssuerApproved
      ? 5
      : loginRoleId.includes(roleInfo.lpi) && !certificateInfo.isLPIApproved && certificateInfo.isLiftingInspectorApproved
      ? 4
      : value.status;
  const getDiagramOfLoad = [];
  const getRiggingImage = [];
  for (let i = 0; value.diagramOfLoad.length > i; i++) {
    getDiagramOfLoad.push(value.diagramOfLoad[i].id);
  }
  for (let i = 0; value.diagramOfProposedRigging.length > i; i++) {
    getRiggingImage.push(value.diagramOfProposedRigging[i].id);
  }
  const payload = {
    ...value,
    diagramOfLoad: getDiagramOfLoad.toString(),
    diagramOfProposedRigging: getRiggingImage.toString(),
    isApproved: liftLPIPI ? true : null,
    status: statusValidation,
  };
  console.log("onSubmitValue", payload);
  const editLink = api.liftingAndHoisting.edit;
   axiosPrivate
    .put(`${editLink}/${certificateDetail.id}`, payload)
    .then((res) => {
      console.log(res);
      handleCloseClick();
      setConfirm({...confirm,submit:false})
  setConfirmValue([])
      toast.success("Successfully Updated");
    })
    .catch((e) => {
      setConfirm({...confirm,submit:false})
  setConfirmValue([])
      console.log(e);
    });
  }
  const onSubmit = async (value) => {
    setConfirm({...confirm,submit:true})
  setConfirmValue([value])
    
  };
  const handleFileUpload = (event, fieldName, setFieldValue, value) => {
    const file = event.target.files[0];

    if (file) {
      const allowedFileTypes = [
        "image/",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      const fileType = file.type;
      const isFileTypeValid = allowedFileTypes.some((type) =>
        fileType.startsWith(type)
      );

      if (!isFileTypeValid) {
        toast.error(
          "File is not supported. Please upload an image, PDF, or Word document."
        );
        return;
      }

      console.log(file.name);
      console.log(fileType);

      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function () {
        const base64String = reader.result;
        const postData = {
          fileName: file.name,
          fileType: fileType,
          base64String: base64String,
        };
        const imageLink = api.imageUrl.add;
        axiosPrivate
          .post(`${imageLink}`, postData)
          .then((res) => {
            console.log(res);
            setFieldValue(fieldName, [...value, res.data.data]);
            fileInputRef.current.value = null;
            fileInputRefLoad.current.value = null;
          })
          .catch((e) => {
            console.log(e);
          });
      };

      reader.onerror = function (error) {
        console.error("Error reading file: ", error);
      };
    } else {
      console.log("No file selected.");
    }
  };
  const handleDelete = (values, setFieldValue, fieldName, id) => {
    const deleteLink = api.imageUrl.delete;
    const deleteFilter = values.filter((d, i) => d.id === id);
    axiosPrivate
      .post(`${deleteLink}`, deleteFilter[0])
      .then((res) => {
        console.log(res);
        const updatedList = values.filter((d, i) => d.id !== id);
        setFieldValue(fieldName, updatedList);
        fileInputRef.current.value = null;
        fileInputRefLoad.current.value = null;
        setConfirm({...confirm,delete:false})
  setConfirmValue([])
        // setConfirmIndexRigging(null);
        // setConfirmIndex(null);
        console.log("setConfirmIndexRigging reset to null");
      })
      .catch((e) => {
        setConfirm({...confirm,delete:false})
        setConfirmValue([])
        console.log(e);
      });
  };
  const handleCloseCertificate = async () => {
    // const validatePermit = api.PTWForm.validatePermitShift;
    // if(certificateDetail.isPHCertificateClosed){
          const closeCertLink = api.liftingAndHoisting.closeCertificate;
          const postData = {
            id: certificateInfo.id,
            isCertificateClosed: true,
            certificateClosedReason:certificateDetail.isPHCertificateClosed? closedRemark.LPI:closedRemark.PH
          };
           axiosPrivate
            .put(`${closeCertLink}/${certificateInfo.id}`, postData)
            .then((res) => {
              toast.success("Certificate Closed");
              setConfirm({...confirm,close:false})
              setConfirmValue([])
              handleCloseClick();
            })
            .catch((e) => {
              setConfirm({...confirm,close:false})
              setConfirmValue([])
              console.log(e);
            });
        // }else{
        //   axiosPrivate.get(`${validatePermit}/${approvedData.id}`).then(res=>{
        //     const closeCertLink = api.liftingAndHoisting.closeCertificate;
        //     const postData = {
        //       id: certificateInfo.id,
        //       isCertificateClosed: true,
        //       certificateClosedReason: closedRemark.PH,
        //     };
        //      axiosPrivate
        //       .put(`${closeCertLink}/${certificateInfo.id}`, postData)
        //       .then((res) => {
        //         toast.success("Certificate Closed");
        //         handleCloseClick();
        //       })
        //       .catch((e) => {
        //         console.log(e);
        //       });
        //       }).catch(e=>{
        //             console.log(e)
        //             toast.error("Permit Is Not Active")
        //           })
        // }
  };
  const validationSchema = Yup.object().shape({
    locationText: Yup.string().required("This field is required"),

    dateandTime: Yup.string().required("This field is required"),

    zone: Yup.string().required("This field is required"),

    operator: Yup.string().required("This field is required"),

    liftingSupervisor: Yup.string().required("This field is required"),

    certificateValidFrom: Yup.string().required("This field is required"),

    certificateValidTo: Yup.string()
      .required("This field is required")
      .test(
        "valid-date-range-to",
        "The 'Until' date should not be before the 'From' date.",
        function (value) {
          const { certificateValidFrom } = this.parent;
          if (value && certificateValidFrom) {
            return moment(value).isSameOrAfter(moment(certificateValidFrom));
          }
          return true;
        }
      ),
    overheadUtilities: Yup.string().required("This field is required"),

    groundConditions: Yup.string().required("This field is required"),

    undergroundUtilities: Yup.string().required("This field is required"),

    vehiclePedestrianTraffic: Yup.string().required("This field is required"),

    pinchPoint: Yup.string().required("This field is required"),

    fallHazards: Yup.string().required("This field is required"),

    otherEquipment: Yup.string().required("This field is required"),

    spotterSignalPerson: Yup.string().required("This field is required"),

    elevatedWarningSigns: Yup.string().required("This field is required"),

    cribbingShoringTrenchPlate: Yup.string().required("This field is required"),

    kRailsJerseyBarrier: Yup.string().required("This field is required"),

    cautionTapeBarricade: Yup.string().required("This field is required"),

    fallProtection: Yup.string().required("This field is required"),

    otherPrecautions: Yup.string().required("This field is required"),

    loadWidth: Yup.string().required("This field is required"),

    noOfLiftPoints: Yup.string().required("This field is required"),

    noOfSlings: Yup.string().required("This field is required"),

    lengthOfSlings: Yup.string().required("This field is required"),

    distanceBetweenHookAndLoad: Yup.string().required("This field is required"),

    slingTension: Yup.string().required("This field is required"),

    diagramOfLoad: Yup.array().min(1, "This field is required"),

    diagramOfProposedRigging: Yup.array().min(1, "This field is required"),
  });
  const handleReject = (value, remark) => {
    const getDiagramOfLoad = [];
    const getRiggingImage = [];
    for (let i = 0; value.diagramOfLoad.length > i; i++) {
      getDiagramOfLoad.push(value.diagramOfLoad[i].id);
    }
    for (let i = 0; value.diagramOfProposedRigging.length > i; i++) {
      getRiggingImage.push(value.diagramOfProposedRigging[i].id);
    }
    const postData = {
      ...value,
      diagramOfLoad: getDiagramOfLoad.toString(),
      diagramOfProposedRigging: getRiggingImage.toString(),
      isApproved: false,
      remarks: remark,
      status: 8,
    };
    console.log(postData);
    const editLink = api.liftingAndHoisting.edit;
    axiosPrivate
      .put(`${editLink}/${certificateInfo.id}`, postData)
      .then((res) => {
        console.log(res);

        // getData()
        handleCloseClick();
        setRemarkButton({ ...remarkButton, reject: false });
        toast.success("Successfully Rejected");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleVisible = (url) => {
    window.open(imageURL + url);
  };
  return (
    <>
      {!loading ? (
        <Formik
          initialValues={initialValue}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({
            setFieldValue,
            handleChange,
            values,
            errors,
            touched,
            setFieldTouched,
          }) => (
            <Form>
              <Grid2 container size={12} spacing={"1rem"}>
                <Grid2 container size={12} alignItems={"center"}>
                  <Grid2 container size={4}>
                    <Box
                      sx={{
                        backgroundColor: commonCss.shadeGreen,
                        display: "flex",
                        alignItems: "cente",
                        p: 1,
                        cursor: "pointer",
                      }}
                      onClick={handleCloseClick}
                    >
                      <ArrowBack color={commonCss.green} />
                    </Box>
                  </Grid2>
                  <Grid2 container size={4} justifyContent={"center"}>
                    <Typography variant="h5" fontWeight="bold" color="white">
                      Lifting And Hoisting
                    </Typography>
                  </Grid2>
                  <Grid2 container size={4} justifyContent={"flex-end"}>
                    <Box
                      sx={{
                        backgroundColor: commonCss.shadeGreen,
                        p: 1,
                      }}
                    >
                      <Typography variant="body1" fontWeight="bold">
                        Permit To Work No.{" "}
                        <span style={{ color: "#e53e3e" }}>
                          {searchParams.get("no")}
                        </span>
                      </Typography>
                    </Box>
                  </Grid2>
                  <Divider
                    sx={{
                      borderColor: commonCss.red,
                      borderWidth: 1,
                      width: "100%",
                    }}
                  />
                </Grid2>
                <Grid2
                  container
                  size={12}
                  gap={0}
                  sx={{
                    display:
                      certificateInfo.isCancel ||
                      (!values.isLPIApproved &&
                        certificateInfo.rejectRemarks !== null &&
                        certificateInfo.rejectRemarks !== "")
                        ? "default"
                        : "none",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setRemarkButton({
                        ...remarkButton,
                        remarkOpen: !remarkButton.remarkOpen,
                      });
                    }}
                  >
                    {remarkButton.remarkOpen ? (
                      <KeyboardArrowUp sx={{ color: commonCss.red }} />
                    ) : (
                      <KeyboardArrowDown sx={{ color: commonCss.red }} />
                    )}

                    <Typography
                      sx={{
                        color: commonCss.red,
                        ml: "0.6rem",
                        fontWeight: 600,
                      }}
                    >
                      Remark
                    </Typography>
                  </Box>
                  <Collapse
                    in={remarkButton.remarkOpen}
                    sx={{
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                      p: remarkButton.remarkOpen ? 1 : 0,
                    }}
                  >
                    <RemarkTableView data={certificateInfo.isCancel
                        ? certificateInfo.cancelOthers
                        : certificateInfo.rejectRemarks}/>
                  </Collapse>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"0.6rem"}>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Location
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="locationText"
                          value={values.locationText}
                          //onBlur={() => setFieldTouched("locationText", true)}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          onChange={(e) =>
                            setFieldValue("locationText", e.target.value)
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                        {errors.locationText && (
                          <ErrorMessage name="locationText">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Date and Time
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              value={
                                values.dateandTime !== null
                                  ? dayjs(values.dateandTime)
                                  : null
                              }
                              readOnly={roleAccess.contractStaff}
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d).utc();
                                    setFieldValue("dateandTime", dateFrom);

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              // onClose={() =>
                              //   setFieldTouched("dateandTime", true)
                              // }
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: roleAccess.contractStaff
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                            {errors.dateandTime && (
                              <ErrorMessage name="dateandTime">
                                {(error) => <ErrorProps>{error}</ErrorProps>}
                              </ErrorMessage>
                            )}
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Zone
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="zone"
                          value={values.zone}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          onChange={(e) =>
                            setFieldValue("zone", e.target.value)
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                          // onBlur={() => setFieldTouched("zone", true)}
                        />
                        {errors.zone && (
                          <ErrorMessage name="zone">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Operator
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="operator"
                          value={values.operator}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          onChange={(e) =>
                            setFieldValue("operator", e.target.value)
                          }
                          //  onBlur={() => setFieldTouched("operator", true)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                        {errors.operator && (
                          <ErrorMessage name="operator">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Lifting Supervisor
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="liftingSupervisor"
                          value={values.liftingSupervisor}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          onChange={(e) =>
                            setFieldValue("liftingSupervisor", e.target.value)
                          }
                          //onBlur={() => setFieldTouched("liftingSupervisor", true)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                        {errors.liftingSupervisor && (
                          <ErrorMessage name="liftingSupervisor">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Validity From
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DatePicker
                              ampm={false}
                              format="DD/MM/YYYY"
                              minDate={
                                approvedData.isLeadPermitIssuerApproved
                                  ? dayjs(approvedData.lpiPermitValidFrom)
                                  : approvedData.isPermitIssuerApproved
                                  ? dayjs(approvedData.piPermitValidFrom)
                                  : approvedData.actualPermitValidFrom !== null
                                  ? dayjs(approvedData.actualPermitValidFrom)
                                  : dayjs(moment())
                              }
                              maxDate={
                                approvedData.isLeadPermitIssuerApproved
                                  ? dayjs(approvedData.lpiPermitValidUpTo)
                                  : approvedData.isPermitIssuerApproved
                                  ? dayjs(approvedData.piPermitValidUpTo)
                                  : approvedData.actualPermitValidUpTo !== null
                                  ? dayjs(approvedData.actualPermitValidUpTo)
                                  : undefined
                              }
                              readOnly={!roleAccess.piContract}
                              value={
                                values.certificateValidFrom !== null
                                  ? dayjs(values.certificateValidFrom)
                                  : null
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d).utc();
                                    setFieldValue(
                                      "certificateValidFrom",
                                      dateFrom
                                    );

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              // onChange={(newValue) => {
                              //   if (newValue !== null && `${newValue.$d}` !== "Invalid Date") {
                              //     const dateFrom = moment(newValue.$d);
                              //     setFieldValue("certificateValidFrom", dateFrom);
                              //     setFieldValue(
                              //       "certificateValidTo",
                              //       dateFrom.clone().add(7, "days")
                              //     );
                              //   }
                              // }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: !roleAccess.piContract
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                            {errors.certificateValidFrom && (
                              <ErrorMessage name="certificateValidFrom">
                                {(error) => <ErrorProps>{error}</ErrorProps>}
                              </ErrorMessage>
                            )}
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Validity Until
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DatePicker
                              ampm={false}
                              format="DD/MM/YYYY"
                              readOnly={!roleAccess.piContract}
                              minDate={
                                approvedData.isLeadPermitIssuerApproved
                                  ? dayjs(approvedData.lpiPermitValidFrom)
                                  : approvedData.isPermitIssuerApproved
                                  ? dayjs(approvedData.piPermitValidFrom)
                                  : approvedData.actualPermitValidFrom !== null
                                  ? dayjs(approvedData.actualPermitValidFrom)
                                  : values.certificateValidFrom !== null
                                  ? dayjs(values.certificateValidFrom)
                                  : dayjs(moment())
                              }
                              maxDate={
                                approvedData.isLeadPermitIssuerApproved
                                  ? dayjs(approvedData.lpiPermitValidUpTo)
                                  : approvedData.isPermitIssuerApproved
                                  ? dayjs(approvedData.piPermitValidUpTo)
                                  : approvedData.actualPermitValidUpTo !== null
                                  ? dayjs(approvedData.actualPermitValidUpTo)
                                  : values.certificateValidFrom !== null
                                  ? dayjs(
                                      moment(values.certificateValidFrom).add(
                                        7,
                                        "days"
                                      )
                                    )
                                  : undefined
                              }
                              value={
                                values.certificateValidTo !== null
                                  ? dayjs(values.certificateValidTo)
                                  : null
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              // onClose={() =>
                              //   setFieldTouched("certificateValidTo", true, false)
                              // }
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d).utc();
                                    setFieldValue(
                                      "certificateValidTo",
                                      dateFrom
                                    );
                                    // setFieldTouched("certificateValidTo", true, false);
                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              // shouldDisableDate={(date) => {
                              //   const validityFrom = values.certificateValidFrom
                              //     ? moment(values.certificateValidFrom)
                              //     : null;
                              //   return (
                              //     !validityFrom ||
                              //     date.isBefore(validityFrom, "day") ||
                              //     date.isAfter(validityFrom.clone().add(7, "days"), "day")
                              //   );
                              // }}
                              // onChange={(newValue) => {
                              //   if (newValue !== null && `${newValue.$d}` !== "Invalid Date") {
                              //     const dateTo = moment(newValue.$d);
                              //     setFieldValue("certificateValidTo", dateTo);
                              //   }
                              // }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: !roleAccess.piContract
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                            {errors.certificateValidTo && (
                              <ErrorMessage name="certificateValidTo">
                                {(error) => <ErrorProps>{error}</ErrorProps>}
                              </ErrorMessage>
                            )}
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"0.6rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                        >
                          HAZARDS IDENTIFIED
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Overhead Utilities / Obstruction
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="overheadUtilities"
                          value={values.overheadUtilities}
                          onChange={(e) =>
                            setFieldValue("overheadUtilities", e.target.value)
                          }
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          //  onBlur={() => setFieldTouched("overheadUtilities", true)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                        {errors.overheadUtilities && (
                          <ErrorMessage name="overheadUtilities">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Ground Conditions
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="groundConditions"
                          value={values.groundConditions}
                          onChange={(e) =>
                            setFieldValue("groundConditions", e.target.value)
                          }
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          // onBlur={() => setFieldTouched("groundConditions", true)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                        {errors.groundConditions && (
                          <ErrorMessage name="groundConditions">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Underground Utilities
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="undergroundUtilities"
                          value={values.undergroundUtilities}
                          onChange={(e) =>
                            setFieldValue(
                              "undergroundUtilities",
                              e.target.value
                            )
                          }
                          //onBlur={() => setFieldTouched("undergroundUtilities", true)}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                        {errors.undergroundUtilities && (
                          <ErrorMessage name="undergroundUtilities">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Vehicle & Pedestrian Traffic
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="vehiclePedestrianTraffic"
                          value={values.vehiclePedestrianTraffic}
                          onChange={(e) =>
                            setFieldValue(
                              "vehiclePedestrianTraffic",
                              e.target.value
                            )
                          }
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          //  onBlur={() => setFieldTouched("vehiclePedestrianTraffic", true)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                        {errors.vehiclePedestrianTraffic && (
                          <ErrorMessage name="vehiclePedestrianTraffic">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Pinch Point
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="pinchPoint"
                          value={values.pinchPoint}
                          onChange={(e) =>
                            setFieldValue("pinchPoint", e.target.value)
                          }
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                          //onBlur={() => setFieldTouched("pinchPoint", true)}
                        />
                        {errors.pinchPoint && (
                          <ErrorMessage name="pinchPoint">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Fall Hazards
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="fallHazards"
                          value={values.fallHazards}
                          onChange={(e) =>
                            setFieldValue("fallHazards", e.target.value)
                          }
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                          //onBlur={() => setFieldTouched("fallHazards", true)}
                        />
                        {errors.fallHazards && (
                          <ErrorMessage name="fallHazards">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 8 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Other Equipment
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="otherEquipment"
                          value={values.otherEquipment}
                          onChange={(e) =>
                            setFieldValue("otherEquipment", e.target.value)
                          }
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                          //onBlur={() => setFieldTouched("otherEquipment", true)}
                        />
                        {errors.otherEquipment && (
                          <ErrorMessage name="otherEquipment">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        )}
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"0.6rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                        >
                          PRECAUTIONS
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Spotter / Signal Person
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="spotterSignalPerson"
                          value={values.spotterSignalPerson}
                          onChange={(e) =>
                            setFieldValue("spotterSignalPerson", e.target.value)
                          }
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                          // onBlur={() => setFieldTouched("spotterSignalPerson", true)}
                        />
                        {errors.spotterSignalPerson && (
                          <ErrorMessage name="spotterSignalPerson">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Elevated Warning Signs
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="elevatedWarningSigns"
                          value={values.elevatedWarningSigns}
                          onChange={(e) =>
                            setFieldValue(
                              "elevatedWarningSigns",
                              e.target.value
                            )
                          }
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                          // onBlur={() => setFieldTouched("elevatedWarningSigns", true)}
                        />
                        {errors.elevatedWarningSigns && (
                          <ErrorMessage name="elevatedWarningSigns">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Cribbing / Shoring / Trench Plate
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="cribbingShoringTrenchPlate"
                          value={values.cribbingShoringTrenchPlate}
                          onChange={(e) =>
                            setFieldValue(
                              "cribbingShoringTrenchPlate",
                              e.target.value
                            )
                          }
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                          //onBlur={() => setFieldTouched("cribbingShoringTrenchPlate", true)}
                        />
                        {errors.cribbingShoringTrenchPlate && (
                          <ErrorMessage name="cribbingShoringTrenchPlate">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            K-Rails / Jersey Barrier
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="kRailsJerseyBarrier"
                          value={values.kRailsJerseyBarrier}
                          onChange={(e) =>
                            setFieldValue("kRailsJerseyBarrier", e.target.value)
                          }
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                          //  onBlur={() => setFieldTouched("kRailsJerseyBarrier", true)}
                        />
                        {errors.kRailsJerseyBarrier && (
                          <ErrorMessage name="kRailsJerseyBarrier">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Caution Tape / Barricade
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="cautionTapeBarricade"
                          value={values.cautionTapeBarricade}
                          onChange={(e) =>
                            setFieldValue(
                              "cautionTapeBarricade",
                              e.target.value
                            )
                          }
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                          //  onBlur={() => setFieldTouched("cautionTapeBarricade", true)}
                        />
                        {errors.cautionTapeBarricade && (
                          <ErrorMessage name="cautionTapeBarricade">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Fall Protection
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="fallProtection"
                          value={values.fallProtection}
                          onChange={(e) =>
                            setFieldValue("fallProtection", e.target.value)
                          }
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                          //onBlur={() => setFieldTouched("fallProtection", true)}
                        />
                        {errors.fallProtection && (
                          <ErrorMessage name="fallProtection">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 8 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Other
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="otherPrecautions"
                          value={values.otherPrecautions}
                          onChange={(e) =>
                            setFieldValue("otherPrecautions", e.target.value)
                          }
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                          //  onBlur={() => setFieldTouched("otherPrecautions", true)}
                        />
                        {errors.otherPrecautions && (
                          <ErrorMessage name="otherPrecautions">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        )}
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"0.6rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                        >
                          LOAD CALCULATIONS
                        </Typography>
                      </Grid2>
                      <Grid2 container size={8}>
                        <Grid2 size={{ xs: 12, md: 6 }}>
                          <InputLabel sx={{ pl: commonCss.labelPadding }}>
                            <Typography
                              variant="body2"
                              fontWeight={commonCss.labelFontWeight}
                              color={commonCss.labelInputColor}
                            >
                              Width / Length / Height / Weight
                              <Typography
                                component="span"
                                color="error"
                                fontWeight="bold"
                                sx={{ ml: 0.5 }}
                              >
                                *
                              </Typography>
                            </Typography>
                          </InputLabel>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            name="loadWidth"
                            value={values.loadWidth}
                            onChange={(e) =>
                              setFieldValue("loadWidth", e.target.value)
                            }
                            slotProps={{
                              input: {
                                readOnly: roleAccess.contractStaff,
                              },
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: roleAccess.contractStaff
                                  ? commonCss.readOnlyBGColor
                                  : commonCss.inputBackgroundColor,
                                borderRadius: commonCss.inputBorderRadius,
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: commonCss.inputBorderColor,
                              },
                            }}
                            //onBlur={() => setFieldTouched("loadWidth", true)}
                          />
                          {errors.loadWidth && (
                            <ErrorMessage name="loadWidth">
                              {(error) => <ErrorProps>{error}</ErrorProps>}
                            </ErrorMessage>
                          )}
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6 }}>
                          <InputLabel sx={{ pl: 0.5 }}>
                            <Typography
                              variant="body2"
                              fontWeight="500"
                              color="black"
                            >
                              Number of Lift Points
                              <Typography
                                component="span"
                                color="error"
                                fontWeight="bold"
                                sx={{ ml: 0.5 }}
                              >
                                *
                              </Typography>
                            </Typography>
                          </InputLabel>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            name="noOfLiftPoints"
                            value={values.noOfLiftPoints}
                            onChange={(e) =>
                              setFieldValue("noOfLiftPoints", e.target.value)
                            }
                            slotProps={{
                              input: {
                                readOnly: roleAccess.contractStaff,
                              },
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: roleAccess.contractStaff
                                  ? commonCss.readOnlyBGColor
                                  : commonCss.inputBackgroundColor,
                                borderRadius: commonCss.inputBorderRadius,
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: commonCss.inputBorderColor,
                              },
                            }}
                            // onBlur={() => setFieldTouched("noOfLiftPoints", true)}
                          />
                          {errors.noOfLiftPoints && (
                            <ErrorMessage name="noOfLiftPoints">
                              {(error) => <ErrorProps>{error}</ErrorProps>}
                            </ErrorMessage>
                          )}
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6 }}>
                          <InputLabel sx={{ pl: commonCss.labelPadding }}>
                            <Typography
                              variant="body2"
                              fontWeight={commonCss.labelFontWeight}
                              color={commonCss.labelInputColor}
                            >
                              Number of Slings
                              <Typography
                                component="span"
                                color="error"
                                fontWeight="bold"
                                sx={{ ml: 0.5 }}
                              >
                                *
                              </Typography>
                            </Typography>
                          </InputLabel>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            name="noOfSlings"
                            value={values.noOfSlings}
                            onChange={(e) =>
                              setFieldValue("noOfSlings", e.target.value)
                            }
                            slotProps={{
                              input: {
                                readOnly: roleAccess.contractStaff,
                              },
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: roleAccess.contractStaff
                                  ? commonCss.readOnlyBGColor
                                  : commonCss.inputBackgroundColor,
                                borderRadius: commonCss.inputBorderRadius,
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: commonCss.inputBorderColor,
                              },
                            }}
                            //  onBlur={() => setFieldTouched("noOfSlings", true)}
                          />
                          {errors.noOfSlings && (
                            <ErrorMessage name="noOfSlings">
                              {(error) => <ErrorProps>{error}</ErrorProps>}
                            </ErrorMessage>
                          )}
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6 }}>
                          <InputLabel sx={{ pl: commonCss.labelPadding }}>
                            <Typography
                              variant="body2"
                              fontWeight={commonCss.labelFontWeight}
                              color={commonCss.labelInputColor}
                            >
                              Length of Slings
                              <Typography
                                component="span"
                                color="error"
                                fontWeight="bold"
                                sx={{ ml: 0.5 }}
                              >
                                *
                              </Typography>
                            </Typography>
                          </InputLabel>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            name="lengthOfSlings"
                            value={values.lengthOfSlings}
                            onChange={(e) =>
                              setFieldValue("lengthOfSlings", e.target.value)
                            }
                            slotProps={{
                              input: {
                                readOnly: roleAccess.contractStaff,
                              },
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: roleAccess.contractStaff
                                  ? commonCss.readOnlyBGColor
                                  : commonCss.inputBackgroundColor,
                                borderRadius: commonCss.inputBorderRadius,
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: commonCss.inputBorderColor,
                              },
                            }}
                            //onBlur={() => setFieldTouched("lengthOfSlings", true)}
                          />
                          {errors.lengthOfSlings && (
                            <ErrorMessage name="lengthOfSlings">
                              {(error) => <ErrorProps>{error}</ErrorProps>}
                            </ErrorMessage>
                          )}
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6 }}>
                          <InputLabel sx={{ pl: commonCss.labelPadding }}>
                            <Typography
                              variant="body2"
                              fontWeight={commonCss.labelFontWeight}
                              color={commonCss.labelInputColor}
                            >
                              Distance Between Hook & Load
                              <Typography
                                component="span"
                                color="error"
                                fontWeight="bold"
                                sx={{ ml: 0.5 }}
                              >
                                *
                              </Typography>
                            </Typography>
                          </InputLabel>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            name="distanceBetweenHookAndLoad"
                            value={values.distanceBetweenHookAndLoad}
                            onChange={(e) =>
                              setFieldValue(
                                "distanceBetweenHookAndLoad",
                                e.target.value
                              )
                            }
                            slotProps={{
                              input: {
                                readOnly: roleAccess.contractStaff,
                              },
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: roleAccess.contractStaff
                                  ? commonCss.readOnlyBGColor
                                  : commonCss.inputBackgroundColor,
                                borderRadius: commonCss.inputBorderRadius,
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: commonCss.inputBorderColor,
                              },
                            }}
                            // onBlur={() => setFieldTouched("distanceBetweenHookAndLoad", true)}
                          />
                          {errors.distanceBetweenHookAndLoad && (
                            <ErrorMessage name="distanceBetweenHookAndLoad">
                              {(error) => <ErrorProps>{error}</ErrorProps>}
                            </ErrorMessage>
                          )}
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6 }}>
                          <InputLabel sx={{ pl: commonCss.labelPadding }}>
                            <Typography
                              variant="body2"
                              fontWeight={commonCss.labelFontWeight}
                              color={commonCss.labelInputColor}
                            >
                              Sling Tension
                              <Typography
                                component="span"
                                color="error"
                                fontWeight="bold"
                                sx={{ ml: 0.5 }}
                              >
                                *
                              </Typography>
                            </Typography>
                          </InputLabel>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            name="slingTension"
                            value={values.slingTension}
                            onChange={(e) =>
                              setFieldValue("slingTension", e.target.value)
                            }
                            slotProps={{
                              input: {
                                readOnly: roleAccess.contractStaff,
                              },
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: roleAccess.contractStaff
                                  ? commonCss.readOnlyBGColor
                                  : commonCss.inputBackgroundColor,
                                borderRadius: commonCss.inputBorderRadius,
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: commonCss.inputBorderColor,
                              },
                            }}
                            // onBlur={() => setFieldTouched("slingTension", true)}
                          />
                          {errors.slingTension && (
                            <ErrorMessage name="slingTension">
                              {(error) => <ErrorProps>{error}</ErrorProps>}
                            </ErrorMessage>
                          )}
                        </Grid2>
                      </Grid2>
                      <Grid2 container size={4}>
                        <img
                          src={LAHimage}
                          alt="lifitingHostingImage"
                          style={{ width: "100%", height: "100%" }}
                        />
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"0.6rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                        >
                          LOAD INFORMATION
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: 0.5 }}>
                          <Typography
                            variant="body2"
                            fontWeight="500"
                            color="black"
                          >
                            Diagram of Load (Include Measurements)
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        {/* {uploadedFiles.diagramOfLoad ? (
                               <Button
                               variant="outlined"
                               sx={{
                                display: roleAccess.contractStaff
                                ? "none"
                                : "default",
                                 backgroundColor: commonCss.red,
                                 borderRadius: commonCss.buttonBorderRadius,
                                 textTransform: "none",
                                 padding: "0.6rem 1rem",
                                 color: commonCss.buttonTextColor,
                                 width: "15vw",
                               }}
                               onClick={() => handleOpenDialog(uploadedFiles.diagramOfLoad)}
                             >
                               View File
                             </Button>
                           ) : siteLocationData?.length > 0 ? (
                            <Button
                            variant="outlined"
                            sx={{
                              display: roleAccess.contractStaff
                              ? "none"
                              : "default",
                              backgroundColor: commonCss.red,
                              borderRadius: commonCss.buttonBorderRadius,
                              textTransform: "none",
                              padding: "0.6rem 1rem",
                              color: commonCss.buttonTextColor,
                              width: "15vw",
                            }}
                            onClick={() => handleOpenDialog(siteLocationData)}
                          >
                            <Typography sx={{ fontSize: "0.9rem" }}>View File</Typography>
                          </Button>
                              ) : ( */}
                        <Button
                          disabled={roleAccess.contractStaff}
                          component="label"
                          sx={{
                            background: commonCss.inputBackgroundColor,
                            display: "flex",
                            gap: "0.3rem",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "10vh",
                            cursor: "pointer",
                            border: `1px solid ${commonCss.inputBorderColor}`,
                            borderRadius: commonCss.inputBorderRadius,
                            mb: 2,
                            color: "inherit",
                          }}
                        >
                          <FileUploadOutlined />{" "}
                          <Typography
                            sx={{ fontSize: "0.9rem", textTransform: "none" }}
                          >
                            {" "}
                            Upload Diagram of Load
                          </Typography>
                          <input
                            readOnly={roleAccess.contractStaff}
                            //     onBlur={() => setFieldTouched("diagramOfLoad", true)}
                            type="file"
                            ref={fileInputRefLoad}
                            // accept={"image/*"}

                            hidden
                            onChange={(e) =>
                              handleFileUpload(
                                e,
                                "diagramOfLoad",
                                setFieldValue,
                                values.diagramOfLoad
                              )
                            }
                          />
                        </Button>
                        <Box
                          sx={{
                            backgroundColor: "white",
                            p: 1,
                            display:
                              values.diagramOfLoad.length !== 0
                                ? "default"
                                : "none",
                          }}
                        >
                          {values.diagramOfLoad.map((att, i) => (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <Typography sx={{ width: "70%" }}>
                                  {att.fileName}
                                </Typography>
                                {/* <Typography>{att}</Typography> */}
                                <Box
                                  sx={{
                                    whiteSpace: "nowrap",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    width: "30%",
                                    alignItems: "center",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => {
                                      handleVisible(att.fileUrl);
                                    }}
                                  >
                                    <Visibility sx={{ color: commonCss.red }} />
                                  </IconButton>
                                  {/* {confirmIndex === i ? (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                        gap: 1,
                                      }}
                                    >
                                      <ErrorProps>
                                        Are you sure wants to delete
                                      </ErrorProps>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          gap: "0.2rem",
                                          mb: 1,
                                        }}
                                      >
                                        <Button
                                          onClick={() =>
                                            handleDelete(
                                              values.diagramOfLoad,
                                              setFieldValue,
                                              "diagramOfLoad",
                                              att.id
                                            )
                                          }
                                          sx={{
                                            color: commonCss.red,
                                            borderColor: commonCss.red,
                                          }}
                                          size="small"
                                          variant="outlined"
                                        >
                                          Yes
                                        </Button>
                                        <Button
                                          onClick={() => setConfirmIndex(null)}
                                          sx={{
                                            color: commonCss.green,
                                            borderColor: commonCss.green,
                                          }}
                                          size="small"
                                          variant="outlined"
                                        >
                                          No
                                        </Button>
                                      </Box>
                                    </Box>
                                  ) : ( */}
                                    <IconButton
                                      disabled={roleAccess.contractStaff}
                                      onClick={() => {
                                        setConfirm({...confirm,delete:true})
                                        setConfirmValue([values.diagramOfLoad,
                                          setFieldValue,
                                          "diagramOfLoad",
                                          att.id])
                                      }}
                                    >
                                      <DeleteOutlined
                                        sx={{ color: commonCss.red }}
                                      />
                                    </IconButton>
                                  {/* )} */}
                                </Box>
                              </Box>
                              <Divider />
                            </>
                          ))}
                        </Box>
                        {/* )} */}
                        {errors.diagramOfLoad && (
                          <ErrorMessage name="diagramOfLoad">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: 0.5 }}>
                          <Typography
                            variant="body2"
                            fontWeight="500"
                            color="black"
                          >
                            Diagram of Proposed Rigging & Slings (Include
                            Measurements)
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        {/* {uploadedFiles.diagramOfProposedRigging ? (
                          <Button
                            variant="outlined"
                            sx={{
                              display: roleAccess.contractStaff
                                ? "none"
                                : "default",
                              backgroundColor: commonCss.red,
                              borderRadius: commonCss.buttonBorderRadius,
                              textTransform: "none",
                              padding: "0.6rem 1rem",
                              color: commonCss.buttonTextColor,
                              width: "15vw",
                            }}
                            onClick={() =>
                              handleOpenDialog(
                                uploadedFiles.diagramOfProposedRigging
                              )
                            }
                          >
                            View File
                          </Button>
                        ) : selectedProposed?.length > 0 ? (
                          <Button
                            variant="outlined"
                            sx={{
                              display: roleAccess.contractStaff
                                ? "none"
                                : "default",
                              backgroundColor: commonCss.red,
                              borderRadius: commonCss.buttonBorderRadius,
                              textTransform: "none",
                              padding: "0.6rem 1rem",
                              color: commonCss.buttonTextColor,
                              width: "15vw",
                            }}
                            onClick={() => handleOpenDialog(siteLocationData)}
                          >
                            <Typography sx={{ fontSize: "0.9rem" }}>
                              View File
                            </Typography>
                          </Button>
                        ) : ( */}
                        {/* <Box
                            component={"label"}
                            sx={{
                              background: commonCss.inputBackgroundColor,
                              visibility: roleAccess.contractStaff
                                ? "hidden"
                                : "visible",
                              display: "flex",
                              gap: "0.3rem",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              height: "10vh",
                              cursor: "pointer",
                              border: `1px solid ${commonCss.inputBorderColor}`,
                              borderRadius: commonCss.inputBorderRadius,
                              mb: 2,
                            }}
                          > */}
                        <Button
                          disabled={roleAccess.contractStaff}
                          component="label"
                          sx={{
                            background: commonCss.inputBackgroundColor,
                            display: "flex",
                            gap: "0.3rem",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "10vh",
                            cursor: "pointer",
                            border: `1px solid ${commonCss.inputBorderColor}`,
                            borderRadius: commonCss.inputBorderRadius,
                            mb: 2,
                            color: "inherit",
                          }}
                        >
                          <FileUploadOutlined />{" "}
                          <Typography
                            sx={{ fontSize: "0.9rem", textTransform: "none" }}
                          >
                            Upload Diagram of Proposed Rigging
                          </Typography>
                          <input
                            readOnly={roleAccess.contractStaff}
                            type="file"
                            ref={fileInputRef}
                            // accept={"image/*"}
                            hidden
                            onChange={(e) =>
                              handleFileUpload(
                                e,
                                "diagramOfProposedRigging",
                                setFieldValue,
                                values.diagramOfProposedRigging
                              )
                            }
                          />
                        </Button>
                        {/* </Box> */}
                        <Box
                          sx={{
                            backgroundColor: "white",
                            p: 1,
                            display:
                              values.diagramOfProposedRigging.length !== 0
                                ? "default"
                                : "none",
                          }}
                        >
                          {values.diagramOfProposedRigging.map((att, i) => (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <Typography sx={{ width: "70%" }}>
                                  {att.fileName}
                                </Typography>
                                {/* <Typography>{att}</Typography> */}
                                <Box
                                  sx={{
                                    whiteSpace: "nowrap",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    width: "30%",
                                    alignItems: "center",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => {
                                      handleVisible(att.fileUrl);
                                    }}
                                  >
                                    <Visibility sx={{ color: commonCss.red }} />
                                  </IconButton>
                                  {/* {confirmIndexRigging === i ? (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                        gap: 1,
                                      }}
                                    >
                                      <ErrorProps>
                                        Are you sure wants to delete
                                      </ErrorProps>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          gap: "0.2rem",
                                          mb: 1,
                                        }}
                                      >
                                        <Button
                                          onClick={() =>
                                            handleDelete(
                                              values.diagramOfProposedRigging,
                                              setFieldValue,
                                              "diagramOfProposedRigging",
                                              att.id
                                            )
                                          }
                                          sx={{
                                            color: commonCss.red,
                                            borderColor: commonCss.red,
                                          }}
                                          size="small"
                                          variant="outlined"
                                        >
                                          Yes
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            setConfirmIndexRigging(null)
                                          }
                                          sx={{
                                            color: commonCss.green,
                                            borderColor: commonCss.green,
                                          }}
                                          size="small"
                                          variant="outlined"
                                        >
                                          No
                                        </Button>
                                      </Box>
                                    </Box>
                                  ) : ( */}
                                    <IconButton
                                      disabled={roleAccess.contractStaff}
                                      onClick={() => {
                                        setConfirm({...confirm,delete:true})
                                        setConfirmValue([values.diagramOfProposedRigging,
                                          setFieldValue,
                                          "diagramOfProposedRigging",
                                          att.id])
                                      }}
                                    >
                                      <DeleteOutlined
                                        sx={{ color: commonCss.red }}
                                      />
                                    </IconButton>
                                  {/* )} */}
                                </Box>
                              </Box>
                              <Divider />
                            </>
                          ))}
                        </Box>
                        {/* )} */}
                        {errors.diagramOfProposedRigging && (
                          <ErrorMessage name="diagramOfProposedRigging">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        )}
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={4}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"1rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                          textAlign={"center"}
                        >
                          Lifting Inspectator
                        </Typography>
                        <Divider
                          sx={{
                            borderColor: commonCss.red,
                            borderWidth: 1,
                            width: "100%",
                            mt: 1,
                          }}
                        />
                      </Grid2>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.normalSentenceWeight}
                          color={commonCss.normalSenternceColor}
                          sx={{ textTransform: "none" }}
                        >
                          I certify that I have reviewed the proposed work and I
                          am satisfied that it is adequately described on the
                          permit to work and that the precautions detailed are
                          adequate and clearly defined
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Name
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={approveInfo.liftName}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.lift,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.lift
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Date/Time
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              value={
                                values.isLiftingInspectorApproved
                                  ? dayjs(values.liftingInspectorApprovedDate)
                                  : null
                              }
                              readOnly={roleAccess.lift}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: roleAccess.lift
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 container size={12} justifyContent={"center"}>
                        {/* <Button
                          type="button"
                          variant="contained"
                          disabled={values.isLPIApproved}
                          sx={{
                            backgroundColor: commonCss.green,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            display: roleAccess.lift ? "none" : "default",
                          }}
                          onClick={() => {
                            setRemarkButton({ ...remarkButton, reject: true });
                          }}
                        >
                          Reject
                        </Button> */}
                        <Button
                          type="submit"
                          variant="contained"
                          disabled={values.isLPIApproved}
                          sx={{
                            backgroundColor: commonCss.green,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            display: roleAccess.lift ? "none" : "default",
                          }}
                          // onClick={() => {
                          //   setFieldValue("isLPIApproved", true);
                          //   setFieldValue("lpiId", login.userId);
                          //   setFieldValue(
                          //     "lpiApprovedDate",
                          //     moment().format("YYYY-MM-DDTHH:mm:ss")
                          //   );
                          //   setApproveInfo({
                          //     ...approveInfo,
                          //     lpiName: login.firstName + " " + login.lastName,
                          //   });
                          // }}
                        >
                          Acknowledge
                        </Button>
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>

                <Grid2 container size={4}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"1rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                          textAlign={"center"}
                        >
                          LPI
                        </Typography>
                        <Divider
                          sx={{
                            borderColor: commonCss.red,
                            borderWidth: 1,
                            width: "100%",
                            mt: 1,
                          }}
                        />
                      </Grid2>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.normalSentenceWeight}
                          color={commonCss.normalSenternceColor}
                          sx={{ textTransform: "none" }}
                        >
                          Lifting And Hoisting is described in the permit to
                          work is allowed between:
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Name
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={approveInfo.lpiName}
                          slotProps={{
                            input: {
                              readOnly: true, // Set readOnly to true
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.lpi
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Date/Time
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              readOnly
                              value={
                                values.isLPIApproved
                                  ? dayjs(values.lpiApprovedDate)
                                  : null
                              }
                              format="DD/MM/YYYY HH:mm"
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: roleAccess.lpi
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            From
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DatePicker
                              ampm={false}
                              format="DD/MM/YYYY"
                              readOnly={roleAccess.lpi}
                              minDate={
                                approvedData.isLeadPermitIssuerApproved
                                  ? dayjs(approvedData.lpiPermitValidFrom)
                                  : approvedData.isPermitIssuerApproved
                                  ? dayjs(approvedData.piPermitValidFrom)
                                  : dayjs(moment())
                              }
                              maxDate={
                                approvedData.isLeadPermitIssuerApproved
                                  ? dayjs(approvedData.lpiPermitValidUpTo)
                                  : approvedData.isPermitIssuerApproved
                                  ? dayjs(approvedData.piPermitValidUpTo)
                                  : undefined
                              }
                              value={
                                values.certificateValidFrom !== null
                                  ? dayjs(values.certificateValidFrom)
                                  : null
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d).utc();
                                    setFieldValue(
                                      "certificateValidFrom",
                                      dateFrom
                                    );

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: roleAccess.lpi
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Until
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DatePicker
                              ampm={false}
                              format="DD/MM/YYYY"
                              readOnly={roleAccess.lpi}
                              minDate={
                                approvedData.isLeadPermitIssuerApproved
                                  ? dayjs(approvedData.lpiPermitValidFrom)
                                  : approvedData.isPermitIssuerApproved
                                  ? dayjs(approvedData.piPermitValidFrom)
                                  : dayjs(moment())
                              }
                              maxDate={
                                approvedData.isLeadPermitIssuerApproved
                                  ? dayjs(approvedData.lpiPermitValidUpTo)
                                  : approvedData.isPermitIssuerApproved
                                  ? dayjs(approvedData.piPermitValidUpTo)
                                  : undefined
                              }
                              value={
                                values.certificateValidTo !== null
                                  ? dayjs(values.certificateValidTo)
                                  : null
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d).utc();
                                    setFieldValue(
                                      "certificateValidTo",
                                      dateFrom
                                    );

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: roleAccess.lpi
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2
                        container
                        size={12}
                        justifyContent={"center"}
                        sx={{ display: roleAccess.lpi ? "none" : "default" }}
                      >
                        <Button
                          type="button"
                          variant="contained"
                          disabled={values.isLPIApproved}
                          sx={{
                            backgroundColor: commonCss.red,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            display: roleAccess.lpi ? "none" : "default",
                          }}
                          onClick={() => {
                            setRemarkButton({ ...remarkButton, reject: true });
                          }}
                        >
                          Reject
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          disabled={values.isLPIApproved}
                          sx={{
                            backgroundColor: commonCss.green,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            display: roleAccess.lpi ? "none" : "default",
                          }}
                          // onClick={() => {
                          //   setFieldValue("isLPIApproved", true);
                          //   setFieldValue("lpiId", login.userId);
                          //   setFieldValue(
                          //     "lpiApprovedDate",
                          //     moment().format("YYYY-MM-DDTHH:mm:ss")
                          //   );
                          //   setApproveInfo({
                          //     ...approveInfo,
                          //     lpiName: login.firstName + " " + login.lastName,
                          //   });
                          // }}
                        >
                          Approve
                        </Button>
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={4}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"1rem"}>
                      <Grid2 item xs={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.normalSentenceWeight}
                          color={commonCss.normalSenternceColor}
                          sx={{ textTransform: "none" }}
                        >
                          Lifting And Hoisting Certificate is certificate and
                          permit to work are withdrawn:
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Permit Holder Name
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          slotProps={{
                            input: {
                              readOnly: true,
                            },
                          }}
                          value={
                            values.isPHCertificateClosed !== null
                              ? approveInfo.certificateClosedName
                              : null
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.certClose
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Permit Holder Date/Time
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              readOnly
                              value={
                                values.isPHCertificateClosed
                                  ? dayjs(values.phCertificateClosedAt)
                                  : null
                              }
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: roleAccess.certClose
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Permit Holder Reason
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="reason"
                          value={closedRemark.PH}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.certClose,
                            },
                          }}
                          onChange={(e) => {
                            setClosedRemark({
                              ...closedRemark,
                              PH: e.target.value,
                            });
                          }}
                          multiline
                          minRows={2}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.certClose
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 container size={12} justifyContent={"center"}>
                        <Button
                          variant="contained"
                          disabled={values.isPHCertificateClosed}
                          sx={{
                            backgroundColor: commonCss.green,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            display: roleAccess.certClose ? "none" : "default",
                          }}
                          onClick={() => {
                            setConfirm({...confirm,close:true})
                          }}
                        >
                          Close
                        </Button>
                      </Grid2>
                      <Grid2
                        container
                        size={12}
                        justifyContent={"center"}
                        sx={{
                          display: values.isPHCertificateClosed
                            ? "default"
                            : "none",
                        }}
                      >
                        <Grid2 size={{ xs: 12 }}>
                          <InputLabel>
                            <Typography
                              variant="body2"
                              fontWeight={commonCss.labelFontWeight}
                              color={commonCss.labelInputColor}
                            >
                              LPI Name
                            </Typography>
                          </InputLabel>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            slotProps={{
                              input: {
                                readOnly: true,
                              },
                            }}
                            value={
                              values.isLPICertificateClosed !== null
                                ? approveInfo.lpiCertClosedName
                                : null
                            }
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: roleAccess.lpiCertCloseReason
                                  ? commonCss.readOnlyBGColor
                                  : commonCss.inputBackgroundColor,
                                borderRadius: commonCss.inputBorderRadius,
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: commonCss.inputBorderColor,
                              },
                            }}
                          />
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                          <InputLabel>
                            <Typography
                              variant="body2"
                              fontWeight={commonCss.labelFontWeight}
                              color={commonCss.labelInputColor}
                            >
                              LPI Date/Time
                            </Typography>
                          </InputLabel>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoItem>
                              <DateTimePicker
                                ampm={false}
                                format="DD/MM/YYYY HH:mm"
                                readOnly
                                value={
                                  values.isLPICertificateClosed
                                    ? dayjs(values.lpiCertificateClosedAt)
                                    : null
                                }
                                viewRenderers={{
                                  hours: renderTimeViewClock,
                                  minutes: renderTimeViewClock,
                                  seconds: renderTimeViewClock,
                                }}
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    variant: "outlined",
                                  },
                                }}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    borderRadius: commonCss.inputBorderRadius,
                                    backgroundColor:
                                      roleAccess.lpiCertCloseReason
                                        ? commonCss.readOnlyBGColor
                                        : commonCss.inputBackgroundColor,
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: commonCss.inputBorderColor,
                                  },
                                }}
                                fullWidth
                              />
                            </DemoItem>
                          </LocalizationProvider>
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                          <InputLabel>
                            <Typography
                              variant="body2"
                              fontWeight={commonCss.labelFontWeight}
                              color={commonCss.labelInputColor}
                            >
                              Reason
                            </Typography>
                          </InputLabel>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            name="reason"
                            value={closedRemark.LPI}
                            slotProps={{
                              input: {
                                readOnly: roleAccess.lpiCertCloseReason,
                              },
                            }}
                            onChange={(e) => {
                              setClosedRemark({
                                ...closedRemark,
                                LPI: e.target.value,
                              });
                            }}
                            multiline
                            minRows={2}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: roleAccess.lpiCertCloseReason
                                  ? commonCss.readOnlyBGColor
                                  : commonCss.inputBackgroundColor,
                                borderRadius: commonCss.inputBorderRadius,
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: commonCss.inputBorderColor,
                              },
                            }}
                          />
                        </Grid2>
                        <Grid2 container size={12} justifyContent={"center"}>
                          <Button
                            variant="contained"
                            disabled={values.isLPICertificateClosed}
                            sx={{
                              backgroundColor: commonCss.green,
                              borderRadius: commonCss.buttonBorderRadius,
                              textTransform: "none",
                              padding: "0.6rem 1rem",
                              color: commonCss.buttonTextColor,
                              width: "15vw",
                              display: roleAccess.lpiCertCloseReason
                                ? "none"
                                : "default",
                            }}
                            onClick={() => {
                              setConfirm({...confirm,close:true})
                            }}
                          >
                            Close
                          </Button>
                        </Grid2>
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2
                  container
                  size={12}
                  justifyContent={"flex-end"}
                  sx={{
                    display: roleAccess.piContract ? "default" : "none",
                  }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      backgroundColor: commonCss.green,
                      borderRadius: commonCss.buttonBorderRadius,
                      textTransform: "none",
                      padding: "0.6rem 1rem",
                      color: commonCss.buttonTextColor,
                      width: "10vw",
                    }}
                  >
                    Submit
                  </Button>
                </Grid2>
              </Grid2>
              <Dialog
                // maxWidth={"lg"}
                open={remarkButton.reject}
                onClose={() => {
                  setRemarkButton({ ...remarkButton, reject: false });
                }}
                maxWidth="md"
                fullWidth
                PaperProps={{
                  sx: {
                    background: commonCss.normalLinearGradient,
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogContent>
                  <Remark
                    handleSubmitRemark={(e) => {
                      handleReject(values, e);
                    }}
                    handleCloseClick={() => {
                      setRemarkButton({ ...remarkButton, reject: false });
                    }}
                  />
                </DialogContent>
              </Dialog>
              <Dialog
        // maxWidth={"lg"}
  open={confirm.submit}
  onClose={()=>{setConfirm({...confirm,submit:false})}}
maxWidth={"md"}
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Confirmation
  onSubmit={()=>{onApproveAndSubmit(...confirmValue)}} 
  name={'submit'}
  handleCloseClick={()=>{
    setConfirm({...confirm,submit:false})
    setConfirmValue([])
    }}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={confirm.close}
  onClose={()=>{setConfirm({...confirm,close:false})}}
maxWidth={"md"}
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Confirmation 
  onSubmit={()=>{handleCloseCertificate()}} 
  name={'close'}
  handleCloseClick={()=>{
    setConfirm({...confirm,close:false})
    setConfirmValue([])
    }}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={confirm.delete}
  onClose={()=>{setConfirm({...confirm,delete:false})}}
maxWidth={"md"}
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Confirmation 
  onSubmit={()=>{handleDelete(...confirmValue)}} 
  name={'delete'}
  handleCloseClick={()=>{
    setConfirm({...confirm,delete:false})
    setConfirmValue([])
    }}/>
  </DialogContent>
       </Dialog>
            </Form>
          )}
        </Formik>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default LiftingAndHoisting;

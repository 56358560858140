import { Box, Checkbox, Divider, FormControlLabel, FormGroup, Grid2, IconButton, Paper, TextField, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { Clear, Save } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { commonCss } from "../StyleConfig/CommonCss";
import { roleInfo } from "../Pages/API/config";


const Remark = ({handleCloseClick,handleSubmitRemark}) => {
     const login = useSelector((state) => state.auth.loginInfo);
     console.log(login)
 
    const dispatch=useDispatch()

    const [remarkText,setRemarkText]=useState("")

    

   

    const handleSave=async()=>{
      const roleInformation=[roleInfo.contractStaff,roleInfo.agt,roleInfo.technican,roleInfo.oe,roleInfo.controller,roleInfo.sme,roleInfo.engineer,roleInfo.operationManager,roleInfo.HSSE,roleInfo.pi,roleInfo.lpi,roleInfo.liftingInspector]
      const commonValues = String(login.roleIds).split(",").filter(value => roleInformation.includes(value));
      let getRoleName=commonValues[0]===roleInfo.contractStaff?"Staff":
      commonValues[0]===roleInfo.agt?"AGT":
      commonValues[0]===roleInfo.technican?"Technician":
      commonValues[0]===roleInfo.sme?"SME":
      commonValues[0]===roleInfo.oe?"OE":
      commonValues[0]===roleInfo.controller?"Controller":
      commonValues[0]===roleInfo.engineer?"Engineer":
      commonValues[0]===roleInfo.HSSE?"HSSE Verifier":
      commonValues[0]===roleInfo.pi?"PI":
      commonValues[0]===roleInfo.lpi?"LPI":"User"
    handleSubmitRemark(`${login.fullName+" @$/ "+ getRoleName +"@$/"+remarkText}`)
    }
   
  return (
    <Paper sx={{ background: commonCss.shadeLinearGradient, p: 1.5 }}>
      <Grid2 container>
        <Grid2 size={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography  fontWeight={600} color={commonCss.green} sx={{fontSize:"1.3rem"}}>
              Remark
            </Typography>
            <Box sx={{display:"flex",alignItems:"center",width:"40%",justifyContent:"flex-end",gap:"1rem"}}>
              <IconButton onClick={handleSave}>
                <Save sx={{color:commonCss.red}}/>
              </IconButton>
              <IconButton onClick={handleCloseClick}>
                <Clear sx={{color:commonCss.red}}/>
              </IconButton>
            </Box>
          </Box>
          <Divider sx={{borderColor: commonCss.red, borderWidth: 1,width:"100%"}}/>
        </Grid2>
        <Grid2 size={12}>
        
               <TextField
              fullWidth
              type="number"
              variant="outlined"
              size="small"
              value={remarkText}
              multiline
              minRows={2}
              onChange={(e)=>{setRemarkText(e.target.value)}}
              sx={{
             
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
           
        </Grid2>
      </Grid2>
    </Paper>
  );
};

export default Remark;

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  createFilterOptions,
  Divider,
  Grid2,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  CellTower,
  Clear,
  Password,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

import { axiosPrivate } from "../../../axios/axios";
import { api, companyInfo, roleInfo, userTypeInfo } from "../../API/config";
import { commonCss } from "../../../StyleConfig/CommonCss";
import NormalTable from "../../../Component/NormalTable";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import moment from "moment";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import ErrorProps from "../../../Component/Errorprops";
import { toast } from "react-toastify";

const UserModal = ({ mode, handleCloseClick, editData }) => {
  const login = useSelector((state) => state.auth.loginInfo);
  const [loading, setLoading] = useState(false);
  const [terminal, setTerminal] = useState([]);
  const [terminalShow, setTerminalShow] = useState(
    mode === "Edit"
      ? editData.userTypeId === userTypeInfo.internal
        ? true
        : false
      : false
  );
  const [userType, setuserType] = useState([]);
  const [role, setRole] = useState([]);
  const [selectRole, setSelRole] = useState(
    mode === "Edit" ? editData.roleIds===null?[]:editData.roleIds : []
  );
  const [company, setCompany] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const multiRoleReject = [
    roleInfo.agt,
    roleInfo.sme,
    roleInfo.liftingInspector,
  ];
  const ableMultiRole = [
    roleInfo.operationManager,
    roleInfo.isolationOwner,
    roleInfo.isolationVerifier,
    roleInfo.contractStaff,
  ];

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };
  useEffect(() => {
    if (login.userTypeId) {
      if (userTypeInfo.external === login.userTypeId) {
        getRoleByUserType(login.userTypeId);
      } else if (userTypeInfo.internal === login.userTypeId) {
        if (userTypeInfo.internal === login.userTypeId) {
        }
        getRoleByUserType(login.userTypeId);
      }
    }
    if (userTypeInfo.internal === login.userTypeId && mode === "Create") {
      setTerminalShow(true);
    }
  }, [login.userTypeId]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    const getUserType = api.userType.getAll;
    await axiosPrivate
      .get(`${getUserType}`)
      .then((res) => {
        setuserType(res.data.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setuserType([]);
        setLoading(false);
      });

    const getCompany = api.company.getAll;
    await axiosPrivate
      .get(`${getCompany}`)
      .then((res) => {
        setCompany(res.data.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setCompany([]);
        setLoading(false);
      });
    const terminalLink = api.terminal.getAll;
    await axiosPrivate
      .get(`${terminalLink}`)
      .then((res) => {
        setTerminal(res.data.data);
      })
      .catch((e) => {
        console.log(e);
        setTerminal([]);
      });

    if (mode === "Edit") {
      getRoleByUserType(editData.userTypeId);
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const initialValue = {
    firstName: mode === "Edit" ? editData.firstName : "",
    lastName: mode === "Edit" ? editData.lastName : "",
    emailId: mode === "Edit" ? editData.emailId : "",
    password: mode === "Edit" ? editData.password : "",
    userTypeId:mode === "Edit"
        ? editData.userTypeId
        : login.userTypeId === userTypeInfo.external
        ? login.userTypeId
        : login.userTypeId,
    companyId:
      mode === "Edit"
        ? editData.companyId
        : login.userTypeId === userTypeInfo.external
        ? login.companyId
        : companyInfo.ps,
    terminalIds:
      mode === "Edit"
        ? editData.terminalIds !== null
          ? editData.terminalIds
          : []
        : [],
    validityUpto: mode === "Edit" ? editData.validityUpto : null,
  };

  const onSubmit = async (value) => {
    console.log(value);
    setLoading(true);

    const createLink = api.user.add;
    const updateLink = api.user.edit;
    if (mode === "Create") {
      const postData = {
        ...value,
        roleIds: selectRole,
      };
      await axiosPrivate
        .post(`${createLink}`, postData)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e.response.data.message)
          console.log(e, "create User");
        });
    } else {
      const updateAC = {
        id: editData.id,
        ...value,
        roleIds: selectRole,
      };
      console.log(updateAC);
      await axiosPrivate
        .put(`${updateLink}/${editData.id}`, updateAC)
        .then((res) => {
          console.log(res);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e.response.data.message)
          console.log(e, "update User");
        });
    }
  };

  const handleCancel = () => {
    handleCloseClick();
  };

  const getRoleByUserType = async (id) => {
    const getRole = api.role.getByUserType;
    await axiosPrivate
      .get(`${getRole}?userTypeId=${id}`)
      .then((res) => {
        console.log(res);
        console.log(id === userTypeInfo.external);
        if (id === userTypeInfo.external) {
          const filterData = res.data.data.filter(
            (fil) =>
              fil.id === roleInfo.contractStaff ||
              fil.id === roleInfo.admin ||
              fil.id === roleInfo.agt
          );

          setRole(filterData);
        } else {
          setRole(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
        setRole([]);
      });
  };

  const handleCheckBox = (e, id) => {
    if (selectRole.includes(id)) {
      const filterRole = selectRole.filter((fil) => fil !== id);
      setSelRole(filterRole);
    } else {
      setSelRole([...selectRole, id]);
    }
  };

  const columns = [
    {
      accessor: "id",
      Cell: ({ cell }) => {
        return (
          <Checkbox
            checked={selectRole.includes(cell.value)}
            sx={{
              "& .MuiSvgIcon-root": {
                color: commonCss.green,
              },
              "&:checked": {
                color: commonCss.green,
              },
            }}
            onClick={(e) => {
              handleCheckBox(e, cell.value);
            }}
          />
        );
      },
    },
    {
      Header: "Role Name",
      accessor: "roleName",
    },
  ];
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("This field is required"),
    emailId: Yup.string().email().required("This field is required"),
    password: Yup.string().required("This field is required"),
    userTypeId: Yup.string().required("This field is required"),
    terminalIds: Yup.array().when("userTypeId", {
      is: (value) => value === userTypeInfo.internal,
      then: (schema) => schema.min(1, "This field is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    companyId: Yup.string().when("userTypeId", {
      is: (value) => value === userTypeInfo.external,
      then: (schema) => schema.required("This field is required"),
      otherwise: (schema) => schema.notRequired(),
    }),

    // roleIds: Yup.array().min(1, "This field is required"),
  });
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: commonCss.titleColor,
            fontSize: commonCss.titleFontSize,
            fontWeight: commonCss.titleFontWeight,
          }}
        >
          {mode === "Edit"
            ? editData.firstName + " " + editData.lastName
            : "New User"}
        </Typography>
        <IconButton onClick={handleCancel}>
          <Clear />
        </IconButton>
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ handleChange, setFieldValue, values, resetForm, errors }) => (
          <Form>
            <Grid2 container spacing={"1rem"}>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    First Name
                    <Typography
                      component="span"
                      color="error"
                      fontWeight="bold"
                      sx={{ ml: 0.5 }}
                    >
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <TextField
                  size={"small"}
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  fullWidth
                  onChange={handleChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: commonCss.inputBackgroundColor,
                      borderRadius: commonCss.inputBorderRadius,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: commonCss.inputBorderColor,
                    },
                  }}
                />
                {errors.firstName && (
                  <ErrorMessage name="firstName">
                    {(error) => <ErrorProps>{error}</ErrorProps>}
                  </ErrorMessage>
                )}
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    Last Name
                  </Typography>
                </InputLabel>
                <TextField
                  size={"small"}
                  type="text"
                  name="lastName"
                  value={values.lastName}
                  fullWidth
                  onChange={handleChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: commonCss.inputBackgroundColor,
                      borderRadius: commonCss.inputBorderRadius,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: commonCss.inputBorderColor,
                    },
                  }}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    Email
                    <Typography
                      component="span"
                      color="error"
                      fontWeight="bold"
                      sx={{ ml: 0.5 }}
                    >
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <TextField
                  size={"small"}
                  type="email"
                  name="emailId"
                  value={values.emailId}
                  fullWidth
                  onChange={handleChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: commonCss.inputBackgroundColor,
                      borderRadius: commonCss.inputBorderRadius,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: commonCss.inputBorderColor,
                    },
                  }}
                />
                {errors.emailId && (
                  <ErrorMessage name="emailId">
                    {(error) => <ErrorProps>{error}</ErrorProps>}
                  </ErrorMessage>
                )}
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    Password
                    <Typography
                      component="span"
                      color="error"
                      fontWeight="bold"
                      sx={{ ml: 0.5 }}
                    >
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <TextField
                  size={"small"}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={values.password}
                  fullWidth
                  onChange={handleChange}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            // onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: commonCss.inputBackgroundColor,
                      borderRadius: commonCss.inputBorderRadius,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: commonCss.inputBorderColor,
                    },
                  }}
                />
                {errors.password && (
                  <ErrorMessage name="password">
                    {(error) => <ErrorProps>{error}</ErrorProps>}
                  </ErrorMessage>
                )}
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    User Type
                    <Typography
                      component="span"
                      color="error"
                      fontWeight="bold"
                      sx={{ ml: 0.5 }}
                    >
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <Select
                  size="small"
                  displayEmpty
                  value={values.userTypeId}
                  name="userTypeId"
                  inputProps={{ "aria-label": "Technician" }}
                  sx={{
                    borderRadius: "13px",
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "747373",
                    },
                    pointerEvents:
                      login.userTypeId === userTypeInfo.external
                        ? "none"
                        : "auto",
                  }}
                  onChange={(e) => {
                    setFieldValue("userTypeId", e.target.value);
                    getRoleByUserType(e.target.value);
                    if (e.target.value === userTypeInfo.internal) {
                      setFieldValue("companyId", companyInfo.ps);
                      setTerminalShow(true);
                    } else {
                      setFieldValue("companyId", null);
                      setTerminalShow(false);
                    }
                  }}
                  fullWidth
                >
                  {userType.map((type, i) => (
                    <MenuItem key={i} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.userTypeId && (
                  <ErrorMessage name="userTypeId">
                    {(error) => <ErrorProps>{error}</ErrorProps>}
                  </ErrorMessage>
                )}
              </Grid2>
              <Grid2
                size={{ xs: 12, md: 6 }}
                sx={{ display: !terminalShow ? "default" : "none" }}
              >
                <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    Company
                    <Typography
                      component="span"
                      color="error"
                      fontWeight="bold"
                      sx={{ ml: 0.5 }}
                    >
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <Select
                  size="small"
                  displayEmpty
                  value={values.companyId}
                  name="companyId"
                  inputProps={{ "aria-label": "Technician" }}
                  sx={{
                    borderRadius: "13px",
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "747373",
                    },
                    pointerEvents:
                      login.userTypeId === userTypeInfo.external
                        ? "none"
                        : "auto",
                  }}
                  onChange={handleChange}
                  fullWidth
                >
                  {company.map((type, i) => (
                    <MenuItem
                      key={i}
                      value={type.id}
                      sx={{
                        display:
                          type.id === companyInfo.ps ? "none" : "default",
                      }}
                    >
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.companyId && (
                  <ErrorMessage name="companyId">
                    {(error) => <ErrorProps>{error}</ErrorProps>}
                  </ErrorMessage>
                )}
              </Grid2>
              <Grid2
                size={{ xs: 12, md: 6 }}
                sx={{ display: terminalShow ? "default" : "none" }}
              >
                <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    Terminal
                    <Typography
                      component="span"
                      color="error"
                      fontWeight="bold"
                      sx={{ ml: 0.5 }}
                    >
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                <Select
                  size="small"
                  displayEmpty
                  value={values.terminalIds[0]}
                  name="terminalIds"
                  sx={{
                    borderRadius: "13px",
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "747373",
                    },
                  }}
                  onChange={(e) => {
                    setFieldValue("terminalIds", [e.target.value]);
                  }}
                  fullWidth
                >
                  {terminal.map((type, i) => (
                    <MenuItem key={i} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.terminalIds && (
                  <ErrorMessage name="terminalIds">
                    {(error) => <ErrorProps>{error}</ErrorProps>}
                  </ErrorMessage>
                )}
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    Validity Upto
                  </Typography>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DatePicker
                      ampm={false}
                      format="DD/MM/YYYY"
                      value={
                        values.validityUpto !== null
                          ? dayjs(values.validityUpto)
                          : null
                      }
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                        },
                      }}
                      onChange={(newValue) => {
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d);
                            setFieldValue("validityUpto", dateFrom);

                            // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                            // setFieldValue("installationDate", dateFormat);
                          }
                        }
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: commonCss.inputBorderRadius,
                          backgroundColor: commonCss.inputBackgroundColor,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: commonCss.inputBorderColor,
                        },
                        pointerEvents:
                          login.userTypeId === userTypeInfo.external
                            ? "none"
                            : "auto",
                      }}
                      fullWidth
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid2>
              <Grid2
                size={{ xs: 12, md: 6 }}
                sx={{ display: role.length !== 0 ? "default" : "none" }}
              >
                <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    Role
                    <Typography
                      component="span"
                      color="error"
                      fontWeight="bold"
                      sx={{ ml: 0.5 }}
                    >
                      *
                    </Typography>
                  </Typography>
                </InputLabel>
                {/* <Select
                size="small"
                displayEmpty
                value={values.roleIds[0]}
                name="roleIds"
                inputProps={{ "aria-label": "Technician" }}
                sx={{
                  borderRadius: "13px",
                  backgroundColor: "white",
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '747373',
                  },
                }}
                onChange={(e)=>{setFieldValue("roleIds",[e.target.value])}}
                fullWidth
              >
                {role.map((type,i)=>(
                  <MenuItem key={i} value={type.id}>{type.roleName}</MenuItem>
                ))}
                
               
              </Select> */}

                <Table>
                  <TableBody>
                    {role.map((rol, i) => (
                      <TableRow>
                        <TableCell
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "black",
                            border: "none",
                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                            padding: "0.5rem",
                          }}
                        >
                          <Checkbox
                            disabled={
                              selectRole.includes(roleInfo.agt)
                                ? ![roleInfo.agt, ...ableMultiRole].includes(
                                    rol.id
                                  )
                                : selectRole.includes(roleInfo.sme)
                                ? ![roleInfo.sme, ...ableMultiRole].includes(
                                    rol.id
                                  )
                                : selectRole.includes(roleInfo.liftingInspector)
                                ? ![
                                    roleInfo.liftingInspector,
                                    ...ableMultiRole,
                                  ].includes(rol.id)
                                : selectRole.filter(
                                    (e) =>
                                      ![
                                        ...multiRoleReject,
                                        ableMultiRole,
                                      ].includes(e)
                                  ).length !== 0
                                ? multiRoleReject.includes(rol.id)
                                : false
                            }
                            checked={selectRole.includes(rol.id)}
                            sx={{
                              color: commonCss.green,
                              "&.Mui-checked": {
                                color: commonCss.green,
                              },
                            }}
                            onClick={(e) => {
                              handleCheckBox(e, rol.id);
                            }}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "black",
                            border: "none",
                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                            padding: "0.5rem",
                          }}
                        >
                          {rol.roleName}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {errors.roleIds && (
                  <ErrorMessage name="roleIds">
                    {(error) => <ErrorProps>{error}</ErrorProps>}
                  </ErrorMessage>
                )}
              </Grid2>
              <Grid2 container size={12} justifyContent={"flex-end"} gap="1rem">
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  sx={{
                    backgroundColor: commonCss.green,
                    borderRadius: commonCss.buttonBorderRadius,
                    textTransform: "none",
                    padding: "0.6rem 1rem",
                    color: commonCss.buttonTextColor,
                    width: "10vw",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  variant="contained"
                  disabled={loading}
                  sx={{
                    backgroundColor: commonCss.green,
                    borderRadius: commonCss.buttonBorderRadius,
                    textTransform: "none",
                    padding: "0.6rem 1rem",
                    color: commonCss.buttonTextColor,
                    width: "10vw",
                  }}
                >
                  Submit
                </Button>
              </Grid2>
            </Grid2>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default UserModal;

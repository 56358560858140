import {
  Button,
  Divider,
  Grid2,
  Paper,
  Typography,
  TextField,
  Select,
  Checkbox,
  ListItem,
  ListItemText,
  ListItemIcon,
  MenuItem,
  List,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Box,
  IconButton,
  Autocomplete,
  createFilterOptions,
  Dialog,
  DialogContent,
  Collapse,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { commonCss } from "../../../StyleConfig/CommonCss";
import {
  ArrowBack,
  EditOutlined,
  FileUploadOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import NormalTableWithRadius from "../../../Component/NormalTableWithRadius";
import dayjs from "dayjs";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { Form, Formik, ErrorMessage } from "formik";
import { useSelector } from "react-redux";
import { api, companyInfo, roleInfo } from "../../API/config";
import { axiosPrivate } from "../../../axios/axios";
import GasTestComponent from "./GasTestComponent";
import Loading from "../../../Component/Loading";
import { toast } from "react-toastify";
import * as Yup from "yup";
import ErrorProps from "../../../Component/Errorprops";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import { isCancel } from "axios";
import Remark from "../../../Component/Remark";
import Confirmation from "../../../Component/Confirmation";
import RemarkTableView from "../../../Component/RemarkTableView";

const HotWork = ({ handleCloseClick, certificateDetail, approvedData }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [certificateInfo, setCertificateInfo] = useState({});
  const login = useSelector((state) => state.auth.loginInfo);
  const loginRoleId = String(login.roleIds).split(",");
  const [agt, setAgt] = useState({
    agtBol: false,
    agtType: "",
    agtCom: "",
    agtId: null,
  });
  const [confirmIndex, setConfirmIndex] = useState(null);
  const [autoAgt, setAutoAgt] = useState("");
  const [agtData, setAgtData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [specifiedFrequency, setSpecifiedFreq] = useState(false);
  const [reassignagt, setReAssignAgt] = useState({
    agtBol: false,
    agtType: "",
    agtCom: "",
    agtId: null,
  });
  const [reAssign, setReassign] = useState(false);

  const [editGas, setEditGas] = useState({ bol: false, data: {} });
  const [gasData, setGasData] = useState([]);
  console.log("gasetest", gasData);
  const [approveInfo, setApproveInfo] = useState({
    engineerName: "",
    lpiName: "",
    certificateClosedName: "",
    lpiCertClosedName:""
  });

  const [roleAccess, setRoleAccess] = useState({
    contractStaff: false,
    engineer: false,
    lpi: false,
    lpiCertClose: false,
    lpiCertCloseReason: false,
    certClose: false,
    agt: false,
    adminAGT: false,
    piContract: false,
    reAssignButton: false,
    submitButton: false,
    cancelButton: false,
  });
  const [closedRemark, setClosedRemark] = useState({
    PH: "",
    LPI: "",
  });
  const [remarkButton, setRemarkButton] = useState({
    reject: false,
    cancel: false,
    remarkOpen: false,
  });
   const [confirm,setConfirm]=useState({
      submit:false,
      close:false,
      reAssign:false,
      agtDel:false,
      agt:false
    })
    const [confirmValue,setConfirmValue]=useState([])

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  useEffect(() => {
    if (certificateInfo.agtId !== null) {
      getDataHotWork();
    }
    getData();
  }, []);
  const getDataHotWork = async () => {
    const getGasDataLink = api.hotWork.getByHotWorkId;
    await axiosPrivate
      .get(`${getGasDataLink}?hotWorkId=${certificateDetail.id}`)
      .then((res) => {
        console.log(res);
        //const filteredData = res.data.data.filter((item) => !item.isDeleted);
        setGasData(res.data.data);
      })
      .catch((e) => {
        console.log(e);
        setGasData([]);
      });
  };

  const handleDeleteGas = (id) => {
    const deletegas = api.hotWork.delete;
    axiosPrivate
      .delete(`${deletegas}/${id}`)
      .then(() => {
        
      setConfirm({...confirm,agtDel:false})
      setConfirmValue([])
        toast.success("Successfully Deleted");
        getDataHotWork();
      })
      .catch((e) => {
        setConfirm({...confirm,agtDel:false})
        setConfirmValue([])
        console.error(e);
      });
  };

  const getData = async () => {
    setLoading(true);
    const getDataLink = api.hotWork.getById;
    await axiosPrivate
      .get(`${getDataLink}/${certificateDetail.id}`)
      .then((res) => {
        console.log(res);
        setCertificateInfo(res.data.data);
        setClosedRemark({
          PH: res.data.data.phCertificateClosedReason,
          LPI: res.data.data.lpiCertificateClosedReason,
        });
        setSpecifiedFreq(res.data.data?.gasTestWhileWorkInProgress);
        setApproveInfo({
          engineerName: res.data.data.isEngineerApproved
            ? res.data.data?.engineerUserDetail?.firstName +
              " " +
              res.data.data?.engineerUserDetail?.lastName
            : "",
          lpiName: res.data.data.isLPIApproved
            ? res.data.data?.lpiUserDetail?.firstName +
              " " +
              res.data.data?.lpiUserDetail?.lastName
            : "",
            certificateClosedName: res.data.data.isPHCertificateClosed
            ? approvedData?.permitHolderUser?.firstName +
              " " +
              approvedData?.permitHolderUser?.lastName
            : "",
            lpiCertClosedName: res.data.data.isLPICertificateClosed
            ? res.data.data?.lpiUserDetail?.firstName +
            " " +
            res.data.data?.lpiUserDetail?.lastName
          : "",

        });
        setRoleAccess({
           piContract: ((loginRoleId.includes(roleInfo.contractStaff)&&approvedData.assignedUsers.includes(login.userId)) &&
                                (!approvedData.isContractApproved ||
                                  res.data.data.status === null) &&
                                (res.data.data.status !== 8 ||
                                  !res.data.data.isCancel ||
                                  !approvedData.isCancel===true)) ||
                              (loginRoleId.includes(roleInfo.pi) &&
                                !approvedData.isPermitIssuerApproved&& approvedData.isHSSEApproved &&
                                res.data.data.status !== 8 &&
                                !res.data.data.isCancel &&
                                !approvedData.isCancel===true),
                    contractStaff:
                      !(loginRoleId.includes(roleInfo.contractStaff)&&approvedData.assignedUsers.includes(login.userId)) ||
                      (approvedData.isContractApproved &&
                        !(res.data.data.status === null)) ||
                      res.data.data.isCancel ||
                      approvedData.isCancel===true,
                    engineer:
                      !(loginRoleId.includes(roleInfo.engineer)&&approvedData.isTechnicianApproved&&approvedData.engineerId===login.userId) ||
                      res.data.data.isEngineerApproved ||
                      res.data.data.status === 8 ||
                      res.data.data.isCancel ||
                      approvedData.isCancel===true,
                      lpi:
                                  !(loginRoleId.includes(roleInfo.lpi)&& approvedData.isPermitIssuerApproved) ||
                                  res.data.data.isLPIApproved ||
                                  res.data.data.status === 8 ||
                                  res.data.data.isCancel ||
                                  approvedData.isCancel===true,
                    lpiCertClose:
                              !loginRoleId.includes(roleInfo.lpi)||
                              res.data.data.isLPICertificateClosed||
                                !res.data.data.isPHCertificateClosed ||
                                res.data.data.status === 8 ||
                                res.data.data.isCancel ||
                                approvedData.isCancel===true,
                                
                                lpiCertCloseReason:res.data.data.isLPICertificateClosed?true:(!loginRoleId.includes(roleInfo.lpi)||
                                res.data.data.isLPICertificateClosed||
                                  !res.data.data.isPHCertificateClosed ||
                                  res.data.data.status === 8 ||
                                  res.data.data.isCancel ||
                                  approvedData.isCancel===true),
                    certClose:
                      login.userId !== approvedData.permitHolderId ||
                      res.data.data.isPHCertificateClosed ||
                      !res.data.data.isLPIApproved ||
                      res.data.data.status === 8 ||
                      res.data.data.isCancel ||
                      approvedData.isCancel===true,
                    agt:
                      !(loginRoleId.includes(roleInfo.agt)&& approvedData.isContractApproved) ||
                      !res.data.data.agtId === login.userId ||
                      res.data.data.status === 8 ||
                      res.data.data.isCancel ||
                      res.data.data.isPHCertificateClosed ||
                      approvedData.isCancel===true,
                    adminAGT:
                      approvedData.isContractApproved &&
                      res.data.data.agtId !== null &&
                      !res.data.data.isPHCertificateClosed
                        ? (loginRoleId.includes(roleInfo.admin) ||
                            (loginRoleId.includes(roleInfo.agt) &&
                              res.data.data.agtId === login.userId) ||
                            (loginRoleId.includes(roleInfo.contractStaff)&&(approvedData.permitHolderId===login.userId))) &&
                          (res.data.data.status !== 8 ||
                            !res.data.data.isCancel ||
                            !approvedData.isCancel===true)
                        : (!approvedData.isContractApproved ||
                            res.data.data.status === null) &&
                          loginRoleId.includes(roleInfo.contractStaff) &&
                          (res.data.data.status !== 8 ||
                            !res.data.data.isCancel ||
                            !approvedData.isCancel===true),
                    // reAssignButton:res.data.data.agtId!==null?(approvedData.isContractApproved&&res.data.data.isAGTApproved?false:approvedData.isContractApproved?roleAccess.adminAGT||approvedData.isContractApproved:roleAccess.adminAGT&&approvedData.isContractApproved):false,
                    reAssignButton:
                      approvedData.isContractApproved &&
                      res.data.data.agtId !== null &&
                      !res.data.data.isPHCertificateClosed
                        ? (loginRoleId.includes(roleInfo.admin) ||
                            (loginRoleId.includes(roleInfo.agt) &&
                              res.data.data.agtId === login.userId) ||
                            (loginRoleId.includes(roleInfo.contractStaff)&&(approvedData.permitHolderId===login.userId))) &&
                          (res.data.data.status !== 8 ||
                            !res.data.data.isCancel ||
                            !approvedData.isCancel===true)
                        : false,
                    submitButton:
                      res.data.data.agtId !== null && !res.data.data.isPHCertificateClosed
                        ? (((loginRoleId.includes(roleInfo.pi)&& approvedData.isHSSEApproved) &&
                            !approvedData.isPermitIssuerApproved) ||
                            (loginRoleId.includes(roleInfo.contractStaff)&&approvedData.assignedUsers.includes(login.userId)) ||
                            (loginRoleId.includes(roleInfo.agt) &&
                              res.data.data.agtId === login.userId) ||
                            loginRoleId.includes(roleInfo.admin)) &&
                          (res.data.data.status !== 8 ||
                            !res.data.data.isCancel ||
                            !approvedData.isCancel===true)
                        : (((loginRoleId.includes(roleInfo.pi)&& approvedData.isHSSEApproved) &&
                            !approvedData.isPermitIssuerApproved) ||
                            !approvedData.isContractApproved ||
                            res.data.data.status === null) &&
                          (res.data.data.status !== 8 ||
                            !res.data.data.isCancel ||
                            !approvedData.isCancel===true),
                   cancelButton: res.data.data.isCancel || approvedData.isCancel===true
                                 ? false
                                 : res.data.data.isClone
                                 ? (loginRoleId.includes(roleInfo.lpi)&&!res.data.data.isLPIApproved)
                                 : false,
          //cancelButton:(res.data.data.status===8||res.data.data.isCancel)?((res.data.data.status===1&&res.data.data.isClone&&res.data.data.agtId===login.userId&&loginRoleId.includes(roleInfo.agt))||(res.data.data.status===2&&res.data.data.isClone&&loginRoleId.includes(roleInfo.engineer))||(res.data.data.status===3&&res.data.data.isClone&&loginRoleId.includes(roleInfo.lpi))):false
        });
        
        setRemarkButton({
          ...remarkButton,
          remarkOpen:
            (res.data.data.isCancel &&
              res.data.data.cancelRemarks !== null &&
              res.data.data.cancelRemarks !== "") ||
            (!res.data.data.isLPIApproved &&
              res.data.data.rejectedRemarks !== null &&
              res.data.data.rejectedRemarks !== "")
              ? true
              : false,
        });
        if (res.data.data.agtId !== null) {
          getAgt(res.data.data.agtUserDetail?.companyId);
        }

        setAgt({
          agtBol: res.data.data.agtId !== null ? true : false,
          agtType: res.data.data.agtUserDetail?.companyId,
          agtCom:
            res.data.data.agtId !== null
              ? res.data.data.agtUserDetail?.firstName +
                " " +
                res.data.data.agtUserDetail?.lastName
              : "",
          agtId: res.data.data.agtId,
        });

        setLoading(false);
      })
      .catch((e) => {
        setCertificateInfo({});
        setLoading(false);
        console.log(e);
      });
  };
  // const handleGas = () => {
  //   if (editGas.bol) {
  //     let update = [...gasData];
  //     update[editGas.i] = getGas;
  //     setGasData(update);
  //     setEditGas({ bol: false, i: null });
  //     setGas({
  //       o: "",
  //       c: "",
  //       h: "",
  //       lel: "",
  //       date: null,
  //       appro: "",
  //     });
  //   } else {
  //     setGasData([...gasData, getGas]);
  //     setGas({
  //       o: "",
  //       c: "",
  //       h: "",
  //       lel: "",
  //       date: null,
  //       appro: "",
  //     });
  //   }
  // };

  const gasColumns = [
    {
      Header: "O2",
      accessor: "o2",
    },

    {
      Header: "CO2",
      accessor: "cO2",
    },
    {
      Header: "H2S",
      accessor: "h2S",
    },
    {
      Header: "LEL",
      accessor: "lel",
    },
    {
      Header: "Date/Time",
      accessor: "agtApprovedDate",
    },
    {
      Header: "AGT Name",
      accessor: (row) =>
        `${row.userMaster.firstName} _ ${row.userMaster.lastName}`,
    },
    {
      accessor: "id",
      Cell: ({ cell }) => {
        return (
          <Box
            sx={{
              whiteSpace: "nowrap",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
            }}
          >
            <IconButton
              disabled={roleAccess.agt}
              onClick={() => {
                setEditGas({ bol: true, data: cell.row.original });
              }}
            >
              <EditOutlined sx={{ color: commonCss.red }} />
            </IconButton>

            {confirmIndex === cell.row.index ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <ErrorProps>Are you sure wants to delete</ErrorProps>
                <Button
                  onClick={() => {
                    handleDeleteGas(cell.row.original.id);
                    setConfirmIndex(null);
                  }}
                  sx={{
                    color: commonCss.red,
                    borderColor: commonCss.red,
                  }}
                  size="small"
                  variant="outlined"
                >
                  Yes
                </Button>
                <Button
                  onClick={() => setConfirmIndex(null)}
                  sx={{
                    color: commonCss.green,
                    borderColor: commonCss.green,
                  }}
                  size="small"
                  variant="outlined"
                >
                  No
                </Button>
              </Box>
            ) : (
              <IconButton
                disabled={roleAccess.agt}
                onClick={() => setConfirmIndex(cell.row.index)}
              >
                <DeleteOutlined sx={{ color: commonCss.red }} />
              </IconButton>
            )}
          </Box>
        );
      },
    },
  ];

  const initialValue = {
    ...certificateInfo,
    id: certificateInfo.id,
    formId: certificateInfo.formId,
    locationSpecified: certificateInfo.locationSpecified,
    ignitionSources: certificateInfo.ignitionSources,
    isFreeFlammable: certificateInfo.isFreeFlammable,
    sewerSafe: certificateInfo.sewerSafe,
    workingAreaSign: certificateInfo.workingAreaSign,
    isFireFightingMaterialExist: certificateInfo.isFireFightingMaterialExist,
    isGasDetectorInstalled: certificateInfo.isGasDetectorInstalled,
    isSafetyOfficierPresent: certificateInfo.isSafetyOfficierPresent,
    ptwHolderId:
      certificateInfo.ptwHolderId === null
        ? login.userId
        : certificateInfo.ptwHolderId,
    ptwHolderApprovedDate:
      certificateInfo.ptwHolderApprovedDate === null
        ? moment().utc()
        : certificateInfo.ptwHolderApprovedDate,
    gasTestOncebeforeCommencementOfTheWork:
      certificateInfo.gasTestOncebeforeCommencementOfTheWork,
    gasTestWhileWorkInProgress: certificateInfo.gasTestWhileWorkInProgress,
    agtId: certificateInfo.agtId,
    engineerId: certificateInfo.engineerId,
    isEngineerApproved: certificateInfo.isEngineerApproved,
    engineerApprovedDate: certificateInfo.engineerApprovedDate,
    isPHCertificateClosed: certificateInfo.isPHCertificateClosed,
    phCertificateClosedAt: certificateInfo.phCertificateClosedAt,
    isLPICertificateClosed: certificateInfo.isLPICertificateClosed,
    lpiCertificateClosedAt: certificateInfo.lpiCertificateClosedAt,
    lpiId: certificateInfo.lpiId,
    isLPIApproved: certificateInfo.isLPIApproved,
    lpiApprovedDate: certificateInfo.lpiApprovedDate,
    certificateValidFrom: certificateInfo.certificateValidFrom,
    certificateValidTo: certificateInfo.certificateValidTo,
    isAGTApproved: certificateInfo.isAGTApproved,
    specifiedFrequency: certificateInfo.specifiedFrequency,
    flammableGases: certificateInfo.flammableGases,
    status: certificateInfo.status,
  };

  const operationInfoList = [
    {
      name: "isFreeFlammable",
      label: "It is free of flammable material",
    },
    {
      name: "sewerSafe",
      label:
        "Sewer and drainer system, and other low lying areas are protected against sparks",
    },
    {
      name: "workingAreaSign",
      label: "The work area is defined with barriers/signs",
    },
    {
      name: "isFireFightingMaterialExist",
      label: "Additional fire fighting materials are present and ready to use",
    },
    {
      name: "isGasDetectorInstalled",
      label: "A continuous gas detector is installed",
    },
    {
      name: "isSafetyOfficierPresent",
      label: "Safety officer to Safety Supervisor",
    },
  ];

  const gasTestInfo = [
    {
      name: "gasTestOncebeforeCommencementOfTheWork",
      label: "• Once before commencement of the work",
    },
    {
      name: "gasTestWhileWorkInProgress",
      label: "• Test while work is in progress, at specified frequency",
    },
  ];

  const handleReject = (value, remark) => {
    const postData = {
      ...value,
      isApproved: false,
      remarks: remark,
      status: 8,
    };
    console.log(postData);
    const editLink = api.hotWork.editHotWork;
    axiosPrivate
      .put(`${editLink}/${certificateInfo.id}`, postData)
      .then((res) => {
        console.log(res);

        // getData()
        handleCloseClick();
        setRemarkButton({ ...remarkButton, reject: false });
        toast.success("Successfully Rejected");
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleCancel = (value, remark) => {
    const postData = {
      ...value,
      isCancel: true,
      cancelRemarks: remark,
      // status:8,
    };
    console.log(postData);
    const editLink = api.hotWork.editHotWork;
    axiosPrivate
      .put(`${editLink}/${certificateInfo.id}`, postData)
      .then((res) => {
        console.log(res);

        // getData()
        setRemarkButton({ ...remarkButton, cancel: false });
        handleCloseClick();
        toast.success("Successfully Cancelled");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onApproveAndSubmit=(value)=>{
    console.log(value)
    let engineerLPIPI =
      loginRoleId.includes(roleInfo.engineer)&&approvedData.isContractApproved ||
            loginRoleId.includes(roleInfo.lpi)&&approvedData.isPermitIssuerApproved;
    let statusValidation =
      (loginRoleId.includes(roleInfo.contractStaff)&&approvedData.assignedUsers.includes(login.userId) && value.status === null)||(loginRoleId.includes(roleInfo.contractStaff)&&approvedData.assignedUsers.includes(login.userId)&&value.status===8)
        ? 1
        : loginRoleId.includes(roleInfo.agt) && approvedData.isContractApproved && !certificateInfo.isAGTApproved  &&
          certificateInfo.agtId === login.userId
        ? 2
        : loginRoleId.includes(roleInfo.engineer) && approvedData.engineerId===login.userId && (certificateInfo.agtId!==null? certificateInfo.isAGTApproved: true) && !certificateInfo.isEngineerApproved
        ? 3
        : loginRoleId.includes(roleInfo.lpi)&& !certificateInfo.isLPIApproved && approvedData.isPermitIssuerApproved && certificateInfo.isEngineerApproved
        ? 4
        : value.status;
    const postData = {
      ...value,
      isApproved: engineerLPIPI ? true : null,
      isAGTApproved:
        loginRoleId.includes(roleInfo.agt)  && !reAssign
          ? true
          : value.isAGTApproved,
      status: statusValidation,
    };
    console.log(postData);
    const editLink = api.hotWork.editHotWork;
     axiosPrivate
      .put(`${editLink}/${certificateInfo.id}`, postData)
      .then((res) => {
        console.log(res);

        // getData()
        setConfirm({...confirm,submit:false})
        setConfirmValue([])
        handleCloseClick();
        toast.success("Successfully Updated");
      })
      .catch((e) => {
        setConfirm({...confirm,submit:false})
        setConfirmValue([])
        console.log(e);
      });
  }

  const onSubmit = async (value) => {
    // console.log(value)
    setConfirm({...confirm,submit:true})
        setConfirmValue([value])
    
  };
  const getAgt = async (companyId) => {
    const getUserByCompanyAndRoleId = api.user.getUserByCompanyIdAndRoleId;
    const getUserByTerminalCompanyAndRole=api.user.getFilteredUsers
    const roleId = roleInfo.agt;
    console.log(companyId, roleId);
    if(companyId===companyInfo.ps){
      const filterData={
        companyId:companyId,
        roleId:roleId,
        terminalId:approvedData.terminalId
      }
       axiosPrivate
      .post(
        `${getUserByTerminalCompanyAndRole}`,filterData
      )
      .then((res) => {
        setAgtData(res.data.data);
        console.log(res.data.data);
      })
      .catch((e) => {
        console.log(e);
        setAgtData([]);
      });
    }else{
       axiosPrivate
      .get(
        `${getUserByCompanyAndRoleId}?companyId=${companyId}&&roleId=${roleId}`
      )
      .then((res) => {
        setAgtData(res.data.data);
        console.log(res.data.data);
      })
      .catch((e) => {
        console.log(e);
        setAgtData([]);
      });
    }
    
  };
  const gasSubmit = async (gasValue) => {
    const postData = {
      ...gasValue,
      hotWorkId: certificateInfo.id,
      agtId: agt.agtId,
    };
    const addLink = api.hotWork.addGasTest;
    if (editGas.bol) {
      const editLink = api.hotWork.editGasTest;
      const putData = {
        ...editGas.data,
        ...gasValue,
        agtId: certificateDetail.agtId,
      };
      console.log(putData);
      axiosPrivate
        .put(`${editLink}/${editGas.data.id}`, putData)
        .then((res) => {
          console.log(res);
          setConfirm({...confirm,agt:false})
        setConfirmValue([])
          toast.success("Sucessfully Updated");
          getDataHotWork();
        })
        .catch((e) => {
          setConfirm({...confirm,agt:false})
        setConfirmValue([])
          console.log(e);
        });
    } else {
      axiosPrivate
        .post(`${addLink}`, postData)
        .then((res) => {
          console.log(res);
          toast.success("Sucessfully Added");
          getDataHotWork();
        })
        .catch((e) => {
          console.log(e, "gas Test");
        });
    }
  };
  const handleAGT = (e, setFieldValue) => {
    const checked = e.target.checked;
    setAgt((prev) => ({ ...prev, agtBol: checked }));
    setFieldValue("agtBol", checked);
    if (!checked) {
      setFieldValue("agtId", "");
    }
  };
  const handleCloseCertificate = async () => {
    // const validatePermit=api.PTWForm.validatePermitShift
  
    //  if(certificateDetail.isPHCertificateClosed){
           const closeCertLink = api.hotWork.closeCertificate;
           const postData = {
             id: certificateInfo.id,
             isCertificateClosed: true,
             certificateClosedReason: certificateDetail.isPHCertificateClosed?closedRemark.LPI:closedRemark.PH
           };
            axiosPrivate
             .put(`${closeCertLink}/${certificateInfo.id}`, postData)
             .then((res) => {
              setConfirm({...confirm,close:false})
        setConfirmValue([])
               toast.success("Certificate Closed");
               handleCloseClick();
             })
             .catch((e) => {
               console.log(e);
             });
        //  }else{
        //    axiosPrivate.get(`${validatePermit}/${approvedData.id}`).then(res=>{
        //      const closeCertLink = api.hotWork.closeCertificate;
        //      const postData = {
        //        id: certificateInfo.id,
        //        isCertificateClosed: true,
        //        certificateClosedReason: closedRemark.PH,
        //      };
        //       axiosPrivate
        //        .put(`${closeCertLink}/${certificateInfo.id}`, postData)
        //        .then((res) => {
        //          toast.success("Certificate Closed");
        //          handleCloseClick();
        //        })
        //        .catch((e) => {
        //          console.log(e);
        //        });
        //        }).catch(e=>{
        //              console.log(e)
        //              toast.error("Permit Is Not Active")
        //            })
        //  }
  };

  const validationSchema = Yup.object().shape({
    locationSpecified: Yup.string().required("This field is required"),

    ignitionSources: Yup.string().required("This field is required"),

    certificateValidFrom: Yup.string().required("This field is required"),

    certificateValidTo: Yup.string().required("This field is required"),

    agtId: Yup.string().when("agtBol", {
      is: (value) => value === true,
      then: (schema) => schema.required("This field is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    agtBol: Yup.boolean().default(false),
  });

  const handleReassign = () => {
    setConfirm({...confirm,reAssign:false})
    setConfirmValue([])
    setReassign(true);
    setReAssignAgt(agt);
  };

  return (
    <>
      {!loading ? (
        <Formik
          initialValues={initialValue}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({
            handleChange,
            setFieldValue,
            values,
            resetForm,
            errors,
            touched,
            setFieldTouched,
          }) => (
            <Form>
              <Grid2 container size={12} spacing={"1rem"}>
                <Grid2 container size={12} alignItems={"center"}>
                  <Grid2 container size={4}>
                    <Box
                      sx={{
                        backgroundColor: commonCss.shadeGreen,
                        p: 1,
                        cursor: "pointer",
                      }}
                      onClick={handleCloseClick}
                    >
                      <ArrowBack color={commonCss.green} />
                    </Box>
                  </Grid2>
                  <Grid2 container size={4} justifyContent={"center"}>
                    <Typography variant="h5" fontWeight="bold" color="white">
                      Hot Work Certificate
                    </Typography>
                  </Grid2>
                  <Grid2 container size={4} justifyContent={"flex-end"}>
                    <Box
                      sx={{
                        backgroundColor: commonCss.shadeGreen,
                        p: 1,
                      }}
                    >
                      <Typography variant="body1" fontWeight="bold">
                        Permit To Work No.{" "}
                        <span style={{ color: "#e53e3e" }}>
                          {searchParams.get("no")}
                        </span>
                      </Typography>
                    </Box>
                  </Grid2>
                  <Divider
                    sx={{
                      borderColor: commonCss.red,
                      borderWidth: 1,
                      width: "100%",
                    }}
                  />
                </Grid2>

                <Grid2 container size={12} alignItems={"center"}>
                  <Typography color="white" variant="body2">
                    (To be completed only by authorised persons)
                  </Typography>
                </Grid2>
                          <Grid2
                                  container
                                  size={12}
                                  gap={0}
                                  sx={{
                                    display:
                                      certificateInfo.isCancel ||
                                      (!values.isLPIApproved &&
                                        certificateInfo.rejectRemarks !== null &&
                                        certificateInfo.rejectRemarks !== "")
                                        ? "default"
                                        : "none",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      width: "100%",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setRemarkButton({
                                        ...remarkButton,
                                        remarkOpen: !remarkButton.remarkOpen,
                                      });
                                    }}
                                  >
                                    {remarkButton.remarkOpen ? (
                                      <KeyboardArrowUp sx={{ color: commonCss.red }} />
                                    ) : (
                                      <KeyboardArrowDown sx={{ color: commonCss.red }} />
                                    )}
                
                                    <Typography
                                      sx={{
                                        color: commonCss.red,
                                        ml: "0.6rem",
                                        fontWeight: 600,
                                      }}
                                    >
                                      Remark
                                    </Typography>
                                  </Box>
                                  <Collapse
                                    in={remarkButton.remarkOpen}
                                    sx={{
                                      background: commonCss.shadeLinearGradient,
                                      width: "100%",
                                      p: remarkButton.remarkOpen ? 1 : 0,
                                    }}
                                  >
                                    <RemarkTableView data={certificateInfo.isCancel
                        ? certificateInfo.cancelOthers
                        : certificateInfo.rejectRemarks}/>
                                  </Collapse>
                                </Grid2>
                <Grid2 container size={12}>
                  <Grid2 size={{ xs: 12, md: 5 }}>
                    <Paper
                      elevation={3}
                      sx={{
                        borderRadius: commonCss.paperBorderRadius,
                        p: 1.5,
                        background: commonCss.shadeLinearGradient,
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <InputLabel sx={{ pl: commonCss.labelPadding, mb: 2 }}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.labelFontWeight}
                          color={commonCss.labelInputColor}
                        >
                          Specify location within terminal
                          <Typography
                            component="span"
                            color="error"
                            fontWeight="bold"
                            sx={{ ml: 0.5 }}
                          >
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        name="locationSpecified"
                        value={values.locationSpecified}
                        onChange={handleChange}
                        multiline
                        rows={2}
                        slotProps={{
                          input: {
                            readOnly: roleAccess.contractStaff,
                          },
                        }}
                        // onBlur={() => setFieldTouched("locationSpecified", true)}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            backgroundColor: roleAccess.contractStaff
                              ? commonCss.readOnlyBGColor
                              : commonCss.inputBackgroundColor,
                            borderRadius: commonCss.inputBorderRadius,
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: commonCss.inputBorderColor,
                          },
                          mb: 2,
                        }}
                      />
                      {errors.locationSpecified && (
                        <ErrorMessage name="locationSpecified">
                          {(error) => <ErrorProps>{error}</ErrorProps>}
                        </ErrorMessage>
                      )}
                      <InputLabel sx={{ pl: commonCss.labelPadding, mb: 2 }}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.labelFontWeight}
                          color={commonCss.labelInputColor}
                        >
                          Specify likely ignition sources
                          <Typography
                            component="span"
                            color="error"
                            fontWeight="bold"
                            sx={{ ml: 0.5 }}
                          >
                            *
                          </Typography>
                        </Typography>
                      </InputLabel>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        name="ignitionSources"
                        value={values.ignitionSources}
                        onChange={handleChange}
                        multiline
                        rows={2}
                        slotProps={{
                          input: {
                            readOnly: roleAccess.contractStaff,
                          },
                        }}
                        //  onBlur={() => setFieldTouched("ignitionSources", true)}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            backgroundColor: roleAccess.contractStaff
                              ? commonCss.readOnlyBGColor
                              : commonCss.inputBackgroundColor,
                            borderRadius: commonCss.inputBorderRadius,
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: commonCss.inputBorderColor,
                          },
                          mb: 2,
                        }}
                      />
                      {errors.ignitionSources && (
                        <ErrorMessage name="ignitionSources">
                          {(error) => <ErrorProps>{error}</ErrorProps>}
                        </ErrorMessage>
                      )}
                    </Paper>
                  </Grid2>

                  <Grid2 size={{ xs: 12, md: 7 }}>
                    <Paper
                      elevation={3}
                      sx={{
                        borderRadius: commonCss.paperBorderRadius,
                        p: 1.5,
                        background: commonCss.shadeLinearGradient,
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontWeight={commonCss.mainLabelHeading}
                        color={commonCss.red}
                      >
                        OPERATIONAL INFORMATION
                      </Typography>
                      <Grid2 item xs={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.normalSentenceWeight}
                          color={commonCss.normalSenternceColor}
                          sx={{ textTransform: "none" }}
                        >
                          I have to checked the work place and the area directly
                          surrounding it within 15 meter and;
                        </Typography>
                      </Grid2>
                      <List sx={{ padding: 0, margin: 2 }}>
                        {operationInfoList.map((text, index) => (
                          <ListItem
                            key={index}
                            sx={{
                              padding: 0,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "flex-start",
                            }}
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  variant="body2"
                                  fontWeight={commonCss.normalSentenceWeight}
                                  color={commonCss.normalSenternceColor}
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "6px",
                                      height: "6px",
                                      backgroundColor: "black",
                                      borderRadius: "50%",
                                      marginTop: "6px",
                                      marginRight: 2,
                                    }}
                                  />
                                  {text.label}
                                </Typography>
                              }
                            />
                            <Checkbox
                              disabled={roleAccess.contractStaff}
                              name={text.name}
                              checked={values[text.name]}
                              onChange={(e) => {
                                setFieldValue(`${text.name}`, e.target.checked);
                              }}
                              sx={{
                                "& .MuiFormControlLabel-label.Mui-disabled": {
                                  color: "black",
                                },
                                color: "#00807F",
                                "&.Mui-checked": {
                                  color: "#00807F",
                                },
                              }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Paper>
                  </Grid2>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"0.6rem"}>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            PTW Holder
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          slotProps={{
                            input: {
                              readOnly: true,
                            },
                          }}
                          value={
                            certificateInfo.ptwHolderId !== null
                              ? certificateInfo?.ptwHolderUserDetail
                                  ?.firstName +
                                " " +
                                certificateInfo?.ptwHolderUserDetail?.lastName
                              : login.firstName + " " + login.lastName
                          }
                          sx={{
                            color: "black",
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: commonCss.readOnlyBGColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Validity From
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DatePicker
                              ampm={false}
                              format="DD/MM/YYYY"
                              readOnly={!roleAccess.piContract}
                              minDate={
                                approvedData.isLeadPermitIssuerApproved
                                  ? dayjs(approvedData.lpiPermitValidFrom)
                                  : approvedData.isPermitIssuerApproved
                                  ? dayjs(approvedData.piPermitValidFrom)
                                  : approvedData.actualPermitValidFrom !== null
                                  ? dayjs(approvedData.actualPermitValidFrom)
                                  : dayjs(moment())
                              }
                              maxDate={
                                approvedData.isLeadPermitIssuerApproved
                                  ? dayjs(approvedData.lpiPermitValidUpTo)
                                  : approvedData.isPermitIssuerApproved
                                  ? dayjs(approvedData.piPermitValidUpTo)
                                  : approvedData.actualPermitValidUpTo !== null
                                  ? dayjs(approvedData.actualPermitValidUpTo)
                                  : undefined
                              }
                              value={
                                values.certificateValidFrom !== null
                                  ? dayjs(values.certificateValidFrom)
                                  : null
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d).utc();
                                    setFieldValue(
                                      "certificateValidFrom",
                                      dateFrom
                                    );
                                    setFieldValue(
                                      "certificateValidTo",
                                      dateFrom
                                    );

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: !roleAccess.piContract
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                            {errors.certificateValidTo && (
                              <ErrorMessage name="certificateValidTo">
                                {(error) => <ErrorProps>{error}</ErrorProps>}
                              </ErrorMessage>
                            )}
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      {/* <Grid2 size={{ xs: 12, md: 6 }} sx={{ mb: 2 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date and Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    readOnly
                    value={values.ptwHolderApprovedDate!==null?dayjs(values.ptwHolderApprovedDate):null}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: commonCss.readOnlyBGColor,
                        borderRadius: commonCss.inputBorderRadius,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2> */}

                      <Grid2 size={{ xs: 12, md: 3 }}>
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label.Mui-disabled": {
                              color: "black",
                            },
                          }}
                          control={
                            <Checkbox
                              disabled={roleAccess.contractStaff}
                              checked={agt.agtBol}
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  color: "#00807F",
                                },
                                "&:checked": {
                                  color: "#00807F",
                                },
                              }}
                              id="agt"
                              color="primary"
                              onChange={(e) => handleAGT(e, setFieldValue)}
                            />
                          }
                          label="AGT"
                        />
                      </Grid2>
                      <Grid2
                        size={{ xs: 12, md: 4 }}
                        mt={0.5}
                        sx={{ display: agt.agtBol ? "default" : "none" }}
                      >
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Company
                          </Typography>
                        </InputLabel>
                        <Select
                          variant="outlined"
                          size="small"
                          defaultValue=""
                          displayEmpty
                          value={agt.agtType}
                          readOnly={roleAccess.contractStaff}
                          onChange={(e) => {
                            setAgt({ ...agt, agtType: e.target.value });
                            getAgt(e.target.value);
                          }}
                          fullWidth
                          sx={{
                            borderRadius: "13px",
                            backgroundColor: roleAccess.contractStaff
                              ? commonCss.readOnlyBGColor
                              : commonCss.inputBackgroundColor,
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "747373",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        >
                          <MenuItem value={companyInfo.ps}>
                            PS Pipeline
                          </MenuItem>
                          <MenuItem value={login.companyId} sx={{display:login.companyId===companyInfo.ps?"none":"default"}}>
                            {login.companyName}
                          </MenuItem>
                        </Select>
                      </Grid2>
                      <Grid2
                        size={{ xs: 12, md: 5 }}
                        sx={{ display: agt.agtBol ? "default" : "none" }}
                      >
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            AGT
                            <Typography
                              component="span"
                              color="error"
                              fontWeight="bold"
                              sx={{ ml: 0.5 }}
                            >
                              *
                            </Typography>
                          </Typography>
                          <Autocomplete
                            readOnly={
                              reAssign ? !reAssign : !roleAccess.adminAGT
                            }
                            name="agtId"
                            inputValue={agt.agtCom}
                            filterOptions={filterOptions}
                            loading={agtData.length === 0}
                            options={agtData}
                            PaperComponent={CustomPaper}
                            getOptionLabel={(option) =>
                              option.firstName + " " + option.lastName || option
                            }
                            onChange={(event, value) => {
                              if (value) {
                                setFieldValue("agtId", value.id);
                                setAgt({
                                  ...agt,
                                  agtCom:
                                    value.firstName + " " + value.lastName,
                                  agtId: value.id,
                                });
                              } else {
                                setFieldValue("agtId", null);
                                setAgt({ ...agt, agtCom: "", agtId: null });
                              }
                            }}
                            // onInputChange={(e, v) => {
                            //   setAgt({...agt,agtCom:v});
                            // }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="agtId"
                                size="small"
                                // variant="standard"

                                onChange={(e) => {
                                  setAgt({ ...agt, agtCom: e.target.value });
                                }}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    backgroundColor: (
                                      reAssign
                                        ? !reAssign
                                        : !roleAccess.adminAGT
                                    )
                                      ? commonCss.readOnlyBGColor
                                      : commonCss.inputBackgroundColor,
                                    borderRadius: commonCss.inputBorderRadius,
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: commonCss.inputBorderColor,
                                  },
                                }}
                                //  onBlur={() => setFieldTouched("agtId", true)}
                              />
                            )}
                          />
                        </InputLabel>
                        {errors.agtId && (
                          <ErrorMessage name="agtId">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        )}
                      </Grid2>
                      <Grid2
                        container
                        size={12}
                        justifyContent={"flex-end"}
                        sx={{
                          display: roleAccess.reAssignButton
                            ? "default"
                            : "none",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: "0.6rem",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: commonCss.red,
                              borderRadius: commonCss.buttonBorderRadius,
                              textTransform: "none",
                              padding: "0.6rem 1rem",
                              color: commonCss.buttonTextColor,
                              width: "15vw",
                              display: !reAssign ? "none" : "default",
                            }}
                            onClick={() => {
                              setReassign(false);
                              setAgt(reassignagt);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: commonCss.red,
                              borderRadius: commonCss.buttonBorderRadius,
                              textTransform: "none",
                              padding: "0.6rem 1rem",
                              color: commonCss.buttonTextColor,
                              width: "15vw",
                              display: reAssign ? "none" : "default",
                            }}
                            onClick={() => {
                              setConfirm({...confirm,reAssign:true})
                            }}
                          >
                            Reassign
                          </Button>
                        </Box>
                      </Grid2>
                    </Grid2>
                    <Typography
                      variant="body2"
                      fontWeight={commonCss.mainLabelHeading}
                      color={commonCss.red}
                    >
                      Note : Operator can be the AGT or a depot staff member who
                      review the workplace
                    </Typography>
                  </Paper>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container alignItems={"center"} spacing={"0.6rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                        >
                          Gas Test
                        </Typography>
                      </Grid2>
                      <Grid2 container size={12} spacing={0}>
                        <Grid2 container size={6} alignItems={"center"}>
                          {gasTestInfo.map((text, index) => (
                            <>
                              <Grid2 size={10}>
                                <Typography
                                  variant="body2"
                                  fontWeight={commonCss.normalSentenceWeight}
                                  color={commonCss.normalSenternceColor}
                                >
                                  {text.label}
                                </Typography>
                              </Grid2>
                              <Grid2 size={2}>
                                <Checkbox
                                  disabled={roleAccess.contractStaff}
                                  name={text.name}
                                  checked={values[text.name]}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `${text.name}`,
                                      e.target.checked
                                    );
                                    if (
                                      text.name === "gasTestWhileWorkInProgress"
                                    ) {
                                      setSpecifiedFreq(true);
                                      setFieldValue("specifiedFrequency", "");
                                    } else {
                                      setSpecifiedFreq(false);
                                      setFieldValue("specifiedFrequency", "");
                                    }
                                  }}
                                  sx={{
                                    "& .MuiFormControlLabel-label.Mui-disabled":
                                      {
                                        color: "black",
                                      },
                                    color: commonCss.green,
                                    "&.Mui-checked": {
                                      color: commonCss.green,
                                    },
                                  }}
                                />
                              </Grid2>
                            </>
                          ))}
                        </Grid2>
                        <Grid2
                          container
                          size={6}
                          alignItems={"flex-end"}
                          sx={{
                            display: specifiedFrequency ? "default" : "none",
                          }}
                        >
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            name="specifiedFrequency"
                            value={values.specifiedFrequency}
                            onChange={handleChange}
                            slotProps={{
                              input: {
                                readOnly: roleAccess.contractStaff,
                              },
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: roleAccess.contractStaff
                                  ? commonCss.readOnlyBGColor
                                  : commonCss.inputBackgroundColor,
                                borderRadius: commonCss.inputBorderRadius,
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: commonCss.inputBorderColor,
                              },
                            }}
                          />
                        </Grid2>
                      </Grid2>
                      <Grid2 size={6}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            The following working area has been tested for
                            flammable gases/vapours
                          </Typography>
                        </InputLabel>
                      </Grid2>
                      <Grid2 size={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={values.flammableGases}
                          name="flammableGases"
                          onChange={handleChange}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>

                <Grid2
                  container
                  size={12}
                  sx={{
                    display:
                      agt.agtBol && values.agtId !== null ? "default" : "none",
                  }}
                >
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"0.6rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                        >
                          Gas test performed
                        </Typography>
                      </Grid2>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.normalSentenceWeight}
                          color={commonCss.normalSenternceColor}
                        >
                          I confirm that I have tested for gas and/or oxygen and
                          that the result are within the specified acceptance
                          levels, and the measured concertation of explosive
                          gasses/vapours is zero % LEL
                        </Typography>
                      </Grid2>
                      <Grid2 size={12}>
                        <GasTestComponent
                          edit={editGas.bol}
                          editData={editGas.data}
                          readOnly={roleAccess.agt}
                          onSubmitGas={(e)=>{
                            setConfirm({...confirm,agt:true})
        setConfirmValue([e])
                          }}
                        /> 
                      </Grid2>
                      <Grid2
                        size={{ xs: 12 }}
                        sx={{
                          display: gasData.length === 0 ? "none" : "default",
                        }}
                      >
                        <Paper sx={{ background: "transparent" }}>
                          <NormalTableWithRadius
                            data={gasData}
                            columns={gasColumns}
                          />
                        </Paper>
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={4}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"1rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                          textAlign={"center"}
                        >
                          Engineer
                        </Typography>
                        <Divider
                          sx={{
                            borderColor: commonCss.red,
                            borderWidth: 1,
                            width: "100%",
                            mt: 1,
                          }}
                        />
                      </Grid2>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.normalSentenceWeight}
                          color={commonCss.normalSenternceColor}
                          sx={{ textTransform: "none" }}
                        >
                          I certify that I have reviewed the proposed work and I
                          am satisfied that it is adequately described on the
                          permit to work and that the precautions detailed are
                          adequate and clearly defined
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Name
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={approveInfo.engineerName}
                          slotProps={{
                            input: {
                              readOnly: true, // Set readOnly to true
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.engineer
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Date/Time
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              value={
                                values.isEngineerApproved
                                  ? dayjs(values.engineerApprovedDate)
                                  : null
                              }
                              readOnly
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: roleAccess.engineer
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 container size={12} justifyContent={"center"}>
                        {/* <Button
                          type="button"
                          variant="contained"
                          // disabled={values.isEngineerApproved}
                          sx={{
                            backgroundColor: commonCss.red,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            display: roleAccess.engineer ? "none" : "default",
                          }}
                          onClick={() => {
                            setRemarkButton({ ...remarkButton, reject: true });
                          }}
                        >
                          Reject
                        </Button> */}
                        <Button
                          type="submit"
                          variant="contained"
                          // disabled={values.isEngineerApproved}
                          sx={{
                            backgroundColor: commonCss.green,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            display: roleAccess.engineer ? "none" : "default",
                          }}
                          // onClick={() => {
                          //   setFieldValue("isEngineerApproved", true);
                          //   setFieldValue("engineerId", login.userId);
                          //   setFieldValue(
                          //     "engineerApprovedDate",
                          //     moment().format("YYYY-MM-DDTHH:mm:ss")
                          //   );
                          //   setApproveInfo({
                          //     ...approveInfo,
                          //     engineerName:
                          //       login.firstName + " " + login.lastName,
                          //   });
                          // }}
                        >
                          Acknowledge
                        </Button>
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={4}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"1rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                          textAlign={"center"}
                        >
                          LPI
                        </Typography>
                        <Divider
                          sx={{
                            borderColor: commonCss.red,
                            borderWidth: 1,
                            width: "100%",
                            mt: 1,
                          }}
                        />
                      </Grid2>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.normalSentenceWeight}
                          color={commonCss.normalSenternceColor}
                          sx={{ textTransform: "none" }}
                        >
                          Hot Work Certificate is described in the permit to
                          work is allowed between:
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Name
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={approveInfo.lpiName}
                          slotProps={{
                            input: {
                              readOnly: true, // Set readOnly to true
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.lpi
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Date/Time
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              readOnly
                              value={
                                values.isLPIApproved
                                  ? dayjs(values.lpiApprovedDate)
                                  : null
                              }
                              format="DD/MM/YYYY HH:mm"
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: roleAccess.lpi
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            From
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DatePicker
                              ampm={false}
                              format="DD/MM/YYYY"
                              readOnly={roleAccess.lpi}
                              minDate={
                                approvedData.isLeadPermitIssuerApproved
                                  ? dayjs(approvedData.lpiPermitValidFrom)
                                  : approvedData.isPermitIssuerApproved
                                  ? dayjs(approvedData.piPermitValidFrom)
                                  : dayjs(moment())
                              }
                              maxDate={
                                approvedData.isLeadPermitIssuerApproved
                                  ? dayjs(approvedData.lpiPermitValidUpTo)
                                  : approvedData.isPermitIssuerApproved
                                  ? dayjs(approvedData.piPermitValidUpTo)
                                  : undefined
                              }
                              value={
                                values.certificateValidFrom !== null
                                  ? dayjs(values.certificateValidFrom)
                                  : null
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d).utc();
                                    setFieldValue(
                                      "certificateValidFrom",
                                      dateFrom
                                    );
                                    setFieldValue(
                                      "certificateValidTo",
                                      dateFrom
                                    );

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: roleAccess.lpi
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      {/* <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Until
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              readOnly={roleAccess.lpi}
                              value={
                                values.certificateValidTo !== null
                                  ? dayjs(values.certificateValidTo)
                                  : null
                              }
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d)
                                    setFieldValue(
                                      "certificateValidTo",
                                      dateFrom
                                    );

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor:roleAccess.lpi?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2> */}
                      <Grid2
                        container
                        size={12}
                        justifyContent={"center"}
                        sx={{ display: roleAccess.lpi ? "none" : "default" }}
                      >
                        <Button
                                            variant="contained"
                                            type="button"
                                            sx={{
                                              backgroundColor: commonCss.red,
                                              borderRadius: commonCss.buttonBorderRadius,
                                              textTransform: "none",
                                              padding: "0.6rem 1rem",
                                              color: commonCss.buttonTextColor,
                                              width: "15vw",
                                              display: roleAccess.cancelButton ? "default" : "none",
                                            }}
                                            onClick={() => {
                                              setRemarkButton({ ...remarkButton, cancel: true });
                                            }}
                                          >
                                            Cancel
                                          </Button>
                        <Button
                          type="button"
                          variant="contained"
                          disabled={values.isLPIApproved}
                          sx={{
                            backgroundColor: commonCss.red,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            display: roleAccess.lpi || certificateInfo.isClone? "none" : "default",
                          }}
                          onClick={() => {
                            setRemarkButton({ ...remarkButton, reject: true });
                          }}
                        >
                          Reject
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          disabled={values.isLPIApproved}
                          sx={{
                            backgroundColor: commonCss.green,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            display: roleAccess.lpi ? "none" : "default",
                          }}
                          // onClick={() => {
                          //   setFieldValue("isLPIApproved", true);
                          //   setFieldValue("lpiId", login.userId);
                          //   setFieldValue(
                          //     "lpiApprovedDate",
                          //     moment().format("YYYY-MM-DDTHH:mm:ss")
                          //   );
                          //   setApproveInfo({
                          //     ...approveInfo,
                          //     lpiName: login.firstName + " " + login.lastName,
                          //   });
                          // }}
                        >
                          Approve
                        </Button>
                      </Grid2>
                    
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={4}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"1rem"}>
                      <Grid2 item xs={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.normalSentenceWeight}
                          color={commonCss.normalSenternceColor}
                          sx={{ textTransform: "none" }}
                        >
                          Hot Work Certificate is certificate and permit to work
                          are withdrawn:
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                           Permit Holder Name
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          slotProps={{
                            input: {
                              readOnly: true,
                            },
                          }}
                          value={
                            values.isPHCertificateClosed !== null
                              ? approveInfo.certificateClosedName
                              : null
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.certClose
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                          Permit Holder Date/Time
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              readOnly
                              value={
                                values.isPHCertificateClosed
                                  ? dayjs(values.phCertificateClosedAt)
                                  : null
                              }
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: roleAccess.certClose
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                           Permit Holder Reason
                          </Typography>
                        </InputLabel>
                          <TextField
                                                  fullWidth
                                                  variant="outlined"
                                                  size="small"
                                                  name="reason"
                                                  value={closedRemark.PH}
                                                  slotProps={{
                                                    input: {
                                                      readOnly: roleAccess.certClose,
                                                    },
                                                  }}
                                                  onChange={(e) => {
                                                    setClosedRemark({...closedRemark,PH:e.target.value});
                                                  }}
                                                  multiline
                                                  minRows={2}
                                                  sx={{
                                                    "& .MuiOutlinedInput-root": {
                                                      backgroundColor: roleAccess.certClose
                                                        ? commonCss.readOnlyBGColor
                                                        : commonCss.inputBackgroundColor,
                                                      borderRadius: commonCss.inputBorderRadius,
                                                    },
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                      borderColor: commonCss.inputBorderColor,
                                                    },
                                                  }}
                                                />
                      </Grid2>
                      <Grid2 container size={12} justifyContent={"center"}>
                        <Button
                          variant="contained"
                          disabled={values.isPHCertificateClosed}
                          sx={{
                            backgroundColor: commonCss.green,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            display: roleAccess.certClose ? "none" : "default",
                          }}
                          onClick={() => {
                            // handleCloseCertificate();
                            setConfirm({...confirm,close:true})
                          }}
                        >
                          Close
                        </Button>
                      </Grid2>
                      <Grid2
                        container
                        size={12}
                        justifyContent={"center"}
                        sx={{display:values.isPHCertificateClosed?"default":"none"}}
                      >
                         <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                           LPI Name
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          slotProps={{
                            input: {
                              readOnly: true,
                            },
                          }}
                          value={
                            values.isLPICertificateClosed !== null
                              ? approveInfo.lpiCertClosedName
                              : null
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.lpiCertCloseReason
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                          LPI Date/Time
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              readOnly
                              value={
                                values.isLPICertificateClosed
                                  ? dayjs(values.lpiCertificateClosedAt)
                                  : null
                              }
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: roleAccess.lpiCertCloseReason
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                          <InputLabel>
                            <Typography
                              variant="body2"
                              fontWeight={commonCss.labelFontWeight}
                              color={commonCss.labelInputColor}
                            >
                              Reason
                            </Typography>
                          </InputLabel>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            name="reason"
                            value={closedRemark.LPI}
                            slotProps={{
                              input: {
                                readOnly: roleAccess.lpiCertCloseReason,
                              },
                            }}
                            onChange={(e) => {
                              setClosedRemark({
                                ...closedRemark,
                                LPI: e.target.value,
                              });
                            }}
                            multiline
                            minRows={2}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: roleAccess.lpiCertCloseReason
                                  ? commonCss.readOnlyBGColor
                                  : commonCss.inputBackgroundColor,
                                borderRadius: commonCss.inputBorderRadius,
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: commonCss.inputBorderColor,
                              },
                            }}
                          />
                        </Grid2>
                        <Grid2 container size={12} justifyContent={"center"}>
                          <Button
                            variant="contained"
                            disabled={values.isLPICertificateClosed}
                            sx={{
                              backgroundColor: commonCss.green,
                              borderRadius: commonCss.buttonBorderRadius,
                              textTransform: "none",
                              padding: "0.6rem 1rem",
                              color: commonCss.buttonTextColor,
                              width: "15vw",
                              display: roleAccess.lpiCertCloseReason
                                ? "none"
                                : "default",
                            }}
                            onClick={() => {
                              setConfirm({...confirm,close:true})
                            }}
                          >
                            Close
                          </Button>
                        </Grid2>
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>

                <Grid2 container size={12} justifyContent={"flex-end"}>
                  {/* <Button
                    variant="contained"
                    type="button"
                    sx={{
                      backgroundColor: commonCss.red,
                      borderRadius: commonCss.buttonBorderRadius,
                      textTransform: "none",
                      padding: "0.6rem 1rem",
                      color: commonCss.buttonTextColor,
                      width: "10vw",
                      display: roleAccess.cancelButton ? "default" : "none",
                    }}
                    onClick={() => {
                      setRemarkButton({ ...remarkButton, cancel: true });
                    }}
                  >
                    Cancel
                  </Button> */}
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      backgroundColor: commonCss.green,
                      borderRadius: commonCss.buttonBorderRadius,
                      textTransform: "none",
                      padding: "0.6rem 1rem",
                      color: commonCss.buttonTextColor,
                      width: "10vw",
                      display: roleAccess.submitButton ? "default" : "none",
                    }}
                  >
                    Submit
                  </Button>
                </Grid2>
              </Grid2>
              <Dialog
                // maxWidth={"lg"}
                open={remarkButton.reject}
                onClose={() => {
                  setRemarkButton({ ...remarkButton, reject: false });
                }}
                maxWidth="md"
                fullWidth
                PaperProps={{
                  sx: {
                    background: commonCss.normalLinearGradient,
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogContent>
                  <Remark
                    handleSubmitRemark={(e) => {
                      handleReject(values, e);
                    }}
                    handleCloseClick={() => {
                      setRemarkButton({ ...remarkButton, reject: false });
                    }}
                  />
                </DialogContent>
              </Dialog>
              <Dialog
                // maxWidth={"lg"}
                open={remarkButton.cancel}
                onClose={() => {
                  setRemarkButton({ ...remarkButton, cancel: false });
                }}
                maxWidth="md"
                fullWidth
                PaperProps={{
                  sx: {
                    background: commonCss.normalLinearGradient,
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogContent>
                  <Remark
                    handleSubmitRemark={(e) => {
                      handleCancel(values, e);
                    }}
                    handleCloseClick={() => {
                      setRemarkButton({ ...remarkButton, cancel: false });
                    }}
                  />
                </DialogContent>
              </Dialog>
              <Dialog
        // maxWidth={"lg"}
  open={confirm.agt}
  onClose={()=>{setConfirm({...confirm,agt:false})}}
maxWidth={"md"}
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Confirmation 
  onSubmit={()=>{gasSubmit(...confirmValue)}} 
  name={'add the gas test'}
  handleCloseClick={()=>{
    setConfirm({...confirm,agt:false})
    setConfirmValue([])
    }}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={confirm.close}
  onClose={()=>{setConfirm({...confirm,close:false})}}
maxWidth={"md"}
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Confirmation 
  onSubmit={()=>{handleCloseCertificate()}} 
  name={'close'}
  handleCloseClick={()=>{
    setConfirm({...confirm,close:false})
    setConfirmValue([])
    }}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={confirm.agtDel}
  onClose={()=>{setConfirm({...confirm,agtDel:false})}}
maxWidth={"md"}
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Confirmation 
  onSubmit={()=>{handleDeleteGas(...confirmValue)}} 
  name={'delete'}
  handleCloseClick={()=>{
    setConfirm({...confirm,agtDel:false})
    setConfirmValue([])
    }}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={confirm.reAssign}
  onClose={()=>{setConfirm({...confirm,reAssign:false})}}
maxWidth={"md"}
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Confirmation 
  onSubmit={()=>{handleReassign()}} 
  name={'reassign'}
  handleCloseClick={()=>{
    setReassign(false)
    setReAssignAgt({
      agtBol: false,
      agtType: "",
      agtCom: "",
      agtId: null,
    })
    setConfirm({...confirm,reAssign:false})
    setConfirmValue([])
    }}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={confirm.submit}
  onClose={()=>{setConfirm({...confirm,submit:false})}}
maxWidth={"md"}
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Confirmation
  onSubmit={()=>{onApproveAndSubmit(...confirmValue)}} 
  name={'submit'}
  handleCloseClick={()=>{
    setConfirm({...confirm,submit:false})
    setConfirmValue([])
    }}/>
  </DialogContent>
       </Dialog>
            </Form>
          )}
        </Formik>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default HotWork;

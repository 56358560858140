import React, { useEffect, useState } from "react";
import { Divider, Grid2, Tab,Box,Paper,Typography, Stepper, StepButton, Step, StepLabel, Collapse } from "@mui/material";

import PTWForm2 from "./PTWForm2";
import PTWForm1 from "./PTWForm1";
import { commonCss } from "../../StyleConfig/CommonCss";
import { useSearchParams } from "react-router-dom";
import { api, roleInfo } from "../API/config";
import { axiosPrivate } from "../../axios/axios";

import {TabContext,TabList,TabPanel} from '@mui/lab';
import Loading from "../../Component/Loading";
import { useSelector } from "react-redux";
import moment from "moment";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import RemarkTableView from "../../Component/RemarkTableView";


export default function MainForm() {
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = 2;

  const permitStatus=useSelector(state=>state.data.permitStatus)
  const isPermitShift=useSelector(state=>state.data.isPermitShift)
  const login = useSelector(state=>state.auth.loginInfo)
  const loginRoleId=String(login.roleIds).split(",")

  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [value,setValue]=useState("1")
  const [remarkButton,setRemarkButton]=useState({reject:false,cancel:false,remarkOpen:((data.isCancel&&(data.otherRemarks!==null&&data.otherRemarks!==""))||(!data.isLeadPermitIssuerApproved&&(data.rejectedRemarks!==null&&data.rejectedRemarks!=="")))?true:false})
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData=async()=>{
    setLoading(true)
    const getDetailByid=api.PTWForm.getDetailById
    await axiosPrivate.get(`${getDetailByid}/${searchParams.get("id")}`).then(res=>{
     setData(res.data.data)
    
     setLoading(false)
      console.log(res)
    }).catch(e=>{
      setData({})
      setLoading(false)
      console.log(e,"MainForm")
    })
  }

 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <Paper
      sx={{
        background: "linear-gradient(to right, #DAF2F2, #FEDADA)",
        borderRadius: "10px",
        p: 1.5,
      }}
    >
      <Grid2 container size={12} spacing={"0.6rem"}>
        <Grid2 container size={12} justifyContent={"center"}>
          <Typography variant="h5" fontWeight="bold" color="#00807F">
            PSP PERMIT TO WORK FORM
          </Typography>
        </Grid2>
        <Grid2 container size={12}>
          <Grid2 size={{ xs: 6 }}>
            <Typography variant="body1" fontWeight="bold">
              Date of issue : {data!==null?moment(data.issueDate).format("DD/MM/YYYY"):""}
            </Typography>
          </Grid2>

          <Grid2 container size={{ xs: 6 }} justifyContent={"flex-end"} alignItems={"center"}>
          <Typography variant="body1" fontWeight="bold" sx={{mr:2}}>
             {!data.isPIPermitClosed||!data.isLPIPermitClosed?
                      //cell.row.original.isLeadPermitIssuerApproved&&moment(cell.row.original.piPermitValidUpTo).format("DD/MM/YYYY")<moment().format("DD/MM/YYYY")?
                     data.isFormExpired?
                      <Typography sx={{ fontSize: "inherit", fontWeight: "inherit", color: commonCss.red  }}>
                      Expired
                    </Typography>:
                      data.isLeadPermitIssuerApproved&&!data.isPHPermitClosed?
                       isPermitShift ? 
                        <Typography sx={{ fontSize: "inherit", fontWeight: "inherit", color: commonCss.green  }}>
                        Active
                      </Typography> : <Typography sx={{ fontSize: "inherit", fontWeight: "inherit", color: commonCss.red  }}>
                        Suspend
                      </Typography>:null:null}
            </Typography>
            <Typography variant="body1" fontWeight="bold">
              No.{" "}
              <span style={{ color: "#FD0000", fontSize: "1.2rem" }}>
                {searchParams.get("no")}
              </span>
            </Typography>
          </Grid2>
          <Divider
            sx={{ borderColor: "#FD0000", borderWidth: 1, width: "100%" }}
          />
        </Grid2>
        <Grid2 container size={12}>
          <Box sx={{ width: '100%',".MuiStepper-root":{
            overflowX: "auto" ,
            whiteSpace: "nowrap"
          }  }}>
          
        <Stepper  activeStep={loginRoleId.includes(roleInfo.contractStaff)&&permitStatus.currentStage===0?0:permitStatus.currentStage+1} alternativeLabel  
        >
        {permitStatus.formStages.map((label, index) => (
          <Step key={index}  >
             <StepLabel sx={{
              ".MuiStepIcon-root.Mui-completed":{
              color:commonCss.green
             },
             ".MuiStepLabel-label.Mui-completed":{
              color:commonCss.green
             },
             ".MuiStepIcon-root.Mui-active":{
              color:commonCss.green
             },
             ".MuiStepLabel-label.Mui-active":{
              color:commonCss.green
             }
             }}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      </Box>
        </Grid2> 
        {loading?null:
        <Grid2 container size={12} gap={0} sx={{display:(data.isCancel||(!data.isLeadPermitIssuerApproved&&(data.rejectedRemarks!==null&&data.rejectedRemarks!=="")))?"default":"none"}} >
         <Box sx={{display:"flex",alignItems:"center",width:"100%",cursor:"pointer"}} onClick={()=>{setRemarkButton({...remarkButton,remarkOpen:!remarkButton.remarkOpen})}}>
           {remarkButton.remarkOpen?<KeyboardArrowUp sx={{color:commonCss.green}}/>:<KeyboardArrowDown sx={{color:commonCss.green}}/>}
         
          <Typography sx={{color:commonCss.green,ml:"0.6rem"}}>
            Remark
          </Typography>
         </Box>
         <Collapse in={remarkButton.remarkOpen} sx={{backgroundColor:"white",width:"100%",p:1}}>
          {/* <Typography>{data.isCancel?data.otherRemarks:data.rejectedRemarks}</Typography> */}
          <RemarkTableView data={data.isCancel?data.otherRemarks:data.rejectedRemarks}/>
         </Collapse>
        </Grid2>
        }
        <Grid2 container size={12}>
        <Box sx={{ width: '100%', typography: 'body1',minHeight:"50vh" }}>
        <TabContext value={value}>
        <Box >
          <TabList onChange={handleChange} aria-label="lab API tabs example" 
          sx={{
            "& .MuiTabs-indicator":{backgroundColor:commonCss.green},
            "& .MuiTab-root.MuiTab-textColorPrimary.Mui-selected":{
              color:commonCss.green
            }
          }}>
            <Tab label="Page 1" value="1" />
            <Tab label="Page 2" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{p:0,pt:1}}>
          {loading?<Loading/>:<PTWForm1 data={data} getData={getData} />}
          
        </TabPanel>
        <TabPanel value="2" sx={{p:0,pt:1}}>{loading?<Loading/>:<PTWForm2 data={data} getData={getData}/>}</TabPanel>
      </TabContext>
      </Box>
        </Grid2>

      </Grid2>
    </Paper>
  );
}

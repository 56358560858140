import { Button, Grid2, InputLabel, MenuItem, Paper, Select, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { commonCss } from "../../StyleConfig/CommonCss";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import Loading from "../../Component/Loading";
import { api, terminalInfo } from "../API/config";
import { axiosPrivate } from "../../axios/axios";
import dayjs from "dayjs";
import PermitByStatus from "./PermitByStatus";
import PermitByCertificate from "./PermitByCertificate";
import PermitByStatusDaily from "./PermitByStatusDaily";

const Dashboard = () => {
  const [fromDate, setFromDate] = useState(moment().subtract(1, "week"));
  const [toDate, setToDate] = useState(moment());
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [terminalId,setTerminalId]=useState(null)
  const [siteId,setSiteId]=useState(null)
  const [terminalData,setTerminalData]=useState([]) 
  const [siteData,setSiteData]=useState([])
  const [noData,setnoData]=useState(false)
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    setLoading(true);
    const getLink = api.dashboard.getAllDashboardDataByDateRange;
    axiosPrivate
      .post(`${getLink}`, { from: fromDate, to: toDate,terminalId:terminalId,siteId:siteId })
      .then((res) => {
        console.log(res);
        setnoData(false)
        setDashboardData(res.data);
        
      })
      .catch((e) => {
        if(e.response.data.message==="No forms available in the specified date range."){
          setnoData(true)
        }else{
          setnoData(false)
        }
        console.log(e);
      });
      
   
        const getSiteLink=api.site.getAll
         axiosPrivate.get(`${getSiteLink}`).then(res=>{
         setSiteData(res.data.data)
        }).catch(e=>{
          setSiteData([])
         console.log(e)
        })
        
      
      const getTerminal=api.terminal.getAll
       axiosPrivate.get(`${getTerminal}`).then(res=>{
        setTerminalData(res.data.data)
      }).catch(e=>{
        setTerminalData([])
        console.log(e)
      })
      setLoading(false);
  };

  const handleClear=()=>{
    setLoading(true)
    const getLink = api.dashboard.getAllDashboardDataByDateRange;
    axiosPrivate
      .post(`${getLink}`, { from: moment().subtract(1, "week"), to: moment(),terminalId:null,siteId:null })
      .then((res) => {
        console.log(res);
        setnoData(false)
        setTerminalId(null)
        setSiteId(null)
        setFromDate(moment().subtract(1, "week"))
        setToDate(moment())
        setDashboardData(res.data);
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        if(e.response.data.message==="No forms available in the specified date range."){
          setnoData(true)
        }else{
          setnoData(false)
        }
        console.log(e);
      });
  }
  return (
    <Grid2 container spacing={"0.6rem"}>
      <Grid2 size={12}>
        <Paper sx={{ p: 1,background: 'linear-gradient(to right, #DAF2F2, #FEDADA)' }}>
          <Grid2 container spacing={"0.6rem"}>
            <Grid2 size={12}>
              <Typography
                sx={{
                  color: commonCss.red,
                  fontSize: "1.5rem",
                  fontWeight: 600,
                  p: 1,
                }}
              >
                Dashboard
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 3 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  From
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DatePicker
                    ampm={false}
                    format="DD/MM/YYYY"
                    value={dayjs(fromDate)}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    onChange={(newValue) => {
                      console.log(newValue);
                      if (newValue !== null) {
                        if (`${newValue.$d}` !== "Invalid Date") {
                          const dateFrom = moment(newValue.$d);
                          setFromDate(dateFrom);

                          // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                          // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                          // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                          // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                          // setFieldValue("installationDate", dateFormat);
                        }
                      }
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 3 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  To
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DatePicker
                    ampm={false}
                    format="DD/MM/YYYY"
                    value={dayjs(toDate)}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    onChange={(newValue) => {
                      console.log(newValue);
                      if (newValue !== null) {
                        if (`${newValue.$d}` !== "Invalid Date") {
                          const dateFrom = moment(newValue.$d);
                          setToDate(dateFrom);

                          // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                          // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                          // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                          // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                          // setFieldValue("installationDate", dateFormat);
                        }
                      }
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 size={{xs:12,md:3}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
                Terminal
              </Typography>
            </InputLabel>
            <Select
                size="small"
                defaultValue=""
                displayEmpty
                name="terminalId"
                value={terminalId}
                sx={{
                  borderRadius: "13px",
                  backgroundColor:commonCss.inputBackgroundColor,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '747373',
                  },
                }}
                onChange={(e)=>{
                 setTerminalId(e.target.value)
                  
                }}
                fullWidth
              >
                {terminalData.map((term,i)=>(
                    <MenuItem  key={i} value={term.id}>{term.name}</MenuItem>
                ))}
              </Select>
          </Grid2>
          <Grid2 size={{xs:12,md:3}} sx={{display:terminalId===terminalInfo.terminal1?"default":"none"}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
                Site
              </Typography>
            </InputLabel>
            <Select
                size="small"
                defaultValue=""
                displayEmpty
                name="siteId"
                value={siteId}
                sx={{
                  borderRadius: "13px",
                  background:commonCss.inputBackgroundColor,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '747373',
                  },
                }}
                onChange={(e)=>{setSiteId(e.target.value)}}
                fullWidth
              >
                {siteData.map((siteList,i)=>(
                 <MenuItem key={i} value={siteList.id}>{siteList.name}</MenuItem>
                ))}
                
                
              </Select>
          </Grid2>
            <Grid2 container size={{ xs: 12 }} justifyContent={"flex-end"}>
            <Button
                variant="contained"
                sx={{
                  backgroundColor: commonCss.green,
                  borderRadius: commonCss.buttonBorderRadius,
                  textTransform: "none",
                  padding: "0.6rem 1rem",
                  color: commonCss.buttonTextColor,
                  width: "15vw",
                }}
                onClick={() => {
                 handleClear()
                }}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: commonCss.green,
                  borderRadius: commonCss.buttonBorderRadius,
                  textTransform: "none",
                  padding: "0.6rem 1rem",
                  color: commonCss.buttonTextColor,
                  width: "15vw",
                }}
                onClick={() => {
                  getData();
                }}
              >
                Search
              </Button>
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      {loading || dashboardData === null ? (
        <Loading />
      ) : (
        <>
        {noData?<Typography sx={{fontSize:"1rem",fontWeight:450,color:"white",textAlign:"center",width:"100%",mt:1}}>No Data</Typography>:
        <Grid2 container size={12} spacing={"0.6rem"}>
          <Grid2 size={{ xs: 12, md: 3 }}>
            <Paper
              sx={{
                width: "100%",
                height: "100%",
                p: 1,
                display: "flex",
                flexDirection: "column",
                gap: "0.4rem",
               background: '#DAF2F2'
              }}
            >
              <Typography sx={{ fontWeight: 500 }}>
                Permit To Work Created
              </Typography>
              <Typography sx={{ fontWeight: 600, fontSize: "1.3rem" }}>
                {dashboardData?.summaryData?.totalForms}
              </Typography>
            </Paper>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 3 }}>
            <Paper
              sx={{
                width: "100%",
                height: "100%",
                p: 1,
                display: "flex",
                flexDirection: "column",
                gap: "0.4rem",
                background: '#DAF2F2'
              }}
            >
              <Typography sx={{ fontWeight: 500 }}>
                Permit To Work Cancelled
              </Typography>
              <Typography sx={{ fontWeight: 600, fontSize: "1.3rem" }}>
                {dashboardData?.summaryData?.cancelledForms}
              </Typography>
            </Paper>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 3 }}>
            <Paper
              sx={{
                width: "100%",
                height: "100%",
                p: 1,
                display: "flex",
                flexDirection: "column",
                gap: "0.4rem",
                background: '#DAF2F2'
              }}
            >
              <Typography sx={{ fontWeight: 500 }}>
                Permit To Work Reject
              </Typography>
              <Typography sx={{ fontWeight: 600, fontSize: "1.3rem" }}>
                {dashboardData?.summaryData?.rejectForms}
              </Typography>
            </Paper>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 3 }}>
            <Paper
              sx={{
                width: "100%",
                height: "100%",
                p: 1,
                display: "flex",
                flexDirection: "column",
                gap: "0.4rem",
                background: 'linear-gradient(to right, #DAF2F2, #FEDADA)'
              }}
            >
              <Typography sx={{ fontWeight: 500 }}>
                Permit To Work Expired
              </Typography>
              <Typography sx={{ fontWeight: 600, fontSize: "1.3rem" }}>
                {dashboardData?.summaryData?.notClosedWithinSevenDays}
              </Typography>
            </Paper>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Paper
              sx={{
                width: "100%",
                height: "100%",
                p: 1,
                display: "flex",
                flexDirection: "column",
                gap: "0.4rem",
                background: ' #DAF2F2'
              }}
            >
              <PermitByStatus data={dashboardData} />
            </Paper>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Paper
              sx={{
                width: "100%",
                height: "100%",
                p: 1,
                display: "flex",
                flexDirection: "column",
                gap: "0.4rem",
                background: 'linear-gradient(to right, #DAF2F2, #FEDADA)'
              }}
            >
              <PermitByCertificate data={dashboardData} />
            </Paper>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 12 }}>
            <Paper
              sx={{
                width: "100%",
                height: "100%",
                p: 1,
                display: "flex",
                flexDirection: "column",
                gap: "0.4rem",
                background: 'linear-gradient(to right, #DAF2F2, #FEDADA)'
              }}
            >
              <PermitByStatusDaily data={dashboardData} />
            </Paper>
          </Grid2>
        </Grid2>
       }
        </>
      )}
    </Grid2>
  );
};

export default Dashboard;

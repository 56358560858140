import { Box, Button, Dialog, DialogContent, Grid2, IconButton, InputLabel, Paper, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { commonCss } from '../../../StyleConfig/CommonCss';
import ErrorProps from '../../../Component/Errorprops';
import { toast } from 'react-toastify';
import { axiosPrivate } from '../../../axios/axios';
import { api } from '../../API/config';
import NormalTableWithRadius from '../../../Component/NormalTableWithRadius';
import { EditOutlined } from '@mui/icons-material';
import { DeleteOutlined } from '@mui/icons-material';
import Confirmation from '../../../Component/Confirmation';

const HazardWorkSheet = ({roleAccess,formId}) => {
  
 
  const [errorsHa, setErrors] = useState({
    hazardsIdentification: "",
    riskAssessmentText: "",
    riskControl: "",
    additionalControl: "",
    thoseAtRisk: "",
  });
  const [getHazard, setHazard] = useState({
    hazardsIdentification:"",
    riskAssessmentText: "",
    riskControl:"",
    additionalControl:'',
    thoseAtRisk: '',
  });
  const [hazardData, setHazardData] = useState([]);
  const [editHazard, setEditHazard] = useState(false);
  const [confirmIndex, setConfirmIndex] = useState(null);
  const [confirm,setConfirm]=useState({
      hazardSubmit:false,
      hazardDel:false
    })
    const [confirmValue,setConfirmValue]=useState([])
  useEffect(()=>{
    
    getHazardDataList()
  },
  [])

  const validate = () => {
    let valid = true;
    let newErrors = {
      hazardsIdentification: "",
      riskAssessmentText: "",
      riskControl: "",
      additionalControl: "",
      thoseAtRisk: "",
    };

    if (!getHazard.hazardsIdentification) {
      newErrors.hazardsIdentification = "This field is required";
      valid = false;
    }

    if (!getHazard.riskAssessmentText) {
      newErrors.riskAssessmentText = "This field is required";
      valid = false;
    }

    if (!getHazard.riskControl) {
      newErrors.riskControl = "This field is required";
      valid = false;
    }

    if (!getHazard.additionalControl) {
      newErrors.additionalControl = "This field is required";
      valid = false;
    }

    if (!getHazard.thoseAtRisk) {
      newErrors.thoseAtRisk = "This field is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };
  const hazardColumns = [
    {
      Header: "Hazards Identification",
      accessor: "hazardsIdentification",
    },

    {
      Header: "Risk Assessment (Impact)",
      accessor: "riskAssessmentText",
    },
    {
      Header: "Risk Control",
      accessor: "riskControl",
    },
    {
      Header: "Additional Control",
      accessor: "additionalControl",
    },
    {
      Header: "Those at Risk",
      accessor: "thoseAtRisk",
    },
    {
      accessor: "id",
      Cell: ({ cell }) => {
        return (
          <Box
            sx={{
              whiteSpace: "nowrap",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
            }}
          >
            <IconButton
              disabled={roleAccess.contractStaffTillCloseTillClose}
              onClick={() => {
                setEditHazard(true);
                setHazard({...cell.row.original})
                // setHazard(cell.row.original);
              }}
            >
              <EditOutlined
                sx={{
                  color: commonCss.red,
                }}
              />
            </IconButton>
            {/* {confirmIndex === cell.row.index ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <ErrorProps>Are you sure wants to delete</ErrorProps>
                <Button
                  onClick={() => {
                    handleDeleteHazard(cell.row.original.id);
                    setConfirmIndex(null);
                  }}
                  sx={{
                    color: commonCss.red,
                    borderColor: commonCss.red,
                  }}
                  size="small"
                  variant="outlined"
                >
                  Yes
                </Button>
                <Button
                  onClick={() => setConfirmIndex(null)}
                  sx={{
                    color: commonCss.green,
                    borderColor: commonCss.green,
                  }}
                  size="small"
                  variant="outlined"
                >
                  No
                </Button>
              </Box>
            ) : ( */}
              <IconButton
                disabled={roleAccess.agt}
                onClick={() =>{
                  setConfirm({...confirm,hazardDel:true})
                  setConfirmValue([cell.row.original.id])
                } 
                  
                }
              >
                <DeleteOutlined sx={{ color: commonCss.red }} />
              </IconButton>
            {/* )} */}
          </Box>
        );
      },
    },
  ];

  const handleDeleteHazard = (id) => {
    const deleteHazard = api.riskAssessment.hazardDelete;
    axiosPrivate
      .delete(`${deleteHazard}/${id}`)
      .then(() => {
        toast.success("Successfully Deleted");
        setConfirm({...confirm,hazardDel:false})
        setConfirmValue([])
        getHazardDataList();
      })
      .catch((e) => {
        setConfirm({...confirm,hazardDel:false})
        setConfirmValue([])
        console.error(e);
      });
  };

  const onHandleHazard=()=>{
        
    if (editHazard) {
      const putData = { id: getHazard.id,formId:formId, ...getHazard };
      
      console.log(putData)
      const editLink = api.riskAssessment.hazardEdit;
        axiosPrivate.put(`${editLink}/${getHazard.id}`,putData).then(res=>{
          
        setHazard({
          hazardsIdentification: "",
          riskAssessmentText: "",
          riskControl: "",
          additionalControl: "",
          thoseAtRisk: "",
        });
        setEditHazard(false)
        getHazardDataList()
        toast.success("Successfully Updated Worksheet");
        setConfirm({...confirm,hazardSubmit:false})
        setConfirmValue([])
        }).catch(e=>{
          setConfirm({...confirm,hazardSubmit:false})
          setConfirmValue([])
          console.log(e)
        })
       
     
    } else {
      const postData = { ...getHazard, formId: formId };
      console.log(postData)
      const createLink = api.riskAssessment.hazardAdd;
       axiosPrivate.post(createLink, postData).then(res=>{
       
        setHazard({
          hazardsIdentification: "",
          riskAssessmentText: "",
          riskControl: "",
          additionalControl: "",
          thoseAtRisk: "",
        });
        getHazardDataList()
        toast.success("Successfully Added Worksheet");
        setConfirm({...confirm,hazardSubmit:false})
        setConfirmValue([])
      }). catch (e=>{
        setConfirm({...confirm,hazardSubmit:false})
        setConfirmValue([])
        console.log(e)
      })
    
  }
  }

  const handleHazard=()=>{
    const isValid = validate();
    if (isValid) {
     
  
    setConfirm({...confirm,hazardSubmit:true})
  }
  }
  const getHazardDataList = async () => {
    const getByRiskIdLink = api.riskAssessment.getHazardByFormId;

    await axiosPrivate
      .get(`${getByRiskIdLink}?formId=${formId}`)
      .then((res) => {
        console.log("hardata", res);
        setHazardData(res.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };



  return (
   <>
    <Grid2 container size={12} spacing={"0.6rem"}>
    <Grid2 size={12}>
      <Typography
        variant="body2"
        fontWeight={commonCss.mainLabelHeading}
        color={commonCss.red}
      >
        HAZARD ANALYSIS WORKSHEET
      </Typography>
    </Grid2>
    <Grid2 size={{ xs: 12, md: 4 }}>
      <InputLabel sx={{ pl: commonCss.labelPadding }}>
        <Typography
          variant="body2"
          fontWeight={commonCss.labelFontWeight}
          color={commonCss.labelInputColor}
        >
          Hazards Identification
          <Typography
            component="span"
            color="error"
            fontWeight="bold"
            sx={{ ml: 0.5 }}
          >
            *
          </Typography>
        </Typography>
      </InputLabel>
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        value={getHazard.hazardsIdentification}
        onChange={(e)=>{
          setHazard({
            ...getHazard,
            hazardsIdentification: e.target.value,
          });
        }}
        slotProps={{
          input: {
            readOnly: roleAccess.contractStaffTillClose,
          },
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            backgroundColor: roleAccess.contractStaffTillClose
              ? commonCss.readOnlyBGColor
              : commonCss.inputBackgroundColor,
            borderRadius: commonCss.inputBorderRadius,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: commonCss.inputBorderColor,
          },
        }}
      />
      <ErrorProps>
        {errorsHa.hazardsIdentification}
      </ErrorProps>
    </Grid2>
    <Grid2 size={{ xs: 12, md: 4 }}>
      <InputLabel sx={{ pl: commonCss.labelPadding }}>
        <Typography
          variant="body2"
          fontWeight={commonCss.labelFontWeight}
          color={commonCss.labelInputColor}
        >
          Risk Assessment (Impact)
          <Typography
            component="span"
            color="error"
            fontWeight="bold"
            sx={{ ml: 0.5 }}
          >
            *
          </Typography>
        </Typography>
      </InputLabel>
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        value={getHazard.riskAssessmentText}
        onChange={(e) => {
          setHazard({
            ...getHazard,
            riskAssessmentText: e.target.value,
          });
        }}
        slotProps={{
          input: {
            readOnly: roleAccess.contractStaffTillClose,
          },
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            backgroundColor: roleAccess.contractStaffTillClose
              ? commonCss.readOnlyBGColor
              : commonCss.inputBackgroundColor,
            borderRadius: commonCss.inputBorderRadius,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: commonCss.inputBorderColor,
          },
        }}
      />
      <ErrorProps>{errorsHa.riskAssessmentText}</ErrorProps>
    </Grid2>
    <Grid2 size={{ xs: 12, md: 4 }}>
      <InputLabel sx={{ pl: commonCss.labelPadding }}>
        <Typography
          variant="body2"
          fontWeight={commonCss.labelFontWeight}
          color={commonCss.labelInputColor}
        >
          Risk Control (Existing Mitigation)
          <Typography
            component="span"
            color="error"
            fontWeight="bold"
            sx={{ ml: 0.5 }}
          >
            *
          </Typography>
        </Typography>
      </InputLabel>
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        value={getHazard.riskControl}
        onChange={(e) => {
          setHazard({
            ...getHazard,
            riskControl: e.target.value,
          });
        }}
        slotProps={{
          input: {
            readOnly: roleAccess.contractStaffTillClose,
          },
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            backgroundColor: roleAccess.contractStaffTillClose
              ? commonCss.readOnlyBGColor
              : commonCss.inputBackgroundColor,
            borderRadius: commonCss.inputBorderRadius,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: commonCss.inputBorderColor,
          },
        }}
      />
      <ErrorProps>{errorsHa.riskControl}</ErrorProps>
    </Grid2>
    <Grid2 size={{ xs: 12, md: 6 }}>
      <InputLabel sx={{ pl: commonCss.labelPadding }}>
        <Typography
          variant="body2"
          fontWeight={commonCss.labelFontWeight}
          color={commonCss.labelInputColor}
        >
          Additional Control (If any)
          <Typography
            component="span"
            color="error"
            fontWeight="bold"
            sx={{ ml: 0.5 }}
          >
            *
          </Typography>
        </Typography>
      </InputLabel>
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        value={getHazard.additionalControl}
        onChange={(e) => {
          setHazard({
            ...getHazard,
            additionalControl: e.target.value,
          });
        }}
        slotProps={{
          input: {
            readOnly: roleAccess.contractStaffTillClose,
          },
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            backgroundColor: roleAccess.contractStaffTillClose
              ? commonCss.readOnlyBGColor
              : commonCss.inputBackgroundColor,
            borderRadius: commonCss.inputBorderRadius,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: commonCss.inputBorderColor,
          },
        }}
      />
      <ErrorProps>{errorsHa.additionalControl}</ErrorProps>
    </Grid2>

    <Grid2 size={{ xs: 12, md: 6 }}>
      <InputLabel sx={{ pl: commonCss.labelPadding }}>
        <Typography
          variant="body2"
          fontWeight={commonCss.labelFontWeight}
          color={commonCss.labelInputColor}
        >
          Those at Risk
          <Typography
            component="span"
            color="error"
            fontWeight="bold"
            sx={{ ml: 0.5 }}
          >
            *
          </Typography>
        </Typography>
      </InputLabel>
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        value={getHazard.thoseAtRisk}
        onChange={(e) => {
          setHazard({
            ...getHazard,
            thoseAtRisk: e.target.value,
          });
        }}
        slotProps={{
          input: {
            readOnly: roleAccess.contractStaffTillClose,
          },
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            backgroundColor: roleAccess.contractStaffTillClose
              ? commonCss.readOnlyBGColor
              : commonCss.inputBackgroundColor,
            borderRadius: commonCss.inputBorderRadius,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: commonCss.inputBorderColor,
          },
        }}
      />
      <ErrorProps>{errorsHa.thoseAtRisk}</ErrorProps>
    </Grid2>
    <Grid2
      container
      size={{ xs: 12 }}
      justifyContent={"center"}
    >
      <Button
        variant="contained"
        sx={{
          display: roleAccess.contractStaffTillClose
            ? "none"
            : "default",
          backgroundColor: commonCss.red,
          borderRadius: commonCss.buttonBorderRadius,
          textTransform: "none",
          padding: "0.6rem 1rem",
          color: commonCss.buttonTextColor,
          width: "15vw",
        }}
        onClick={handleHazard}
      >
        Add Worksheet
      </Button>
    </Grid2>
   
  </Grid2>
  <Grid2
                      size={{ xs: 12 }}
                      sx={{
                        display: hazardData.length === 0 ? "none" : "default",
                      }}
                    >
                      <Paper sx={{ background: "transparent" }}>
                        <NormalTableWithRadius
                          data={hazardData}
                          columns={hazardColumns}
                        />
                      </Paper>
                    </Grid2>
                    <Dialog
        // maxWidth={"lg"}
  open={confirm.hazardDel}
  onClose={()=>{setConfirm({...confirm,hazardDel:false})}}
maxWidth={"md"}
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Confirmation
  onSubmit={()=>{handleDeleteHazard(...confirmValue)}} 
  name={'submit'}
  handleCloseClick={()=>{
    setConfirm({...confirm,hazardDel:false})
    setConfirmValue([])
    }}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={confirm.hazardSubmit}
  onClose={()=>{setConfirm({...confirm,hazardSubmit:false})}}
maxWidth={"md"}
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Confirmation
  onSubmit={()=>{onHandleHazard()}} 
  name={'submit'}
  handleCloseClick={()=>{
    setConfirm({...confirm,hazardSubmit:false})
    setConfirmValue([])
    }}/>
  </DialogContent>
       </Dialog>
  </>
 
  )
}

export default HazardWorkSheet
import React, { Fragment, useEffect, useState } from "react";
import {
  Grid2,
  Box,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  FormControl,
  RadioGroup,
  Radio,
  List,
  ListItem,
  Paper,
  MenuItem,
  Button,
  Select,
  Container,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Divider,
  InputLabel,
  FormGroup,
  Dialog,
  Collapse,
  DialogContent,
  createFilterOptions,
  Autocomplete,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";

import NormalTable from "../../Component/NormalTable";
import { Add, AddCircleOutline, Approval, DeleteOutline, EditOutlined, FlashOffRounded, KeyboardArrowDown, KeyboardArrowRight, KeyboardArrowUp, Minimize, PlusOne, UploadFile, Visibility } from "@mui/icons-material";
import { commonCss } from "../../StyleConfig/CommonCss";
import CertificateSelect from "./CertificateSelect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import RadiationCertificate from "./Certificate/RadiationCertificate";
import MethodStatement from "./Forms/MethodStatement";
import RiskAssessment from "./Forms/RiskAssessment";
import IsolationCertificate from "./Certificate/IsolationCertificate";
import LiftingAndHoisting from "./Certificate/LiftingAndHoisting";
import SafeOverride from "./Certificate/SafeOverride";
import Excavation from "./Certificate/Excavation";
import ConfinedSpaceCertificate from "./Certificate/ConfinedSpaceCertificate";
import HotWork from "./Certificate/HotWork";
import { api, companyInfo,  infoId,  operationMeasure,  roleInfo, terminalInfo } from "../API/config";
import { axiosPrivate, imageURL } from "../../axios/axios";
import Loading from "../../Component/Loading";
import { Form, Formik } from "formik";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import moment from "moment";
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import ErrorProps from "../../Component/Errorprops";
import ViewImage from "./ViewImage";
import * as yup from "yup"
import Remark from "../../Component/Remark";
import { useRef } from "react";
import Confirmation from "../../Component/Confirmation";

const PTWForm1 = ({data,getData}) => {
  const login = useSelector((state) => state.auth.loginInfo);
  const loginRoleId=String(login.roleIds).split(",")
  const [searchParams,setSearchParams]=useSearchParams()
  const [confirmIndex, setConfirmIndex] = useState(null);
  
  const [deleteBoolean,setDeleteBoolean]=useState({
    enableHotWorkCertificate:data.enableHotWorkCertificate,
    enableConfinedSpaceCertificate:data.enableConfinedSpaceCertificate,
    enableSystemOverridingCertificate:data.enableSystemOverridingCertificate,
    enableExcavationCertificate:data.enableExcavationCertificate,
    enableIsolationCertificate:data.enableIsolationCertificate,
    enableLiftingAndHoistingCertificate:data.enableLiftingAndHoistingCertificate,
    enableRadiationCertificate:data.enableRadiationCertificate,
  })
  const [location,setLocation]=useState("")
  const [loading,setLoading]=useState(false)
  const [holderName,setHolderName]=useState({
    ptwHolderName:data.permitHolderId!==null?data?.permitHolderUser?.firstName+" "+data?.permitHolderUser?.lastName:login.firstName+" "+login.lastName,
    companyName:data.companyId!==null?data?.companyDetail?.name:login.companyName,
    technicianName:data.technicianId!==null?data?.technicianUser?.firstName+" "+data?.technicianUser?.lastName:"",
    //supEmail:data?.workingArea?.pspSupervisorId!==null?data?.workingArea?.pspSupervisorDetail?.emailId:""
    oeControllerName:data.isOEControllerApproved?data?.oeControllerUserDetails?.firstName+" "+data?.oeControllerUserDetails?.lastName:""
  })
  const [error,setError]=useState({})
  const [certificateInfo,setCertificateInfo]=useState({})
  const [certificateAddList,setCertificateAddList]=useState({})
  const [certificateListOpen,setCertificateListOpen]=useState({
        hotWork:false,
        confinedSpace:false,
        radiation:false,
        excavation:false,
        isolation:false,
        safe:false,
        lifting:false
  })
  const [certificateDataList,setCertificateDataList]=useState({
    enableHotWorkCertificate:[],
    enableConfinedSpaceCertificate:[],
    enableSystemOverridingCertificate:[],
    enableExcavationCertificate:[],
    enableIsolationCertificate:[],
    enableLiftingAndHoistingCertificate:[],
    enableRadiationCertificate:[],

  })
  const [isolationList,setIsolationList]=useState({
    formId:{
      mechanical:{},
      electrical:{},
      instrumentation:{}
    },
    attributeValue:[]
  })
  const [terminal,setTerminal]=useState([])
  const [site,setSite]=useState([])
  const [formTypeData,setFormTypeData]=useState([])
  const [technician,setTechnician]=useState([])
  const [riskLevel,setRiskLevel]=useState([])
  const [riskLevelBol,setRiskLevelBol]=useState({
    isRiskAssessmentRequired:false,
    isMethodStatementRequired:false,
    jmsDocuments:[],
    jsaDocuments:[],
    riskLevelId:data.riskLevelId,
    listJSM:data?.formDetail?.isJMSRequired,
    listJSA:data?.formDetail?.isJSARequired,
    listRiskAssementRequired:data?.formDetail?.isRiskAssessmentRequired,
    listMethod:data?.formDetail?.isMethodStatementRequired,
    jmsCollapse:false,
    jsaCollapse:false
  })
  const [classifiedWhere,setClassifiedWhere]=useState([])
  const [classifiedWithWhat,setClassifiedWithWhat]=useState([])
  const [oiWhere,setOiWhere]=useState([])
  const [apWhere,setAPWhere]=useState([])
  const [productInvolved,setProductInvolved]=useState([])
  const [roleAccess,setRoleAccess]=useState({
    displayContract:loginRoleId.includes(roleInfo.contractStaff)&&(data.assignedUsers!==undefined?data.assignedUsers.includes(login.userId):false)&&!data.isContractApproved&&!data.isCancel===true,
    saveApprove:data.isContractApproved?((loginRoleId.includes(roleInfo.technican)&&data.technicianId===login.userId&&data.isTechnicianApproved&&data.isContractApproved)||data.isCancel===true):((loginRoleId.includes(roleInfo.contractStaff)&&(data.assignedUsers!==undefined?data.assignedUsers.includes(login.userId):false)&&data.isContractApproved)||data.isCancel===true),
    contractStaff:!(loginRoleId.includes(roleInfo.contractStaff)&&(data.assignedUsers!==undefined?data.assignedUsers.includes(login.userId):false))||data.isContractApproved||data.isCancel===true,
    contractAndTech:(!(loginRoleId.includes(roleInfo.contractStaff)&&(data.assignedUsers!==undefined?data.assignedUsers.includes(login.userId):false))&&!(loginRoleId.includes(roleInfo.technican)&&data.technicianId===login.userId))||(data.isContractApproved&&data.isTechnicianApproved)||data.isCancel===true,
    technician:!(loginRoleId.includes(roleInfo.technican)&&data.technicianId===login.userId)||data.isTechnicianApproved||!data.isContractApproved||data.isCancel===true,
    displayTech:((loginRoleId.includes(roleInfo.technican)&&data.technicianId===login.userId)&&data.isContractApproved)||data.isTechnicianApproved,
    cancelTech:((loginRoleId.includes(roleInfo.technican)&&data.technicianId===login.userId)||data.permitHolderId===login.userId||(loginRoleId.includes(roleInfo.contractStaff)&&(data.assignedUsers!==undefined?data.assignedUsers.includes(login.userId):false)))&&!data.isCancel===true,
    displayTechOperationMeasure:((loginRoleId.includes(roleInfo.technican)||data.isTechnicianApproved)&&data.isContractApproved)&&data.enableIsolationCertificate,
    adminTechnician:(loginRoleId.includes(roleInfo.admin)||(loginRoleId.includes(roleInfo.technican)&&data.technicianId===login.userId))&&!data.isTechnicianApproved&&data.isContractApproved&&!data.isCancel===true,
    smeMechnical:data?.operationalMeasures?.mechanical?.smeApproverId!==login.userId||data.isCancel===true,
    smeElectrical:data?.operationalMeasures?.electrical?.smeApproverId!==login.userId||data.isCancel===true,
    smeInstrumentation:data?.operationalMeasures?.instrumentation?.smeApproverId!==login.userId||data.isCancel===true,
    hsse: (loginRoleId.includes(roleInfo.HSSE)&&data.isOperationMangerApproved) ? data?.formDetail?.jsaDocumentsIsApproved || data.isCancel===true : true,
    pi: (loginRoleId.includes(roleInfo.pi)&&data.isHSSEApproved) ? data?.formDetail?.jmsDocumentsIsApproved || data.isCancel===true : true,
    rejectButton:(loginRoleId.includes(roleInfo.contractStaff)&&(data.assignedUsers!==undefined?data.assignedUsers.includes(login.userId):false))||(((loginRoleId.includes(roleInfo.technican)&&data.technicianId===login.userId)&&data.isTechnicianApproved))||data.isCancel===true,
    cloneContractStaff:(loginRoleId.includes(roleInfo.contractStaff)&&data.permitHolderId===login.userId)&&data.isLeadPermitIssuerApproved&&!data.isCancel===true
  })
const [certificateOpen,setCertificateOpen]=useState(false)
const [engineerData,setEngineerData]=useState([])
const [labourList,setLabourList]=useState(data?.formDetail?.staffListText!==null&&data?.formDetail?.staffListText!==undefined?String(data?.formDetail?.staffListText).split(','):[])
const [labour,setLabour]=useState("")
const [editLabour,setEditLabour]=useState({bol:false,i:null})
const [autoCompleteValue,setAutoCompleteValue]=useState({
  smeElectrical:data?.operationalMeasures?.electrical?.smeApproverId!==null&&data?.operationalMeasures?.electrical?.smeApproverId!==undefined?data?.operationalMeasures?.electrical?.smeApproverUserDetail?.firstName+" "+data?.operationalMeasures?.electrical?.smeApproverUserDetail?.lastName:"",
  smeMechnical:data?.operationalMeasures?.mechanical?.smeApproverId!==null&&data?.operationalMeasures?.mechanical?.smeApproverId!==undefined?data?.operationalMeasures?.mechanical?.smeApproverUserDetail?.firstName+" "+data?.operationalMeasures?.mechanical?.smeApproverUserDetail?.lastName:"",
  smeInstrumentation:data?.operationalMeasures?.instrumentation?.smeApproverId!==null&&data?.operationalMeasures?.instrumentation?.smeApproverId!==undefined?data?.operationalMeasures?.instrumentation?.smeApproverUserDetail?.firstName+" "+data?.operationalMeasures?.instrumentation?.smeApproverUserDetail?.lastName:"",
  reAssignTech:data.technicianId!==null?data?.technicianUser?.firstName+" "+data?.technicianUser?.lastName:""
})
const [smeData,setSmeData]=useState([])
const [reAssign,setAssign]=useState({
  technician:false
})
const [reAssignData,setReAssignData]=useState({
  technician:{
    formId:data.id,
    technicianId:data.technicianId
  }
})
const [viewImageValue,setViewImageValue]=useState({
  bol:false,
  dataValue:""
})
const [remarkButton,setRemarkButton]=useState({reject:false,cancel:false})
const [confirm,setConfirm]=useState({
  acknowledge:false,
  approve:false,
  staffDelete:false,
  certificateDelete:false,
  attachmentDelete:false,
  reAssign:false,
  clone:false,
  approveDoc:false
})

const [confirmValue,setConfirmValue]=useState([])

// const [validateDate,setValidateDate]=useState({
//   from:data.piPermitValidFrom,
//   to:data.piPermitValidTo
// })


 
  const fileInputRef = useRef(null);
  const navigate=useNavigate()
  const dispatch=useDispatch()

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();
 
  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  useEffect(() => {
    getDetails();
  }, []); 
  
  const getDetails=async()=>{

    setLoading(true)
    if(data.siteId!==null){
      getSite()
    }
    const getTerminal=api.terminal.getAll
    await axiosPrivate.get(`${getTerminal}`).then(res=>{
      setTerminal(res.data.data)
    }).catch(e=>{
      setTerminal([])
      console.log(e)
    })
    const getFormTypeLink=api.formType.getAll
    await axiosPrivate.get(`${getFormTypeLink}`).then(res=>{
      setFormTypeData(res.data.data)
    }).catch(e=>{
      setFormTypeData([])
      console.log(e)
    })
    // const getTechnican=api.user.getUserByCompanyIdAndRoleId
    // await axiosPrivate.get(`${getTechnican}?companyId=${companyInfo.ps}&roleId=${roleInfo.technican}`).then(res=>{
    //   setTechnician(res.data.data)
    // }).catch(e=>{
    //   setTechnician([])
    //   console.log(e)
    // })
    
    // const getSME=api.user.getUserByCompanyIdAndRoleId
    // await axiosPrivate.get(`${getSME}?companyId=${companyInfo.ps}&roleId=${roleInfo.sme}`).then(res=>{
    //   setSmeData(res.data.data)
    // }).catch(e=>{
    //   setSmeData([])
    //   console.log(e)
    // })
   

    const getRiskLevel=api.attributeValue.getByKeyName
    await axiosPrivate.get(`${getRiskLevel}?keyName=RiskLevel`).then(res=>{
       setRiskLevel(res.data.data)
    }).catch(e=>{
      setRiskLevel([])
      console.log(e)
    })
    const getClassifiedWhere=api.attributeValue.getByKeyName
    await axiosPrivate.get(`${getClassifiedWhere}?keyName=ClassifiedAreaWhere`).then(res=>{
       setClassifiedWhere(res.data.data)
    }).catch(e=>{
      setClassifiedWhere([])
      console.log(e)
    })
    const getClassifiedWithWhat=api.attributeValue.getByKeyName
    await axiosPrivate.get(`${getClassifiedWithWhat}?keyName=ClassifiedAreaWithWhat`).then(res=>{
       setClassifiedWithWhat(res.data.data)
    }).catch(e=>{
      setClassifiedWithWhat([])
      console.log(e)
    })
    const getOIWhere=api.attributeValue.getByKeyName
    await axiosPrivate.get(`${getOIWhere}?keyName=OperationalInformationWhat`).then(res=>{
       setOiWhere(res.data.data)
    }).catch(e=>{
      setOiWhere([])
      console.log(e)
    })
    const getAPWhere=api.attributeValue.getByKeyName
    await axiosPrivate.get(`${getAPWhere}?keyName=AdditionalPPEWhere`).then(res=>{
       setAPWhere(res.data.data)
    }).catch(e=>{
      setAPWhere([])
      console.log(e)
    })
    const getProductInvl=api.attributeValue.getByKeyName
    await axiosPrivate.get(`${getProductInvl}?keyName=ProductInvolvedClass`).then(res=>{
       setProductInvolved(res.data.data)
    }).catch(e=>{
      setProductInvolved([])
      console.log(e)
    })
    // const getEngineerLink=api.user.getUserByCompanyIdAndRoleId
    // await axiosPrivate.get(`${getEngineerLink}?companyId=${companyInfo.ps}&roleId=${roleInfo.engineer}`).then(res=>{
    //   setEngineerData(res.data.data)
    // }).catch(e=>{
    //   setEngineerData([])
    //   console.log(e)
    // })
    if (data.terminalId !== null) {
      handleUserById(data.terminalId);
    } 
    if(data.enableIsolationCertificate){
    const getIsolationLink=api.isolation.getDataByFormId
    axiosPrivate.get(`${getIsolationLink}?formId=${searchParams.get('id')}`).then(res=>{
      console.log(res)
      let getDataIsolation={
        formId:{
          mechanical:{},
          electrical:{},
          instrumentation:{}
        },
        attributeValue:[]
      }
        for(let i=0;res.data.data.length>i;i++){
          
         getDataIsolation={formId:{...getDataIsolation.formId,
            [res.data.data[i]?.specifyEnergyIsolatedDetail?.id===operationMeasure.mechanical?"mechanical":
            res.data.data[i]?.specifyEnergyIsolatedDetail?.id===operationMeasure.electrical?"electrical":
            res.data.data[i]?.specifyEnergyIsolatedDetail?.id===operationMeasure.instrumentation?"instrumentation":null
          ]:res.data.data[i],
          },
          attributeValue:[...getDataIsolation.attributeValue, res.data.data[i]?.specifyEnergyIsolatedDetail?.id]
        }
        }
        console.log(getDataIsolation)
        setIsolationList(getDataIsolation)
      }).catch(e=>{
        console.log(e)
      })
  }
   const getDocumentLink=api.imageUrl.getUploadFile
   let getDocumentJMSData=data?.formDetail?.jmsDocuments!==null&&data?.formDetail?.jmsDocuments!==undefined&&data?.formDetail?.jmsDocuments!==""?String(data?.formDetail?.jmsDocuments).split(","):[]
   let getDocumentJSAData=data?.formDetail?.jsaDocuments!==null&&data?.formDetail?.jsaDocuments!==undefined&&data?.formDetail?.jsaDocuments!==""?String(data?.formDetail?.jsaDocuments).split(","):[]
   console.log(getDocumentJMSData,getDocumentJSAData) 
   let storeJMSData=[]
   let storeJSAData=[]
   if(data?.formDetail?.isJMSRequired&&getDocumentJMSData.length!==0){
     storeJMSData=await axiosPrivate.post(`${getDocumentLink}`,getDocumentJMSData)
   }
   if(data?.formDetail?.isJSARequired&&getDocumentJSAData.length!==0){
    storeJSAData=await axiosPrivate.post(`${getDocumentLink}`,getDocumentJSAData)
  }
 
  setRiskLevelBol({...riskLevelBol,
    jsaDocuments:getDocumentJSAData.length===0?[]:storeJSAData.data.data,
    jsaCollapse:getDocumentJSAData.length!==0,
    jmsDocuments:getDocumentJMSData.length===0?[]:storeJMSData.data.data,
    jmsCollapse:getDocumentJMSData.length!==0,
  })
    setLoading(false)
  }
  const handleUserById = async (terminalId) => {

    const payLoaduserTechnician = {
      companyId: companyInfo.ps,
      roleId: roleInfo.technican,
      terminalId: terminalId
    };
    const payLoaduserEng = {
      companyId: companyInfo.ps,
      roleId: roleInfo.engineer,
      terminalId: terminalId
    };
    const payLoaduserSme = {
      companyId: companyInfo.ps,
      roleId: roleInfo.sme,
      terminalId: terminalId
    };
      const getTechnican = api.user.getFilteredUsers;
      await axiosPrivate.post(getTechnican, payLoaduserTechnician)
      .then(res=>{
        setTechnician(res.data.data);
        console.log(res.data.data);
      })
      .catch(e=>{
        setTechnician([])
        console.log(e)
      });
      const getEng = api.user.getFilteredUsers;
      await axiosPrivate.post(getEng, payLoaduserEng)
      .then(res=>{
        setEngineerData(res.data.data);
        console.log(res.data.data);
      })
      .catch(e=>{
        setEngineerData([])
        console.log(e)
      });
      const getSme = api.user.getFilteredUsers;
      await axiosPrivate.post(getSme, payLoaduserSme)
      .then(res=>{
        setSmeData(res.data.data);
        console.log(res.data.data);
      })
      .catch(e=>{
        setSmeData([])
        console.log(e)
      })
  };
  
  const initialValue={
    ...data,
    id:data.id,
    terminalId: data.terminalId,
    siteId: data.siteId,
    locationText:data.locationText,
    formTypeId: data.formTypeId,
    workDescription:data.workDescription,
    descriptionOfWork:data.descriptionOfWork,
    // DescriptionOfWork:"test",
    technicianId:data.technicianId,
    riskLevelId:data.riskLevelId,
    companyId:data.companyId,
    actualPermitValidFrom:data.actualPermitValidFrom,
    actualPermitValidUpTo:data.actualPermitValidUpTo,
    permitHolderId:data.isContractApproved?data.permitHolderId:login.userId,
    isEngineerAdviseRequied:data.isEngineerAdviseRequied?data.isEngineerAdviseRequied:data.enableConfinedSpaceCertificate||data.enableHotWorkCertificate||data.enableExcavationCertificate||data.enableIsolationCertificate||data.enableRadiationCertificate||data.enableSystemOverridingCertificate,
    engineerId:data.engineerId,
    enableHotWorkCertificate:data.enableHotWorkCertificate,
    enableConfinedSpaceCertificate:data.enableConfinedSpaceCertificate,
    enableSystemOverridingCertificate:data.enableSystemOverridingCertificate,
    enableExcavationCertificate:data.enableExcavationCertificate,
    enableIsolationCertificate:data.enableIsolationCertificate,
    enableLiftingAndHoistingCertificate:data.enableLiftingAndHoistingCertificate,
    enableRadiationCertificate:data.enableRadiationCertificate,
    formDetail: {
    formId: data.id,
    isRiskAssessmentRequired: data?.formDetail?.isRiskAssessmentRequired,
    isMethodStatementRequired: data?.formDetail?.isMethodStatementRequired,
    isJMSRequired: data?.formDetail?.isJMSRequired,
    isJSARequired: data?.formDetail?.isJSARequired,
    jmsDocuments: data?.formDetail?.jmsDocuments,
    jsaDocuments: data?.formDetail?.jsaDocuments,
    classifiedAreaWhere:data?.formDetail?.classifiedAreaWhere!==null&&data?.formDetail?.classifiedAreaWhere!==undefined? String(data?.formDetail?.classifiedAreaWhere).split(','):[],
    classifiedAreaWithWhat:data?.formDetail?.classifiedAreaWithWhat!==null&&data?.formDetail?.classifiedAreaWithWhat!==undefined? String(data?.formDetail?.classifiedAreaWithWhat).split(','):[],
    classifiedAreaAnyOther: data?.formDetail?.classifiedAreaAnyOther,
    operationalInformationWhat:data?.formDetail?.operationalInformationWhat!==null&&data?.formDetail?.operationalInformationWhat!==undefined? String(data?.formDetail?.operationalInformationWhat).split(','):[],
    operationalInformationOthers:data?.formDetail?.operationalInformationOthers,
    productInvolvedClassId:data?.formDetail?.productInvolvedClassId,
    productInvolvedOtherDesc: data?.formDetail?.productInvolvedOtherDesc,
    additionalPPPEWhere:data?.formDetail?.additionalPPPEWhere!==null&&data?.formDetail?.additionalPPPEWhere!==undefined? String(data?.formDetail?.additionalPPPEWhere).split(','):[],
    specialclothingSpecified:data?.formDetail?.specialclothingSpecified,
    
   
    },
    workingArea: {
    formId: data.id,
    demarcation: data?.workingArea?.demarcation,
    fireExtinguisher: data?.workingArea?.fireExtinguisher,
    useOfWarningSigns: data?.workingArea?.useOfWarningSigns,
    otherSpecified: data?.workingArea?.otherSpecified,
    otherSpecifiedText: data?.workingArea?.otherSpecifiedText,
    technicianId: data?.workingArea?.technicianId,
    pspSupervisorId: data?.workingArea?.pspSupervisorId!==undefined&&null?data?.workingArea?.pspSupervisorId:null
  },
  operationalMeasures: {
    formId: data.id,
    shutdownOP: data?.operationalMeasures?.shutdownOP,
    fireprotectionOP: data?.operationalMeasures?.fireprotectionOP,
    automaticfireprotectionOP: data?.operationalMeasures?.automaticfireprotectionOP,
    otherMeasures: data?.operationalMeasures?.otherMeasures,
    electrical: {
      equipment: data?.operationalMeasures?.electrical?.equipment,
      tagNO: data?.operationalMeasures?.electrical?.tagNO,
      lockedoff: data?.operationalMeasures?.electrical?.lockedoff,
      isolated: data?.operationalMeasures?.electrical?.isolated,
      smeApproverId:data?.operationalMeasures?.electrical?.smeApproverId,
      isSMEApproverApproved:data?.operationalMeasures?.electrical?.isSMEApproverApproved,
      smeApproverRemarks:data?.operationalMeasures?.electrical?.smeApproverRemarks
    },
    instrumentation: {
      equipment: data?.operationalMeasures?.instrumentation?.equipment,
      tagNO: data?.operationalMeasures?.instrumentation?.tagNO,
      lockedoff: data?.operationalMeasures?.instrumentation?.lockedoff,
      isolated: data?.operationalMeasures?.instrumentation?.isolated,
      disconnected: data?.operationalMeasures?.instrumentation?.disconnected,
      bypass: data?.operationalMeasures?.instrumentation?.bypass,
      smeApproverId:data?.operationalMeasures?.instrumentation?.smeApproverId,
      isSMEApproverApproved:data?.operationalMeasures?.instrumentation?.isSMEApproverApproved,
      smeApproverRemarks:data?.operationalMeasures?.instrumentation?.smeApproverRemarks
    },
    mechanical: {
      equipment: data?.operationalMeasures?.mechanical?.equipment,
      tagNO: data?.operationalMeasures?.mechanical?.tagNO,
      pressurefree: data?.operationalMeasures?.mechanical?.pressurefree,
      empty: data?.operationalMeasures?.mechanical?.empty,
      disconnected: data?.operationalMeasures?.mechanical?.disconnected,
      blankedoff: data?.operationalMeasures?.mechanical?.blankedoff, 
      lockedoff: data?.operationalMeasures?.mechanical?.lockedoff,
      flushed: data?.operationalMeasures?.mechanical?.flushed,
      ventilationSpecified: data?.operationalMeasures?.mechanical?.ventilationSpecified,
      smeApproverId:data?.operationalMeasures?.mechanical?.smeApproverId,
      isSMEApproverApproved:data?.operationalMeasures?.mechanical?.isSMEApproverApproved,
      smeApproverRemarks:data?.operationalMeasures?.mechanical?.smeApproverRemarks,
    }
  },
  }
 const certificateName=[
    {
        name:"enableHotWorkCertificate",
        label:"Hot Work",
        api:"hotWork",
        clone:true,
        cloneName: "hotWorkClone",
    },
    {
        name:"enableConfinedSpaceCertificate",
        label:"Confined Space",
        api:"confinedSpace",
        clone:true,
        cloneName:"confinedSpaceClone",
    },
    {
        name:"enableSystemOverridingCertificate",
        label:"System Overriding",
        api:"systemOverriding"
    },
    {
        name:"enableExcavationCertificate",
        label:"Excavation",
        api:"excavation"
    },
    {
        name:"enableIsolationCertificate",
        label:"Isolation",
        api:"isolation"
    },
    {
        name:"enableLiftingAndHoistingCertificate",
        label:"Lifting & Hoisting",
        api:"liftingAndHoisting"
    },
    {
        name:"enableRadiationCertificate",
        label:"Radiation",
        api:"radiation" 
    },
  ]
  const certificateColumns=[
    {
      Header:"No",
      accessor: "refNo",
      Cell: ({ cell }) => {
        return (
          <Typography
            sx={{
              fontWeight: 500,
            }}
          >
            {/* {Number(cell.row.id)+1} */}
            {cell.value}

          </Typography>
        );
      },
      // disableFilters: true,
    },
    {
      Header:"Status",
      accessor: "status",
      // accessor: "status",
      // disableFilters: true,
      Cell:({cell})=>{
        return(
          <Typography sx={{fontSize:"inherit",fontWeight:"inherit",color:"inherit"}}>
            {cell.row.original.isCancel?"Cancelled":
            cell.row.original.isLPICertificateClosed?"Closed":
            cell.row.original.reconnectionIsIsolationVerifierClosed?"Isolation Verifier Closed":
            cell.row.original.reconnectionIsIsolationOwnerClosed?"Isolation Owner Closed":
            cell.row.original.isTechnicianReconnection?"Reconnection":
            cell.row.original.isPHCertificateClosed?"Closed Initiated": cell.value===1?"Contractor Submitted":
            cell.value===null?"New":
            cell.value===2?"AGT Acknowledge":
            cell.value===3?"Engineer Acknowledge":
            cell.value===4?"Lead Permit Issuer Approved":
            cell.value===5?"Lifting Inspector Acknowledge":
            cell.value===6?"Isolation Owner Acknowledge":
            cell.value===7?"Isolation Verifier Acknowledge":
            cell.value===9?"Technician Submitted":
            cell.value===8?"Rejected":
            "Submitted"}
             {/* {cell.value.toUpperCase()==="SUBMITTED"?"Contractor Sumbitted":cell.value+" "+"Approved"} */}
          </Typography>
        )
      }
    },
    {
      Header:"Validity From",
      accessor: "certificateValidFrom", 
      // disableFilters: true,
      Cell:({cell})=>{
        return(
          <Typography sx={{fontSize:"inherit",fontWeight:"inherit",color:"inherit"}}>
            {cell.value==null?null:moment(cell.value).format("DD/MM/YYYY")}
          </Typography>

        )
      }
    },
    {
      Header:"Validity To",
      accessor: "certificateValidTo",
      // disableFilters: true,
      Cell:({cell})=>{
        return(
          <Typography sx={{fontSize:"inherit",fontWeight:"inherit",color:"inherit"}}>
            {cell.value==null?null:moment(cell.value).format("DD/MM/YYYY")}
          </Typography>

        )
      }
    },
    {
      // Header:"Validity To",
      accessor: "isAGTApproved",
      // disableFilters: true,
      Cell:({cell})=>{
        return(
           <>
          {/* {JSON.stringify({d:moment(cell.row.original.certificateValidTo).format("DD/MM/YYYY"),dd:moment().format("DD/MM/YYYY"),ddd:moment(cell.row.original.certificateValidTo).format("DD/MM/YYYY")<moment().format("DD/MM/YYYY")})} */}
            {cell.row.original.certificateValidTo===null||!cell.row.original.isLPIApproved?null:moment(cell.row.original.certificateValidTo).format("DD/MM/YYYY")<moment().format("DD/MM/YYYY")?
            <Typography sx={{fontSize:"inherit",fontWeight:"inherit",color:"red"}}>Expired</Typography>:<Typography sx={{fontSize:"inherit",fontWeight:"inherit",color:"green"}}>Active</Typography>}
          </>

        )
      }
    },
    {
      accessor:"id",
      Cell: ({ cell }) => {
        return (
          <Box sx={{ whiteSpace: "nowrap",display:"flex",justifyContent:"flex-end",width:"100%",alignItems:"center" }}>
          
            <IconButton onClick={()=>{
              setCertificateInfo(cell.row.original)
              if(cell.row.original.type==="enableHotWorkCertificate"){
                setCertificateListOpen({...certificateListOpen,hotWork:true})
              }else if(cell.row.original.type==="enableConfinedSpaceCertificate"){
                setCertificateListOpen({...certificateListOpen,confinedSpace:true})
              }
              else if(cell.row.original.type==="enableRadiationCertificate"){
                setCertificateListOpen({...certificateListOpen,radiation:true})
              }
              else if(cell.row.original.type==="enableExcavationCertificate"){
                setCertificateListOpen({...certificateListOpen,excavation:true})
              }
              else if(cell.row.original.type==="enableIsolationCertificate"){
                setCertificateListOpen({...certificateListOpen,isolation:true})
              }
              else if(cell.row.original.type==="enableSystemOverridingCertificate"){
                setCertificateListOpen({...certificateListOpen,safe:true})
              }else{
                setCertificateListOpen({...certificateListOpen,lifting:true})
              }
            }}>
              <EditOutlined sx={{color:commonCss.red}}
              
              />
            </IconButton>
            <IconButton
            sx={{display:roleAccess.contractStaff||cell.row.original.status===4?"none":"default"}}
             onClick={()=>{
              if(cell.row.original.type==="enableHotWorkCertificate"){
                setConfirmValue(["hotWork",cell.row.original,cell.row.original.type,certificateDataList[cell.row.original.type].length===1?0:1])
              }else if(cell.row.original.type==="enableConfinedSpaceCertificate"){
                setConfirmValue(["confinedSpace",cell.row.original,cell.row.original.type,certificateDataList[cell.row.original.type].length===1?0:1])
              }
              else if(cell.row.original.type==="enableRadiationCertificate"){
                setConfirmValue(["radiation",cell.row.original,cell.row.original.type,certificateDataList[cell.row.original.type].length===1?0:1])
              }
              else if(cell.row.original.type==="enableExcavationCertificate"){
                setConfirmValue(["excavation",cell.row.original,cell.row.original.type,certificateDataList[cell.row.original.type].length===1?0:1])
              }
              else if(cell.row.original.type==="enableIsolationCertificate"){
                setConfirmValue(["isolation",cell.row.original,cell.row.original.type,certificateDataList[cell.row.original.type].length===1?0:1])
              }
              else if(cell.row.original.type==="enableSystemOverridingCertificate"){
                setConfirmValue(["systemOverriding",cell.row.original,cell.row.original.type,certificateDataList[cell.row.original.type].length===1?0:1])
              }else{
                setConfirmValue(["liftingAndHoisting",cell.row.original,cell.row.original.type,certificateDataList[cell.row.original.type].length===1?0:1])
              }
              setConfirm({...confirm,certificateDelete:true})
            }}>
              <Minimize sx={{color:commonCss.red}}/>
            </IconButton>
           
          </Box>
        );
      },
    }
    
  ]
  const riskLevelColumns=[
    {
      accessor: "label",
      Cell:({cell,row})=>{
        return(
          <Box sx={{display:"flex",gap:"0.2rem",alignItems:"center"}}>
            { row.original.name==="isJMSRequired"&&riskLevelBol[row.original.docName].length!==0||row.original.name==="isJSARequired"&&riskLevelBol[row.original.docName].length!==0?
           <IconButton onClick={()=>{row.toggleRowExpanded()}}>
          {row.isExpanded ? (
              <KeyboardArrowDown />
            ) : (
              <KeyboardArrowRight />
            )}</IconButton>:null}
            <Typography sx={{fontSize:"inherit",fontWeight:"inherit",color:"inherit"}}>
              {cell.value}
          </Typography>
          </Box>
          
        )
      }
      // disableFilters: true,
    },
    {
      accessor:"listLevel",
      Cell:({cell})=>{
         return (
          <>
          
          </>
         )
      }
    },
    {
      accessor:"id",
      Cell: ({ cell ,row}) => {
        return (
          <Box sx={{ whiteSpace: "nowrap",display:"flex",justifyContent:"flex-end",width:"100%",alignItems:'center' }}>
            <IconButton 
            sx={{display:cell.row.original.label!=="JMS"&&cell.row.original.label!=="JSA"?"default":"none"}}
            
            onClick={()=>{
                setRiskLevelBol({...riskLevelBol,[cell.row.original.name]:true})
           
            }}
            >
              <EditOutlined sx={{color:commonCss.red}}/>
              
            </IconButton>
            <IconButton 
            component={"label"} 
            sx={{display:(cell.row.original.label==="JMS"||cell.row.original.label==="JSA")?"default":"none"}}
            disabled={roleAccess.contractStaff}
            >
          
              <UploadFile sx={{color:commonCss.red}}/>
              <input
                  type="file"
                  hidden
                  onChange={(e) => {
                    if (e.target.files[0] !== undefined) {
                      const file = e.target.files[0];
                      let reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = function () {
                        // const dataSplit = reader.result.split("base64,")[1];
                        const dataSplit=reader.result
                        // Process the base64 data here
                        const postData={
                          fileName: e.target.files[0].name,
                          fileType: e.target.files[0].type,
                          base64String: dataSplit,
                        }
                        handleDocument(postData,cell.row.original.docName)
                        if(riskLevelBol[cell.row.original.docName].length===0){
                          row.toggleRowExpanded();
                        }
                        // setRiskLevelBol({...riskLevelBol,[cell.row.original.docName]:dataSplit})
                      };
                      reader.onerror = function (error) {};
                    }
                  }}
                />
            </IconButton>
            {/* <IconButton 
            sx={{display:(cell.row.original.label==="JMS"||cell.row.original.label==="JSA")&&(riskLevelBol[cell.row.original.docName]!==""&&riskLevelBol[cell.row.original.docName]!==null&&riskLevelBol[cell.row.original.docName]!==undefined)?"default":"none"}}
            onClick={()=>{
              setViewImageValue({
                bol:true,
                dataValue:riskLevelBol[cell.row.original.docName]
              })
            }}
            >
            <Visibility sx={{color:commonCss.red}}/>
              
            </IconButton> */}
            <IconButton sx={{display:(cell.row.original.label==="JMS"||cell.row.original.label==="JSA")&&(riskLevelBol[cell.row.original.docName]!==""&&riskLevelBol[cell.row.original.docName]!==null&&riskLevelBol[cell.row.original.docName]!==undefined)&&(cell.row.original.label==="JMS"?(loginRoleId.includes(roleInfo.pi)&&data.isHSSEApproved)&&!data?.formDetail?.jmsDocumentsIsApproved:(loginRoleId.includes(roleInfo.HSSE)&&data.isOperationMangerApproved)&&!data?.formDetail?.jsaDocumentsIsApproved)?"default":"none"}}
              disabled={cell.row.original.label==="JMS"?roleAccess.pi:roleAccess.hsse}
            onClick={()=>{
              setConfirm({...confirm,approveDoc:true})
            setConfirmValue([(cell.row.original.label==="JMS"?2:1)])
            //  handleSaveDocument((cell.row.original.label==="JMS"?2:1))
            }}>
              <Approval sx={{color:commonCss.red}}/>
            </IconButton>
           
          </Box>
        );
      },
    }
    
  ]
  const documentColumn=[
    {
      accessor:"fileName",
     
    },
    {
      accessor:"fileUrl",
      Cell:({cell})=>{
        return(
          <IconButton 
          onClick={()=>{
            window.open(`${imageURL+cell.row.original.fileUrl}`)
          }}
            // onClick={()=>{
            //   setViewImageValue({
            //     bol:true,
            //     dataValue:riskLevelBol[cell.row.original.docName]
            //   })
            // }}
            >
            <Visibility sx={{color:commonCss.red}}/>
              
            </IconButton>
        )
      }
    }
  ]
  const riskLevelTypes=[
    {
      name:"isRiskAssessmentRequired",
      label:"Risk Assesment",
      listName:'listRiskAssementRequired',
      flag:riskLevelBol.riskLevelId===infoId.low,
      listLevel:riskLevelBol.listRiskAssementRequired,
    },
    {
      name:"isMethodStatementRequired",
      label:"Method Statement",
      listName:'listMethod',
      flag:riskLevelBol.riskLevelId===infoId.low,
      listLevel:riskLevelBol.listMethod,
    },
    {
      name:"isJMSRequired",
      label:"JMS",
      listName:'listJSM',
      flag:riskLevelBol.riskLevelId!==null,
      listLevel:riskLevelBol.listJSM,
      docName:"jmsDocuments",
      collapse:"jmsCollapse"
    },
    {
      name:"isJSARequired",
      label:"JSA",
      flag:riskLevelBol.riskLevelId!==null,
      listName:'listJSA',
      listLevel:riskLevelBol.listJSA,
      docName:"jsaDocuments",
      collapse:"jsaCollapse"
    },
  ]
  const workingArea=[
    {
      name:"demarcation",
      label:"Demarcation"
    },
    {
      name:"fireExtinguisher",
      label:"Fire Extinguisher"
    },
    {
      name:"useOfWarningSigns",
      label:"Use of Warning Signs"
    },
    {
      name:"otherSpecified",
      label:"Others"
    },
    
  ]
  const mechnicalOM=[
    {
      name:"pressurefree",
      label:"Pressure free"
    },
    {
      name:"empty",
      label:"Empty"
    },
    {
      name:"disconnected",
      label:"Disconnected"
    },
    {
      name:"blankedoff",
      label:"Blanked off"
    },
    {
      name:"lockedoff",
      label:"Locked off"
    },
    {
      name:"flushed",
      label:"Flushed"
    },
    
  ]
  const electricalOM=[
    {
      name:"lockedoff",
      label:"Locked off"
    },
    {
      name:"isolated",
      label:"Isolated"
    },
    
  ]
  const instrumentationOM=[
    {
      name:"lockedoff",
      label:"Locked off"
    },
    {
      name:"isolated",
      label:"Isolated"
    },
    {
      name:"disconnected",
      label:"Disconnected"
    },
    {
      name:"bypass",
      label:"Bypass"
    },
    
  ]
 

  const handleDocument=(base64,name,collapse,collapseValue)=>{
    console.log(name,collapse,collapse,base64)
    const uploadLink=api.imageUrl.add
    axiosPrivate.post(`${uploadLink}`,base64).then(res=>{
      console.log({...riskLevelBol,[name]:[...riskLevelBol[name],res.data.data]})
      setRiskLevelBol({...riskLevelBol,[name]:[...riskLevelBol[name],res.data.data],[collapse]:collapseValue})
      fileInputRef.current.value = null;
     
    }).catch(e=>{
      console.log(e)
    })
  }

  const handleDeleteDocument=(name,id,collapse,collapseValue)=>{
    const deleteLink=api.imageUrl.delete
    console.log(riskLevelBol[name],name)
    const filterDelete=riskLevelBol[name].filter(fil=>fil.id!==id)
    axiosPrivate.post(`${deleteLink}`,{id:id}).then(res=>{
      setRiskLevelBol({...riskLevelBol,[name]:filterDelete,[collapse]:collapseValue})
      fileInputRef.current.value = null;
      setConfirm({...confirm,attachmentDelete:false})
      setConfirmValue([])
    }).catch(e=>{
      console.log(e)
      setConfirm({...confirm,attachmentDelete:false})
    setConfirmValue([])
    })


  }

  const handleSaveDocument=(type)=>{
  const saveDocLink=api.PTWForm.saveDocumentApprove
  const postData={
    id:data.id,
    type:type,
    isApproved:true
  }
  console.log(postData) 
  axiosPrivate.post(`${saveDocLink}`,postData).then(res=>{
    setConfirm({...confirm,approveDoc:false})
            setConfirmValue([])
    toast.success("Successfully Approved")
    getData()
  }).catch(e=>{
    setConfirm({...confirm,approveDoc:false})
    setConfirmValue([])
    console.log(e)
  })
  }

  const certificateDelete=(apiName,certData,type,index)=>{
 const deleteLink=api[apiName].deleteCertificate
 console.log(apiName,certData,type,index)
 axiosPrivate.delete(`${deleteLink}/${certData.id}`).then(res=>{
  if(Number(index)===0){
    setDeleteBoolean({
      ...deleteBoolean,[type]:false
     })
   onSave({...initialValue,[type]:false})
   
  }else{
  handleCertificate({name:type,api:apiName})
  }
  toast.success("Successfully Deleted")
  setConfirm({...confirm,certificateDelete:false})
  setConfirmValue([])
  
 }).catch(e=>{
  console.log(e)
  setConfirm({...confirm,certificateDelete:false})
    setConfirmValue([])
 })
  }
  
  const handleStaff=()=>{
    if(editLabour.bol){
     let updateLabour=[...labourList] 
     updateLabour[editLabour.i]=labour
     setLabourList(updateLabour)
     setEditLabour({bol:false,i:null})
     setLabour("")
    }else{
     setLabourList([...labourList,labour])
     setLabour("")
    }
 }

 const handleDelete = (index) => {
  const updatedList = labourList.filter((_, i) => i !== index);
  setLabourList(updatedList);
  setConfirmIndex(null); 
  setConfirm({...confirm,staffDelete:false})
  setConfirmValue([])
};


 const handleCertificate=async(nameCert,type)=>{
  const id= searchParams.get("id")
  console.log("check")
  if(certificateAddList[nameCert.name]&&type){
    setCertificateAddList({...certificateAddList,[nameCert.name]:false})
  }else{
  const getLink=api[nameCert.api].getDataByFormId
  console.log(getLink)
  await axiosPrivate.get(`${getLink}?formId=${id}`).then(res=>{
    setCertificateAddList({...certificateAddList,[nameCert.name]:true})
    setCertificateDataList({...certificateDataList,[nameCert.name]:res.data.data})
    console.log(res)
  }).catch(e=>{
    console.log(e)
    setCertificateAddList({...certificateAddList,[nameCert.name]:false})
    setCertificateDataList({...certificateDataList,[nameCert.name]:[]})
  })
}


 }

 const getSite=async(value)=>{
 const getSiteLink=api.site.getAll
 await axiosPrivate.get(`${getSiteLink}`).then(res=>{
  setSite(res.data.data)
 }).catch(e=>{
  console.log(e)
 })
 }


 const onSave=async(value,navBoll)=>{
  const saveFormLink=api.PTWForm.saveForm
  const getJMS=riskLevelBol.jmsDocuments.map(obj => obj.id)
  const getJSA=riskLevelBol.jsaDocuments.map(obj => obj.id)
  const filterEngineerRequired=[value].filter(fil=>fil.enableConfinedSpaceCertificate||fil.enableExcavationCertificate||fil.enableHotWorkCertificate||fil.enableIsolationCertificate||fil.enableLiftingAndHoistingCertificate||fil.enableRadiationCertificate||fil.enableSystemOverridingCertificate)
  const postData={ 
    ...value,
    // ...deleteBoolean,
    isEngineerAdviseRequied:filterEngineerRequired.length===0?false:value.isEngineerAdviseRequied,
    formDetail:{
      ...value.formDetail,
      jmsDocuments:value.formDetail.isJMSRequired?getJMS.toString():value.formDetail.jmsDocuments,
      jsaDocuments:value.formDetail.isJSARequired?getJSA.toString():value.formDetail.jsaDocuments,
      classifiedAreaWhere:value.formDetail.classifiedAreaWhere.length!==0?value.formDetail.classifiedAreaWhere.toString():null,
      classifiedAreaWithWhat:value.formDetail.classifiedAreaWithWhat.length!==0?value.formDetail.classifiedAreaWithWhat.toString():null,
      operationalInformationWhat:value.formDetail.operationalInformationWhat.length!==0?value.formDetail.operationalInformationWhat.toString():null,
      additionalPPPEWhere:value.formDetail.additionalPPPEWhere.length!==0?value.formDetail.additionalPPPEWhere.toString():null,
      staffListText:labourList.length!==0?labourList.toString():null
    },
    operationalMeasures:{
      ...value.operationalMeasures,
      mechanical:value.enableIsolationCertificate&&isolationList.attributeValue.includes(operationMeasure.mechanical)?{
        ...value.operationalMeasures.mechanical
      }:null,
      electrical:value.enableIsolationCertificate&&isolationList.attributeValue.includes(operationMeasure.electrical)?{
        ...value.operationalMeasures.electrical
      }:null,
      instrumentation:value.enableIsolationCertificate&&isolationList.attributeValue.includes(operationMeasure.instrumentation)?{
        ...value.operationalMeasures.instrumentation
      }:null,
    }
    
  } 
   console.log(postData)
  await axiosPrivate.post(`${saveFormLink}`,postData).then(res=>{
    console.log(res)
    if(navBoll){
     navigate(`/formlist`)
    }
    // else{
    //   getData()
    // }
   }).catch(e=>{
    console.log(e)
   })
  
 }

 const saveApproval=async(approve,remark,type)=>{
  console.log(approve,remark,type)
  const aprovalFormLink=api.PTWForm.saveApproval
    
      const appData=(type===undefined)?{
        id:data.id,
        isApproved:approve,
        verifierId:login.userId,
        remarks:remark
        }:{
          id:data.id,
          isApproved:approve,
          verifierId:login.userId,
          omType:type,
          remarks:remark
          }
      console.log(appData)
      await axiosPrivate.post(`${aprovalFormLink}`,appData).then(res=>{
        setRemarkButton({...remarkButton,reject:false})
        setConfirm({...confirm,approve:false,acknowledge:false})
        setConfirmValue([])
        navigate("/formlist")
        // if(type!==undefined){
        //   if(data?.operationalMeasures?.electrical!==null){
        //     if(loginRoleId.includes(data?.operationalMeasures?.electrical.smeApproverId&&!data?.operationalMeasures?.electrical.isSMEApproverApproved)){
        //        getData() 
        //     }
        //   }else if(

        //   )
        // }else{
          
        // }
       

      }).catch(e=>{
        toast.error(e.response.data.message)
        
        setConfirm({...confirm,approve:false,acknowledge:false})
    setConfirmValue([])
       console.log(e)
      })
 }
 
 const onAcknowledge=(value)=>{
  const saveFormLink=api.PTWForm.saveForm
  console.log(value)
  axiosPrivate.post(`${saveFormLink}`,value).then(async (res)=>{
    console.log(res)
     saveApproval(true,null)
     setConfirm({...confirm,acknowledge:false})
     setConfirmValue([])
  }).catch(e=>{
    console.log(e)
    setConfirm({...confirm,acknowledge:false})
    setConfirmValue([])

   })
 }
 const onSubmit=async(value)=>{
 
  const getJMS=riskLevelBol.jmsDocuments.map(obj => obj.id)
  const getJSA=riskLevelBol.jsaDocuments.map(obj => obj.id)
  const filterEngineerRequired=[deleteBoolean].filter(fil=>fil.enableConfinedSpaceCertificate||fil.enableExcavationCertificate||fil.enableHotWorkCertificate||fil.enableIsolationCertificate||fil.enableLiftingAndHoistingCertificate||fil.enableRadiationCertificate||fil.enableSystemOverridingCertificate)
  const postData={ 
    ...value, 
    isEngineerAdviseRequied:filterEngineerRequired.length===0?false:true,
    ...deleteBoolean,
    formDetail:{
      ...value.formDetail,
      jmsDocuments:value.formDetail.isJMSRequired?getJMS.toString():value.formDetail.jmsDocuments,
      jsaDocuments:value.formDetail.isJSARequired?getJSA.toString():value.formDetail.jsaDocuments,
      classifiedAreaWhere:value.formDetail.classifiedAreaWhere.length!==0?value.formDetail.classifiedAreaWhere.toString():null,
      classifiedAreaWithWhat:value.formDetail.classifiedAreaWithWhat.length!==0?value.formDetail.classifiedAreaWithWhat.toString():null,
      operationalInformationWhat:value.formDetail.operationalInformationWhat.length!==0?value.formDetail.operationalInformationWhat.toString():null,
      additionalPPPEWhere:value.formDetail.additionalPPPEWhere.length!==0?value.formDetail.additionalPPPEWhere.toString():null,
      staffListText:labourList.length!==0?labourList.toString():null
    },
    operationalMeasures:{
      ...value.operationalMeasures,
      mechanical:value.enableIsolationCertificate&&isolationList.attributeValue.includes(operationMeasure.mechanical)?{
        ...value.operationalMeasures.mechanical
      }:null,
      electrical:value.enableIsolationCertificate&&isolationList.attributeValue.includes(operationMeasure.electrical)?{
        ...value.operationalMeasures.electrical
      }:null,
      instrumentation:value.enableIsolationCertificate&&isolationList.attributeValue.includes(operationMeasure.instrumentation)?{
        ...value.operationalMeasures.instrumentation
      }:null,
    }
  }
   console.log(postData)
 const roleNameValidation=(loginRoleId.includes(roleInfo.contractStaff)&&!data.isContractApproved)?"contractor":"technician"
  validationSchema[roleNameValidation]
  .validate(postData, { abortEarly: false })
  .then((responseData) => {
    setError({})
    console.log("no validation errors");
    console.log(responseData);
    setConfirm({...confirm,acknowledge:true})
    setConfirmValue([postData])
})
  .catch((err) => {

   const errors= err.inner.reduce((acc, error) => {
      return {
        ...acc,
        [error.path]: error.errors[0],
      }
    }, {})
    setError(errors)
    toast.error("Please Fill Required Fields")
});
  
   
  
 }
 
 const handleReassign=async()=>{
  const reassignLink=api.PTWForm.reAssign
  console.log(reAssignData.technician)
  await axiosPrivate.post(`${reassignLink}`,reAssignData.technician).then(res=>{
    toast.success("Successfully Reassigned")
    setAutoCompleteValue({...autoCompleteValue,reAssignTech:""})
    setAssign({...reAssign,technician:false})
    setConfirm({...confirm,reAssign:false})
    navigate("/formlist")
  }).catch(e=>{
    console.log(e)
    setConfirm({...confirm,reAssign:false})
    setConfirmValue([])
  })
 }

 const handleIsolationLink=(type)=>{
  console.log("check")
  if(type==="mechanical"){
  setCertificateInfo(isolationList.formId.mechanical)
  }else if(type==="electrical"){
    setCertificateInfo(isolationList.formId.electrical)
 }else if(type==="instrumentation"){
  setCertificateInfo(isolationList.formId.instrumentation)
 }
 setCertificateListOpen({...certificateListOpen,isolation:true})
 }
 const handleCloneAPI=(link,id,certDetail)=>{
  axiosPrivate.post(`${link}/${id}`).then(res=>{
    handleCertificate(certDetail)
    setConfirm({...confirm,clone:false})
    setConfirmValue([])
    toast.success("Successfully Created")
   }).catch(e=>{
    console.log(e)
    setConfirm({...confirm,clone:false})
    setConfirmValue([])
   })
 }
 const handleClone=(certDetail)=>{
  const link=api[certDetail.api].clone

     const getDataLink=api[certDetail.api].getDataByFormId
     axiosPrivate.get(`${getDataLink}?formId=${data.id}`).then(res=>{
        if(res.data.data[0].isLPICertificateClosed){
        handleCloneAPI(link,res.data.data[0].id,certDetail)
        }else{
          toast.error("LPI Not Close The Certificate")
        }
     }).catch(e=>{
      console.log(e)
     })
  

 }

 const OnCancel=(remark)=>{
  const cancelLink=api.PTWForm.cancelPermit
  axiosPrivate.get(`${cancelLink}?id=${data.id}&&remarks=${remark}`).then(res=>{
    toast.success("Permit Cancelled")
     setRemarkButton({...remarkButton,cancel:false})
    navigate("/formlist")
  }).catch(e=>{
    console.log(e)
  })
 }

 const validationSchema={
  contractor:yup.object().shape({
  terminalId:yup.string().required('This field is required'),
  siteId:yup.string().when("terminalId",
    {
      is:(value)=>(value===terminalInfo.terminal1),
      then: (schema) => schema.required('This field is required'),
      otherwise:  (schema) => schema.notRequired(),
    }
  ),
  technicianId:yup.string().required('This field is required'),
  riskLevelId:yup.string().required('This field is required'),
  actualPermitValidFrom:yup.date().required('This field is required'),
  actualPermitValidUpTo:yup.date().required('This field is required'),
  descriptionOfWork:yup.string().required('This field is required'),
  formDetail:yup.object().shape({
    jmsDocuments:yup.string().when(["isJMSRequired"],{
      is:(value)=>value===true,
      then: (schema) => schema.required('This field is required'),
      otherwise:  (schema) => schema.notRequired(),
    }),
    jsaDocuments:yup.string().when(["isJSARequired"],{
      is:(value)=>value===true,
      then: (schema) => schema.required('This field is required'),
      otherwise:  (schema) => schema.notRequired(),
      
    }),
  })
 }),
 technician:yup.object().shape({
  engineerId:yup.string().when("isEngineerAdviseRequied",
    {
      is:(value)=>value===true,
      then: (schema) => schema.required('This field is required'),
      otherwise:  (schema) => schema.notRequired(),
    }
  ),
  operationalMeasures:yup.object().shape({
  
    mechanical:isolationList.attributeValue.length!==0&&isolationList.attributeValue.includes(operationMeasure.mechanical)?yup.object().shape({
      smeApproverId:yup.string().when("tagNO",
        {
          is:(value)=>isolationList.attributeValue.includes(operationMeasure.mechanical),
          then: (schema) => schema.required('This field is required'),
          otherwise:  (schema) => schema.notRequired(),
        }
      ),
    }):yup.string().nullable(),
    electrical:isolationList.attributeValue.length!==0&&isolationList.attributeValue.includes(operationMeasure.electrical)?yup.object().shape({
      smeApproverId:yup.string().when("tagNO",
        {
          is:(value)=>isolationList.attributeValue.includes(operationMeasure.electrical),
          then: (schema) => schema.required('This field is required'),
          otherwise:  (schema) => schema.notRequired(),
        }
      ),
    }):yup.string().nullable(),
    instrumentation:isolationList.attributeValue.length!==0&&isolationList.attributeValue.includes(operationMeasure.instrumentation)?yup.object().shape({
      smeApproverId:yup.string().when("tagNO",
        {
          is:(value)=>isolationList.attributeValue.includes(operationMeasure.instrumentation),
          then: (schema) => schema.required('This field is required'),
          otherwise:  (schema) => schema.notRequired(),
        }
      ),
    }):yup.string().nullable()
  }),
  formDetail:yup.object().shape({
    
    additionalPPPEWhere:yup.string().required('This field is required'),
  })
 }),
}

//  const handleValidation=(values)=>{
//   validationSchema
//   .validate(values, { abortEarly: false })
//   .catch((err) => {
//    const errors= err.inner.reduce((acc, error) => {
//     console.log(acc,error)
//       return {
//         ...acc,
//         [error.path]: error.errors[0],
//       }
//     }, {})
//     setError(errors)
//     console.log(errors)
// });
//  }

 
// const renderRowSubComponent = (row) => {
//   console.log(row)
//   return (
   
//      <NormalTable data={riskLevelBol[row.row.original.docName]} columns={documentColumn} />
//   );
// }
 
  return (
    <>
    {loading?<Loading/>:
    
      <Formik initialValues={initialValue} onSubmit={onSubmit} enableReinitialize>
        {({ handleChange, setFieldValue, values, resetForm }) => (

          <Form>
        <Grid2 container spacing={"0.6rem"} >
       
        <Grid2 container size={12} alignItems={"center"} spacing={"1rem"}>
        <Grid2 size={{xs:12,md:4}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
                Terminal
                 <Typography
                  component="span"
                  color="error"
                  fontWeight="bold"
                  sx={{ ml: 0.5 }}
                  >
                  *
                  </Typography>
              </Typography>
            </InputLabel>
            <Select
                size="small"
                defaultValue=""
                displayEmpty
                name="terminalId"
                value={values.terminalId}
                //readOnly={!values.assignedUsers.includes(login.roleIds)}
                readOnly={roleAccess.contractStaff}
                sx={{
                  borderRadius: "13px",
                  backgroundColor: roleAccess.contractStaff?commonCss.readOnlyBGColor:"white",
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '747373',
                  },
                }}
                onChange={(e)=>{
                  handleChange(e)
                  if(terminalInfo.terminal1===e.target.value){
                    getSite(e.target.value)
                  }
                  handleUserById(e.target.value);
                  
                }}
                // onFocus={()=>{handleValidation(values)}}
                fullWidth
              >
                {terminal.map((term,i)=>(
                    <MenuItem  key={i} value={term.id}>{term.name}</MenuItem>
                ))}
              </Select>
              {error.terminalId!==undefined?
               <ErrorProps>{error.terminalId}</ErrorProps>:null}

          </Grid2>
          <Grid2 size={{xs:12,md:4}} sx={{display:values.terminalId===terminalInfo.terminal1?"default":"none"}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
                Site
                 <Typography
                                  component="span"
                                  color="error"
                                  fontWeight="bold"
                                  sx={{ ml: 0.5 }}
                                  >
                                  *
                                  </Typography>
              </Typography>
            </InputLabel>
            <Select
                size="small"
                defaultValue=""
                displayEmpty
                name="siteId"
                value={values.siteId}
                readOnly={roleAccess.contractStaff}
                sx={{
                  borderRadius: "13px",
                  backgroundColor:roleAccess.contractStaff?commonCss.readOnlyBGColor: "white",
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '747373',
                  },
                }}
                onChange={handleChange}
                fullWidth
              >
                {site.map((siteList,i)=>(
                 <MenuItem key={i} value={siteList.id}>{siteList.name}</MenuItem>
                ))}
                
                {error.siteId!==undefined?
               <ErrorProps>{error.siteId}</ErrorProps>:null}
              </Select>
          </Grid2>
          <Grid2 size={{xs:12,md:4}} sx={{display:values.terminalId!==null?"default":"none"}}>
            <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
                Location
                <Typography
                                  component="span"
                                  color="error"
                                  fontWeight="bold"
                                  sx={{ ml: 0.5 }}
                                  >
                                  *
                                  </Typography>
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="locationText"
              slotProps={{
                input:{
                  readOnly:roleAccess.contractStaff
                }
              }}
              value={values.locationText}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: roleAccess.contractStaff?commonCss.readOnlyBGColor:'white', 
                  borderRadius:"13px"
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: '747373', 
                },
              }}
              onChange={(e)=>{
                setFieldValue("locationText",e.target.value)
                setLocation(e.target.value)
              }}
            />
          </Grid2>
          <Grid2 container size={{xs:12,md:4}} justifyContent={"flex-start"} mt={1.8} sx={{display:values.terminalId===terminalInfo.terminal1?"default":"none"}}>
           <FormControl>
                <RadioGroup
                  name="formTypeId"
                  row
                  value={values.formTypeId}
                  onChange={handleChange}
                >
                  {formTypeData.map((formTypeDat,i)=>(
                    <FormControlLabel
                    value={formTypeDat.id}
                    sx={{"& .MuiFormControlLabel-label.Mui-disabled":{
                  
                  color:"black"
                  
                 }}}
                    control={
                      <Radio
                      disabled={roleAccess.contractStaff}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fill: "#00807F",
                          },
                          "&:checked": {
                            color: "#00807F",
                          },
                          
                        }}
                      />
                    }
                    label={formTypeDat.name}
                  />
                  ))}
                  
                </RadioGroup>
              </FormControl>
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
                Technician
                 <Typography
                                  component="span"
                                  color="error"
                                  fontWeight="bold"
                                  sx={{ ml: 0.5 }}
                                  >
                                  *
                                  </Typography>
              </Typography>
            </InputLabel>
            <Select
                size="small"
                defaultValue=""
                displayEmpty
                name="technicianId"
                readOnly={roleAccess.contractStaff}
                value={values.technicianId}
                sx={{
                  borderRadius: "13px",
                  backgroundColor:roleAccess.contractStaff?commonCss.readOnlyBGColor: "white",
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '747373',
                  },
                }}
                fullWidth
                onChange={handleChange}
              >
                {technician.map((userTech,i)=>(
                  <MenuItem key={i} value={userTech.id} onClick={()=>{setHolderName({...holderName,technicianName:userTech.firstName+" "+userTech.lastName})}}>{userTech.firstName+" "+userTech.lastName}</MenuItem>
                ))}
              </Select>
              {error.technicianId!==undefined?
               <ErrorProps>{error.technicianId}</ErrorProps>:null}
          </Grid2>
          
        <Grid2 size={{xs:12,md:4}}>
        <InputLabel sx={{pl:0.5}}>
            <Typography variant="body2" fontWeight="500" color="black">
              Description of work
               <Typography
                                component="span"
                                color="error"
                                fontWeight="bold"
                                sx={{ ml: 0.5 }}
                                >
                                *
                                </Typography>
            </Typography>
          </InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            name="descriptionOfWork"
            value={values.descriptionOfWork}
            multiline
            maxRows={3}
            slotProps={{
              input:{readOnly:roleAccess.contractStaff}
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: roleAccess.contractStaff?commonCss.readOnlyBGColor:'white', 
                borderRadius:"13px"
              },
              '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: '747373', 
              },
            }}
            onChange={handleChange}
          />
          {error.descriptionOfWork!==undefined?
               <ErrorProps>{error.descriptionOfWork}</ErrorProps>:null}
        </Grid2>
        <Grid2 size={{ xs: 12 ,md:4}}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    From
                    <Typography
                                component="span"
                                color="error"
                                fontWeight="bold"
                                sx={{ ml: 0.5 }}
                                >
                                *
                                </Typography>
                  </Typography>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DatePicker
                      ampm={false}
                      format="DD/MM/YYYY"
                      value={values.actualPermitValidFrom!==null?dayjs(values.actualPermitValidFrom):null}
                      readOnly={roleAccess.contractStaff}
                      minDate={data.isContractApproved||data.actualPermitValidFrom!==null?dayjs(data.actualPermitValidFrom):dayjs(moment())}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: commonCss.inputBorderRadius,
                          backgroundColor: roleAccess.contractStaff?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                      }}
                      onChange={(newValue) => {
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).utc()
                            // .format(
                            //   "YYYY-MM-DDTHH:mm:ss"
                            // );
                            setFieldValue("actualPermitValidFrom",dateFrom)
                            setFieldValue("actualPermitValidUpTo",moment(dateFrom).add(7,"days").utc())
                            // setValidateDate({
                            //   ...validateDate,
                            //   from:dateFrom
                            // })
                            
                            // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                            // setFieldValue("installationDate", dateFormat);
                          }
                        }
                      }}
                      fullWidth
                    />
                  </DemoItem>
                </LocalizationProvider>
                {error.actualPermitValidFrom!==undefined?
               <ErrorProps>{error.actualPermitValidFrom}</ErrorProps>:null}
              </Grid2>
              <Grid2 size={{ xs: 12,md:4 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                   Until
                   <Typography
                                component="span"
                                color="error"
                                fontWeight="bold"
                                sx={{ ml: 0.5 }}
                                >
                                *
                                </Typography>
                  </Typography>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DatePicker
                      ampm={false}
                      format="DD/MM/YYYY"
                      minDate={values.actualPermitValidFrom!==null?dayjs(values.actualPermitValidFrom):dayjs(moment())}
                      maxDate={values.actualPermitValidFrom!==null?dayjs(values.actualPermitValidFrom).add(7,"days"):undefined}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                        },
                      }}
                      value={values.actualPermitValidUpTo!==null?dayjs(values.actualPermitValidUpTo):null}
                      onChange={(newValue) => {
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).utc()
                            // .format(
                            //   "YYYY-MM-DDTHH:mm:ss"
                            // );
                            setFieldValue("actualPermitValidUpTo",dateFrom)
                            // setValidateDate({
                            //   ...validateDate,
                            //   to:dateFrom
                            // })
                            // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                            // setFieldValue("installationDate", dateFormat);
                          }
                        }
                      }}
                      readOnly={roleAccess.contractStaff}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: commonCss.inputBorderRadius,
                          backgroundColor: roleAccess.contractStaff?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                      }}
                      fullWidth
                    />
                  </DemoItem>
                </LocalizationProvider>
                {error.actualPermitValidUpTo!==undefined?
               <ErrorProps>{error.actualPermitValidUpTo}</ErrorProps>:null}
              </Grid2>

        </Grid2>
        <Grid2 container size={12} rowSpacing={"1rem"} columnSpacing={"2rem"} alignItems={"center"} >
          <Grid2  size={{xs:12,md:4}}>
            <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
                Risk Level
                 <Typography
                                  component="span"
                                  color="error"
                                  fontWeight="bold"
                                  sx={{ ml: 0.5 }}
                                  >
                                  *
                                  </Typography>
              </Typography>
            </InputLabel>
            <Select
               fullWidth
                size="small"
                defaultValue=""
                displayEmpty
                value={values.riskLevelId}
                readOnly={roleAccess.contractStaff}
                name="riskLevelId"
                inputProps={{ "aria-label": "Risk Level" }}
                sx={{
                  borderRadius: "13px",
                  backgroundColor: roleAccess.contractStaff?commonCss.readOnlyBGColor:"white",
                  '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: '747373', 
                },
                  
                }}
                onChange={(e)=>{
                  handleChange(e)
                  setRiskLevelBol({...riskLevelBol,
                    riskLevelId:e.target.value,
                    listJSM:false,
                    listJSA:false,
                    listRiskAssementRequired:false,
                    listMethod:false})

                }}
              >
                {riskLevel.map((level,i)=>(
                   <MenuItem key={i} value={level.id}>{level.name}</MenuItem>
                ))}
            </Select>
            {error.riskLevelId!==undefined?
               <ErrorProps>{error.riskLevelId}</ErrorProps>:null}

          </Grid2>
          <Grid2 container size={8} spacing={1} alignItems="center" sx={{display:values.riskLevelId!==null?"default":"none",mt:2}}>
          
          <FormGroup row >
            {riskLevelTypes.map((typeLevel,i)=>(
              <>
               <FormControlLabel 
               key={i}
               sx={{display:typeLevel.flag?"default":"none", ml:1.5,
                "& .MuiFormControlLabel-label.Mui-disabled":{
                  
                  color:"black"
                  
                 }
               }}
               control={
               <Checkbox 
               disabled={roleAccess.contractStaff}
               name={typeLevel.name} 
               checked={values.formDetail[typeLevel.name]}
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
                 
               }}
               onChange={(e)=>{
                setFieldValue(`formDetail.${typeLevel.name}`,e.target.checked)
                setRiskLevelBol({...riskLevelBol,[typeLevel.listName]:e.target.checked})
              }}
             />
               
               } label={typeLevel.label} />
               </>
            ))}
          
          </FormGroup>
          {error.isRiskAssessmentRequired!==undefined?
               <ErrorProps>{error.isRiskAssessmentRequired}</ErrorProps>:null}
          </Grid2>
          <Grid2 size={6} sx={{display:values.formDetail.isRiskAssessmentRequired||values.formDetail.isMethodStatementRequired||values.formDetail.isJMSRequired||values.formDetail.isJSARequired?"default":"none"}}>
            <Paper elevation={4} sx={{p:1}}>
              {riskLevelTypes.map((type,i)=>(
                <Grid2 container key={i}  sx={{display:type.listLevel?'default':'none'}}>
                <Grid2 size={12} container alignItems={"center"} >
                  <Grid2 size={4}>
                   <Box sx={{display:"flex",gap:"0.2rem",alignItems:"center"}}>
               
            <Typography sx={{fontSize:"14px",fontWeight:400,color:"black"}}>
              {type.label}
          </Typography>
          <IconButton 
                  sx={{display:(type.name==="isJMSRequired"&&riskLevelBol[type.docName].length!==0)||(type.name==="isJSARequired"&&riskLevelBol[type.docName].length!==0)?"default":"none"}}
                  onClick={()=>{
                    setRiskLevelBol({...riskLevelBol,[type.collapse]:!riskLevelBol[type.collapse]})
                  }}>
          {riskLevelBol[type.collapse] ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowDown  />
            )}</IconButton>
          </Box>
                  </Grid2>
                  <Grid2 size={4}>
                  {(type.name==="isJMSRequired"&&data?.formDetail?.jmsDocumentsIsApproved)?<Typography sx={{fontSize:"14px",fontWeight:"400",color:"black"}}>{moment(data?.formDetail?.jmsDocumentsApprovedAt).format("DD/MM/YYYY")}</Typography>:null}
                  {((type.name==="isJSARequired"&&data?.formDetail?.jsaDocumentsIsApproved))?<Typography sx={{fontSize:"14px",fontWeight:"400",color:"black"}}>{moment(data?.formDetail?.jsaDocumentsApprovedAt).format("DD/MM/YYYY")}</Typography>:null}
                  </Grid2>
                  <Grid2 size={4}>
                  <Box sx={{ whiteSpace: "nowrap",display:"flex",justifyContent:"flex-end",width:"100%",alignItems:'center' }}>
          
          <IconButton 
          sx={{display:type.label!=="JMS"&&type.label!=="JSA"?"default":"none"}}
          
          onClick={()=>{
              setRiskLevelBol({...riskLevelBol,[type.name]:true})
         
          }}
          >
            <EditOutlined sx={{color:commonCss.red}}/>
            
          </IconButton>
          <IconButton 
          component={"label"} 
          sx={{display:(type.label==="JMS"||type.label==="JSA")&&roleAccess.displayContract?"default":"none"}}
          disabled={roleAccess.contractStaff}
          >
            <UploadFile sx={{color:commonCss.red}}/>
            <input
                type="file"
                ref={fileInputRef}
                hidden
                onChange={(e) => {
                  if (e.target.files[0] !== undefined) {
                    const file = e.target.files[0];
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function () {
                      // const dataSplit = reader.result.split("base64,")[1];
                      const dataSplit=reader.result
                      // Process the base64 data here
                      const postData={
                        fileName: e.target.files[0].name,
                        fileType: e.target.files[0].type,
                        base64String: dataSplit,
                      }
                      if(riskLevelBol[type.docName].length===0){
                       
                        handleDocument(postData,type.docName,type.collapse,true)
                      }else{
                        handleDocument(postData,type.docName,type.collapse,riskLevelBol[type.collapse])
                      }
                     
                      
                      // setRiskLevelBol({...riskLevelBol,[cell.row.original.docName]:dataSplit})
                    };
                    reader.onerror = function (error) {};
                  }
                }}
              />
          </IconButton>
          {/* <IconButton 
          sx={{display:(cell.row.original.label==="JMS"||cell.row.original.label==="JSA")&&(riskLevelBol[cell.row.original.docName]!==""&&riskLevelBol[cell.row.original.docName]!==null&&riskLevelBol[cell.row.original.docName]!==undefined)?"default":"none"}}
          onClick={()=>{
            setViewImageValue({
              bol:true,
              dataValue:riskLevelBol[cell.row.original.docName]
            })
          }}
          >
          <Visibility sx={{color:commonCss.red}}/>
            
          </IconButton> */}
          <IconButton sx={{display:(type.label==="JMS"||type.label==="JSA")&&(riskLevelBol[type.docName].length!==0)&&(type.label==="JMS"?!roleAccess.pi:!roleAccess.hsse)?"default":"none"}}
            disabled={type.label==="JMS"?roleAccess.pi:roleAccess.hsse}
          onClick={()=>{
            setConfirm({...confirm,approveDoc:true})
            setConfirmValue([(type.label==="JMS"?2:1)])
          //  handleSaveDocument((type.label==="JMS"?2:1))
          }}>
            <Approval sx={{color:commonCss.red}}/>
          </IconButton>
         
        </Box>
                  </Grid2>
                 
                </Grid2>
                <Grid2 size={12}>
                 <Divider />
                 </Grid2>
                 <Grid2 size={12} sx={{display:((type.name==="isJMSRequired"&&riskLevelBol[type.docName].length!==0)||(type.name==="isJSARequired"&&riskLevelBol[type.docName].length!==0))?"default":"none"}}>
                 <Collapse in={riskLevelBol[type.collapse]}>
                 {riskLevelBol[type.docName]===undefined?null:riskLevelBol[type.docName].map((docData,i)=>(
                  <Grid2 size={12} container alignItems={"center"} key={i} >
                  <Grid2 size={8}>
                  <Typography>{docData.fileName}</Typography>
                  </Grid2>
                  <Grid2 size={4}>
                  <Box sx={{ whiteSpace: "nowrap",display:"flex",justifyContent:"flex-end",width:"100%",alignItems:'center' }}>
          
       
          <IconButton 
         
          onClick={()=>{
            window.open(`${imageURL+docData.fileUrl}`)
          }}
          >
          <Visibility sx={{color:commonCss.red}}/>
            
          </IconButton >
          <IconButton sx={{display:roleAccess.contractStaff?"none":"default"}}
          onClick={()=>{

           if(i===0){
            setConfirmValue([type.docName,docData.id,type.collapse,false])
           }else{
            setConfirmValue([type.docName,docData.id,type.collapse,riskLevelBol[type.collapse]])
            
           }
           setConfirm({...confirm,attachmentDelete:true})
          }}
          >
            <DeleteOutline sx={{color:commonCss.red}}/>
          </IconButton>
          
         
        </Box>
                  </Grid2>
                 
                </Grid2>
                 ))}
                 
                 </Collapse>
                 </Grid2>
                </Grid2>
              ))}
            {/* <NormalTable data={riskLevelTypes.filter(fil=>fil.listLevel)} columns={riskLevelColumns} hideHeader={true} renderRowSubComponent={renderRowSubComponent}  /> */}
            </Paper>

            {error[`formDetail.jmsDocuments`]!==undefined||error["formDetail.jsaDocuments"]!==undefined?
               <ErrorProps>Please Upload Attachment</ErrorProps>:null}
          </Grid2>
        </Grid2>
        <Grid2 container size={12} alignItems={"center"} spacing={1}>
          <Grid2 size={6}>
          <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Appendix – Associated Certificate
              </Typography>
              </InputLabel>
          </Grid2>
          <Grid2 size={6}>
            <Box sx={{display:"flex",justifyContent:"flex-end",width:"100%"}}>
            <Button
            variant="contained"
            sx={{
              display:roleAccess.contractStaff?"none":"default",
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw"
            }}
            onClick={()=>{setCertificateOpen(true)}}
          >
            Add Certificate
          </Button>
            </Box>
          </Grid2>
          <Grid2 size={12}>
            
            {certificateName.map((
              nameCertificate,i)=>(
              <>
              <Box sx={{display:values[nameCertificate.name]&&deleteBoolean[nameCertificate.name]?"default":"none"}}>
              <Box sx={{display:"flex",justifyContent:'space-between',alignItems:"center"}}>
                <Box sx={{cursor:"pointer",display:"flex",alignItems:"center"}}
                onClick={()=>{
                   handleCertificate(nameCertificate,true)
                }}
                >
                  <IconButton>
                    {certificateAddList[nameCertificate.name]?<KeyboardArrowUp sx={{color:commonCss.green}}/>:<KeyboardArrowDown sx={{color:commonCss.green}}/>}
                  </IconButton>
                <Typography sx={{fontSize:"0.9rem",
                  fontWeight:500,
                  color:commonCss.green,
                  cursor:'pointer'
                  }}

                  > 
                  {nameCertificate.label}
                </Typography>
                <IconButton sx={{display:data[nameCertificate.cloneName] && nameCertificate.clone ? "default" : "none", }}>
                      <CircleIcon
                       
                        sx={{
                          color: commonCss.red,
                          fontSize:"0.6rem"
                        }}
                      />
                    </IconButton>
                </Box>
                <IconButton sx={{display:nameCertificate.clone?"default":"none"}}  
                disabled={!roleAccess.cloneContractStaff}
                onClick={()=>{
                  setConfirm({...confirm,clone:true})
                  setConfirmValue([nameCertificate])
                  }}>
                  <AddCircleOutline 
                  sx={{
                    color:roleAccess.cloneContractStaff?commonCss.red:"default"
                    
                  }}
                  />
                </IconButton>
              </Box>
              <Divider sx={{width:"100%"}} />
              
              <Paper elevation={4} sx={{p:1,display:certificateAddList[nameCertificate.name]?"default":"none"}}>
               <Collapse in={certificateAddList[nameCertificate.name]} >
                <NormalTable 
                data={certificateDataList[nameCertificate.name].map(obj => {
                            return { ...obj, type: nameCertificate.name };
                             })} 
                columns={certificateColumns} />
               </Collapse >
               </Paper>
               </Box>
              </>
            ))}
         
          </Grid2>
        </Grid2>
        <Grid2 container size={12} spacing={"1rem"}>
          <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
            PERMIT HOLDER
          </Typography>
          </Grid2>
          <Grid2 size={{xs:12,md:4}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Name
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={holderName.ptwHolderName}
                slotProps={{
                  input: {
                    readOnly: true,
                  },
                }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor:roleAccess.contractStaff?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Company
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={holderName.companyName}
                slotProps={{
                  input: {
                    readOnly: true, // Set readOnly to true
                  },
                }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor:roleAccess.contractStaff?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2  size={4}>
          <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              No.Of Staffs
              </Typography>
              </InputLabel>
              
              <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={labourList.length}
              slotProps={{
                input:{
                  readOnly:true
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor:roleAccess.contractStaff?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
            />
              
          </Grid2>
          
         <Grid2  size={5}>
          <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Staff List
              </Typography>
              </InputLabel>
              <Box sx={{display:"flex",gap:"1rem",alignItems:'center'}}>
              <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={labour}
              slotProps={{
                input:{
                  readOnly:roleAccess.contractStaff
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor:roleAccess.contractStaff?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              onChange={(e)=>{setLabour(e.target.value)}}
            />
              <IconButton disabled={roleAccess.contractStaff} sx={{border:`3px solid ${commonCss.red}`,p:0,}} onClick={handleStaff} >
            <Add sx={{color:commonCss.red}}/>
         </IconButton>
              </Box>
          </Grid2>
         
         <Grid2 container size={12}>
         <Grid2 size={5} sx={{display:labourList.length!==0?"default":"none"}}>
          <Paper elevation={4} >
              {labourList.map((labData,i)=>(
                <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",p:1}}>
                  <Typography>{i+1}</Typography>
                  <Typography>{labData}</Typography>
                  <Box sx={{
                                      whiteSpace: "nowrap",
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      width: "30%",
                                      alignItems: "center",
                                    }}>
                  <IconButton disabled={roleAccess.contractStaff} onClick={()=>{
                   setLabour(labData)
                   setEditLabour({bol:true,i:i})
            }} >
              <EditOutlined sx={{color:commonCss.red}}/>
            </IconButton>
            {/* {confirmIndex === i ? (


 <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "flex-end",
                                          alignItems: "center",
                                          gap: 1,
                                        }}
                                      >
                                        <ErrorProps>
                                          Are you sure wants to delete
                                        </ErrorProps>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            gap: "0.2rem",
                                            mb: 1,
                                          }}
                                        >
                                          <Button
                                            onClick={() =>{
                                              handleDelete(i)
                                            }
                                              
                                              
                                            }
                                            sx={{
                                              color: commonCss.red,
                                              borderColor: commonCss.red,
                                            }}
                                            size="small"
                                            variant="outlined"
                                          >
                                            Yes
                                          </Button>
                                          <Button
                                            onClick={() =>
                                              setConfirmIndex(null)
                                            }
                                            sx={{
                                              color: commonCss.green,
                                              borderColor: commonCss.green,
                                            }}
                                            size="small"
                                            variant="outlined"
                                          >
                                            No
                                          </Button>
                                        </Box>
                                      </Box>

) : ( */}

<IconButton
    disabled={roleAccess.contractStaff}
    onClick={() => {
      // setConfirmIndex(i)
      setConfirm({...confirm,staffDelete:true})
      setConfirmValue([i])
      } }
>
    <DeleteOutlined sx={{ color: commonCss.red }} />
</IconButton>
{/* )} */}
 </Box>
                </Box>
              ))}
             
               </Paper>
          </Grid2>
         </Grid2>
     
         
         <Grid2 container size={12} alignItems={"flex-end"} sx={{display:roleAccess.adminTechnician?"default":"none"}}>
         
          <Grid2 size={{xs:12,md:4}}>
          <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Technician Reassign
              </Typography>
              </InputLabel>
          <Autocomplete
                  readOnly={!roleAccess.adminTechnician}
                  name="technicianId"
                  inputValue={autoCompleteValue.reAssignTech}
                  filterOptions={filterOptions}
                  loading={technician.length === 0 }
                  options={technician}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) => option.firstName+" "+option.lastName || option}
                  onChange={(event, value) => {
                    if (value) {
                      setReAssignData({...reAssignData,technician:{...reAssignData.technician,technicianId:value.id}})
                      setAutoCompleteValue({...autoCompleteValue,reAssignTech:value.firstName+" "+value.lastName});
                      setFieldValue("technicianId",value.id)
                    } else {
                      setReAssignData({...reAssignData,technician:{...reAssignData.technician,technicianId:null}})
                      setAutoCompleteValue({...autoCompleteValue,reAssignTech:""});
                      setFieldValue("technicianId",data.technicianId)
                    }
                  }}
                  // onInputChange={(e, v) => {
                  //   setAgt({...agt,agtCom:v});
                  // }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="technicianId"
                      size="small"
                      // variant="standard"
                      
                      onChange={(e)=>{setAutoCompleteValue({...autoCompleteValue,reAssignTech:e.target.value})}}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: !roleAccess.adminTechnician?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                          borderRadius:  commonCss.inputBorderRadius,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: commonCss.inputBorderColor,
                        },
                      }}
                      
                    />
                  )}
                />
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
            <Box sx={{display:"flex",gap:"0.6rem",alignItems:"center"}}>
            {/* <Button
            variant="contained"
             sx={{
               backgroundColor: commonCss.red,
               borderRadius: commonCss.buttonBorderRadius,
               textTransform: "none",
               padding: "0.6rem 1rem",
               color: commonCss.buttonTextColor,
               width: "15vw",
             }}
             onClick={() => {
               setAssign({...reAssign,technician:false})
               setAutoCompleteValue({...autoCompleteValue,reAssignTech:""})
               setReAssignData({...reAssignData,technician:{formId:data.id,technicianId:null}})
             }}
           >
             Cancel
             </Button> */}
             <Button
                        
                          variant="contained"
                          sx={{
                            backgroundColor: commonCss.red,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                          }}
                          onClick={() => {
                            
                            setConfirm({...confirm,reAssign:true})
                          }}
                        >
                          Reassign
              </Button>
            </Box>
          
          </Grid2>
         
        </Grid2>
      
       
        </Grid2>
        <Grid2 container size={12} spacing={"1rem"}>
          <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          CLASSIFIED AREA
          </Typography>
          </Grid2>
          
          <Grid2 container size={8}  >
          
           <Grid2 size={12}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Where
              </Typography>
              </InputLabel>
           </Grid2>
          
           <Grid2 size={12} mt={values?.formDetail?.classifiedAreaWithWhat.includes(infoId.classifiedAreaWithWhatOtherId)?-11:-4}  >
            <FormGroup  >
            {classifiedWhere.map((typeLevel,i)=>(
               <FormControlLabel 
               key={i}
               sx={{"& .MuiFormControlLabel-label.Mui-disabled":{
                color:"black"
               }}}
               control={
               <Checkbox 
               disabled={roleAccess.contractAndTech}
               value={typeLevel.id}
               checked={values?.formDetail?.classifiedAreaWhere.includes(typeLevel.id)}
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
               onChange={(e)=>{
                if(e.target.checked){
                  setFieldValue(`formDetail.classifiedAreaWhere`,[...values.formDetail.classifiedAreaWhere,typeLevel.id])
                }else{
                  const filterDataList=values.formDetail.classifiedAreaWhere.filter(fil=>fil!==typeLevel.id)
                  setFieldValue(`formDetail.classifiedAreaWhere`,filterDataList)
                }
                
                
               }}
             />
               
               } label={typeLevel.name} />
            ))}
          
          </FormGroup>
           
          </Grid2>
          </Grid2>
          <Grid2 container size={4} >
          <Grid2 size={12}>
          <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              With What
              </Typography>
              </InputLabel>
          </Grid2>
          <Grid2  size={{xs:12}}  >
          <FormGroup  >
            {classifiedWithWhat.map((typeLevel,i)=>(
               <FormControlLabel 
               sx={{"& .MuiFormControlLabel-label.Mui-disabled":{
                color:"black"
               }}}
               key={i}
               checked={values?.formDetail?.classifiedAreaWithWhat.includes(typeLevel.id)}
               control={
               <Checkbox 
               value={typeLevel.id} 
               disabled={roleAccess.contractAndTech}
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
               onChange={(e)=>{
                if(e.target.checked){
                  setFieldValue("formDetail.classifiedAreaWithWhat",[...values.formDetail.classifiedAreaWithWhat,typeLevel.id],'formDetail')
                }else{
                  const filterDataList=values.formDetail.classifiedAreaWithWhat.filter(fil=>fil!==typeLevel.id)
                  setFieldValue("formDetail.classifiedAreaWithWhat",filterDataList,'formDetail')
                }
               }}
             />
               
               } label={typeLevel.name} />
            ))}
          
          </FormGroup>
           
          </Grid2>
          <Grid2 size={{xs:12}} sx={{display:values?.formDetail?.classifiedAreaWithWhat.includes(infoId.classifiedAreaWithWhatOtherId)?"default":"none"}}  >
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={values.formDetail.classifiedAreaAnyOther}
              slotProps={{
                input:{
                  readOnly:roleAccess.contractAndTech
                }
              }}
              name="formDetail.classifiedAreaAnyOther"
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: roleAccess.contractAndTech?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              onChange={handleChange}
              
            />
          </Grid2>
          </Grid2>
          
        </Grid2>
        <Grid2 container size={12} spacing={"1rem"}>
          <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          OPERATIONAL INFORMATION
          </Typography>
          </Grid2>
          <Grid2 size={12}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Where
              </Typography>
              </InputLabel>
           </Grid2>
           <Grid2 container size={12} spacing={0} gap={0}>
          {oiWhere.map((typeLevel,i)=>(
            <Grid2 size={{xs:6,md:3}} key={i}  >
               <FormControlLabel 
                sx={{"& .MuiFormControlLabel-label.Mui-disabled":{
                  color:"black"
                 }}}
               control={
               <Checkbox 
               checked={values.formDetail.operationalInformationWhat.includes(typeLevel.id)}
               disabled={roleAccess.contractAndTech}
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
               onChange={(e)=>{
                 if(e.target.checked){
                  setFieldValue("formDetail.operationalInformationWhat",[...values.formDetail.operationalInformationWhat,typeLevel.id])
                }else{
                  const filterDataList=values.formDetail.operationalInformationWhat.filter(fil=>fil!==typeLevel.id)
                  setFieldValue("formDetail.operationalInformationWhat",filterDataList)
                }
               }}
             />
               
               } label={typeLevel.name} />
               </Grid2>
            ))}
            </Grid2>
            <Grid2 size={{xs:7}} sx={{display:values?.formDetail?.operationalInformationWhat.includes(infoId.operationalInformationOtherId)?"default":"none"}}  >
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="formDetail.operationalInformationOthers"
              value={values.formDetail.operationalInformationOthers}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor:roleAccess.contractAndTech?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              onChange={handleChange}
            />
          </Grid2>
            
        </Grid2>
        <Grid2 container size={12} spacing={"1rem"}>
          <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
           PPE
          </Typography>
          </Grid2>
          <Grid2 size={12}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Where
              </Typography>
              </InputLabel>
           </Grid2>
           <Grid2 container size={12} spacing={0} gap={0}>
          {apWhere.map((typeLevel,i)=>(
            <Grid2 size={{xs:6,md:3}} key={i}  >
               <FormControlLabel 
               sx={{"& .MuiFormControlLabel-label.Mui-disabled":{
                color:"black"
               }}}
               control={
               <Checkbox  
               checked={values.formDetail.additionalPPPEWhere.includes(typeLevel.id)}
               disabled={roleAccess.contractAndTech}
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
               onChange={(e)=>{
                
                if(e.target.checked){
                  setFieldValue("formDetail.additionalPPPEWhere",[...values.formDetail.additionalPPPEWhere,typeLevel.id])
                }else{
                  const filterDataList=values.formDetail.additionalPPPEWhere.filter(fil=>fil!==typeLevel.id)
                  setFieldValue("formDetail.additionalPPPEWhere",filterDataList)
                }
               }}
             />
               
               } label={typeLevel.name} />
               </Grid2>
            ))}
            
            </Grid2>
            {error["formDetail.additionalPPPEWhere"]!==undefined?
               <ErrorProps>{error["formDetail.additionalPPPEWhere"]}</ErrorProps>:null}
            <Grid2 size={{xs:12}}   >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              ADDITIONAL PPE
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="formDetail.specialclothingSpecified"
              value={values.formDetail.specialclothingSpecified}
              multiline
              rows={2}
              slotProps={{
                input:{
                  readOnly:roleAccess.contractAndTech
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor:roleAccess.contractAndTech?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              onChange={handleChange}
            />
          </Grid2>
          
        </Grid2>
        <Grid2 container size={12} spacing={"1rem"}>
        <Grid2 container size={5} sx={{display:roleAccess.displayTech?"default":"none"}}>
          <Box sx={{width:"100%",display:"flex",flexDirection:"column",gap:"1rem"}}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          PRODUCT INVOLVED
          </Typography>
          </Grid2> 
          <Grid2 size={12}>
          <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Class
                  </Typography>
                </InputLabel>
                <Select
                size="small"
                defaultValue=""
                displayEmpty
                readOnly={roleAccess.technician}
                name="formDetail.productInvolvedClassId"
                value={values.formDetail.productInvolvedClassId}
                onChange={handleChange}
                sx={{
                  borderRadius: commonCss.inputBorderRadius,
                  backgroundColor:roleAccess.technician?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: commonCss.inputBorderColor,
                  },
                }} 
                fullWidth
              >
                {productInvolved.map((inv,i)=>(
                    <MenuItem key={i} value={inv.id}>{inv.name}</MenuItem>
                ))}
              </Select>
          </Grid2>
          <Grid2 size={12}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            name="formDetail.productInvolvedOtherDesc"
            value={values.formDetail.productInvolvedOtherDesc}
            multiline
            rows={2}
            slotProps={{
              input:{
                readOnly:roleAccess.technician
              }
            }}
            onChange={handleChange}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: roleAccess.technician?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor, 
                borderRadius:"13px"
              },
              '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: '747373', 
              },
            }}
          />
       
        </Grid2>
        </Box>
        </Grid2>
        <Grid2 container size={5}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          WORKING AREA
          </Typography>
          </Grid2> 
          <Grid2  size={{xs:12}}  >
          <FormGroup  >
            {workingArea.map((typeLevel,i)=>(
               <FormControlLabel 
               key={i}
               sx={{"& .MuiFormControlLabel-label.Mui-disabled":{
                color:"black"
               }}}
               control={
               <Checkbox 
               disabled={roleAccess.contractAndTech}
               name={typeLevel.name} 
               checked={values.workingArea[typeLevel.name]}
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
               onChange={(e)=>{
                setFieldValue(`workingArea.${typeLevel.name}`,e.target.checked)
               }}
             />
               
               } label={typeLevel.label} />
            ))}
          
          </FormGroup>
           
          </Grid2>
          <Grid2 size={12} sx={{display:values.workingArea.otherSpecified?"default":"none"}}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            name="workingArea.otherSpecifiedText"
            value={values.workingArea.otherSpecifiedText}
            onChange={handleChange}
            slotProps={{
              input:{
                readOnly:roleAccess.contractAndTech
              }
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: roleAccess.contractAndTech?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor, 
                borderRadius:"13px"
              },
              '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: '747373', 
              },
            }}
          />
       
        </Grid2>
        </Grid2>

        </Grid2>
        <Grid2 container size={12} spacing={"1rem"} sx={{display:roleAccess.displayTechOperationMeasure?"default":"none"}} >
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          OPERATIONAL MEASURES
          </Typography>
          </Grid2>
          <Grid2  size={4} sx={{display:isolationList.attributeValue.includes(operationMeasure.mechanical)?"default":"none"}}>
            <Box sx={{width:"100%"}}>
              <Grid2 container size={12} spacing={"0.5rem"}>
              <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.green}>
          MECHANICAL
          </Typography>
              </Grid2> 
            <Grid2 size={{xs:12}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Equipment
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="operationalMeasures.mechanical.equipment"
              value={values.operationalMeasures.mechanical.equipment}
              
              onChange={handleChange}
              slotProps={{
                input:{
                  readOnly:roleAccess.technician
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: roleAccess.technician?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              ID/Tag Number
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="operationalMeasures.mechanical.tagNO"
              value={values.operationalMeasures.mechanical.tagNO}
              onChange={handleChange}
              slotProps={{
                input:{
                  readOnly:roleAccess.technician
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor:roleAccess.technician?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
             SME
              <Typography
                               component="span"
                               color="error"
                               fontWeight="bold"
                               sx={{ ml: 0.5 }}
                               >
                               *
                               </Typography>
              </Typography>
            </InputLabel>
            <Autocomplete
                  readOnly={roleAccess.technician}
                  name="operationalMeasures.mechanical.smeApproverId"
                  inputValue={autoCompleteValue.smeMechnical}
                  filterOptions={filterOptions}
                  loading={smeData.length === 0 }
                  options={smeData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) => option.firstName+" "+option.lastName || option}
                  onChange={(event, value) => {
                    if (value) {
                      setFieldValue("operationalMeasures.mechanical.smeApproverId",value.id)
                      setAutoCompleteValue({...autoCompleteValue,smeMechnical:value.firstName+" "+value.lastName,agtId:value.id});
                    } else {
                      setFieldValue("operationalMeasures.mechanical.smeApproverId",null)
                      setAutoCompleteValue({...autoCompleteValue,smeMechnical:""});
                    }
                  }}
                  // onInputChange={(e, v) => {
                  //   setAgt({...agt,agtCom:v});
                  // }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="operationalMeasures.mechanical.smeApproverId"
                      size="small"
                      // variant="standard"
                      
                      onChange={(e)=>{setAutoCompleteValue({...autoCompleteValue,smeMechnical:e.target.value})}}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: roleAccess.technician?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                          borderRadius:  commonCss.inputBorderRadius,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: commonCss.inputBorderColor,
                        },
                      }}
                      
                    />
                  )}
                />
                
                {error["operationalMeasures.mechanical.smeApproverId"]!==undefined?
               <ErrorProps>{error["operationalMeasures.mechanical.smeApproverId"]}</ErrorProps>:null}
          </Grid2>
          
          <Grid2  size={{xs:12}}  >
          <FormGroup  >
            {mechnicalOM.map((typeLevel,i)=>(
               <FormControlLabel 
               key={i}
               sx={{"& .MuiFormControlLabel-label.Mui-disabled":{
                color:"black"
               }}}
               control={
               <Checkbox 
               disabled={roleAccess.technician}
               name={typeLevel.name} 
               checked={values.operationalMeasures.mechanical[typeLevel.name]}
               onChange={(e)=>{
                setFieldValue(`operationalMeasures.mechanical.${typeLevel.name}`,e.target.checked)
               }}
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
               
             />
               
               } label={typeLevel.label} />
            ))}
          
          </FormGroup>
           
          </Grid2>
          <Grid2 size={{xs:12}}>
          <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Ventilation, specify
                  </Typography>
                </InputLabel>
                <TextField
            fullWidth
            variant="outlined"
            size="small"
            name="operationalMeasures.mechanical.ventilationSpecified"
            value={values.operationalMeasures.mechanical.ventilationSpecified}
            onChange={handleChange}
            multiline
            rows={2}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor:roleAccess.technician?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor, 
                borderRadius:"13px"
              },
              '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: '747373', 
              },
            }}
          />
          </Grid2>
          <Grid2 size={12} >
          <InputLabel>
                <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                  See Isolation certificate
                </Typography>
              </InputLabel>
              <Typography
                variant="body2"
                fontWeight={commonCss.labelFontWeight} color={commonCss.green}
                 mt={1}
                 sx={{cursor:"pointer"}}
                 onClick={()=>{
                  handleIsolationLink("mechanical")
                 }}
              >
                1. Isolation Certificate
              </Typography>
          </Grid2>
          <Grid2 size={{xs:12}}>
          <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                   Remark
                  </Typography>
                </InputLabel>
                <TextField
            fullWidth
            variant="outlined"
            size="small"
            name="operationalMeasures.mechanical.smeApproverRemarks"
            value={values.operationalMeasures.mechanical.smeApproverRemarks}
            onChange={handleChange}
            multiline
            rows={2}
            slotProps={{
              input:{
                readOnly:roleAccess.smeMechnical||values?.operationalMeasures?.mechanical?.isSMEApproverApproved
              }
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor:(roleAccess.smeMechnical||values?.operationalMeasures?.mechanical?.isSMEApproverApproved)?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor, 
                borderRadius:"13px"
              },
              '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: '747373', 
              },
            }}
          />
          </Grid2>
          <Grid2 size={{xs:12}} sx={{display:roleAccess.smeMechnical||values?.operationalMeasures?.mechanical?.isSMEApproverApproved?"none":"default"}}>
            <Button
            variant="contained"
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw",
              height:"6vh"
            }}
            onClick={()=>{
              setConfirm({...confirm,approve:true})
              setConfirmValue([true,values?.operationalMeasures?.mechanical?.smeApproverRemarks,3])
            }}
          >
            Acknowledge
          </Button>
            
          </Grid2>
              </Grid2>
            </Box>
          </Grid2>
          <Grid2  size={4} sx={{display:isolationList.attributeValue.includes(operationMeasure.electrical)?"default":"none"}}>
            <Box sx={{width:"100%"}}>
              <Grid2 container size={12} spacing={"0.5rem"}>
              <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.green}>
          ELECTRICAL
          </Typography>
              </Grid2> 
            <Grid2 size={{xs:12}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Instrument
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="operationalMeasures.electrical.equipment"
              value={values.operationalMeasures.electrical.equipment}
              onChange={handleChange}
              slotProps={{
                input:{
                  readOnly:roleAccess.technician
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor:roleAccess.technician?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              ID/Tag Number
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="operationalMeasures.electrical.tagNO"
              value={values.operationalMeasures.electrical.tagNO}
              onChange={handleChange}
              slotProps={{
                input:{
                  readOnly:roleAccess.technician
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor:roleAccess.technician?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
             SME
              </Typography>
            </InputLabel>
            <Autocomplete
                  readOnly={roleAccess.technician}
                  name="operationalMeasures.electrical.smeApproverId"
                  inputValue={autoCompleteValue.smeElectrical}
                  filterOptions={filterOptions}
                  loading={smeData.length === 0 }
                  options={smeData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) => option.firstName+" "+option.lastName || option}
                  onChange={(event, value) => {
                    if (value) {
                      setFieldValue("operationalMeasures.electrical.smeApproverId",value.id)
                      setAutoCompleteValue({...autoCompleteValue,smeElectrical:value.firstName+" "+value.lastName,agtId:value.id});
                    } else {
                      setFieldValue("operationalMeasures.electrical.smeApproverId",null)
                      setAutoCompleteValue({...autoCompleteValue,smeElectrical:""});
                    }
                  }}
                  // onInputChange={(e, v) => {
                  //   setAgt({...agt,agtCom:v});
                  // }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="operationalMeasures.electrical.smeApproverId"
                      size="small"
                      // variant="standard"
                      
                      onChange={(e)=>{setAutoCompleteValue({...autoCompleteValue,smeElectrical:e.target.value})}}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: roleAccess.technician?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                          borderRadius:  commonCss.inputBorderRadius,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: commonCss.inputBorderColor,
                        },
                      }}
                      
                    />
                  )}
                />
                {error["operationalMeasures.electrical.smeApproverId"]!==undefined?
               <ErrorProps>{error["operationalMeasures.electrical.smeApproverId"]}</ErrorProps>:null}
          </Grid2>
          <Grid2  size={{xs:12}}  >
          <FormGroup  >
            {electricalOM.map((typeLevel,i)=>(
               <FormControlLabel 
               key={i}
               sx={{"& .MuiFormControlLabel-label.Mui-disabled":{
                color:"black"
               }}}
               control={
               <Checkbox 
               disabled={roleAccess.technician}
               name={typeLevel.name} 
               checked={values.operationalMeasures.electrical[typeLevel.name]}
               onChange={(e)=>{
                setFieldValue(`operationalMeasures.electrical.${typeLevel.name}`,e.target.checked)
               }}
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
              
             />
               
               } label={typeLevel.label} />
            ))}
          
          </FormGroup>
           
          </Grid2>
          
          <Grid2 size={12} >
          <InputLabel>
                <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                  See Isolation certificate
                </Typography>
              </InputLabel>
              <Typography
                variant="body2"
                fontWeight={commonCss.labelFontWeight} color={commonCss.green}
                 mt={1}
                 sx={{cursor:"pointer"}}
                 onClick={()=>{
                  handleIsolationLink("electrical")
                 }}
              >
                1. Isolation Certificate
              </Typography>
          </Grid2>
          <Grid2 size={{xs:12}}>
          <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                   Remark
                  </Typography>
                </InputLabel>
                <TextField
            fullWidth
            variant="outlined"
            size="small"
            name="operationalMeasures.electrical.smeApproverRemarks"
            value={values.operationalMeasures.electrical.smeApproverRemarks}
            onChange={handleChange}
            multiline
            rows={2}
            slotProps={{
              input:{
                readOnly:roleAccess.smeElectrical||values?.operationalMeasures?.electrical?.isSMEApproverApproved
              }
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor:(roleAccess.smeElectrical||values?.operationalMeasures?.electrical?.isSMEApproverApproved)?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor, 
                borderRadius:"13px"
              },
              '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: '747373', 
              },
            }}
          />
          </Grid2>
          <Grid2 size={{xs:12}} sx={{display:roleAccess.smeElectrical||values?.operationalMeasures?.electrical?.isSMEApproverApproved?"none":"default"}}>
            <Button
            variant="contained"
           
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw",
              height:"6vh"
            }}
            onClick={()=>{
              setConfirm({...confirm,approve:true})
              setConfirmValue([true,values.operationalMeasures.electrical.smeApproverRemarks,1])
            }}
          >
            Acknowledge
          </Button>
            
          </Grid2>
              </Grid2>
            </Box>
          </Grid2>
          <Grid2  size={4}sx={{display:isolationList.attributeValue.includes(operationMeasure.instrumentation)?"default":"none"}}>
            <Box sx={{width:"100%"}}>
              <Grid2 container size={12} spacing={"0.5rem"}>
              <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.green}>
          INSTRUMENTATION:{"<"}24
          </Typography>
              </Grid2> 
            <Grid2 size={{xs:12}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Equipment/Electrical Motor (Specify):
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name='operationalMeasures.instrumentation.equipment'
              value={values.operationalMeasures.instrumentation.equipment}
              onChange={handleChange}
              slotProps={{
                input:{
                  readOnly:roleAccess.technician
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor:roleAccess.technician?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              ID/Tag Number
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name='operationalMeasures.instrumentation.tagNO'
              value={values.operationalMeasures.instrumentation.tagNO}
              onChange={handleChange}
              slotProps={{
                input:{
                  readOnly:roleAccess.technician
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor:roleAccess.technician?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
             SME
              </Typography>
            </InputLabel>
            <Autocomplete
                  readOnly={roleAccess.technician}
                  name="operationalMeasures.instrumentation.smeApproverId"
                  inputValue={autoCompleteValue.smeInstrumentation}
                  filterOptions={filterOptions}
                  loading={smeData.length === 0 }
                  options={smeData}
                  PaperComponent={CustomPaper}
                  getOptionLabel={(option) => option.firstName+" "+option.lastName || option}
                  onChange={(event, value) => {
                    if (value) {
                      setFieldValue("operationalMeasures.instrumentation.smeApproverId",value.id)
                      setAutoCompleteValue({...autoCompleteValue,smeInstrumentation:value.firstName+" "+value.lastName,agtId:value.id});
                    } else {
                      setFieldValue("operationalMeasures.instrumentation.smeApproverId",null)
                      setAutoCompleteValue({...autoCompleteValue,smeInstrumentation:""});
                    }
                  }}
                  // onInputChange={(e, v) => {
                  //   setAgt({...agt,agtCom:v});
                  // }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="operationalMeasures.instrumentation.smeApproverId"
                      size="small"
                      // variant="standard"
                      
                      onChange={(e)=>{setAutoCompleteValue({...autoCompleteValue,smeInstrumentation:e.target.value})}}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: roleAccess.technician?commonCss.readOnlyBGColor:commonCss.inputBackgroundColor,
                          borderRadius:  commonCss.inputBorderRadius,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: commonCss.inputBorderColor,
                        },
                      }}
                      
                    />
                  )}
                />
                {error["operationalMeasures.instrumentation.smeApproverId"]!==undefined?
               <ErrorProps>{error["operationalMeasures.instrumentation.smeApproverId"]}</ErrorProps>:null}
          </Grid2>
          <Grid2  size={{xs:12}}  >
          <FormGroup  >
            {instrumentationOM.map((typeLevel,i)=>(
               <FormControlLabel 
               key={i}
               sx={{"& .MuiFormControlLabel-label.Mui-disabled":{
                color:"black"
               }}}
               control={
               <Checkbox 
               disabled={roleAccess.technician}
               name={typeLevel.name} 
               checked={values.operationalMeasures.instrumentation[typeLevel.name]}
               onChange={(e)=>{
                setFieldValue(`operationalMeasures.instrumentation.${typeLevel.name}`,e.target.checked)
               }}
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
              
             />
               
               } label={typeLevel.label} />
            ))}
          
          </FormGroup>
           
          </Grid2>
          
          <Grid2 size={12} >
          <InputLabel>
                <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                  See Isolation certificate
                </Typography>
              </InputLabel>
              <Typography
                variant="body2"
                fontWeight={commonCss.labelFontWeight} color={commonCss.green}
                 mt={1}
                 sx={{cursor:"pointer"}}
                 onClick={()=>{
                  handleIsolationLink("instrumentation")
                 }}
              >
                1. Isolation Certificate
              </Typography>
          </Grid2>
          <Grid2 size={{xs:12}}>
          <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                   Remark
                  </Typography>
                </InputLabel>
                <TextField
                
            fullWidth
            variant="outlined"
            size="small"
            name="operationalMeasures.instrumentation.smeApproverRemarks"
            value={values.operationalMeasures.instrumentation.smeApproverRemarks}
            onChange={handleChange}
            multiline
            rows={2}
            slotProps={{
              input:{
                readOnly:roleAccess.smeInstrumentation||values?.operationalMeasures?.instrumentation?.isSMEApproverApproved
              }
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor:(roleAccess.smeInstrumentation||values?.operationalMeasures?.instrumentation?.isSMEApproverApproved)?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor, 
                borderRadius:"13px"
              },
              '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: '747373', 
              },
            }}
          />
          </Grid2>
          <Grid2 size={{xs:12}} sx={{display:roleAccess.smeInstrumentation||values?.operationalMeasures?.instrumentation?.isSMEApproverApproved?"none":"default"}}>
            <Button
            variant="contained"
           
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw",
              height:"6vh"
            }}
            onClick={()=>{
              setConfirm({...confirm,approve:true})
              setConfirmValue([true,values?.operationalMeasures?.instrumentation?.smeApproverRemarks,2])
            }}
          >
            Acknowledge
          </Button>
            
          </Grid2>
              </Grid2>
            </Box>
          </Grid2>
        </Grid2>
        <Grid2 container size={12} spacing={"0.5rem"} sx={{display:roleAccess.displayTech?"default":"none"}}>
          <Grid2 container size={12} alignItems={"center"} spacing={"0.5rem"}>
        <Grid2 size={{xs:12,md:4}}>
          <FormControlLabel
               sx={{
                "& .MuiFormControlLabel-label.Mui-disabled":{
                  
                  color:"black"
                  
                 }
               }}
               control={
               <Checkbox 
               checked={values.isEngineerAdviseRequied}
               name={values.isEngineerAdviseRequied} 
               disabled={roleAccess.technician||(data.enableConfinedSpaceCertificate||data.enableHotWorkCertificate||data.enableExcavationCertificate||data.enableIsolationCertificate||data.enableRadiationCertificate||data.enableSystemOverridingCertificate)}
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
               onChange={(e)=>{
                
               setFieldValue("isEngineerAdviseRequied",e.target.checked)
               }}
             />
               
               } label={"Engineering Advice Required"} />
          </Grid2>
          <Grid2 container size={{xs:12,md:8}} sx={{display:values.isEngineerAdviseRequied?"default":"none"}} alignItems={"center"} spacing={"1rem"}>
           <Grid2 size={{xs:12,md:6}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
                Engineer
                 <Typography
                                  component="span"
                                  color="error"
                                  fontWeight="bold"
                                  sx={{ ml: 0.5 }}
                                  >
                                  *
                                  </Typography>
              </Typography>
            </InputLabel>
            <Select
                size="small"
                defaultValue=""
                displayEmpty
                name="engineerId"
                value={values.engineerId}
                onChange={handleChange}
                readOnly={roleAccess.technician}
                sx={{
                  
                  backgroundColor: roleAccess.technician?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius,
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                }}
                fullWidth
              >
                {engineerData.map((engData,i)=>(
                  <MenuItem key={i} value={engData.id} disabled={data.permitHolderId===engData.id}>{engData.firstName+" "+engData.lastName}</MenuItem>
                ))}
                
              </Select>
              {error.engineerId!==undefined?
               <ErrorProps>{error.engineerId}</ErrorProps>:null}
          </Grid2>
         
          </Grid2>
          </Grid2>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          WORKING AREA
          </Typography>
          </Grid2 >
          <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor}>
            Permit Supervisor is aware of the safety instructions as in the:
          </Typography>
          <List>
            <ListItem>
              <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor}>
                • Terminal safety manual
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor}>
                • Permit to Work and associated certificates
              </Typography>
            </ListItem>
          </List>
        </Grid2>
        <Grid2 container size={12} mb={2} mt={-1} sx={{display:reAssign.technician?'none':"default"}} alignItems={"flex-end"}>
        <Grid2 size={{xs:12,md:4}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Name
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={holderName.technicianName}
              slotProps={{
                input: {
                  readOnly: true, // Set readOnly to true
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: roleAccess.technician?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
            {/* <Select
                size="small"
                defaultValue=""
                displayEmpty
                name="technicianId"
                value={values.workingArea.pspSupervisorId}
                inputProps={{ "aria-label": "Technician" }}
                sx={{
                  borderRadius: "13px",
                  backgroundColor: "white",
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '747373',
                  },
                }}
                fullWidth
                onChange={handleChange}
              >
                {technician.map((userTech,i)=>(
                  <MenuItem key={i} value={userTech.id} onClick={()=>{setHolderName({...holderName,supEmail:userTech.emailId})}}>{userTech.firstName+" "+userTech.lastName}</MenuItem>
                ))}
              </Select> */}
          </Grid2>
         
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Date and Time
              </Typography>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem>
                <DateTimePicker
                  ampm={false}
                  format="DD/MM/YYYY HH:mm"
                  value={values.isTechnicianApproved?dayjs(values.technicianApprovedAt):null}
                  readOnly={roleAccess.technician}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "outlined",
                    },
                   
                  
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor:roleAccess.technician?commonCss.readOnlyBGColor: commonCss.inputBackgroundColor, 
                      borderRadius:commonCss.inputBorderRadius
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                     borderColor: commonCss.inputBorderColor, 
                    },
                  }}
                  fullWidth
                />
              </DemoItem>
            </LocalizationProvider>
          </Grid2> 
          
          
        </Grid2>
       
        </Grid2>
        
        <Grid2 container size={12} justifyContent={'flex-end'} sx={{display:(!(loginRoleId.includes(roleInfo.contractStaff)&&(data.assignedUsers!==undefined?data.assignedUsers.includes(login.userId):false))&&!(loginRoleId.includes(roleInfo.technican)&&data.technicianId===login.userId))||reAssign.technician || data.isCancel===true|| data.isLeadPermitIssuerApproved?"none":"default"}}>
        
            <Box sx={{display:"flex",alignItems:"center",gap:"1rem"}}>
           
            <Button
            size="small" 
            variant='contained'
            onClick={()=>{onSave(values,true)}}
            sx={{
                backgroundColor:commonCss.green,
                borderRadius: commonCss.buttonBorderRadius ,
                textTransform: "none", 
                padding: "0.6rem 1rem", 
                color:commonCss.buttonTextColor,
                width:"10vw",
                display:roleAccess.saveApprove?"none":"default"
            }}
          >
            Save
            
          </Button>
          <Button
            size="small" 
            variant='contained'
            type="submit"
            sx={{
                backgroundColor:commonCss.green,
                borderRadius: commonCss.buttonBorderRadius ,
                textTransform: "none", 
                padding: "0.6rem 1rem", 
                color:commonCss.buttonTextColor,
                width:"10vw",
                display:roleAccess.saveApprove?"none":"default"
            }}
          >
            {/* {loginRoleId.includes(roleInfo.contractStaff)||loginRoleId.includes(roleInfo.technican)?"Submit":"Acknowledge"} */}
            {/* Acknowledge */}
            Submit
          </Button>
          <Button
            size="small" 
            variant='contained'
            onClick={()=>{setRemarkButton({...remarkButton,cancel:true})}}
            sx={{
                backgroundColor:commonCss.red,
                borderRadius: commonCss.buttonBorderRadius ,
                textTransform: "none", 
                padding: "0.6rem 1rem", 
                color:commonCss.buttonTextColor,
                width:"10vw",
                display:roleAccess.cancelTech?"default":"none"
            }}
          >
            Cancel
            
          </Button>
          {/* <Button
            size="small" 
            variant='contained'
            onClick={()=>{setRemarkButton({...remarkButton,reject:true})}}
            sx={{
                backgroundColor:commonCss.red,
                borderRadius: commonCss.buttonBorderRadius ,
                textTransform: "none", 
                padding: "0.6rem 1rem", 
                color:commonCss.buttonTextColor,
                width:"10vw",
                display:roleAccess.rejectButton?"none":"default"
            }}
          >
            Reject
            
          </Button> */}
          </Box>
        </Grid2>

          
          </Grid2>
          
       <Dialog
        open={certificateOpen}
        onClose={()=>{setCertificateOpen(false)}}
        PaperProps={{
          sx: {
            maxWidth: {xs:"100vw",md:"50vw"},
            width:{xs:"100vw",md:"55vw"}
          },
        }}
        children={
         <CertificateSelect handleCloseClick={()=>{
          setCertificateOpen(false)
          getData()
         
          // updateCertificate()
        }} data={values} deleteBol={deleteBoolean} riskData={riskLevelBol}  />
        }
        />
         
        <Dialog
        // maxWidth={"lg"}
  open={certificateListOpen.radiation}
  onClose={()=>{setCertificateListOpen({...certificateListOpen,radiation:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
> 
  <DialogContent >
  <RadiationCertificate 
  certificateDetail={certificateInfo} 
  approvedData={values}
  handleCloseClick={()=>{
    setCertificateListOpen({...certificateListOpen,radiation:false})
    handleCertificate({
      name:"enableRadiationCertificate",
      label:"Radiation",
      api:"radiation" 
  },)
  }}
  />
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={certificateListOpen.isolation}
  onClose={()=>{setCertificateListOpen({...certificateListOpen,isolation:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <IsolationCertificate approvedData={values} 
  certificateDetail={certificateInfo} 
  handleCloseClick={()=>{
    setCertificateListOpen({...certificateListOpen,isolation:false})
    handleCertificate({
      name:"enableIsolationCertificate",
      label:"Isolation",
      api:"isolation"
  })
    }}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={certificateListOpen.lifting}
  onClose={()=>{setCertificateListOpen({...certificateListOpen,lifting:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <LiftingAndHoisting approvedData={values} certificateDetail={certificateInfo}  
  handleCloseClick={()=>{
    setCertificateListOpen({...certificateListOpen,lifting:false})
    handleCertificate({
      name:"enableLiftingAndHoistingCertificate",
      label:"Lifting & Hoisting",
      api:"liftingAndHoisting"
  })
    }}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={certificateListOpen.excavation}
  onClose={()=>{setCertificateListOpen({...certificateListOpen,excavation:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Excavation location={values.locationText} 
  certificateDetail={certificateInfo} 
  approvedData={values}
  handleCloseClick={()=>{
    setCertificateListOpen({...certificateListOpen,excavation:false})
    handleCertificate({
      name:"enableExcavationCertificate",
      label:"Excavation",
      api:"excavation"
  })
    }}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={certificateListOpen.confinedSpace}
  onClose={()=>{setCertificateListOpen({...certificateListOpen,confinedSpace:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <ConfinedSpaceCertificate approvedData={values} certificateDetail={certificateInfo} 
  handleCloseClick={()=>{
    setCertificateListOpen({...certificateListOpen,confinedSpace:false})
    handleCertificate({
      name:"enableConfinedSpaceCertificate",
      label:"Confined Space",
      api:"confinedSpace"
  })
    }}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={certificateListOpen.hotWork}
  onClose={()=>{setCertificateListOpen({...certificateListOpen,hotWork:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <HotWork certificateDetail={certificateInfo} approvedData={values} 
  handleCloseClick={()=>{
    setCertificateListOpen({...certificateListOpen,hotWork:false})
    handleCertificate({
      name:"enableHotWorkCertificate",
      label:"Hot Work",
      api:"hotWork"
  })
    }}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={certificateListOpen.safe}
  onClose={()=>{setCertificateListOpen({...certificateListOpen,safe:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <SafeOverride certificateDetail={certificateInfo} 
  approvedData={values}
  handleCloseClick={()=>{
    setCertificateListOpen({...certificateListOpen,safe:false})
    handleCertificate({
      name:"enableSystemOverridingCertificate",
      label:"System Overriding",
      api:"systemOverriding"
  })
    }}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={riskLevelBol.isMethodStatementRequired}
  onClose={()=>{setRiskLevelBol({...riskLevelBol,isMethodStatementRequired:false})}}
  PaperProps={{
    sx: {
      
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <MethodStatement  approvedData={values} dataInfo={{description:values.description,location:values.formDetail?.locationText}} handleCloseClick={()=>{setRiskLevelBol({...riskLevelBol,isMethodStatementRequired:false})}}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={riskLevelBol.isRiskAssessmentRequired}
  onClose={()=>{setRiskLevelBol({...riskLevelBol,isRiskAssessmentRequired:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <RiskAssessment  approvedData={values} handleCloseClick={()=>{setRiskLevelBol({...riskLevelBol,isRiskAssessmentRequired:false})}}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={viewImageValue.bol}
  onClose={()=>{setViewImageValue({bol:false,dataValue:""})}}
  maxWidth="xl"
  fullWidth
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <ViewImage selectedFile={{data:viewImageValue.dataValue,type:"image"}}  handleCloseClick={()=>{setViewImageValue({bol:false,dataValue:""})}}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={remarkButton.reject}
  onClose={()=>{setRemarkButton({...remarkButton,reject:false})}}
  maxWidth="md"
  fullWidth
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Remark handleSubmitRemark={(e)=>{
    saveApproval(false,e)
  }}  handleCloseClick={()=>{setRemarkButton({...remarkButton,reject:false})}}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={remarkButton.cancel}
  onClose={()=>{setRemarkButton({...remarkButton,cancel:false})}}
  maxWidth="md"
  fullWidth
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Remark handleSubmitRemark={(e)=>{
    OnCancel(e)
  }}  handleCloseClick={()=>{setRemarkButton({...remarkButton,cancel:false})}}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={confirm.acknowledge}
  onClose={()=>{setConfirm({...confirm,acknowledge:false})}}
maxWidth={"md"}
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Confirmation 
  onSubmit={()=>{onAcknowledge(...confirmValue)}} 
  name={'submit this permit'}
  handleCloseClick={()=>{
    setConfirm({...confirm,acknowledge:false})
    setConfirmValue([])
    }}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={confirm.approve}
  onClose={()=>{setConfirm({...confirm,approve:false})}}
maxWidth={"md"}
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Confirmation 
  onSubmit={()=>{saveApproval(...confirmValue)}} 
  name={'Acknowledge'}
  handleCloseClick={()=>{
    setConfirm({...confirm,approve:false})
    setConfirmValue([])
    }}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={confirm.certificateDelete}
  onClose={()=>{setConfirm({...confirm,certificateDelete:false})}}
maxWidth={"md"}
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Confirmation 
  onSubmit={()=>{certificateDelete(...confirmValue)}} 
  name={'delete this certificate'}
  handleCloseClick={()=>{
    setConfirm({...confirm,certificateDelete:false})
    setConfirmValue([])
    }}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={confirm.reAssign}
  onClose={()=>{setConfirm({...confirm,reAssign:false})}}
maxWidth={"md"}
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Confirmation 
  onSubmit={()=>{handleReassign()}} 
  name={'reassign'}
  handleCloseClick={()=>{
    setConfirm({...confirm,reAssign:false})
    setConfirmValue([])
    }}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={confirm.staffDelete}
  onClose={()=>{setConfirm({...confirm,staffDelete:false})}}
maxWidth={"md"}
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Confirmation 
  onSubmit={()=>{handleDelete(...confirmValue)}} 
  name={'delete'}
  handleCloseClick={()=>{
    setConfirm({...confirm,staffDelete:false})
    setConfirmValue([])
    }}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={confirm.clone}
  onClose={()=>{setConfirm({...confirm,clone:false})}}
maxWidth={"md"}
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Confirmation 
  onSubmit={()=>{handleClone(...confirmValue)}} 
  name={'clone'}
  handleCloseClick={()=>{
    setConfirm({...confirm,clone:false})
    setConfirmValue([])
    }}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={confirm.attachmentDelete}
  onClose={()=>{setConfirm({...confirm,attachmentDelete:false})}}
maxWidth={"md"}
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Confirmation 
  onSubmit={()=>{handleDeleteDocument(...confirmValue)}} 
  name={'delete'}
  handleCloseClick={()=>{
    setConfirm({...confirm,attachmentDelete:false})
    setConfirmValue([])
    }}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={confirm.approveDoc}
  onClose={()=>{setConfirm({...confirm,approveDoc:false})}}
maxWidth={"md"}
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Confirmation 
  onSubmit={()=>{handleSaveDocument(...confirmValue)}} 
  name={'approve the document'}
  handleCloseClick={()=>{
    setConfirm({...confirm,approveDoc:false})
    setConfirmValue([])
    }}/>
  </DialogContent>
       </Dialog>
      </Form>
        )}
        
          
         
       </Formik>
  } 
   </>
  );
};

export default PTWForm1;

import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { useSelector } from "react-redux";
import {
  CheckCircle,
  DeleteOutline,
  EditOffOutlined,
  EditOutlined,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";

import CompanyModal from "./CompanyModal";
import { api } from "../../API/config";
import { commonCss } from "../../../StyleConfig/CommonCss";
import TableContainerComp from "../../../Component/TableContainer";
import { axiosPrivate } from "../../../axios/axios";
import Confirmation from "../../../Component/Confirmation";

const Company = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [create, setCreate] = useState(false);
  const [mode, setMode] = useState("");
  const [editData, setEditData] = useState({});
  const [filterValue, setFilterValue] = useState("enableData");
  const [backUpData, setBackUpData] = useState([]);
  const [confirm,setConfirm]=useState({
    delete:false
      })
    const [confirmValue,setConfirmValue]=useState([])

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setIsLoading(true);
    const getLink=api.company.getAll
   await axiosPrivate.get(`${getLink}`).then(res=>{
    console.log(res.data.data)
    setData(res.data.data)
    setIsLoading(false)
   }).catch(e=>{
    console.log(e)
    setIsLoading(false)
   })
  };

  const handleButtonNew = () => {
    setMode("Create");
    setCreate(true);
  };
  const handleCreateClose = () => {
    setMode("");
    setCreate(false);
    getData();
  };

  const handleEdit = (data) => {
    setEditData(data);
    setMode("Edit");
    setCreate(true);
  };

  // const handleDelete = (id) => {
  //   const deleteLink = masterAPI["assetStatus"].delete;
  //   axiosPrivate
  //     .delete(`${deleteLink}/${id}`)
  //     .then((res) => {
  //       console.log(res.data);
  //       getData();
  //     })
  //     .catch((e) => {
  //       console.log(e, "deleteassetStatus");
  //     });
  // };

  const handleDelete = async(id) => {
        // const enableLink = api.user.deleteUndo;
        const disableLink = api.company.delete;
        // if(e.target.checked){
        //  await axiosPrivate
        //   .put(`${enableLink}/${id}`)
        //   .then((res) => {
        //     console.log(res.data);
        //     getData();
        //   })
        //   .catch((e) => {
        //     console.log(e, "enable ");
        //   });
        // }else{
         await axiosPrivate
          .delete(`${disableLink}/${id}`)
          .then((res) => {
            console.log(res.data);
            setConfirm({...confirm,delete:false})
            setConfirmValue([])
            getData();
          })
          .catch((e) => {
            setConfirm({...confirm,delete:false})
            setConfirmValue([])
            console.log(e, "disable");
          });
        // }
      };

  const columns = [
    // {
    //   accessor: "id",
    //   disableFilters: true,
    //   Cell: () => {
    //     return (
    //       <Box sx={{width:color.checkBoxWidth }}>
    //       <Checkbox
    //         // icon={<RadioButtonUncheckedOutlined />}
    //         // checkedIcon={<CheckCircle />}
    //       />
    //       </Box>
    //     );
    //   },
    // },
    {
      Header: "Name",
      accessor: "name",
      // disableFilters: true,
    },

    {
      Header: "Actions",
      Cell: ({ cell }) => {
        // console.log(cell)
        return (
          <Box sx={{ whiteSpace: "nowrap" }}>
            <IconButton>
              <EditOutlined
                onClick={() => {
                  handleEdit(cell.row.original);
                }}
                sx={{
                  // fontSize: color.masterEditDelButton,
                  color: commonCss.red,
                }}
              />
            </IconButton>
            <IconButton >
                                      <DeleteOutline
                                        onClick={() => {
                                          setConfirm({...confirm,delete:true})
            setConfirmValue([cell.row.original.id])
                                        }}
                                        sx={{
                                          color:commonCss.red,
                                        }}
                                      />
                                    </IconButton>
            {/* <IconButton disabled={!actionsAllow.delete}>
              <DeleteOutline
                onClick={() => {
                  handleDelete(cell.row.original.id);
                }}
                sx={{
                  fontSize: color.masterEditDelButton,
                  color:actionsAllow.delete? color.masterEditDelButtonColor:"default",
                }}
              />
            </IconButton> */}
            {/* <Switch
            disabled={!actionsAllow.delete}
            checked={cell.row.original.isActive}
            onChange={(e)=>{handleDelete(e,cell.row.original.id)}}
           
             /> */}
          </Box>
        );
      },
    },
  ];

  const handleChangeFilter = (e) => {
    if (e === "showAll") {
      setData(backUpData);
    } else {
      const filterDataResult = backUpData.filter((fil) => fil.isActive);

      setData(filterDataResult);
    }
    setFilterValue(e);
  };

  return (
    <>
      <Box>
        {/* <Typography
          sx={{
            color: commonCss.titleColor,
             fontSize: commonCss.titleFontSize,
             fontWeight: commonCss.titleFontWeight,
             mb:1
          }}
        >
          {api.terminal.title}
        </Typography> */}
      </Box>

      <TableContainerComp
        columns={columns}
        data={data}
        buttonNew={handleButtonNew}
        loading={isLoading}
        title={api.company.title}
        // buttonDisable={!actionsAllow.create}
        // filterValue={filterValue}
        // handleChange={handleChangeFilter}
        // filterShow={true}
      />

      <Dialog
        // maxWidth={"lg"}
        open={create}
        onClose={handleCreateClose}
        PaperProps={{
          sx: {
            maxWidth: { xs: "100vw", md: "50vw" },
            width: { xs: "100vw", md: "50vw" },
            background: commonCss.shadeLinearGradient,
            borderRadius: "10px",
          },
        }}
      >
        <DialogContent>
          <CompanyModal
            handleCloseClick={handleCreateClose}
            mode={mode}
            editData={editData}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        // maxWidth={"lg"}
  open={confirm.delete}
  onClose={()=>{setConfirm({...confirm,delete:false})}}
maxWidth={"md"}
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Confirmation 
  onSubmit={()=>{handleDelete([...confirmValue])}} 
  name={'delete'}
  handleCloseClick={()=>{
    setConfirm({...confirm,delete:false})
    setConfirmValue([])
    }}/>
  </DialogContent>
       </Dialog>

      
    </>
  );
};

export default Company;

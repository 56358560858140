import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  drawerOpen: {
    bol: false,
    open: false,
  },
 permitStatus:null,
 isPermitShift:false
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    drawer: (state, actions) => {
      state.drawerOpen = actions.payload;
    },
    permitStatus:(state,actions)=>{
      state.permitStatus=actions.payload.permitStatus
      state.isPermitShift=actions.payload.isPermitShift
    }
    
   
  },
});

export const {
  drawer,
  permitStatus
} = dataSlice.actions;

export const dataReducers = dataSlice.reducer;

import React from 'react'
import NormalTable from './NormalTable'

const RemarkTableView = ({data}) => {
  console.log(data)
    const column=[
        {
            Header:"Name",
            accessor:"name"
        },
        {
            Header:"Role",
            accessor:"role"
        },
        {
            Header:"Remark",
            accessor:"remark"
        },
    ]
  return (
    <>
    {data!==null&&data!==undefined?
    <NormalTable data={
        [{
            role:data.split("@$/")[1],
            name:data.split("@$/")[0],
            remark:data.split("@$/")[2],
        }]
    }
    columns={column}
    />:null}
    </>
  )
}

export default RemarkTableView
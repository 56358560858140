import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Select,
  Divider,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Button,
  Paper,
  Radio,
  createFilterOptions,
  Autocomplete,
  Dialog,
  DialogContent,
  Collapse,
} from "@mui/material";
import { Grid2 } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { commonCss } from "../../../StyleConfig/CommonCss";
import TableSample from "../../../Component/TableSample";
import { ArrowBack,   KeyboardArrowDown,
  KeyboardArrowUp, } from "@mui/icons-material";
import moment from "moment";
import dayjs from "dayjs";
import NormalTableWithRadius from "../../../Component/NormalTableWithRadius";
import { Form, Formik, ErrorMessage } from "formik";
import Loading from "../../../Component/Loading";
import { useSelector } from "react-redux";
import { api, companyInfo, operationMeasure, roleInfo } from "../../API/config";
import { axiosPrivate } from "../../../axios/axios";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import ErrorProps from "../../../Component/Errorprops";
import Remark from "../../../Component/Remark";
import Confirmation from "../../../Component/Confirmation";
import RemarkTableView from "../../../Component/RemarkTableView";

const IsolationCertificate = ({
  certificateDetail,
  handleCloseClick,
  approvedData,
}) => {
  const login = useSelector((state) => state.auth.loginInfo);
  const loginRoleId = String(login.roleIds).split(",");
  const [searchParams, setSearchParams] = useSearchParams();

  const [typeMeasure, setTypeMeasure] = useState([]);
  const [checked, setChecked] = useState({
    electrical: false,
    mechanical: false,
  });
  const [certificateInfo, setCertificateInfo] = useState({});
  const [approveInfo, setApproveInfo] = useState({
    engineerName: "",
    lpiName: "",
    certificateClosedName: "",
    lpiCertClosedName:""
  });

  const [roleAccess, setRoleAccess] = useState({
    contractStaff: false,
    engineer: false,
    lpi: false,
    lpiCertClose: false,
    lpiCertCloseReason:false,
    certClose: false,
    io: false,
    iv: false,
    reconnectionIV: false,
    reconnectionIO: false,
    technician: false,
    piContract: false,
    assignIOIV:false,
    submitButton:false
  });
  const [loading, setLoading] = useState(false);

  const [isolationData, setIsolationData] = useState([
    {
      id: 1,
      rowName: "Isolation Owner",
      fieldName:"isolationOwnerId",
      Name: "",
      action: "",
      autoCom: "io",
    },
    {
      id: 2,
      rowName: "Isolation Verifier",
      fieldName:"isolationVerifierId",
      Name: "",
      action: "",
      autoCom: "iv",
    },
  ]);
  const [reconnectionData, setReconnectionData] = useState([
    {
      id: 1,
      rowName: "Isolation Owner",
      Name: "",
      action: "",
      autoCom: "reconnectionIO",
    },
    {
      id: 2,
      rowName: "Isolation Verifier",
      Name: "",
      action: "",
      autoCom: "reconnectionIV",
    },
  ]);

  const [ioData, setIOData] = useState([]);
  const [ivData, setIVData] = useState([]);
  const [autoCompleteValue, setAutoCompleteValue] = useState({
    iv: "",
    io: "",
    reconnectionIV: "",
    reconnectionIO: "",
  });
  const [closedRemark, setClosedRemark] = useState({
    PH: "",
    LPI: "",
  });
  const [deisolationBol, setDeisolationBol] = useState(false);
  const [remarkButton, setRemarkButton] = useState({
    reject: false,
    cancel: false,
    remarkOpen: false,
  });
  const [getMeasure,setGetMeasure]=useState([])
  const [error,setError]=useState({})
      const [confirm,setConfirm]=useState({
          submit:false,
          close:false,
          deIsolationFalse:false,
          deIsolationTrue:false,
          ioivApprove:false
        })
      const [confirmValue,setConfirmValue]=useState([])

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    
    const getDataByForm=api.isolation.getDataByFormId
    axiosPrivate.get(`${getDataByForm}?formId=${approvedData.id}`).then(resForm=>{
      const getByKey = api.attributeValue.getByKeyName;
       axiosPrivate
        .get(`${getByKey}?keyName=SpecifyEnergyIsolated`)
        .then((res) => {
          const resData=res.data.data.filter(fil=>{
            let filterCheck=resForm.data.data.filter(fill=>fill.specifyEnergyIsolatedId===fil.id)
            if(filterCheck.length===0){
              return  false
            }else{
             return true
            }
          })
          

          setGetMeasure(resData.map((ress)=>{return ress.id}))
          setTypeMeasure(res.data.data);
        })
        .catch((e) => {
          console.log(e);
        });
    })
    const getById = api.isolation.getById;
    await axiosPrivate
      .get(`${getById}/${certificateDetail.id}`)
      .then((res) => {
        console.log(res);
        setCertificateInfo(res.data.data);
        setClosedRemark({
          PH: res.data.data.phCertificateClosedReason,
          LPI: res.data.data.lpiCertificateClosedReason,
        });
        setChecked({
          electrical: res.data.data.electricalDetails !== null ? true : false,
          mechanical: res.data.data.mechanicalDetails !== null ? true : null,
        });
        setAutoCompleteValue({
          io:
            res.data.data.isolationOwnerId !== null
              ? res.data.data?.isolationOwnerUserDetail?.firstName +
                " " +
                res.data.data?.isolationOwnerUserDetail?.lastName
              : "",
          iv:
            res.data.data.isolationVerifierId !== null
              ? res.data.data?.isolationVerifierUserDetail?.firstName +
                " " +
                res.data.data?.isolationVerifierUserDetail?.lastName
              : "",
          reconnectionIO:
            res.data.data.reconnectionPermitIssuerId !== null
              ? res.data.data?.reconnectionPermitIssuerUserDetail?.firstName +
                " " +
                res.data.data?.reconnectionPermitIssuerUserDetail?.lastName
              : "",
          reconnectionIV:
            res.data.data.reconnectionPermitVerifierId !== null
              ? res.data.data?.reconnectionPermitVerifierUserDetail?.firstName +
                " " +
                res.data.data?.reconnectionPermitVerifierUserDetail?.lastName
              : "",
              
        });
        setApproveInfo({
          engineerName: res.data.data.isEngineerApproved
            ? res.data.data?.engineerUserDetail?.firstName +
              " " +
              res.data.data?.engineerUserDetail?.lastName
            : "",
          lpiName: res.data.data.isLPIApproved
            ? res.data.data?.lpiUserDetail?.firstName +
              " " +
              res.data.data?.lpiUserDetail?.lastName
            : "",
            certificateClosedName: res.data.data.isPHCertificateClosed
            ? approvedData?.permitHolderUser?.firstName +
              " " +
              approvedData?.permitHolderUser?.lastName
            : "",
            lpiCertClosedName: res.data.data.isLPICertificateClosed
            ? res.data.data?.lpiUserDetail?.firstName +
            " " +
            res.data.data?.lpiUserDetail?.lastName
          : "",
        });
        setRoleAccess({
          submitButton:(loginRoleId.includes(roleInfo.contractStaff)&&approvedData.assignedUsers.includes(login.userId)&&res.data.data.status===8)?
                    (((loginRoleId.includes(roleInfo.contractStaff)&&approvedData.assignedUsers.includes(login.userId)) &&
                    !approvedData.isContractApproved &&
                    !approvedData.isCancel===true) ||
                  ((loginRoleId.includes(roleInfo.pi)&&approvedData.isHSSEApproved) &&
                    !approvedData.isPermitIssuerApproved &&
                    (res.data.data.status !== 8) & !approvedData.isCancel===true)||((loginRoleId.includes(roleInfo.technican)&&approvedData.technicianId===login.userId) &&
                    !approvedData.isTechnicianApproved &&
                    (res.data.data.status !== 8) & !approvedData.isCancel===true)):
                    (((loginRoleId.includes(roleInfo.contractStaff)&&approvedData.assignedUsers.includes(login.userId)) &&
                    !approvedData.isContractApproved &&
                    res.data.data.status !== 8 &&
                    !approvedData.isCancel===true) ||
                  ((loginRoleId.includes(roleInfo.pi)&&approvedData.isHSSEApproved) &&
                    !approvedData.isPermitIssuerApproved &&
                    (res.data.data.status !== 8) & !approvedData.isCancel===true)||((loginRoleId.includes(roleInfo.technican)&&approvedData.technicianId===login.userId) &&
                    !approvedData.isTechnicianApproved &&
                    (res.data.data.status !== 8) & !approvedData.isCancel===true)),
              piContract:(loginRoleId.includes(roleInfo.contractStaff)&&approvedData.assignedUsers.includes(login.userId)&&res.data.data.status===8)?
                                  (((loginRoleId.includes(roleInfo.contractStaff)&&approvedData.assignedUsers.includes(login.userId)) &&
                                  !approvedData.isContractApproved &&
                                  !approvedData.isCancel===true) ||
                                ((loginRoleId.includes(roleInfo.pi)&&approvedData.isHSSEApproved) &&
                                  !approvedData.isPermitIssuerApproved &&
                                  (res.data.data.status !== 8) & !approvedData.isCancel===true)):
                                  (((loginRoleId.includes(roleInfo.contractStaff)&&approvedData.assignedUsers.includes(login.userId)) &&
                                  !approvedData.isContractApproved &&
                                  res.data.data.status !== 8 &&
                                  !approvedData.isCancel===true) ||
                                ((loginRoleId.includes(roleInfo.pi)&&approvedData.isHSSEApproved) &&
                                  !approvedData.isPermitIssuerApproved &&
                                  (res.data.data.status !== 8) & !approvedData.isCancel===true)),
                        contractStaff:
                          !(loginRoleId.includes(roleInfo.contractStaff)&&approvedData.assignedUsers.includes(login.userId))||
                          approvedData.isContractApproved ||
                          approvedData.isCancel===true,
                         engineer:
                                    !(loginRoleId.includes(roleInfo.engineer)&&approvedData.isTechnicianApproved&&approvedData.engineerId===login.userId) ||
                                    res.data.data.isEngineerApproved ||
                                    res.data.data.status === 8 ||
                                    res.data.data.isCancel ||
                                    approvedData.isCancel===true,
                                    lpi:
                                                !(loginRoleId.includes(roleInfo.lpi)&& approvedData.isPermitIssuerApproved) ||
                                                res.data.data.isLPIApproved ||
                                                res.data.data.status === 8 ||
                                                res.data.data.isCancel ||
                                                approvedData.isCancel===true,
                        // lpiCertClose:
                        //           !loginRoleId.includes(roleInfo.lpi)||
                        //           res.data.data.isLPICertificateClosed||
                        //             !res.data.data.isPHCertificateClosed ||
                        //             res.data.data.status === 8 ||
                        //             res.data.data.isCancel ||
                        //             approvedData.isCancel===true,
                                    
                        //             lpiCertCloseReason:res.data.data.isLPICertificateClosed?true:(!loginRoleId.includes(roleInfo.lpi)||
                        //             res.data.data.isLPICertificateClosed||
                        //               !res.data.data.isPHCertificateClosed ||
                        //               res.data.data.status === 8 ||
                        //               res.data.data.isCancel ||
                        //               approvedData.isCancel===true),
                        // certClose:
                        //   login.userId !== approvedData.permitHolderId ||
                        //   res.data.data.isPHCertificateClosed ||
                        //   !res.data.data.isLPIApproved ||
                        //   res.data.data.status === 8 ||
                        //   approvedData.isCancel===true,
          lpiCertClose:
                    !loginRoleId.includes(roleInfo.lpi)||
                    res.data.data.isLPICertificateClosed||
                      !res.data.data.isPHCertificateClosed ||
                      res.data.data.status === 8 ||
                      res.data.data.isCancel ||
                      approvedData.isCancel===true,
                      
                      lpiCertCloseReason:res.data.data.isLPICertificateClosed?true:(!loginRoleId.includes(roleInfo.lpi)||
                      res.data.data.isLPICertificateClosed||
                    //  (res.data.data.isDeIsolation && !res.data.data?.reconnectionIsIsolationVerifierClosed)||
                        !res.data.data.isPHCertificateClosed ||
                        res.data.data.status === 8 ||
                        res.data.data.isCancel ||
                        approvedData.isCancel===true),
          certClose:
            login.userId !== approvedData.permitHolderId ||
            res.data.data.isPHCertificateClosed ||
            !res.data.data.isLPIApproved ||
            res.data.data.status === 8 ||
            approvedData.isCancel===true,
          io:
            !(loginRoleId.includes(roleInfo.isolationOwner)&&approvedData.isLeadPermitIssuerApproved) ||
            login.userId !== res.data.data.isolationOwnerId ||
            res.data.data.isIsolationOwnerApproved ||
            !approvedData.isContractApproved ||
            res.data.data.status === 8 ||
            approvedData.isCancel===true,
          iv:
            !(loginRoleId.includes(roleInfo.isolationVerifier)&&approvedData.isLeadPermitIssuerApproved) ||
            login.userId !== res.data.data.isolationVerifierId ||
            res.data.data.isIsolationVerifierApproved ||
            !res.data.data.isIsolationOwnerApproved ||
            res.data.data.status === 8 ||
            approvedData.isCancel===true,
          reconnectionIO:
            !loginRoleId.includes(roleInfo.isolationOwner) ||
            login.userId !== res.data.data.reconnectionPermitIssuerId ||
            res.data.data?.reconnectionIsIsolationOwnerClosed ||
            !res.data.data.isPHCertificateClosed ||
            !res.data.data.isDeIsolation ||
            res.data.data.status === 8 ||
            approvedData.isCancel===true,
          reconnectionIV:
            !loginRoleId.includes(roleInfo.isolationVerifier) ||
            login.userId !== res.data.data.reconnectionPermitVerifierId ||
            res.data.data?.reconnectionIsIsolationVerifierClosed ||
            !res.data.data.reconnectionIsIsolationOwnerClosed ||
            res.data.data.status === 8 ||
            approvedData.isCancel===true,
          technician:
            !(loginRoleId.includes(roleInfo.technican)&& approvedData.technicianId===login.userId) ||
            !res.data.data.isPHCertificateClosed ||
            res.data.data.isDeIsolation ||
            res.data.data.status === 8 ||
            approvedData.isCancel===true,
            assignIOIV:
            !(loginRoleId.includes(roleInfo.technican)&& approvedData.technicianId===login.userId&&approvedData.isContractApproved) ||
            approvedData.isTechnicianApproved||
            res.data.data.status === 8 ||
            approvedData.isCancel===true,
        });
        setRemarkButton({
          ...remarkButton,
          remarkOpen:
            (res.data.data.isCancel &&
              res.data.data.cancelRemarks !== null &&
              res.data.data.cancelRemarks !== "") ||
            (!res.data.data.isLPIApproved &&
              res.data.data.rejectedRemarks !== null &&
              res.data.data.rejectedRemarks !== "")
              ? true
              : false,
        });
        console.log(!loginRoleId.includes(roleInfo.lpi),
        res.data.data.isLPICertificateClosed,
        !res.data.data?.reconnectionIsIsolationVerifierClosed,
          !res.data.data.isPHCertificateClosed ,
          res.data.data.status === 8 ,
          res.data.data.isCancel ,
          approvedData.isCancel===true)
        setIsolationData([
          {
            id: 1,
            rowName: "Isolation Owner",
            fieldName:"isolationOwnerId",
            Name:
              res.data.data.isolationOwnerId !== null
                ? res.data.data.isolationOwnerId
                : "",
            action: "",
            autoCom: "io",
          },
          {
            id: 2,
            rowName: "Isolation Verifier",
            fieldName:"isolationVerifierId",
            Name:
              res.data.data.isolationVerifierId !== null
                ? res.data.data.isolationVerifierId
                : "",
            action: "",
            autoCom: "iv",
          },
        ]);
        setReconnectionData([
          {
            id: 1,
            rowName: "Isolation Owner",
            Name: "",
            action:
              res.data.data.reconnectionPermitIssuerId !== null
                ? res.data.data.reconnectionPermitIssuerId
                : "",
            autoCom: "reconnectionIO",
          },
          {
            id: 2,
            rowName: "Isolation Verifier",
            fieldName:"isolationVerifierId",
            Name:
              res.data.data.reconnectionPermitVerifierId !== null
                ? res.data.data.reconnectionPermitVerifierId
                : "",
            action: "",
            autoCom: "reconnectionIV",
          },
        ]);
      })
      .catch((e) => {
        console.log(e);
      });
    const getIO = api.user.getFilteredUsers;
    const IODataFilter={
      companyId:companyInfo.ps,
      roleId:roleInfo.isolationOwner,
      terminalId:approvedData.terminalId
    }
     axiosPrivate
      .post(
        `${getIO}`,IODataFilter
      )
      .then((res) => {
        setIOData(res.data.data);
      })
      .catch((e) => {
        setIOData([]);
        console.log(e);
      });
    const IVDataFilter={
      companyId:companyInfo.ps,
      roleId:roleInfo.isolationVerifier,
      terminalId:approvedData.terminalId
    }
     axiosPrivate
      .post(
        `${getIO}`,IVDataFilter
      )
      .then((res) => {
        setIVData(res.data.data);
      })
      .catch((e) => {
        setIVData([]);
        console.log(e);
      });
    setLoading(false);
  };

  const initialValue = {
    ...certificateInfo,
    id: certificateInfo.id,
    formId: certificateInfo.formId,
    lotoNo: certificateInfo.lotoNo,
    isolatedEquipment: certificateInfo.isolatedEquipment,
    locationText: certificateInfo.locationText,
    specifyEnergyIsolatedId: certificateInfo.specifyEnergyIsolatedId,
    isolationOwnerId: certificateInfo.isolationOwnerId,
    isolationVerifierId: certificateInfo.isolationVerifierId,
    electricalDetails: certificateInfo.electricalDetails,
    mechanicalDetails: certificateInfo.mechanicalDetails,
    engineerId: certificateInfo.engineerId,
    isEngineerApproved: certificateInfo.isEngineerApproved,
    engineerApprovedDate: certificateInfo.engineerApprovedDate,
    isPHCertificateClosed: certificateInfo.isPHCertificateClosed,
    phCertificateClosedAt: certificateInfo.phCertificateClosedAt,
    isLPICertificateClosed: certificateInfo.isLPICertificateClosed,
    lpiCertificateClosedAt: certificateInfo.lpiCertificateClosedAt,
    lpiId: certificateInfo.lpiId,
    isLPIApproved: certificateInfo.isLPIApproved,
    lpiApprovedDate: certificateInfo.lpiApprovedDate,
    certificateValidFrom: certificateInfo.certificateValidFrom,
    certificateValidTo: certificateInfo.certificateValidTo,
    reconnectionPermitIssuerId: certificateInfo.reconnectionPermitIssuerId,
    reconnectionPermitVerifierId: certificateInfo.reconnectionPermitVerifierId,
    isDeIsolation: certificateInfo.isDeIsolation,
    status: certificateInfo.status,
  };

  const validationSchema = Yup.object().shape({
    lotoNo: Yup.string().required("This field is required"),

    isolatedEquipment: Yup.string().required("This field is required"),

    locationText: Yup.string().required("This field is required"),

    specifyEnergyIsolatedId: Yup.string().required("This field is required"),

    certificateValidTo: Yup.string()
   .required("This field is required")
     .test(
       "valid-date-range-to",
       "The 'Until' date should not be before the 'From' date.",
       function (value) {
         const { certificateValidFrom } = this.parent;
         if (value && certificateValidFrom) {
           return moment(value).isSameOrAfter(moment(certificateValidFrom));
         }
         return true; 
       }
     ),

    certificateValidFrom: Yup.string().required("This field is required"),
  });

  const onSubmitValidationSchema=Yup.object().shape({
    

    isolationOwnerId:Yup.string().required("This field is required"),

    isolationVerifierId:Yup.string().required("This field is required")
  })
const onApproveAndSubmit=(value)=>{
  const editLink = api.isolation.edit;
  axiosPrivate
  .put(`${editLink}/${certificateInfo.id}`, value)
  .then((res) => {
    setConfirm({...confirm,submit:false})
    setConfirmValue([])
    toast.success("Successfully Updated");
    handleCloseClick();
    
  })
  .catch((e) => {
    setConfirm({...confirm,submit:false})
    setConfirmValue([])
    console.log(e);
  });
}
  const onSubmit = async (value) => {
    let engineerLPIPI =
      loginRoleId.includes(roleInfo.engineer)&&approvedData.isContractApproved ||
            loginRoleId.includes(roleInfo.lpi)&&approvedData.isPermitIssuerApproved;
     
    let statusValidation =
      (loginRoleId.includes(roleInfo.contractStaff)&&approvedData.assignedUsers.includes(login.userId) && value.status === null)||(loginRoleId.includes(roleInfo.contractStaff)&&approvedData.assignedUsers.includes(login.userId)&&value.status===8)
        ? 1
        : loginRoleId.includes(roleInfo.engineer) && approvedData.engineerId===login.userId && approvedData.isTechnicianApproved && !certificateInfo.isEngineerApproved
        ? 3
        : loginRoleId.includes(roleInfo.lpi) && !certificateInfo.isLPIApproved && approvedData.isPermitIssuerApproved && certificateInfo.isEngineerApproved
        ? 4
        : loginRoleId.includes(roleInfo.technican)&&approvedData.isContractApproved && !approvedData.isTechnicianApproved && approvedData.technicianId===login.userId ?9: value.status;
    const postData = {
      ...value,
      refNo:value.specifyEnergyIsolatedId===operationMeasure.electrical?"ELE001":value.specifyEnergyIsolatedId===operationMeasure.mechanical?"MEC001":value.specifyEnergyIsolatedId===operationMeasure.instrumentation?"INT001":value.refNo,
      isolationOwnerId: isolationData[0].Name,
      isolationVerifierId: isolationData[1].Name,
      reconnectionPermitIssuerId: isolationData[0].Name,
      reconnectionPermitVerifierId: isolationData[1].Name,
      isApproved: engineerLPIPI ? true : null,
      status: statusValidation,
    };
    console.log(postData);
   
    if(!roleAccess.assignIOIV){
     
      onSubmitValidationSchema
        .validate(postData, { abortEarly: false })
        .then((responseData) => {
          setError({})
          setConfirm({...confirm,submit:true})
            setConfirmValue([postData])
        
          console.log("no validation errors");
          console.log(responseData);
         
      })
        .catch((err) => {
      
         const errors= err.inner.reduce((acc, error) => {
            return {
              ...acc,
              [error.path]: error.errors[0],
            }
          }, {})
          console.log(errors)
          setError(errors)
          toast.error("Please Fill Required Fields")
      });}else{
        setError({})
        setConfirm({...confirm,submit:true})
          setConfirmValue([postData])
      }
  };

  const handleIsolation = async () => {
    let ioiv =
      login.userId === certificateInfo.isolationOwnerId ||
      login.userId === certificateInfo.isolationVerifierId ||
      (certificateInfo.isPHCertificateClosed &&
        (login.userId === certificateInfo.reconnectionPermitIssuerId ||
          login.userId === certificateInfo.reconnectionPermitVerifierId));
    let statusValidation =
     ( login.userId === certificateInfo.isolationOwnerId && !certificateInfo.isIsolationOwnerApproved)
        ? 6
        : (login.userId === certificateInfo.isolationVerifierId && !certificateInfo.isIsolationVerifierApproved)
        ? 7
        // : loginRoleId.includes(roleInfo.engineer)
        // ? 3
        // : loginRoleId.includes(roleInfo.lpi)
        // ? 4
        : certificateInfo.status;
    const postData = {
      ...certificateInfo,
      isApproved: ioiv ? true : null,
      status: statusValidation,
    };
    console.log(postData);
    const editLink = api.isolation.edit;
    await axiosPrivate
      .put(`${editLink}/${certificateInfo.id}`, postData)
      .then((res) => {
        toast.success("Successfully Updated");
        setConfirm({...confirm,ioivApprove:false})
        setConfirmValue([])
        handleCloseClick();
      })
      .catch((e) => {
        setConfirm({...confirm,ioivApprove:false})
        setConfirmValue([])
        console.log(e);
      });
  };
  const handleReconnection = () => {
    const closeCertLink = api.isolation.closeCertificate;
    const postData = {
      id: certificateInfo.id,
      isCertificateClosed: true,
    };
    console.log(postData);
    axiosPrivate
      .put(`${closeCertLink}/${certificateInfo.id}`, postData)
      .then((res) => {
        toast.success("Certificate Closed");
        setConfirm({...confirm,deIsolationTrue:false})
        setConfirmValue([])
        handleCloseClick();
      })
      .catch((e) => {
        setConfirm({...confirm,deIsolationTrue:false})
        setConfirmValue([])
        console.log(e);
      });
  };

  const handleCloseCertificate = () => {
    // const validatePermit=api.PTWForm.validatePermitShift
    //   if(certificateDetail.isPHCertificateClosed){
            const closeCertLink = api.isolation.closeCertificate;
            const postData = {
              id: certificateInfo.id,
              isCertificateClosed: true,
              isDeIsolation:deisolationBol,
              certificateClosedReason:certificateDetail.isPHCertificateClosed? closedRemark.LPI:closedRemark.PH
            };
            
             axiosPrivate
              .put(`${closeCertLink}/${certificateInfo.id}`, postData)
              .then((res) => {
                toast.success("Certificate Closed");
                setConfirm({...confirm,close:false})
        setConfirmValue([])
                handleCloseClick();
              })
              .catch((e) => {
                toast.error(e.response.data.message)
                setConfirm({...confirm,close:false})
        setConfirmValue([])
                console.log(e);
              });
          // }else{
          //   axiosPrivate.get(`${validatePermit}/${approvedData.id}`).then(res=>{
          //     const closeCertLink = api.isolation.closeCertificate;
          //     const postData = {
          //       id: certificateInfo.id,
          //       isCertificateClosed: true,
          //       isDeIsolation:deisolationBol,
          //       certificateClosedReason: closedRemark.PH,
          //     };
          //     console.log(postData)
          //      axiosPrivate
          //       .put(`${closeCertLink}/${certificateInfo.id}`, postData)
          //       .then((res) => {
          //         toast.success("Certificate Closed");
          //         handleCloseClick();
          //       })
          //       .catch((e) => {
          //         toast.error(e.response.data.message)
          //         console.log(e);
          //       });
          //       }).catch(e=>{
          //             console.log(e)
          //             toast.error("Permit Is Not Active")
          //           })
          // }
  };

  const handleReject = (value, remark) => {
    const postData = {
      ...value,
      isolationOwnerId: isolationData[0].Name,
      isolationVerifierId: isolationData[1].Name,
      reconnectionPermitIssuerId: isolationData[0].Name,
      reconnectionPermitVerifierId: isolationData[1].Name,
      isApproved: false,
      remarks: remark,
      status: 8,
    };
    console.log(postData);
    const editLink = api.isolation.edit;
    axiosPrivate
      .put(`${editLink}/${certificateInfo.id}`, postData)
      .then((res) => {
        console.log(res);

        // getData()
        handleCloseClick();
        setRemarkButton({ ...remarkButton, reject: false });
        toast.success("Successfully Rejected");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const isolationColumns = [
    {
      accessor: "rowName",
      Cell: ({ cell }) => {
        return (
          <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
            {cell.value}
          </Typography>
        );
      },
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ cell }) => {
        return (
          <>
          <Autocomplete
            readOnly={roleAccess.assignIOIV}
            name="name"
            inputValue={autoCompleteValue[cell.row.original.autoCom]}
            filterOptions={filterOptions}
            loading={
              cell.row.original.autoCom === "io"
                ? ioData.length === 0
                : ivData.length === 0
            }
            options={cell.row.original.autoCom === "io" ? ioData : ivData}
            PaperComponent={CustomPaper}
            getOptionLabel={(option) =>
              option.firstName + " " + option.lastName || option
            }
            onChange={(event, value) => {
              if (value) {
                cell.row.original.Name = value.id;
                setAutoCompleteValue({
                  ...autoCompleteValue,
                  [cell.row.original.autoCom]:
                    value.firstName + " " + value.lastName,
                });
              } else {
                cell.row.original.Name = null;
                setAutoCompleteValue({
                  ...autoCompleteValue,
                  [cell.row.original.autoCom]: "",
                });
              }
            }}
            onBlur={(e, value) => {
              if (value) {
                const dataUpdate = isolationData;
                dataUpdate[cell.row.original.id - 1].Name = value.id;
                setIsolationData(dataUpdate);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="companyId"
                size="small"
                // variant="standard"
                fullWidth
                onChange={(e) => {
                  setAutoCompleteValue({
                    ...autoCompleteValue,
                    [cell.row.original.autoCom]: e.target.value,
                  });
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: roleAccess.assignIOIV
                      ? commonCss.readOnlyBGColor
                      : commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            )}
          />
          {error[cell.row.original.fieldName]!==undefined && (
             <ErrorProps>{error[cell.row.original.fieldName]}</ErrorProps>
           )}
          </>
        );
      },
    },
    {
      Header: "Action",
      accessor: "button",
      Cell: ({ cell }) => {
        return (
          <Button
            variant="contained"
            disabled={roleAccess[cell.row.original.autoCom]}
            sx={{
              backgroundColor: commonCss.red,
              borderRadius: commonCss.buttonBorderRadius,
              textTransform: "none",
              padding: "0.6rem 1rem",
              color: commonCss.buttonTextColor,
              width: "5vw",
            }}
            onClick={() => {
              setConfirm({...confirm,ioivApprove:true})
            }}
          >
            OK
          </Button>
        );
      },
    },
    //   {
    //     Header: "Company",
    //     accessor: "companhy",
    //     Cell:({cell})=>{
    //       const [value,setValue]=useState(cell.value)
    //       return(
    //         <TextField
    //               fullWidth
    //               variant="outlined"
    //               size="small"
    //               value={value}
    //               sx={{
    //                 "& .MuiOutlinedInput-root": {
    //                   backgroundColor: commonCss.inputBackgroundColor,
    //                   borderRadius: commonCss.inputBorderRadius,
    //                 },
    //                 "& .MuiOutlinedInput-notchedOutline": {
    //                   borderColor: commonCss.inputBorderColor,
    //                 },
    //               }}
    //               onChange={(e) => {
    //                 setValue(e.target.value)
    //                 cell.row.original.company = e.target.value;
    //               }}
    //               onBlur={(e) =>
    //                 handleIsolation(
    //                   e.target.value,
    //                   cell.row.original,
    //                   "company"
    //                 )
    //               }
    //             />
    //       )
    //     }
    //   },
    //   {
    //     Header: "Date/Time",
    //     accessor: "date",
    //     Cell:({cell})=>{
    //       const [value,setValue]=useState(cell.value)
    //       return(
    //         <LocalizationProvider dateAdapter={AdapterDayjs}>
    //   <DemoItem>
    //     <DateTimePicker
    //       ampm={false}
    //       format="DD/MM/YYYY HH:mm"
    //       viewRenderers={{
    //         hours: renderTimeViewClock,
    //         minutes: renderTimeViewClock,
    //         seconds: renderTimeViewClock,
    //       }}
    //       slotProps={{
    //         textField: {
    //           size: "small",
    //           variant: "outlined",
    //         },
    //       }}
    //       value={cell.value!==null ? dayjs(cell.value) : null}
    //       onChange={(newValue) => {
    //         if (newValue !== null) {
    //           if (`${newValue.$d}` !== "Invalid Date") {
    //             const dateFrom = moment(newValue.$d).format(
    //               "YYYY-MM-DD HH:MM "
    //             );

    //             setValue(dateFrom)
    //             cell.row.original.date = dateFrom;
    //       }}

    //     }}
    //     onBlur={(e) =>
    //       handleIsolation(
    //         value,
    //         cell.row.original,
    //         "date"
    //       )
    //     }

    //       sx={{
    //         "& .MuiOutlinedInput-root": {
    //           backgroundColor: commonCss.inputBackgroundColor,
    //           borderRadius: commonCss.inputBorderRadius,
    //         },
    //         "& .MuiOutlinedInput-notchedOutline": {
    //           borderColor: commonCss.inputBorderColor,
    //         },
    //       }}
    //       fullWidth
    //     />
    //   </DemoItem>
    // </LocalizationProvider>
    //       )
    //     }
    //   }
  ];

  const reconnectionColumns = [
    {
      accessor: "rowName",
      Cell: ({ cell }) => {
        return (
          <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
            {cell.value}
          </Typography>
        );
      },
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ cell }) => {
        return (
          <Autocomplete
            readOnly={roleAccess.technician}
            name="name"
            inputValue={autoCompleteValue[cell.row.original.autoCom]}
            filterOptions={filterOptions}
            loading={
              cell.row.original.autoCom === "io"
                ? ioData.length === 0
                : ivData.length === 0
            }
            options={cell.row.original.autoCom === "io" ? ioData : ivData}
            PaperComponent={CustomPaper}
            getOptionLabel={(option) =>
              option.firstName + " " + option.lastName || option
            }
            onChange={(event, value) => {
              if (value) {
                cell.row.original.Name = value.id;
                setAutoCompleteValue({
                  ...autoCompleteValue,
                  [cell.row.original.autoCom]:
                    value.firstName + " " + value.lastName,
                });
              } else {
                cell.row.original.Name = null;
                setAutoCompleteValue({
                  ...autoCompleteValue,
                  [cell.row.original.autoCom]: "",
                });
              }
            }}
            onBlur={(e, value) => {
              if (value) {
                const dataUpdate = isolationData;
                dataUpdate[cell.row.original.id - 1].Name = value.id;
                setReconnectionData(dataUpdate);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="companyId"
                size="small"
                // variant="standard"
                fullWidth
                onChange={(e) => {
                  setAutoCompleteValue({
                    ...autoCompleteValue,
                    [cell.row.original.autoCom]: e.target.value,
                  });
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: roleAccess.technician
                      ? commonCss.readOnlyBGColor
                      : commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            )}
          />
        );
      },
    },
    {
      Header: "Action",
      accessor: "button",
      Cell: ({ cell }) => {
        return (
          <Button
            variant="contained"
            disabled={roleAccess[cell.row.original.autoCom]}
            sx={{
              backgroundColor: commonCss.red,
              borderRadius: commonCss.buttonBorderRadius,
              textTransform: "none",
              padding: "0.6rem 1rem",
              color: commonCss.buttonTextColor,
              width: "5vw",
            }}
            onClick={() => {
              if (certificateInfo.isTechnicianReconnection) {
                setConfirm({...confirm,deIsolationFalse:true})
        setConfirmValue([true])
              } else {
                setConfirm({...confirm,deIsolationTrue:true})
              }
            }}
          >
            OK
          </Button>
        );
      },
    },
  ];

  const handleDeisolationButton = (cert) => {
    const reconnectionCertLink = api.isolation.deisolation;
    const postData = {
      id: certificateInfo.id,
      isCertificateClosed: cert,
      // certificateClosedReason: closedRemark,
      isDeIsolation: true,
      isTechnicianReconnection: true,
    };
    console.log(postData);
    axiosPrivate
      .put(`${reconnectionCertLink}/${certificateInfo.id}`, postData)
      .then((res) => {
        toast.success("Certificate Reconnected");
        setConfirm({...confirm,deIsolationFalse:false})
        setConfirmValue([])
        handleCloseClick();
      })
      .catch((e) => {
        setConfirm({...confirm,deIsolationFalse:false})
        setConfirmValue([])
        console.log(e);
      });
  };

  return (
    <>
      {!loading ? (
        <Formik
          initialValues={initialValue}
         onSubmit={onSubmit}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({ handleChange, setFieldValue, values, resetForm, errors, touched, setFieldTouched }) => (
            <Form>
              <Grid2 container size={12} spacing={"1rem"}>
                <Grid2 container size={12} alignItems={"center"}>
                  <Grid2 container size={4}>
                    <Box
                      sx={{
                        backgroundColor: commonCss.shadeGreen,
                        display: "flex",
                        alignItems: "cente",
                        p: 1,
                        cursor: "pointer",
                      }}
                      onClick={handleCloseClick}
                    >
                      <ArrowBack color={commonCss.green} />
                    </Box>
                  </Grid2>
                  <Grid2 container size={4} justifyContent={"center"}>
                    <Typography variant="h5" fontWeight="bold" color="white">
                      Isolation Certificate-Lock out/Tag out
                    </Typography>
                  </Grid2>
                  <Grid2 container size={4} justifyContent={"flex-end"}>
                    <Box
                      sx={{
                        backgroundColor: commonCss.shadeGreen,
                        p: 1,
                      }}
                    >
                      <Typography variant="body1" fontWeight="bold">
                        Permit To Work No.{" "}
                        <span style={{ color: "#e53e3e" }}>
                          {searchParams.get("no")}
                        </span>
                      </Typography>
                    </Box>
                  </Grid2>
                  <Divider
                    sx={{
                      borderColor: commonCss.red,
                      borderWidth: 1,
                      width: "100%",
                    }}
                  />
                </Grid2>
                <Grid2 container size={12} alignItems={"center"}>
                  <Typography color="white" variant="body2">
                    (To be completed only by authorised persons)
                  </Typography>
                </Grid2>
                  <Grid2
                                  container
                                  size={12}
                                  gap={0}
                                  sx={{
                                    display:
                                      certificateInfo.isCancel ||
                                      (!values.isLPIApproved &&
                                        certificateInfo.rejectRemarks !== null &&
                                        certificateInfo.rejectRemarks !== "")
                                        ? "default"
                                        : "none",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      width: "100%",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setRemarkButton({
                                        ...remarkButton,
                                        remarkOpen: !remarkButton.remarkOpen,
                                      });
                                    }}
                                  >
                                    {remarkButton.remarkOpen ? (
                                      <KeyboardArrowUp sx={{ color: commonCss.red }} />
                                    ) : (
                                      <KeyboardArrowDown sx={{ color: commonCss.red }} />
                                    )}
                
                                    <Typography
                                      sx={{
                                        color: commonCss.red,
                                        ml: "0.6rem",
                                        fontWeight: 600,
                                      }}
                                    >
                                      Remark
                                    </Typography>
                                  </Box>
                                  <Collapse
                                    in={remarkButton.remarkOpen}
                                    sx={{
                                      background: commonCss.shadeLinearGradient,
                                      width: "100%",
                                      p: remarkButton.remarkOpen ? 1 : 0,
                                    }}
                                  >
                                    <RemarkTableView data={certificateInfo.isCancel
                        ? certificateInfo.cancelOthers
                        : certificateInfo.rejectRemarks}/>
                                    {/* <Typography>
                                      {certificateInfo.isCancel
                                        ? certificateInfo.cancelOthers
                                        : certificateInfo.rejectRemarks}
                                    </Typography> */}
                                  </Collapse>
                                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container spacing={2} mb={2}>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Identify number(LOTO number)
                             <Typography
                                                          component="span"
                                                          color="error"
                                                          fontWeight="bold"
                                                          sx={{ ml: 0.5 }}
                                                        >
                                                          *
                                                        </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="lotoNo"
                          value={values.lotoNo}
                          onChange={handleChange}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                        {errors.lotoNo && (
                          <ErrorMessage name="lotoNo">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Isolated Equipment
                             <Typography
                                                          component="span"
                                                          color="error"
                                                          fontWeight="bold"
                                                          sx={{ ml: 0.5 }}
                                                        >
                                                          *
                                                        </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="isolatedEquipment"
                          value={values.isolatedEquipment}
                          onChange={handleChange}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                        {errors.isolatedEquipment && (
                          <ErrorMessage name="isolatedEquipment">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Location
                             <Typography
                                                          component="span"
                                                          color="error"
                                                          fontWeight="bold"
                                                          sx={{ ml: 0.5 }}
                                                        >
                                                          *
                                                        </Typography>
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="locationText"
                          value={values.locationText}
                          onChange={handleChange}
                          slotProps={{
                            input: {
                              readOnly: roleAccess.contractStaff,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.contractStaff
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                        {errors.locationText && (
                          <ErrorMessage name="locationText">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel sx={{ pl: commonCss.labelPadding }}>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Operational Measure Types
                             <Typography
                                                          component="span"
                                                          color="error"
                                                          fontWeight="bold"
                                                          sx={{ ml: 0.5 }}
                                                        >
                                                          *
                                                        </Typography>
                          </Typography>
                        </InputLabel>
                        <Select
                          size="small"
                          defaultValue=""
                          displayEmpty
                          value={values.specifyEnergyIsolatedId}
                          name="specifyEnergyIsolatedId"
                          readOnly={roleAccess.contractStaff}
                          sx={{
                            borderRadius: "13px",
                            backgroundColor: roleAccess.contractStaff
                              ? commonCss.readOnlyBGColor
                              : commonCss.inputBackgroundColor,
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "747373",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                          fullWidth
                          onChange={handleChange}
                        >
                          {typeMeasure.map((measure, i) => (
                            <MenuItem key={i} value={measure.id} disabled={getMeasure.includes(measure.id)}>
                              {measure.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.specifyEnergyIsolatedId && (
                          <ErrorMessage name="specifyEnergyIsolatedId">
                            {(error) => <ErrorProps>{error}</ErrorProps>}
                          </ErrorMessage>
                        )}
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Validity From
                             <Typography
                                                          component="span"
                                                          color="error"
                                                          fontWeight="bold"
                                                          sx={{ ml: 0.5 }}
                                                        >
                                                          *
                                                        </Typography>
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DatePicker
                              ampm={false}
                              format="DD/MM/YYYY"
                              readOnly={!roleAccess.piContract}
                              minDate={
                                approvedData.isLeadPermitIssuerApproved
                                  ? dayjs(approvedData.lpiPermitValidFrom)
                                  : approvedData.isPermitIssuerApproved
                                  ? dayjs(approvedData.piPermitValidFrom)
                                  : approvedData.actualPermitValidFrom !== null
                                  ? dayjs(approvedData.actualPermitValidFrom)
                                  : dayjs(moment())
                              }
                              maxDate={
                                approvedData.isLeadPermitIssuerApproved
                                  ? dayjs(approvedData.lpiPermitValidUpTo)
                                  : approvedData.isPermitIssuerApproved
                                  ? dayjs(approvedData.piPermitValidUpTo)
                                  : approvedData.actualPermitValidUpTo !== null
                                  ? dayjs(approvedData.actualPermitValidUpTo)
                                  : undefined
                              }
                              value={
                                values.certificateValidFrom !== null
                                  ? dayjs(values.certificateValidFrom)
                                  : null
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d).utc();
                                    setFieldValue(
                                      "certificateValidFrom",
                                      dateFrom
                                    );

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              //  onChange={(newValue) => {
                              //     if (newValue !== null && `${newValue.$d}` !== "Invalid Date") {
                              //       const dateFrom = moment(newValue.$d);
                              //       setFieldValue("certificateValidFrom", dateFrom);
                              //       setFieldValue(
                              //         "certificateValidTo",
                              //         dateFrom.clone().add(7, "days")
                              //       );
                              //     }
                              //   }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: !roleAccess.piContract
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                            {errors.certificateValidFrom && (
                              <ErrorMessage name="certificateValidFrom">
                                {(error) => <ErrorProps>{error}</ErrorProps>}
                              </ErrorMessage>
                            )}
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 4 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Validity Utill
                             <Typography
                                                          component="span"
                                                          color="error"
                                                          fontWeight="bold"
                                                          sx={{ ml: 0.5 }}
                                                        >
                                                          *
                                                        </Typography>
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DatePicker
                              ampm={false}
                              format="DD/MM/YYYY"
                              readOnly={!roleAccess.piContract}
                              minDate={
                                approvedData.isLeadPermitIssuerApproved
                                  ? dayjs(approvedData.lpiPermitValidFrom)
                                  : approvedData.isPermitIssuerApproved
                                  ? dayjs(approvedData.piPermitValidFrom)
                                  : approvedData.actualPermitValidFrom !== null
                                  ? dayjs(approvedData.actualPermitValidFrom)
                                  : values.certificateValidFrom !== null
                                  ? dayjs(values.certificateValidFrom)
                                  : dayjs(moment())
                              }
                              maxDate={
                                approvedData.isLeadPermitIssuerApproved
                                  ? dayjs(approvedData.lpiPermitValidUpTo)
                                  : approvedData.isPermitIssuerApproved
                                  ? dayjs(approvedData.piPermitValidUpTo)
                                  : approvedData.actualPermitValidUpTo !== null
                                  ? dayjs(approvedData.actualPermitValidUpTo)
                                  : values.certificateValidFrom !== null
                                  ? dayjs(
                                      moment(values.certificateValidFrom).add(
                                        7,
                                        "days"
                                      )
                                    )
                                  : undefined
                              }
                              value={
                                values.certificateValidTo !== null
                                  ? dayjs(values.certificateValidTo)
                                  : null
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                             // onClose={() => setFieldTouched("certificateValidTo", true, false)}
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d).utc();
                                    setFieldValue(
                                      "certificateValidTo",
                                      dateFrom
                                    );
                                   // setFieldTouched("certificateValidTo", true, false);
                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              // shouldDisableDate={(date) => {
                              //       const validityFrom = values.certificateValidFrom
                              //         ? moment(values.certificateValidFrom)
                              //         : null;
                              //       return (
                              //         !validityFrom ||
                              //         date.isBefore(validityFrom, "day") ||
                              //         date.isAfter(validityFrom.clone().add(7, "days"), "day")
                              //       );
                              //     }}
                              //     onChange={(newValue) => {
                              //       if (newValue !== null && `${newValue.$d}` !== "Invalid Date") {
                              //         const dateTo = moment(newValue.$d);
                              //         setFieldValue("certificateValidTo", dateTo);
                              //       }
                              //     }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: !roleAccess.piContract
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                            {errors.certificateValidTo && (
                              <ErrorMessage name="certificateValidTo">
                                {(error) => <ErrorProps>{error}</ErrorProps>}
                              </ErrorMessage>
                            )}
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container spacing={2}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          color={commonCss.red}
                          fontWeight={commonCss.mainLabelHeading}
                        >
                          PERSON(S) COMPLETING THE ISOLATION
                        </Typography>
                      </Grid2>
                      <Grid2 size={12}>
                        <NormalTableWithRadius
                          data={isolationData}
                          columns={isolationColumns}
                        />
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container spacing={2} mb={2}>
                      <Typography
                        variant="body2"
                        fontWeight={commonCss.labelFontWeight}
                        color={commonCss.labelInputColor}
                      >
                        I have personally checked the equipment to be worked on
                        under this Isolation certificate and certify it to have
                        been isolated. Accidental reconnection of this equipment
                        has been prevented by the following means:
                      </Typography>
                    </Grid2>

                    <Grid2 container mb={2}>
                      <Grid2 container size={{ xs: 12, md: 4 }} spacing={2}>
                        <FormControlLabel
                          control={
                            <Radio
                              readOnly={roleAccess.contractStaff}
                              checked={checked.electrical}
                              onChange={(e) => {
                                setChecked({
                                  electrical: true,
                                  mechanical: false,
                                });
                              }}
                              sx={{
                                color: commonCss.green,
                                "&.Mui-checked": {
                                  color: commonCss.green,
                                },
                              }}
                            />
                          }
                          label="Electrical"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              readOnly={roleAccess.contractStaff}
                              checked={checked.mechanical}
                              onChange={(e) => {
                                setChecked({
                                  electrical: false,
                                  mechanical: true,
                                });
                              }}
                              sx={{
                                color: commonCss.green,
                                "&.Mui-checked": {
                                  color: commonCss.green,
                                },
                              }}
                            />
                          }
                          label="Mechanical"
                        />
                      </Grid2>

                      {checked.electrical && (
                        <Grid2 size={{ xs: 12, md: 8 }}>
                          {/* <InputLabel>
                  <Typography variant="body2" fontWeight="bold" color="black">
                    Electrical
                  </Typography>
                </InputLabel> */}
                          <TextField
                            variant="outlined"
                            size="small"
                            name="electricalDetails"
                            value={values.electricalDetails}
                            onChange={handleChange}
                            multiline
                            minRows={2}
                            maxRows={5}
                            fullWidth
                            slotProps={{
                              input: {
                                readOnly: roleAccess.contractStaff,
                              },
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: roleAccess.contractStaff
                                  ? commonCss.readOnlyBGColor
                                  : commonCss.inputBackgroundColor,
                                borderRadius: commonCss.inputBorderRadius,
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: commonCss.inputBorderColor,
                              },
                            }}
                          />
                        </Grid2>
                      )}

                      {checked.mechanical && (
                        <Grid2 size={{ xs: 12, md: 8 }}>
                          {/* <InputLabel>
                  <Typography variant="body2" fontWeight="bold" color="black">
                    Mechanical
                  </Typography>
                </InputLabel> */}
                          <TextField
                            variant="outlined"
                            size="small"
                            name="mechanicalDetails"
                            value={values.mechanicalDetails}
                            onChange={handleChange}
                            multiline
                            minRows={2}
                            maxRows={5}
                            fullWidth
                            slotProps={{
                              input: {
                                readOnly: roleAccess.contractStaff,
                              },
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: roleAccess.contractStaff
                                  ? commonCss.readOnlyBGColor
                                  : commonCss.inputBackgroundColor,
                                borderRadius: commonCss.inputBorderRadius,
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: commonCss.inputBorderColor,
                              },
                            }}
                          />
                        </Grid2>
                      )}
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={4}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"1rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                          textAlign={"center"}
                        >
                          Engineer
                        </Typography>
                        <Divider
                          sx={{
                            borderColor: commonCss.red,
                            borderWidth: 1,
                            width: "100%",
                            mt: 1,
                          }}
                        />
                      </Grid2>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.normalSentenceWeight}
                          color={commonCss.normalSenternceColor}
                          sx={{ textTransform: "none" }}
                        >
                          I certify that I have reviewed the proposed work and I
                          am satisfied that it is adequately described on the
                          permit to work and that the precautions detailed are
                          adequate and clearly defined
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Name
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={approveInfo.engineerName}
                          slotProps={{
                            input: {
                              readOnly: true, // Set readOnly to true
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.engineer
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Date/Time
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              format="DD/MM/YYYY HH:mm"
                              value={
                                values.isEngineerApproved
                                  ? dayjs(values.engineerApprovedDate)
                                  : null
                              }
                              readOnly
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: roleAccess.engineer
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 container size={12} justifyContent={"center"}>
                        {/* <Button
                          type="button"
                          variant="contained"
                          // disabled={values.isEngineerApproved}
                          sx={{
                            backgroundColor: commonCss.red,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            display: roleAccess.engineer ? "none" : "default",
                          }}
                          onClick={() => {
                            setRemarkButton({ ...remarkButton, reject: true });
                          }}
                        >
                          Reject
                        </Button> */}
                        <Button
                          type="submit"
                          variant="contained"
                          // disabled={values.isEngineerApproved}
                          sx={{
                            backgroundColor: commonCss.green,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            display: roleAccess.engineer ? "none" : "default",
                          }}
                          // onClick={() => {
                          //   setFieldValue("isEngineerApproved", true);
                          //   setFieldValue("engineerId", login.userId);
                          //   setFieldValue(
                          //     "engineerApprovedDate",
                          //     moment().format("YYYY-MM-DDTHH:mm:ss")
                          //   );
                          //   setApproveInfo({
                          //     ...approveInfo,
                          //     engineerName:
                          //       login.firstName + " " + login.lastName,
                          //   });
                          // }}
                        >
                          Acknowledge
                        </Button>
                      </Grid2>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={4}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container size={12} spacing={"1rem"}>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.mainLabelHeading}
                          color={commonCss.red}
                          textAlign={"center"}
                        >
                          LPI
                        </Typography>
                        <Divider
                          sx={{
                            borderColor: commonCss.red,
                            borderWidth: 1,
                            width: "100%",
                            mt: 1,
                          }}
                        />
                      </Grid2>
                      <Grid2 size={12}>
                        <Typography
                          variant="body2"
                          fontWeight={commonCss.normalSentenceWeight}
                          color={commonCss.normalSenternceColor}
                          sx={{ textTransform: "none" }}
                        >
                          Isolation Certificate is described in the permit to
                          work is allowed between:
                        </Typography>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Name
                          </Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={approveInfo.lpiName}
                          slotProps={{
                            input: {
                              readOnly: true, // Set readOnly to true
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: roleAccess.lpi
                                ? commonCss.readOnlyBGColor
                                : commonCss.inputBackgroundColor,
                              borderRadius: commonCss.inputBorderRadius,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: commonCss.inputBorderColor,
                            },
                          }}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Date/Time
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DateTimePicker
                              ampm={false}
                              readOnly
                              value={
                                values.isLPIApproved
                                  ? dayjs(values.lpiApprovedDate)
                                  : null
                              }
                              format="DD/MM/YYYY HH:mm"
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: roleAccess.lpi
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            From
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DatePicker
                              ampm={false}
                              format="DD/MM/YYYY"
                              readOnly={roleAccess.lpi}
                              minDate={
                                approvedData.isLeadPermitIssuerApproved
                                  ? dayjs(approvedData.lpiPermitValidFrom)
                                  : approvedData.isPermitIssuerApproved
                                  ? dayjs(approvedData.piPermitValidFrom)
                                  : dayjs(moment())
                              }
                              maxDate={
                                approvedData.isLeadPermitIssuerApproved
                                  ? dayjs(approvedData.lpiPermitValidUpTo)
                                  : approvedData.isPermitIssuerApproved
                                  ? dayjs(approvedData.piPermitValidUpTo)
                                  : undefined
                              }
                              value={
                                values.certificateValidFrom !== null
                                  ? dayjs(values.certificateValidFrom)
                                  : null
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d).utc();
                                    setFieldValue(
                                      "certificateValidFrom",
                                      dateFrom
                                    );

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: roleAccess.lpi
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2 size={{ xs: 12 }}>
                        <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Until
                          </Typography>
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoItem>
                            <DatePicker
                              ampm={false}
                              format="DD/MM/YYYY"
                              readOnly={roleAccess.lpi}
                              minDate={
                                approvedData.isLeadPermitIssuerApproved
                                  ? dayjs(approvedData.lpiPermitValidFrom)
                                  : approvedData.isPermitIssuerApproved
                                  ? dayjs(approvedData.piPermitValidFrom)
                                  : dayjs(moment())
                              }
                              maxDate={
                                approvedData.isLeadPermitIssuerApproved
                                  ? dayjs(approvedData.lpiPermitValidUpTo)
                                  : approvedData.isPermitIssuerApproved
                                  ? dayjs(approvedData.piPermitValidUpTo)
                                  : undefined
                              }
                              value={
                                values.certificateValidTo !== null
                                  ? dayjs(values.certificateValidTo)
                                  : null
                              }
                              slotProps={{
                                textField: {
                                  size: "small",
                                  variant: "outlined",
                                },
                              }}
                              onChange={(newValue) => {
                                console.log(newValue);
                                if (newValue !== null) {
                                  if (`${newValue.$d}` !== "Invalid Date") {
                                    const dateFrom = moment(newValue.$d).utc();
                                    setFieldValue(
                                      "certificateValidTo",
                                      dateFrom
                                    );

                                    // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                                    // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                                    // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                                    // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                                    // setFieldValue("installationDate", dateFormat);
                                  }
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: commonCss.inputBorderRadius,
                                  backgroundColor: roleAccess.lpi
                                    ? commonCss.readOnlyBGColor
                                    : commonCss.inputBackgroundColor,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: commonCss.inputBorderColor,
                                },
                              }}
                              fullWidth
                            />
                          </DemoItem>
                        </LocalizationProvider>
                      </Grid2>
                      <Grid2
                        container
                        size={12}
                        justifyContent={"center"}
                        sx={{ display: roleAccess.lpi ? "none" : "default" }}
                      >
                        <Button
                          type="button"
                          variant="contained"
                          disabled={values.isLPIApproved}
                          sx={{
                            backgroundColor: commonCss.red,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            display: roleAccess.lpi ? "none" : "default",
                          }}
                          onClick={() => {
                            setRemarkButton({ ...remarkButton, reject: true });
                          }}
                        >
                          Reject
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          disabled={values.isLPIApproved}
                          sx={{
                            backgroundColor: commonCss.green,
                            borderRadius: commonCss.buttonBorderRadius,
                            textTransform: "none",
                            padding: "0.6rem 1rem",
                            color: commonCss.buttonTextColor,
                            width: "15vw",
                            display: roleAccess.lpi ? "none" : "default",
                          }}
                          // onClick={() => {
                          //   setFieldValue("isLPIApproved", true);
                          //   setFieldValue("lpiId", login.userId);
                          //   setFieldValue(
                          //     "lpiApprovedDate",
                          //     moment().format("YYYY-MM-DDTHH:mm:ss")
                          //   );
                          //   setApproveInfo({
                          //     ...approveInfo,
                          //     lpiName: login.firstName + " " + login.lastName,
                          //   });
                          // }}
                        >
                          Approve
                        </Button>
                      </Grid2>
                   
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2 container size={4}>
                                                 <Paper
                                                   elevation={3}
                                                   sx={{
                                                     borderRadius: commonCss.paperBorderRadius,
                                                     p: 1.5,
                                                     background: commonCss.shadeLinearGradient,
                                                     width: "100%",
                                                   }}
                                                 >
                                                   <Grid2 container size={12} spacing={"1rem"}>
                                                     <Grid2 item xs={12}>
                                                       <Typography
                                                         variant="body2"
                                                         fontWeight={commonCss.normalSentenceWeight}
                                                         color={commonCss.normalSenternceColor}
                                                         sx={{ textTransform: "none" }}
                                                       >
                                                         Isolation Certificate is certificate and permit to work
                                                         are withdrawn:
                                                       </Typography>
                                                     </Grid2>
                                                     <Grid2 size={{ xs: 12 }}>
                                                       <InputLabel>
                                                         <Typography
                                                           variant="body2"
                                                           fontWeight={commonCss.labelFontWeight}
                                                           color={commonCss.labelInputColor}
                                                         >
                                                          Permit Holder Name
                                                         </Typography>
                                                       </InputLabel>
                                                       <TextField
                                                         fullWidth
                                                         variant="outlined"
                                                         size="small"
                                                         slotProps={{
                                                           input: {
                                                             readOnly: true,
                                                           },
                                                         }}
                                                         value={
                                                           values.isPHCertificateClosed !== null
                                                             ? approveInfo.certificateClosedName
                                                             : null
                                                         }
                                                         sx={{
                                                           "& .MuiOutlinedInput-root": {
                                                             backgroundColor: roleAccess.certClose
                                                               ? commonCss.readOnlyBGColor
                                                               : commonCss.inputBackgroundColor,
                                                             borderRadius: commonCss.inputBorderRadius,
                                                           },
                                                           "& .MuiOutlinedInput-notchedOutline": {
                                                             borderColor: commonCss.inputBorderColor,
                                                           },
                                                         }}
                                                       />
                                                     </Grid2>
                                                     <Grid2 size={{ xs: 12 }}>
                                                       <InputLabel>
                                                         <Typography
                                                           variant="body2"
                                                           fontWeight={commonCss.labelFontWeight}
                                                           color={commonCss.labelInputColor}
                                                         >
                                                         Permit Holder Date/Time
                                                         </Typography>
                                                       </InputLabel>
                                                       <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                         <DemoItem>
                                                           <DateTimePicker
                                                             ampm={false}
                                                             format="DD/MM/YYYY HH:mm"
                                                             readOnly
                                                             value={
                                                               values.isPHCertificateClosed
                                                                 ? dayjs(values.phCertificateClosedAt)
                                                                 : null
                                                             }
                                                             viewRenderers={{
                                                               hours: renderTimeViewClock,
                                                               minutes: renderTimeViewClock,
                                                               seconds: renderTimeViewClock,
                                                             }}
                                                             slotProps={{
                                                               textField: {
                                                                 size: "small",
                                                                 variant: "outlined",
                                                               },
                                                             }}
                                                             sx={{
                                                               "& .MuiOutlinedInput-root": {
                                                                 borderRadius: commonCss.inputBorderRadius,
                                                                 backgroundColor: roleAccess.certClose
                                                                   ? commonCss.readOnlyBGColor
                                                                   : commonCss.inputBackgroundColor,
                                                               },
                                                               "& .MuiOutlinedInput-notchedOutline": {
                                                                 borderColor: commonCss.inputBorderColor,
                                                               },
                                                             }}
                                                             fullWidth
                                                           />
                                                         </DemoItem>
                                                       </LocalizationProvider>
                                                     </Grid2>
                                                     <Grid2 size={{ xs: 12 }}>
                                                       <InputLabel>
                                                         <Typography
                                                           variant="body2"
                                                           fontWeight={commonCss.labelFontWeight}
                                                           color={commonCss.labelInputColor}
                                                         >
                                                          Permit Holder Reason
                                                         </Typography>
                                                       </InputLabel>
                                                         <TextField
                                                                                 fullWidth
                                                                                 variant="outlined"
                                                                                 size="small"
                                                                                 name="reason"
                                                                                 value={closedRemark.PH}
                                                                                 slotProps={{
                                                                                   input: {
                                                                                     readOnly: roleAccess.certClose,
                                                                                   },
                                                                                 }}
                                                                                 onChange={(e) => {
                                                                                   setClosedRemark({...closedRemark,PH:e.target.value});
                                                                                 }}
                                                                                 multiline
                                                                                 minRows={2}
                                                                                 sx={{
                                                                                   "& .MuiOutlinedInput-root": {
                                                                                     backgroundColor: roleAccess.certClose
                                                                                       ? commonCss.readOnlyBGColor
                                                                                       : commonCss.inputBackgroundColor,
                                                                                     borderRadius: commonCss.inputBorderRadius,
                                                                                   },
                                                                                   "& .MuiOutlinedInput-notchedOutline": {
                                                                                     borderColor: commonCss.inputBorderColor,
                                                                                   },
                                                                                 }}
                                                                               />
                                                     </Grid2>
                                                     <Grid2 size={12}>
                        {/* <InputLabel>
                          <Typography
                            variant="body2"
                            fontWeight={commonCss.labelFontWeight}
                            color={commonCss.labelInputColor}
                          >
                            Is this deisolate?
                          </Typography>
                        </InputLabel> */}
                        {/* <Box sx={{display:"flex",gap:"0.6rem",justifyContent:"center",alignItems:"center",width:"100%"}}>
                     
                  <FormControlLabel
                   
                   sx={{"& .MuiFormControlLabel-label.Mui-disabled":{
                 
                 color:"black"
                 
                }}}
                   control={
                     <Checkbox
                     checked={!deisolationBol}
                     disabled={roleAccess.certClose}
                     onClick={(e)=>{setDeisolationBol(e.target.checked)}}
                       sx={{
                         "& .MuiSvgIcon-root": {
                           fill: "#00807F",
                         },
                         "&:checked": {
                           color: "#00807F",
                         },
                         
                       }}
                     />
                   }
                   label={"No"}
                 />
                  </Box> */}
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label.Mui-disabled": {
                              color: "black",
                            },
                          }}
                          control={
                            <Checkbox
                              checked={deisolationBol || values.isDeIsolation}
                              disabled={roleAccess.certClose}
                              onClick={(e) => {
                                setDeisolationBol(e.target.checked);
                              }}
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fill: "#00807F",
                                },
                                "&:checked": {
                                  color: "#00807F",
                                },
                              }}
                            />
                          }
                          label={"Is This Deisolate?"}
                        />
                      </Grid2>
                                                     <Grid2 container size={12} justifyContent={"center"}>
                                                       <Button
                                                         variant="contained"
                                                         disabled={values.isPHCertificateClosed}
                                                         sx={{
                                                           backgroundColor: commonCss.green,
                                                           borderRadius: commonCss.buttonBorderRadius,
                                                           textTransform: "none",
                                                           padding: "0.6rem 1rem",
                                                           color: commonCss.buttonTextColor,
                                                           width: "15vw",
                                                           display: roleAccess.certClose ? "none" : "default",
                                                         }}
                                                         onClick={() => {
                                                          setConfirm({...confirm,close:true})
                                                         }}
                                                       >
                                                         Close
                                                       </Button>
                                                     </Grid2>
                                                     <Grid2
                                                       container
                                                       size={12}
                                                       justifyContent={"center"}
                                                       sx={{display:values.isPHCertificateClosed?"default":"none"}}
                                                     >
                                                        <Grid2 size={{ xs: 12 }}>
                                                       <InputLabel>
                                                         <Typography
                                                           variant="body2"
                                                           fontWeight={commonCss.labelFontWeight}
                                                           color={commonCss.labelInputColor}
                                                         >
                                                          LPI Name
                                                         </Typography>
                                                       </InputLabel>
                                                       <TextField
                                                         fullWidth
                                                         variant="outlined"
                                                         size="small"
                                                         slotProps={{
                                                           input: {
                                                             readOnly: true,
                                                           },
                                                         }}
                                                         value={
                                                           values.isLPICertificateClosed !== null
                                                             ? approveInfo.lpiCertClosedName
                                                             : null
                                                         }
                                                         sx={{
                                                           "& .MuiOutlinedInput-root": {
                                                             backgroundColor: roleAccess.lpiCertCloseReason
                                                               ? commonCss.readOnlyBGColor
                                                               : commonCss.inputBackgroundColor,
                                                             borderRadius: commonCss.inputBorderRadius,
                                                           },
                                                           "& .MuiOutlinedInput-notchedOutline": {
                                                             borderColor: commonCss.inputBorderColor,
                                                           },
                                                         }}
                                                       />
                                                     </Grid2>
                                                     <Grid2 size={{ xs: 12 }}>
                                                       <InputLabel>
                                                         <Typography
                                                           variant="body2"
                                                           fontWeight={commonCss.labelFontWeight}
                                                           color={commonCss.labelInputColor}
                                                         >
                                                         LPI Date/Time
                                                         </Typography>
                                                       </InputLabel>
                                                       <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                         <DemoItem>
                                                           <DateTimePicker
                                                             ampm={false}
                                                             format="DD/MM/YYYY HH:mm"
                                                             readOnly
                                                             value={
                                                               values.isLPICertificateClosed
                                                                 ? dayjs(values.lpiCertificateClosedAt)
                                                                 : null
                                                             }
                                                             viewRenderers={{
                                                               hours: renderTimeViewClock,
                                                               minutes: renderTimeViewClock,
                                                               seconds: renderTimeViewClock,
                                                             }}
                                                             slotProps={{
                                                               textField: {
                                                                 size: "small",
                                                                 variant: "outlined",
                                                               },
                                                             }}
                                                             sx={{
                                                               "& .MuiOutlinedInput-root": {
                                                                 borderRadius: commonCss.inputBorderRadius,
                                                                 backgroundColor: roleAccess.lpiCertCloseReason
                                                                   ? commonCss.readOnlyBGColor
                                                                   : commonCss.inputBackgroundColor,
                                                               },
                                                               "& .MuiOutlinedInput-notchedOutline": {
                                                                 borderColor: commonCss.inputBorderColor,
                                                               },
                                                             }}
                                                             fullWidth
                                                           />
                                                         </DemoItem>
                                                       </LocalizationProvider>
                                                     </Grid2>
                                                       <Grid2 size={{ xs: 12 }}>
                                                         <InputLabel>
                                                           <Typography
                                                             variant="body2"
                                                             fontWeight={commonCss.labelFontWeight}
                                                             color={commonCss.labelInputColor}
                                                           >
                                                             Reason
                                                           </Typography>
                                                         </InputLabel>
                                                         <TextField
                                                           fullWidth
                                                           variant="outlined"
                                                           size="small"
                                                           name="reason"
                                                           value={closedRemark.LPI}
                                                           slotProps={{
                                                             input: {
                                                               readOnly: roleAccess.lpiCertCloseReason,
                                                             },
                                                           }}
                                                           onChange={(e) => {
                                                             setClosedRemark({
                                                               ...closedRemark,
                                                               LPI: e.target.value,
                                                             });
                                                           }}
                                                           multiline
                                                           minRows={2}
                                                           sx={{
                                                             "& .MuiOutlinedInput-root": {
                                                               backgroundColor: roleAccess.lpiCertCloseReason
                                                                 ? commonCss.readOnlyBGColor
                                                                 : commonCss.inputBackgroundColor,
                                                               borderRadius: commonCss.inputBorderRadius,
                                                             },
                                                             "& .MuiOutlinedInput-notchedOutline": {
                                                               borderColor: commonCss.inputBorderColor,
                                                             },
                                                           }}
                                                         />
                                                       </Grid2>
                                                       <Grid2 container size={12} justifyContent={"center"}>
                                                         <Button
                                                           variant="contained"
                                                           disabled={values.isLPICertificateClosed}
                                                           sx={{
                                                             backgroundColor: commonCss.green,
                                                             borderRadius: commonCss.buttonBorderRadius,
                                                             textTransform: "none",
                                                             padding: "0.6rem 1rem",
                                                             color: commonCss.buttonTextColor,
                                                             width: "15vw",
                                                             display: roleAccess.lpiCertCloseReason
                                                               ? "none"
                                                               : "default",
                                                           }}
                                                           onClick={() => {
                                                            setConfirm({...confirm,close:true})
                                                           }}
                                                         >
                                                           Close
                                                         </Button>
                                                       </Grid2>
                                                     </Grid2>
                                                   </Grid2>
                                                 </Paper>
                                               </Grid2>
                <Grid2 container size={12}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: commonCss.paperBorderRadius,
                      p: 1.5,
                      background: commonCss.shadeLinearGradient,
                      width: "100%",
                    }}
                  >
                    <Grid2 container spacing={2} mb={2}>
                      <Typography
                        variant="body2"
                        fontWeight={commonCss.normalSentenceWeight}
                        color={commonCss.normalSenternceColor}
                        sx={{ textTransform: "none" }}
                      >
                        I have personally checked the equipment isolated above
                        and confirm that work on it is completed and said
                        equipment has been re-connected and is in a safe working
                        condition.
                      </Typography>
                    </Grid2>
                    <Grid2 size={12}>
                      <NormalTableWithRadius
                        data={reconnectionData}
                        columns={reconnectionColumns}
                      />
                    </Grid2>
                    <Grid2
                      container
                      spacing={2}
                      mb={2}
                      justifyContent={"flex-end"}
                      sx={{
                        display: roleAccess.technician ? "none" : "default",
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          width: "15vw",
                          backgroundColor: "#FF0000",
                          color: "white",
                          borderRadius: "10px",
                          "&:hover": {
                            backgroundColor: "#cc0000",
                          },
                          mt: 2,
                        }}
                        onClick={() => {
                          setConfirm({...confirm,deIsolationFalse:true})
                          setConfirmValue([false])
                        }}
                      >
                        Reconnection
                      </Button>
                    </Grid2>
                  </Paper>
                </Grid2>
                <Grid2
                  container
                  size={12}
                  justifyContent={"flex-end"}
                  sx={{ display: roleAccess.submitButton ? "default" : "none" }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      backgroundColor: commonCss.green,
                      borderRadius: commonCss.buttonBorderRadius,
                      textTransform: "none",
                      padding: "0.6rem 1rem",
                      color: commonCss.buttonTextColor,
                      width: "10vw",
                    }}
                  >
                    Submit
                  </Button>
                </Grid2>
              </Grid2>
              <Dialog
                // maxWidth={"lg"}
                open={remarkButton.reject}
                onClose={() => {
                  setRemarkButton({ ...remarkButton, reject: false });
                }}
                maxWidth="md"
                fullWidth
                PaperProps={{
                  sx: {
                    background: commonCss.normalLinearGradient,
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogContent>
                  <Remark
                    handleSubmitRemark={(e) => {
                      handleReject(values, e);
                    }}
                    handleCloseClick={() => {
                      setRemarkButton({ ...remarkButton, reject: false });
                    }}
                  />
                </DialogContent>
              </Dialog>
              <Dialog
        // maxWidth={"lg"}
  open={confirm.submit}
  onClose={()=>{setConfirm({...confirm,submit:false})}}
maxWidth={"md"}
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Confirmation
  onSubmit={()=>{onApproveAndSubmit(...confirmValue)}} 
  name={'submit'}
  handleCloseClick={()=>{
    setConfirm({...confirm,submit:false})
    setConfirmValue([])
    }}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={confirm.close}
  onClose={()=>{setConfirm({...confirm,close:false})}}
maxWidth={"md"}
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Confirmation 
  onSubmit={()=>{handleCloseCertificate()}} 
  name={'close'}
  handleCloseClick={()=>{
    setConfirm({...confirm,close:false})
    setConfirmValue([])
    }}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={confirm.deIsolationTrue}
  onClose={()=>{setConfirm({...confirm,deIsolationTrue:false})}}
maxWidth={"md"}
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Confirmation 
  onSubmit={()=>{handleReconnection()}} 
  name={'submit'}
  handleCloseClick={()=>{
    setConfirm({...confirm,deIsolationTrue:false})
    setConfirmValue([])
    }}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={confirm.deIsolationFalse}
  onClose={()=>{setConfirm({...confirm,deIsolationFalse:false})}}
maxWidth={"md"}
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Confirmation 
  onSubmit={()=>{handleDeisolationButton(...confirmValue)}} 
  name={'submit'}
  handleCloseClick={()=>{
    setConfirm({...confirm,deIsolationFalse:false})
    setConfirmValue([])
    }}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={confirm.ioivApprove}
  onClose={()=>{setConfirm({...confirm,ioivApprove:false})}}
maxWidth={"md"}
  PaperProps={{
    sx: {
     
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Confirmation 
  onSubmit={()=>{handleIsolation(...confirmValue)}} 
  name={'submit'}
  handleCloseClick={()=>{
    setConfirm({...confirm,ioivApprove:false})
    setConfirmValue([])
    }}/>
  </DialogContent>
       </Dialog>
            </Form>
          )}
        </Formik>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default IsolationCertificate;

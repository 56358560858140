import { Box, Checkbox, Divider, FormControlLabel, FormGroup, Grid2, IconButton, Paper, TextField, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { commonCss } from "../../StyleConfig/CommonCss";
import { Clear, Save } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { api } from "../API/config";
import { axiosPrivate } from "../../axios/axios";

const CertificateSelect = ({handleCloseClick,data,deleteBol,riskData}) => {
    const [searchParams,setSearchParams]=useSearchParams()
 
    const [certificate,setCertificate]=useState([])
    const [certificateCount,setCertificateCount]=useState({})
    const [selectedCertificate,setSelectedCertificate]=useState({
      ...data,
      companyId:data.companyId,
      workDescription:data.workDescription,
      enableHotWorkCertificate:data.enableHotWorkCertificate&&deleteBol.enableHotWorkCertificate,
      enableConfinedSpaceCertificate:data.enableConfinedSpaceCertificate&&deleteBol.enableConfinedSpaceCertificate,
      enableSystemOverridingCertificate:data.enableSystemOverridingCertificate&&deleteBol.enableSystemOverridingCertificate,
      enableExcavationCertificate:data.enableExcavationCertificate&&deleteBol.enableExcavationCertificate,
      enableIsolationCertificate:data.enableIsolationCertificate&&deleteBol.enableIsolationCertificate,
      enableLiftingAndHoistingCertificate:data.enableLiftingAndHoistingCertificate&&deleteBol.enableLiftingAndHoistingCertificate,
      enableRadiationCertificate:data.enableRadiationCertificate&&deleteBol.enableRadiationCertificate,
      hotWorkCertificateCount:0,
      confinedSpaceCertificateCount:0,
      liftingAndHoistingCertificateCount:0,
      radiationCertificateCount:0,
      isolationCertificateCount:0,
      excavationCertificateCount:0,
      systemOverridingCertificateCount:0,
      operationalMeasures:data.enableIsolationCertificate?{...data.operationalMeasures}:null,
      formDetail:{
        ...data.formDetail,
        jmsDocuments:data?.formDetail?.isJMSRequired?riskData.jmsDocuments.map(obj => obj.id).toString():data?.formDetail?.jmsDocuments,
        jsaDocuments:data?.formDetail?.isJSARequired?riskData.jsaDocuments.map(obj => obj.id).toString():data?.formDetail?.jsaDocuments,
        classifiedAreaWhere:data.formDetail.classifiedAreaWhere.length!==0?data.formDetail.classifiedAreaWhere.toString():null,
        classifiedAreaWithWhat:data.formDetail.classifiedAreaWithWhat.length!==0?data.formDetail.classifiedAreaWithWhat.toString():null,
        operationalInformationWhat:data.formDetail.operationalInformationWhat.length!==0?data.formDetail.operationalInformationWhat.toString():null,
        additionalPPPEWhere:data.formDetail.additionalPPPEWhere.length!==0?data.formDetail.additionalPPPEWhere.toString():null,
      }
    })
 
    const dispatch=useDispatch()

    

    const certificateNameList=[
        {
            name:"enableHotWorkCertificate",
            label:"Hot Work",
            countName:"hotWorkCertificateCount"
        },
        {
            name:"enableConfinedSpaceCertificate",
            label:"Confined Space",
            countName:"confinedSpaceCertificateCount"
        },
        {
            name:"enableSystemOverridingCertificate",
            label:"System Overriding",
            countName:"systemOverridingCertificateCount"
        },
        {
            name:"enableExcavationCertificate",
            label:"Excavation",
            countName:"excavationCertificateCount"
        },
        {
            name:"enableIsolationCertificate",
            label:"Isolation",
            countName:"isolationCertificateCount"
        },
        {
            name:"enableLiftingAndHoistingCertificate",
            label:"Lifting & Hoisting",
            countName:"liftingAndHoistingCertificateCount"
        },
        {
            name:"enableRadiationCertificate",
            label:"Radiation",
            countName:"radiationCertificateCount"
        },
    ]

    const handleSave=async()=>{
      if(selectedCertificate.enableHotWorkCertificate||selectedCertificate.enableConfinedSpaceCertificate||selectedCertificate.enableSystemOverridingCertificate||selectedCertificate.enableExcavationCertificate||selectedCertificate.enableIsolationCertificate||selectedCertificate.enableLiftingAndHoistingCertificate||selectedCertificate.enableRadiationCertificate){
       const saveLink=api.PTWForm.saveForm
       const postData={...selectedCertificate,isolationCertificateCount:Number(selectedCertificate.isolationCertificateCount)}
       console.log(postData)
       await axiosPrivate.post(`${saveLink}`,postData).then(res=>{
        console.log(res)
        toast.success("Successfully Added")
        handleCloseClick()
       }).catch(e=>{
        console.log(e,"add certificate")
       })
      }else{
        toast.error("Select Certificate")
      }
    }
    const handleCheckBox=(e,detail)=>{
        
        // if(e.target.checked){
        //     setCertificate([...certificate,detail])
        //     setCertificateCount({...certificateCount,[detail.name]:1})
        //   }else{
        //     setCertificate(certificate.filter(fil=>fil.label!==detail.label))
        //     setCertificateCount({...certificateCount,[detail.name]:0})
        //   }
        console.log({...selectedCertificate,[e.target.name]:e.target.checked,[detail.countName]:detail.countName!=="isolationCertificateCount"?1:e.target.checked?selectedCertificate.isolationCertificateCount:0})
        
        setSelectedCertificate({...selectedCertificate,[e.target.name]:e.target.checked,[detail.countName]:detail.countName!=="isolationCertificateCount"?1:e.target.checked?Number(selectedCertificate.isolationCertificateCount)===0?1:selectedCertificate.isolationCertificateCount:0})
    }
  return (
    <Paper sx={{ background: commonCss.shadeLinearGradient, p: 1.5 }}>
      <Grid2 container>
        <Grid2 size={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography  fontWeight={600} color={commonCss.green} sx={{fontSize:"1.3rem"}}>
              Certificate
            </Typography>
            <Box sx={{display:"flex",alignItems:"center",width:"40%",justifyContent:"flex-end",gap:"1rem"}}>
              <IconButton onClick={handleSave}>
                <Save sx={{color:commonCss.red}}/>
              </IconButton>
              <IconButton onClick={handleCloseClick}>
                <Clear sx={{color:commonCss.red}}/>
              </IconButton>
            </Box>
          </Box>
          <Divider sx={{borderColor: commonCss.red, borderWidth: 1,width:"100%"}}/>
        </Grid2>
        <Grid2 size={12}>
        <FormGroup  >
            {certificateNameList.map((typeLevel,i)=>(
              <Fragment key={i}>
                <Box sx={{display:"flex",alignItems:"flex-end"}}>
               <FormControlLabel
               
               sx={{ mt:1.5}}
               control={
               <Checkbox 
               checked={selectedCertificate[`${typeLevel.name}`]}
               name={typeLevel.name} 
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
               onChange={(e)=>{handleCheckBox(e,typeLevel)}}
             />
               
               } label={typeLevel.label} />
               <TextField
              fullWidth
              type="number"
              variant="outlined"
              size="small"
              slotProps={{
                input:{
                  inputProps: { 
                    max: 3, min: 1
                }
                }
              }}
              
              value={selectedCertificate.isolationCertificateCount}
              onChange={(e)=>{setSelectedCertificate({...selectedCertificate,isolationCertificateCount:e.target.value})}}
              sx={{
                display:typeLevel.label==="Isolation"?selectedCertificate.enableIsolationCertificate?"default":"none":"none",
                width:"10vw",
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
            </Box>
               </Fragment>
            ))}
          
          </FormGroup>
        </Grid2>
      </Grid2>
    </Paper>
  );
};

export default CertificateSelect;

import {
  Box,
  Button,
  Divider,
  Grid2,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Clear } from "@mui/icons-material";

import { axiosPrivate } from "../../../axios/axios";
import { api } from "../../API/config";
import { commonCss } from "../../../StyleConfig/CommonCss";
import { toast } from "react-toastify";

const CompanyModal = ({ mode, handleCloseClick, editData }) => {
  const [loading, setLoading] = useState(false);

  const initialValue = {
    name: mode === "Edit" ? editData.name : "",
  };

  const onSubmit = async (value) => {
    console.log(value);
    setLoading(true);

    const createLink = api.company.add;
    const updateLink = api.company.edit;
    if (mode === "Create") {
      await axiosPrivate
        .post(`${createLink}`, value)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e.response.data.message)
          console.log(e, "create Company");
        });
    } else {
      const updateAC = {
        id:editData.id,
        ...value,
      };
    
      await axiosPrivate
        .put(`${updateLink}/${editData.id}`, updateAC)
        .then((res) => {
          console.log(res);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e.response.data.message)
          console.log(e, "update Company");
        });
    }
  };

  const handleCancel = () => {
    handleCloseClick();
  };

  return (
    <Box >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: commonCss.titleColor,
            fontSize: commonCss.titleFontSize,
            fontWeight: commonCss.titleFontWeight,
          }}
        >
          {mode === "Edit" ? editData.name : "New Company"}
        </Typography>
        <IconButton onClick={handleCancel}>
          <Clear />
        </IconButton>
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
      <Formik initialValues={initialValue} onSubmit={onSubmit} >
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
            <Grid2 container spacing={"1rem"}>
              <Grid2 size={12}>
                <InputLabel>
                  <Typography
                    variant="body2"
                    fontWeight={commonCss.labelFontWeight}
                    color={commonCss.labelInputColor}
                  >
                    Name
                  </Typography>
                </InputLabel>
                <TextField
                  size={"small"}
                  type="text"
                  name="name"
                  value={values.name}
                  fullWidth
                  onChange={handleChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: commonCss.inputBackgroundColor,
                      borderRadius: commonCss.inputBorderRadius,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: commonCss.inputBorderColor,
                    },
                  }}
                />
              </Grid2>
              <Grid2 container size={12} justifyContent={"flex-end"} gap="1rem">
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  sx={{
                    backgroundColor: commonCss.green,
                    borderRadius: commonCss.buttonBorderRadius,
                    textTransform: "none",
                    padding: "0.6rem 1rem",
                    color: commonCss.buttonTextColor,
                    width: "10vw",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  variant="contained"
                  disabled={loading}
                  sx={{
                    backgroundColor: commonCss.green,
                    borderRadius: commonCss.buttonBorderRadius,
                    textTransform: "none",
                    padding: "0.6rem 1rem",
                    color: commonCss.buttonTextColor,
                    width: "10vw",
                  }}
                >
                  Submit
                </Button>
              </Grid2>
            </Grid2>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CompanyModal;

import { Box, Card, Grid2, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableContainerComp from "../../Component/TableContainer";
import { axiosPrivate } from "../../axios/axios";
import { api } from "../API/config";
import moment from "moment";
import { commonCss } from "../../StyleConfig/CommonCss";
import { Link } from "react-router-dom";
import Loading from "../../Component/Loading";
import { toast } from "react-toastify";
import { Beenhere, Check } from "@mui/icons-material";

const ActivePermitShift = () => {
  const [data, setData] = useState([]);
    const [count,setCount]=useState({
      actionPending:[],
      approved:[]
    })
    
    const [backUp,setBackUp]=useState([])
  const [loading,setLoading]=useState(false)

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true)
    const getLink = api.PTWForm.getPermitShift;
    axiosPrivate
      .get(`${getLink}`)
      .then((res) => {
        console.log(res)
        const resData=res.data.data
   
      const approveFilter=resData.filter(fil=>fil.formStatus===3)
      const action=resData.filter(fil=>fil.formStatus===6)
      
       setCount({
        actionPending:action,
        approved:approveFilter
       })
       setData(res.data.data)
       setBackUp(res.data.data)
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        setData([])
        toast.error(e.response.data.message)
        console.log(e);
      });
  };

  const columns = [
    {
      Header: "Form No",
      accessor: "formNo",
      Cell: ({ cell }) => {
        return (
          // <Link
          //   // to={`/ptwform?id=${cell.row.original.id}&no=${cell.value}`}
          //   style={{
          //     color: "#FD0000",
          //     textDecorationLine: "underline",
          //     textDecorationColor: commonCss.green,
          //     fontWeight: 800,
          //   }}
          // >
          //   {cell.value}
          // </Link>
          <Typography
            sx={{
              fontSize: "inherit",
              fontWeight: "inherit",
              color: "inherit",
            }}
          >
            {cell.value }
          </Typography>
        );
      },
    },
    {
      Header: "Contractor",
      accessor: "contractor",
    },
    {
      Header: "Date",
      accessor: "issueDate",
      Cell: ({ cell }) => {
        return (
          <Typography
            sx={{
              fontSize: "inherit",
              fontWeight: "inherit",
              color: "inherit",
            }}
          >
            {cell.value !== null
              ? moment(cell.value).format("DD/MM/YYYY")
              : null}
          </Typography>
        );
      },
    },
    {
      Header: "Location",
      accessor: "locationText",
    },
    {
      Header: "Status",
      accessor: "formStatusText",
      Cell: ({ cell }) => {
        return (
          <Typography
            sx={{
              fontSize: "inherit",
              fontWeight: "inherit",
              color: "inherit",
            }}
          >
            {cell.row.original.deIsolationStatus &&
            cell.value.toUpperCase() === "CLOSED"
              ? "Deisolation"
              : cell.value}
          </Typography>
        );
      },
    },
     {
          accessor: "isPermitShift",
          disableFilters: true,
          Cell: ({ cell }) => {
            return (
    
              <>
          
              {!cell.row.original.isPIPermitClosed||!cell.row.original.isLPIPermitClosed?
              //cell.row.original.isLeadPermitIssuerApproved&&moment(cell.row.original.piPermitValidUpTo).format("DD/MM/YYYY")<moment().format("DD/MM/YYYY")?
              cell.row.original.isFormExpired?
              <Typography sx={{ fontSize: "inherit", fontWeight: "inherit", color: commonCss.red  }}>
              Expired
            </Typography>:null:null}
              </>
            );
          }
        },
  ];
  const handleCard=(value)=>{
    setData(count[value])
  }
  const handleShowAll=()=>{
    setData(backUp)
  }
  return (
    <>
    {loading?<Loading/>:
    <Box sx={{display:"flex",flexDirection:'column'}}>
    <Grid2 container size={12} mb={"1rem"} spacing={"1rem"}>
    
      <Grid2 size={{xs:12,md:6}}>
      <Card sx={{
        wordWrap: "break-word",
        backgroundColor: "#43d39e",
        padding: "0.8rem 1rem",
        cursor:"pointer",
        // height:"12vh"
     
      }}
      
      onClick={()=>{handleCard("approved")}}>
      
      <Box sx={{display:"flex",justifyContent:"space-between"}}>
        <Box>
        <Typography
            sx={{
              wordWrap: "break-word",
              fontSize: "0.9rem",
              fontWeight: 800,
              color: "white",
              textTransform: "uppercase",
              letterSpacing: "0.3px",
            }}
          >
            Approved
          </Typography>
          <Typography
           
           sx={{
            fontWeight: 600,
            color: "white",
            fontSize:"1.7rem",
            mt:"0.5rem"
          }}
          >
            {count.approved.length}
           
          </Typography>
        </Box>
        <Box >
        <Beenhere  sx={{fontSize:"4rem",color:"white"}} />
        </Box>
      </Box>
      </Card>
      </Grid2>
      <Grid2 size={{xs:12,md:6}}>
      <Card sx={{
        wordWrap: "break-word",
        backgroundColor: "#ffbe0b",
        padding: "0.8rem 1rem",
        cursor:"pointer",
        // height:"12vh"
     
      }}
      
      onClick={()=>{handleCard("actionPending")}}>
      
      <Box sx={{display:"flex",justifyContent:"space-between"}}>
        <Box>
        <Typography
            sx={{
              wordWrap: "break-word",
              fontSize: "0.9rem",
              fontWeight: 800,
              color: "white",
              textTransform: "uppercase",
              letterSpacing: "0.3px",
            }}
          >
            Approved / Action Pending
          </Typography>
          <Typography
           
           sx={{
            fontWeight: 600,
            color: "white",
            fontSize:"1.7rem",
            mt:"0.5rem"
          }}
          >
            {count.actionPending.length}
           
          </Typography>
        </Box>
        <Box >
        <Check  sx={{fontSize:"4rem",color:"white"}} />
        </Box>
      </Box>
      </Card>
      </Grid2>
     
    </Grid2>
    <TableContainerComp
      columns={columns}
      data={data}
      title={"Active Permit"}
      buttonDisable={true}
      filterShow={true}
      showAll={true}
        handleShowAll={handleShowAll}
    />
    </Box>
}
    </>
  );
};

export default ActivePermitShift;

import { Box, Button, Grid2, Typography } from "@mui/material";
import React from "react";
import { commonCss } from "../StyleConfig/CommonCss";

const Confirmation = ({name, onSubmit, handleCloseClick}) => {
  return (
    <Grid2 container>
      <Grid2 size={12} container justifyContent={"center"}>
        <Typography
          fontWeight={400}
          color={"white"}
          sx={{ fontSize: "1rem" }}
        >
          Are you sure do you want to {name}?
        </Typography>
      </Grid2>
      <Grid2 size={12} container justifyContent={"center"}>
        <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",gap:"0.6rem"}}>
          <Button
            type="button"
            variant="contained"
            // disabled={values.isEngineerApproved}
            sx={{
              backgroundColor: commonCss.green,
              borderRadius: commonCss.buttonBorderRadius,
              textTransform: "none",
              padding: "0.6rem 1rem",
              color: commonCss.buttonTextColor,
              // width: "15vw",
            }}
            onClick={onSubmit}
          >
            Yes
          </Button>
          <Button
            type="button"
            variant="contained"
            // disabled={values.isEngineerApproved}
            sx={{
              backgroundColor: commonCss.green,
              borderRadius: commonCss.buttonBorderRadius,
              textTransform: "none",
              padding: "0.6rem 1rem",
              color: commonCss.buttonTextColor,
              // width: "15vw",
            }}
            onClick={handleCloseClick}
          >
            No
          </Button>
        </Box>
      </Grid2>
    </Grid2>
  );
};

export default Confirmation;
